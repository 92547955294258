import { css, SerializedStyles } from '@emotion/react'
import dot from '@/assets/lp/dot.svg'

const style = {
  dotted: css`
    max-width: 100%;
    height: 4px;
    margin: 24px 0;
    background: url(${dot});
    background-size: 10px 4px;
    background-position: left top;
    background-repeat: repeat-x;
  `,
}

export const Separator = (prop: { rootCss?: SerializedStyles }) => {
  return <div css={[style.dotted, prop.rootCss]} />
}
