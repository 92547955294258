import { Button } from 'antd'
import { useState } from 'react'
import { ContactSuspended, YesNoDialogBase } from 'inheritance-components'

export type ContactSuspendEventHandler = (arg: { suspended: boolean }) => Promise<{ ok: boolean }>

export const PublicInfoContactSuspendedButton = ({
  contactSuspended,
  onContactSuspend,
}: {
  contactSuspended: Exclude<ContactSuspended, undefined>
  onContactSuspend: ContactSuspendEventHandler
}) => {
  const [dialogOpen, setDialogOpen] = useState<'contactSuspend' | 'contactUnsuspend' | undefined>(undefined)

  const closeDialog = () => setDialogOpen(undefined)

  const onContactUnsuspendDialogOpen = () => {
    setDialogOpen('contactUnsuspend')
  }

  const onContactSuspendDialogOpen = () => {
    setDialogOpen('contactSuspend')
  }

  return (
    <>
      {/* 停止用ダイアログ */}
      <YesNoDialogBase
        open={dialogOpen === 'contactSuspend'}
        title="問い合わせ受付を停止"
        iconColor="yellow"
        content="問い合わせの受付を停止すると、メールや電話の問い合わせが届かなくなります。"
        cancelText="キャンセル"
        onCancel={closeDialog}
        okText="停止する"
        onOk={async () => {
          const { ok } = await onContactSuspend({ suspended: true })
          if (ok) closeDialog()
        }}
      />

      {/* 再開用ダイアログ */}
      <YesNoDialogBase
        open={dialogOpen === 'contactUnsuspend'}
        title="問い合わせ受付を再開"
        iconColor="yellow"
        content="問い合わせの受付を再開すると、メールや電話の問い合わせが届くようになります。"
        cancelText="キャンセル"
        onCancel={closeDialog}
        okText="再開する"
        onOk={async () => {
          const { ok } = await onContactSuspend({ suspended: false })
          if (ok) closeDialog()
        }}
      />

      {contactSuspended === 'suspended' ? (
        <Button onClick={onContactUnsuspendDialogOpen}>問い合わせ受付を再開する</Button>
      ) : (
        <Button onClick={onContactSuspendDialogOpen}>問い合わせ受付を停止する</Button>
      )}
    </>
  )
}
