import { ENV } from '@/config/environment'
import { notification } from 'antd'
import { Authenticator, ProvidorType } from 'inheritance-auth'
export * from 'inheritance-auth'
export * from './hook'

let authenticator: Authenticator | undefined = undefined

export const getAuthenticator = (providorType: ProvidorType = 'email'): Authenticator | undefined => {
  if (authenticator) return authenticator

  const apiKey = ENV.FIREBASE_API_KEY
  const authDomain = ENV.FIREBASE_AUTH_DOMAIN
  const tenantId = providorType === 'email' ? ENV.GIP_TENANT_ID : null

  try {
    authenticator = new Authenticator(apiKey, authDomain, tenantId)
    return authenticator
  } catch (e) {
    // 環境変数が揃っていれば、基本的にAuthenticatorのconstructが失敗することはない
    // Storybookでのページ表示用で型を揃えるために、エラーハンドリングし、失敗したらundefinedを返す
    console.error(e)
    notification.error({ message: '認証情報の取得に失敗しました' })
    return undefined
  }
}
