import { TopBottomLayout } from 'inheritance-components'
import { ReactNode } from 'react'
import { GlobalHeader } from './GlobalHeader'

type Props = {
  children: ReactNode
  pageHeader?: ReactNode
}

/**
 * using Top-Bottom Layout
 * @see: https://ant.design/docs/spec/layout#2.-Top-Bottom-Layout
 * */
export const AppLayout = (props: Props) => {
  return (
    <TopBottomLayout globalHeader={<CustomAppHeader pageHeader={props.pageHeader} />}>{props.children}</TopBottomLayout>
  )
}

const CustomAppHeader = ({ pageHeader }: Pick<Props, 'pageHeader'>) => {
  return (
    <>
      <GlobalHeader />
      {pageHeader}
    </>
  )
}
