import { TextsWithNewline } from '../../text'
import {
  ContractApplicationDetails,
  ContractApplicationScreeningQuestionDetails,
  InvitationCode,
} from 'inheritance-api'
import { GenericDescriptionGroupList, DescriptionItemGroupType } from '../generic'
import { contractApiSchemaToItemGroup } from './convert'
import { ContractDescriptionValue } from './type'

type ContractDetailProps =
  | {
      kind: 'normalized'
      itemGroups: DescriptionItemGroupType<ContractDescriptionValue>[]
    }
  | {
      kind: 'api-schema'
      contractDetail: ContractApplicationDetails
      screeningQuestion: ContractApplicationScreeningQuestionDetails
      invitationCode?: InvitationCode
    }

export const ContractDetail = (props: ContractDetailProps) => {
  const itemGroups =
    props.kind === 'normalized'
      ? props.itemGroups
      : contractApiSchemaToItemGroup(props.contractDetail, props.screeningQuestion, props.invitationCode)

  return (
    <GenericDescriptionGroupList<ContractDescriptionValue>
      itemGroups={itemGroups}
      renderDescriptionItem={(value) => <TextsWithNewline text={value} />}
    />
  )
}
