import { isGooglebot } from './utils/google-bot'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

type Brand = {
  readonly brand: string
  readonly version: string
}

type NavigatorUAData = {
  readonly brands: Brand[]
  readonly mobile: boolean
  readonly platform: string
}

/**
 * navigatorをinterfaceで拡張する
 */
declare global {
  interface Navigator {
    userAgentData: NavigatorUAData
  }
}

type MobileOS = 'iOS' | 'Android' | 'other'
// NOTE: only for storybook
export type DebugMode = MobileOS | undefined

type UserAgent = {
  size: {
    isSpClient: boolean
    isPcClient: boolean
  }
  mobileOS: MobileOS
  /**
   * https://developers.google.com/search/docs/crawling-indexing/overview-google-crawlers?hl=ja#common-crawlers
   */
  isGooglebot: boolean
  isIOSSafari: boolean
}

const initialValue: UserAgent = {
  size: {
    /**
     * 初期値だけpc/spともにfalseにしている。
     * Nextでは、SSR時にどちらかに判定してしまうと、
     * クライアントレンダリング時にSP→PCの画面切り替えが発生してしまうため。
     */
    isSpClient: false,
    isPcClient: false,
  },
  mobileOS: 'other',
  isGooglebot: false,
  isIOSSafari: false,
}

const iOSRegex = /iPhone|iPad|iPod/
const AndroidRegex = /Android/

const getMobileOS = (debugMode: DebugMode): MobileOS => {
  if (debugMode) return debugMode

  const uaData = navigator.userAgentData

  if (uaData) {
    // Safari以外でのiOSはサポート対象ではないが、一応ケア
    if (uaData.platform.match(iOSRegex)) return 'iOS'
    if (uaData.platform.match(AndroidRegex)) return 'Android'
    return 'other'
  } else {
    // Safari/FirefoxはuserAgentData非対応
    const ua = navigator.userAgent
    if (ua.match(iOSRegex)) return 'iOS'
    if (ua.match(AndroidRegex)) return 'Android'
    return 'other'
  }
}

const isIOSSafari = (): boolean => {
  // iOS Safariの場合にtrueを返す
  const ua = navigator.userAgent
  return !!(ua.match(iOSRegex) && ua.match(/Safari/))
}

const getUserAgentSize = () => {
  const ua = navigator.userAgent
  // iPadはPC扱い
  const isMobile = ua.match(/iPhone|Android.+Mobile/) ? true : false
  return {
    isSpClient: isMobile,
    isPcClient: !isMobile,
  }
}

const getSize = (debugMode: DebugMode): UserAgent['size'] => {
  if (debugMode) {
    return {
      isSpClient: debugMode !== 'other',
      isPcClient: debugMode === 'other',
    }
  }

  if (navigator.userAgentData) {
    const uaData = navigator.userAgentData

    if (uaData.brands.length === 0 && !uaData.platform) {
      // 特定の端末でbrannds, platformがない場合があるためua判定で対応
      return getUserAgentSize()
    }

    return {
      isSpClient: uaData.mobile,
      isPcClient: !uaData.mobile,
    }
  } else {
    // Safari/FirefoxはuserAgentData非対応
    return getUserAgentSize()
  }
}

const userAgentCtx = createContext(initialValue)

export const UserAgentProvider = ({ children, debugMode }: { children: ReactNode; debugMode?: DebugMode }) => {
  const [state, setState] = useState(initialValue)

  useEffect(() => {
    setState({
      size: getSize(debugMode),
      mobileOS: getMobileOS(debugMode),
      isGooglebot: isGooglebot(),
      isIOSSafari: isIOSSafari(),
    })
  }, [debugMode])

  return <userAgentCtx.Provider value={state}>{children}</userAgentCtx.Provider>
}

export const useUserAgent = () => {
  return useContext(userAgentCtx)
}
