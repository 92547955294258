import { createContext, ReactNode, useContext } from 'react'
import { designToken } from './designToken'
import { useProAndMgmtDesignToken } from 'inheritance-components/src/components/design-system/token'

const designTokenCtx = createContext(designToken)

export const DesignTokenProvider = ({
  children,
  ctxValue = designToken,
}: {
  children: ReactNode
  ctxValue?: typeof designToken
}) => {
  return <designTokenCtx.Provider value={ctxValue}>{children}</designTokenCtx.Provider>
}

export const useDesignToken = () => {
  const proAndMgmtToken = useProAndMgmtDesignToken()
  const token = useContext(designTokenCtx)
  return {
    ...proAndMgmtToken,
    ...token,
  }
}
