import { TextsWithNewline } from '../../text'
import { DescriptionItemType, GenericDescriptionGroup, GenericDescriptionList } from '../generic'
import { churnToItems, forceChurnToItems } from './convert'
import { ChurnDescriptionValue, ChurnType, ForceChurnType } from './type'

type ChurnDetailProps =
  | (
      | {
          kind: 'normalized'
          items: DescriptionItemType<ChurnDescriptionValue>[]
        }
      | {
          kind: 'schema'
          churnDetail: ChurnType
        }
    ) & {
      title?: string
    }

export const ChurnDetail = ({ title, ...props }: ChurnDetailProps) => {
  const items = props.kind === 'normalized' ? props.items : churnToItems(props.churnDetail)

  const renderDescriptionItem = (value: ChurnDescriptionValue) => <TextsWithNewline text={value} />

  if (title) {
    return (
      <GenericDescriptionGroup<ChurnDescriptionValue>
        group={{ title, items }}
        renderDescriptionItem={renderDescriptionItem}
      />
    )
  }

  return <GenericDescriptionList<ChurnDescriptionValue> items={items} renderDescriptionItem={renderDescriptionItem} />
}

export const ForceChurnDetail = ({ forceChurnDetail }: { forceChurnDetail: ForceChurnType }) => {
  const renderDescriptionItem = (value: ChurnDescriptionValue) => <TextsWithNewline text={value} />

  return (
    <GenericDescriptionGroup<ChurnDescriptionValue>
      group={{ title: '強制解約情報', items: forceChurnToItems(forceChurnDetail) }}
      renderDescriptionItem={renderDescriptionItem}
    />
  )
}
