import { useRpcContext } from '@/rpc/RpcContext'
import { getErrObjForLogging } from '@/share/error'

export const useHandleRecaptchaError = () => {
  const { callPostProfessionalError } = useRpcContext()

  const handleRecaptchaError = async (error: Error) => {
    await callPostProfessionalError({ data: getErrObjForLogging(error) })
  }

  return {
    handleRecaptchaError,
  }
}
