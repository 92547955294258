export const SWR_KEYS = {
  professionalCategories: 'professionalCategories',
  contractApplicationEmailValidation: 'contractApplicationEmailValidation',
  additionalInfo: 'additionalInfo',
  supportServicesMaster: 'supportServicesMaster',
  postProfessionalPasswordResetSendEmail: 'postProfessionalPasswordResetSendEmail',
  getProfessionalPasswordResetValidate: 'getProfessionalPasswordResetValidate',
  placementApplicationFormValue: 'placementApplicationFormValue',
  putProfessionalPlacement: 'putProfessionalPlacement',
  professional: 'professional',
  userVisitData: 'userVisitData',
  createAccount: 'createAccount',
  callApiVersion: 'callApiVersion',
  error: 'error',
  serviceInformation: 'serviceInformation',
} as const
