// eslint-disable-next-line node/no-extraneous-import
import { z } from 'zod'

// サービスお知らせ（メンテナンス情報など）
export const ServiceInformationSchema = z.object({
  information: z.array(
    z.object({
      // お知らせの文言：改行ごとに要素を切ってセットされている。
      lines: z.array(z.string()),
    })
  ),
})
export type ServiceInformation = z.infer<typeof ServiceInformationSchema>
