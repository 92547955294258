import { createContext, ReactNode, useContext } from 'react'
import { designToken } from './designToken'

const designTokenCtx = createContext(designToken)

export const GlobalDesignTokenProvider = ({
  children,
  ctxValue = designToken,
}: {
  children: ReactNode
  ctxValue?: typeof designToken
}) => {
  return <designTokenCtx.Provider value={ctxValue}>{children}</designTokenCtx.Provider>
}

const DEFAULT_BREAK_POINT = 1024

export const useGlobalDesignToken = (breakpoint: number = DEFAULT_BREAK_POINT) => {
  const token = useContext(designTokenCtx)

  return {
    ...token,
    mediaQuery: token.mediaQuery(breakpoint),
    breakpoint,
  }
}
