import { getPath } from '@/router'
import { css } from '@emotion/react'
import { notification, Layout } from 'antd'
import { RecaptchaV2Invisible, getRecaptchaToken, RecaptchaCallbackArg } from 'inheritance-recaptcha'
import { useState } from 'react'
import { ENV } from '@/config/environment'
import { useNavigate } from 'react-router-dom'
import { resetRecaptcha } from 'inheritance-recaptcha/src/RecaptchaV2Invisible'
import { useRpcContext } from '@/rpc/RpcContext'
import { useHandleRecaptchaError } from '@/share/recaptcha/hook'
import { getErrMsg } from '@/share/error'

import { Hero } from './components/Hero'
import { Demands } from './components/Demands'
import { Introduction } from './components/Introduction'
import { ServiceFeature } from './components/ServiceFeature'
import { FooterForm } from './components/FooterForm'
import { FormType } from './components/EmailForm'
import { CopyRightFooter } from '@/share/components/CopyRightFooter/CopyRightFooter'
import bk_logo_purpose from '@/assets/lp/bk_logo_purpose.svg'
import bk_logo from '@/assets/lp/bk_logo.svg'
import { useDesignToken } from '@/share/components/design-system/token'
import { RevisedAdvertiserAgreementAlert } from './components/RevisedAdvertiserAgreementAlert'

const useStyles = () => {
  const { layout, topBottomLayout, color, antdThemeColor, themeColor } = useDesignToken()

  return {
    header: css`
      padding-left: ${topBottomLayout.sideMargin};
      padding-right: ${topBottomLayout.sideMargin};
      background-color: ${color.background.white} !important; // antd custom style 上書き
      padding-inline: 0 !important; // antd style 上書き
    `,
    headerContent: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: ${layout.globalHeaderHeight}px;
      margin: 0 auto;
      max-width: ${layout.minWidth}px;
      color: ${themeColor.text.black};
    `,
    logoLeft: css`
      margin-left: 88px;
    `,
    logoRight: css`
      margin-right: 88px;
    `,
    layout: {
      background: css`
        min-width: ${layout.minWidth}px;
        background-color: ${antdThemeColor.token.colorBgLayout};
      `,
      content: css`
        position: relative;
        width: 100%;
        max-width: ${layout.contentMaxWidth}px;
        margin: 0 auto;
        background-color: ${color.background.white};
      `,
    },
    recaptcha: css`
      position: fixed;
      top: 100px;
      left: 0;
    `,
  }
}

const useContractApplicationMutation = () => {
  const navigate = useNavigate()
  const { callSendEmailForContractApplication } = useRpcContext()
  const [mutating, setMutating] = useState(false)

  const mutate = async (recaptchaToken: string, formValues: FormType) => {
    setMutating(true)
    const { email: emailAddress } = formValues

    const { error } = await callSendEmailForContractApplication({ emailAddress, recaptchaToken })
    setMutating(false)

    resetRecaptcha()

    if (error) {
      notification.open({
        message: '利用の申し込みに失敗しました',
        description: getErrMsg({ error }),
        placement: 'top',
      })
      return
    }

    navigate({ pathname: getPath('lpEmailSent'), search: `?email=${encodeURIComponent(emailAddress)}` })
  }
  return { mutating, mutate }
}

type FormValue = {
  emailTop: string
  emailBottom: string
  emailRecaptchaCallbackValue: string
}

export const LandingPage = () => {
  const styles = useStyles()
  const { mutating, mutate } = useContractApplicationMutation()
  const [formValue, setFormValue] = useState<FormValue>({
    emailTop: '',
    emailBottom: '',
    emailRecaptchaCallbackValue: '',
  })
  const { handleRecaptchaError } = useHandleRecaptchaError()

  const onEmailTopChange = (value: string) => setFormValue({ ...formValue, emailTop: value })
  const onEmailBottomChange = (value: string) => setFormValue({ ...formValue, emailBottom: value })

  const onSubmit = async (value: string) => {
    setFormValue({ ...formValue, emailRecaptchaCallbackValue: value })
    const token = await getRecaptchaToken()
    if (!token) return
    await mutate(token, { email: value })
  }

  const recaptchaCallback = async (arg: RecaptchaCallbackArg) => {
    if (arg.type === 'verified') {
      await mutate(arg.token, { email: formValue.emailRecaptchaCallbackValue })
      return
    }
    await handleRecaptchaError(arg.error)
  }

  return (
    <Layout css={styles.layout.background}>
      <Layout.Header css={styles.header}>
        <div css={styles.headerContent}>
          <img css={styles.logoLeft} src={bk_logo_purpose} alt="MFUG 世界が進むチカラになる。" />
          <img css={styles.logoRight} src={bk_logo} alt="三菱UFJ銀行" />
        </div>
      </Layout.Header>

      <Layout.Content css={styles.layout.content}>
        <RevisedAdvertiserAgreementAlert />
        <Hero emailFormProps={{ email: formValue.emailTop, onEmailChange: onEmailTopChange, onSubmit, mutating }} />
        <Demands />
        <Introduction />
        <ServiceFeature />
        <FooterForm
          emailFormProps={{ email: formValue.emailBottom, onEmailChange: onEmailBottomChange, onSubmit, mutating }}
        />
        <CopyRightFooter showLogo={false} />
      </Layout.Content>

      <div css={styles.recaptcha}>
        <RecaptchaV2Invisible callback={recaptchaCallback} siteKey={ENV.RECAPTCHA_SITE_KEY} />
      </div>
    </Layout>
  )
}
