import { ReactNode, useEffect, useState, Suspense } from 'react'
import { getPath } from '@/router'
import { useLocation, Navigate } from 'react-router-dom'
import { getIdTokenByUser } from '@/auth'
import { Loading } from 'inheritance-components'
import { useAuthContext } from './AuthContext'
import { ENV } from '@/config/environment'

export const AuthGuard = (props: {
  children: ReactNode
  isLoginPage?: boolean
  isPasswordResetConfirmPage?: boolean
  isCreateAccountConfirmPage?: boolean
}) => {
  const [isAuthStateChanged, setIsAuthStateChanged] = useState(false)
  const [idToken, setIdToken] = useState<string | undefined>(undefined)
  const [idTokenByPhoneAuth, setIdTokenByPhoneAuth] = useState<string | undefined>(undefined)
  const location = useLocation()
  const { getAuthenticator } = useAuthContext()
  const isRequirePhoneAuthRoute = props.isPasswordResetConfirmPage || props.isCreateAccountConfirmPage

  useEffect(() => {
    const authenticator = getAuthenticator()
    if (!authenticator) throw new Error('Failed to get authenticator')

    const unsubscribe = authenticator.auth.onAuthStateChanged(async (user) => {
      // idTokenを取得し、認証が必要なページへのアクセスを許可する
      if (user) {
        // email/password認証プロバイダでログイン済みの場合
        if (user.tenantId === ENV.GIP_TENANT_ID && user.emailVerified) {
          setIdToken(await getIdTokenByUser(user))

          // phoneNumber認証プロバイダでログイン済みの場合
        } else if (!user.emailVerified && isRequirePhoneAuthRoute) {
          setIdTokenByPhoneAuth(await getIdTokenByUser(user))
        }
      }
      setIsAuthStateChanged(true)
      unsubscribe()
    })
    return unsubscribe()
  }, [getAuthenticator, location, isRequirePhoneAuthRoute])

  if (!isAuthStateChanged) {
    return <Loading />
  } else {
    if (props.isLoginPage) {
      return idToken ? <Navigate to={getPath('home')} /> : <Suspense fallback={<Loading />}>{props.children}</Suspense>
    } else if (isRequirePhoneAuthRoute) {
      return idTokenByPhoneAuth ? (
        <Suspense fallback={<Loading />}>{props.children}</Suspense>
      ) : (
        <Navigate to={getPath('login')} />
      )
    } else {
      return idToken ? <Suspense fallback={<Loading />}>{props.children}</Suspense> : <Navigate to={getPath('login')} />
    }
  }
}
