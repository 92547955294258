import { Form, TimePicker, TimePickerProps, Typography } from 'antd'
import { FormLabel } from './FormLabel'
import { Rule } from 'antd/es/form'
import { judgeRequired } from './judgeRequired'
import { FormLabelWithGuideText } from './FormLabelWithGuildeText'
import { css } from '@emotion/react'

type RangePickerFormProps = {
  label: string
  name: string
  rules: Rule[]
  placeholder?: {
    from: string
    to: string
  }
  annotation?: string
  guideText?: string
  colon?: boolean
} & Pick<TimePickerProps, 'minuteStep' | 'format' | 'style'>

export const RangePickerForm = ({
  label,
  name,
  rules,
  placeholder,
  minuteStep,
  format,
  style,
  guideText,
  colon,
  annotation,
}: RangePickerFormProps) => {
  const styles = {
    annotation: css`
      display: block;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      margin-top: 4px;
    `,
  }

  return (
    <Form.Item
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      name={name}
      rules={rules}
      // labelでrequiredを表現するため「*」は不要
      required={false}
      extra={annotation && <Typography.Text css={styles.annotation}>{annotation}</Typography.Text>}
      colon={colon}
      style={guideText ? { marginBottom: 0 } : {}}
    >
      <TimePicker.RangePicker
        placeholder={placeholder ? [placeholder.from, placeholder.to] : undefined}
        minuteStep={minuteStep}
        format={format}
        style={style}
      />
    </Form.Item>
  )
}
