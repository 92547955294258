import { AppBaseError } from './AppBaseError'

// ステータスコード系
// 400
export class BadRequestError extends AppBaseError {}
// アカウント作成APIで既にEmailが使われている時に発生するエラー
export class EmailAlreadyInUseError extends AppBaseError {}

// 500
export class InternalServerError extends AppBaseError {}

// ネットワークエラー
export class NetworkError extends AppBaseError {}

// 認証系
export class AuthenticationError extends AppBaseError {}

// その他のエラー
export class SystemError extends AppBaseError {}

// 操作競合
export class ConflictError extends AppBaseError {}
