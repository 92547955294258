import { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { ImageProps } from 'inheritance-utils'

export type ImageFetcher = () => Promise<File | undefined>

export const ImageLoader = ({ imageProps, imageFetcher }: { imageProps: ImageProps; imageFetcher: ImageFetcher }) => {
  const thumbnailCss = css`
    width: auto;
    height: ${imageProps.thumbnailHeight}px;
    min-width: ${imageProps.thumbnailWidth}px;
    max-width: ${imageProps.thumbnailWidth}px;
    max-height: ${imageProps.thumbnailHeight}px;
  `
  const [src, setSrc] = useState<string | undefined>(undefined)

  useEffect(() => {
    let objectURL: string | undefined

    const loadImage = async () => {
      const file = await imageFetcher()
      objectURL = file ? URL.createObjectURL(file) : undefined

      setSrc(objectURL)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadImage()

    return () => {
      if (objectURL) URL.revokeObjectURL(objectURL)
    }
  }, [imageFetcher])

  return <img src={src} css={thumbnailCss} />
}
