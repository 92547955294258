import { getApiAuthMasterClient } from '../client'
import { ProfessionalCategoryCode } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
export type { ProfessionalCategoryCode } from 'inheritance-api'

export const callGetSupportServiceMaster = async (professionalCategoryCode: ProfessionalCategoryCode) => {
  try {
    const apiClient = await getApiAuthMasterClient()
    const { data: supportServices } = await apiClient.getMasterSupportServices(professionalCategoryCode, {
      secure: true,
    })
    return { supportServices }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
