import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { Typography } from 'antd'

export const CreateAccountURLExpired = () => {
  return (
    <ErrorScreen
      kind="composition"
      title="有効期限が切れています"
      content={
        <>
          <Typography.Paragraph type="secondary">
            再度アカウント作成するには、運営までご連絡ください。
          </Typography.Paragraph>
        </>
      }
    />
  )
}
