import { Rule } from 'antd/lib/form'

export const EIGHT_BIT_STRING_MAX_LEN = 255
const EMAIL_MAX_LEN = 254

function assertString(value: unknown): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error('不正な値です')
  }
}

const passwordConfirm: Rule = ({ getFieldValue }) => ({
  async validator(_, value) {
    if (!value || getFieldValue('password') === value) {
      return
    }
    throw new Error('パスワードが一致しません')
  },
})

const emailConfirm: Rule = ({ getFieldValue }) => ({
  async validator(_, value) {
    if (!value || getFieldValue('email') === value) {
      return
    }
    throw new Error('メールアドレスが一致しません')
  },
})

const createMobileTelConfirmError = () => new Error('携帯電話番号が一致しません')

const composeMobileTelConfirm = (name: string) => {
  const mobileTelConfirm: Rule = ({ getFieldValue }) => ({
    async validator(_, value) {
      if (!value) return

      // 確認用電話番号があって非確認番号がない場合は、不一致エラーにする
      const mobileTel = getFieldValue(name)
      if (!mobileTel) throw createMobileTelConfirmError()

      assertString(mobileTel)
      assertString(value)

      if (mobileTel.replaceAll(' ', '') === value.replaceAll(' ', '')) return

      throw createMobileTelConfirmError()
    },
  })

  return mobileTelConfirm
}

const maxNumber = (max: number) => {
  const maxNumberConfirm: Rule = () => ({
    async validator(_, value) {
      if (!value) return

      if (value > max) {
        throw new Error(`${max}以内で入力してください`)
      }
    },
  })

  return maxNumberConfirm
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RuleFactory = (...args: any[]) => Rule

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRules = (rules: any[]) => {
  // 全項目でチェックする内容を追加
  return rules.concat([RULES.tab, RULES.backslash, RULES.doubleQuote, RULES.controlCode])
}

export const RULES = {
  tab: { pattern: new RegExp('^[^\\t]*$'), message: 'タブは入力できません' },
  backslash: { pattern: new RegExp('^[^\\\\]*$'), message: '\\ は入力できません' },
  doubleQuote: { pattern: new RegExp('^[^"]*$'), message: '" は入力できません' },
  /* eslint no-control-regex: off */
  controlCode: { pattern: new RegExp('^[^\\x00-\\x08\\x0B-\\x1F\\x7F]*$'), message: '不正な文字が入力されています' },
  requireEntry: { required: true, message: '入力して下さい' },
  requireSelection: { required: true, message: '選択してください' },
  kana: {
    /**
     * Katakana (https://unicode.org/charts/PDF/U30A0.pdf)
     * - \u30A0: "゠" KATAKANA-HIRAGANA DOUBLE HYPHEN (二重ハイフン、二分二重ダッシュ)
     * - \u30FF: "ヿ" KATAKANA DIGRAPH KOTO (コト)
     */
    pattern: new RegExp('^[\u30A0-\u30FF]+$'),
    message: '全角カタカナで入力して下さい',
  },
  corporateNameKana: {
    // 半角・全角スペースも入力可
    pattern: new RegExp('^[\u30A0-\u30FF\u{20}\u{3000}]+$'),
    message: '全角カタカナで入力して下さい',
  },
  postCodeFirst: { pattern: new RegExp('^([0-9]){3}$'), message: '半角数字3文字で入力して下さい' },
  postCodeSecond: { pattern: new RegExp('^([0-9]){4}$'), message: '半角数字4文字で入力して下さい' },
  email: { type: 'email', message: 'メールアドレスの形式に誤りがあります' },
  emailConfirm,
  passwordPattern: {
    /**
     * 半角英数字記号で8文字以上
     * 大文字、小文字、数字、記号（@ # $ % & ? ! - _）を含む
     */
    pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&?!_-])[a-zA-Z0-9@#$%&?!_-]{8,}$/),
    message: 'パスワードの形式をご確認下さい',
  },
  passwordConfirm,
  tel: {
    async validator(_, value) {
      if (!value) {
        return
      }

      assertString(value)

      const regexp = new RegExp('^([0-9]){10,11}$')
      if (!regexp.test(value.replaceAll(' ', ''))) {
        throw new Error('電話番号の形式に誤りがあります')
      }
    },
  },
  mobileTel: {
    async validator(_, value) {
      if (!value) {
        return
      }

      assertString(value)

      const regexp = new RegExp('^0[7-9]{1}0[0-9]{8}$')
      if (!regexp.test(value.replaceAll(' ', ''))) {
        throw new Error('携帯電話番号の形式に誤りがあります')
      }
    },
  },
  composeMobileTelConfirm,
  contractApplicationId: {
    async validator(_, value) {
      if (!value) {
        return
      }
      const regexp = new RegExp('^PCA([0-9]){12}$', 'i')
      if (!regexp.test(value)) {
        throw new Error('利用申込IDの形式に誤りがあります')
      }
    },
  },
  alphabetNumber: {
    pattern: new RegExp(/^[a-zA-Z0-9]*$/),
    message: '半角英数字で入力して下さい',
  },
  requireNumber: { pattern: new RegExp('^([0-9])+$'), message: '半角数字で入力して下さい' },
  requireCheck: {
    async validator(_, value) {
      if (!value) {
        throw new Error('チェックして下さい')
      }
    },
  },
  /**
   * @param maxLen default: EIGHT_BIT_STRING_MAX_LEN
   */
  maxLen: (maxLen: number = EIGHT_BIT_STRING_MAX_LEN): Rule => ({
    max: maxLen,
    message: `${maxLen}文字以内で入力してください`,
  }),
  emailMaxLen: { max: EMAIL_MAX_LEN, message: `${EMAIL_MAX_LEN}文字以内で入力してください` },
  url: {
    async validator(_, value) {
      if (!value) {
        return
      }

      try {
        new URL(value)
      } catch (_) {
        throw new Error('URLの形式に誤りがあります')
      }

      const pattern = /^https?:\/\//

      if (!pattern.test(value)) {
        throw new Error('「https://」または「http://」で始まるURLを入力してください')
      }

      const blank = /\s/

      if (blank.test(value)) {
        throw new Error('URLに空白が含まれています')
      }
    },
  },
  maxNumber,
  notBlank: {
    async validator(_, value) {
      const pattern = /^\s+$/

      if (pattern.test(value)) {
        throw new Error('空白以外の文字を入力して下さい')
      }
    },
  },
} as const satisfies Record<string, Rule | RuleFactory>
