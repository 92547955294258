import { css } from '@emotion/react'
import { Button } from 'antd'
import { ReactNode } from 'react'
import { Typography } from 'antd'

const styles = {
  pinContainer: css`
    padding: 48px 0;
    text-align: center;
  `,
  pinHeading: css`
    font-weight: normal !important;
    margin-bottom: 16px;
  `,
  pinDescription: css`
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  `,
  pinResendContainer: css`
    padding-top: 32px;
    margin-top: 48px;
    border-top: 1px solid #f0f0f0;
  `,
  pinResendDescription: css`
    display: block;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 24px;
  `,
}

export type PinInputLayoutProps = {
  header: ReactNode
  pinInput: ReactNode
  buttonArea: {
    description: string
    button: {
      label: string
      to: string
    }
  }
}

export const PinInputLayout = (props: PinInputLayoutProps) => {
  return (
    <>
      {props.header}
      <div css={styles.pinContainer}>
        <Typography.Title level={4} css={styles.pinHeading}>
          認証コードを入れてください
        </Typography.Title>
        <p css={styles.pinDescription}>SMSで受信した6桁の認証コードを入力してください。</p>
        {props.pinInput}
        <div css={styles.pinResendContainer}>
          <p css={styles.pinResendDescription}>{props.buttonArea.description}</p>
          <Button htmlType="button" href={props.buttonArea.button.to}>
            {props.buttonArea.button.label}
          </Button>
        </div>
      </div>
    </>
  )
}
