import { useEffect } from 'react'
import { routes } from '@/router'
import { useLocation } from 'react-router-dom'
import { designToken } from 'inheritance-components/src/components/design-system/token'

export const TITLES = {
  lp: 'そうぞくガイドへの広告掲載をご希望の方へ',
  others: 'そうぞくガイド　専門家サービス',
} as const

export const useGetTitle = (): string => {
  const { pathname } = useLocation()

  if (pathname.startsWith(routes.lp.path)) {
    return TITLES.lp
  } else {
    return TITLES.others
  }
}

export const useTitleChangeEffect = () => {
  const title = useGetTitle()

  useEffect(() => {
    document.title = title
  }, [title])
}

/**
 * 画面横幅が `designToken.layout.minWidth` より狭い場合、
 * - PC: コンテンツは等倍表示で、横にスクロールさせる
 * - SP: コンテンツを縮小表示し、横にスクロールさせない
 */
export const VIEWPORT_CONTENT = {
  pc: 'width=device-width, initial-scale=1.0',
  sp: `width=${designToken.layout.minWidth}`,
} as const

export const getViewportMeta = (): string => {
  return window.innerWidth > designToken.layout.minWidth ? VIEWPORT_CONTENT.pc : VIEWPORT_CONTENT.sp
}

const setViewportMeta = () => {
  const meta = document.querySelector("meta[name='viewport']")
  if (!meta) return

  const content = getViewportMeta()
  meta.setAttribute('content', content)
}

export const useViewPortResizeEffect = () => {
  useEffect(() => {
    setViewportMeta()
    window.addEventListener('resize', setViewportMeta)

    return () => window.removeEventListener('resize', setViewportMeta)
  }, [])
}
