import { Form, Input } from 'antd'
import { FormLabel } from 'inheritance-components'
import { FormLabelWithGuideText } from 'inheritance-components'

export const ReadOnlyForm = (props: {
  label: string
  name: string
  value: string
  guideText?: string
  colon?: boolean
}) => {
  return (
    <Form.Item
      name={props.name}
      initialValue={props.value}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        props.guideText ? (
          <FormLabelWithGuideText label={props.label} guideText={props.guideText} />
        ) : (
          <FormLabel label={props.label} required />
        )
      }
      colon={props.colon}
      style={props.guideText ? { marginBottom: 0 } : {}}
    >
      <Input type="text" value={props.value} bordered={false} readOnly />
    </Form.Item>
  )
}
