import { PutProfessionalPlacementPreviewResponse, PutProfessionalPlacementRequest } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
import { getApiAuthProfessionalClient } from '../client'

export const callPutProfessionalPreviewPlacement = async ({
  data,
}: {
  data: PutProfessionalPlacementRequest
}): Promise<{ data?: PutProfessionalPlacementPreviewResponse; error?: Error }> => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    return await apiClient.putProfessionalPlacementPreview(data, { secure: true })
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
