import { buildRethrowingError } from '../buildRethrowingError'
import { getApiAuthProfessionalClient } from '../client'

export const callPutProfessionalChurn = async ({
  reason,
}: {
  reason: string[]
}): Promise<{ endDate: string; error: undefined } | { error: Error }> => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    const {
      data: { endDate },
    } = await apiClient.putProfessionalChurn({ reason }, { secure: true })

    return { endDate, error: undefined }
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
