import { PROFILE_CHURN_STATUS } from '@/share/components/PlacementForm/profileStatus'
import {
  BusinessCategory,
  ChurnApplicationDetails,
  GetProfessionalSelfResponse,
  PlacementApplicationStatusCode,
  PublicInformation,
} from 'inheritance-api'
import { ContactSuspended } from 'inheritance-components'

const HOME_CONDITIONS = {
  // 初期画面
  primal: 'primal',

  // 強制解約
  terminated: 'terminated',

  // 掲載情報のみ表示
  'only-placement': 'only-placement',

  // 掲載申込と公開情報をタブ分け表示
  'placement-n-public-tab': 'placement-n-public-tab',

  // 公開情報のみ表示
  'only-public': 'only-public',

  // 公開情報と解約の説明を表示
  'public-n-churn': 'public-n-churn',

  // 公開情報と解約説明、掲載申込を表示
  'placement-n-churn-tab': 'placement-n-churn-tab',
} as const

export type PrimalValueType =
  | {
      homeCondition: typeof HOME_CONDITIONS.primal
      placementEditable: true
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['can-apply']
    }
  | {
      homeCondition: typeof HOME_CONDITIONS.primal
      churnApplication: Exclude<ChurnApplicationDetails, undefined>
      placementEditable: false
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['completed']
    }
  | {
      homeCondition: typeof HOME_CONDITIONS.primal
      forceChurn: Exclude<GetProfessionalSelfResponse['forceChurn'], undefined>
      placementEditable: false
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['terminated']
    }

export type ProfileValueType = { businessCategory?: BusinessCategory } & (
  | {
      homeCondition: (typeof HOME_CONDITIONS)['only-placement']
      placementApplication: Exclude<GetProfessionalSelfResponse['placementApplication'], undefined>
      placementEditable: boolean
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['can-apply']
    }
  | {
      homeCondition: (typeof HOME_CONDITIONS)['placement-n-public-tab']
      placementApplication: Exclude<GetProfessionalSelfResponse['placementApplication'], undefined>
      publicInformation: PublicInformation
      contactSuspended: Exclude<ContactSuspended, undefined>
      placementEditable: boolean
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['can-apply']
    }
  | {
      homeCondition: (typeof HOME_CONDITIONS)['only-public']
      publicInformation: PublicInformation
      contactSuspended: Exclude<ContactSuspended, undefined>
      placementEditable: true
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['can-apply']
    }
  | {
      homeCondition: (typeof HOME_CONDITIONS)['public-n-churn']
      publicInformation: PublicInformation
      contactSuspended: Exclude<ContactSuspended, undefined>
      churnApplication: Exclude<ChurnApplicationDetails, undefined>
      placementEditable: true
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['completed']
    }
  | {
      homeCondition: (typeof HOME_CONDITIONS)['placement-n-churn-tab']
      placementApplication: Exclude<GetProfessionalSelfResponse['placementApplication'], undefined>
      publicInformation: PublicInformation
      contactSuspended: Exclude<ContactSuspended, undefined>
      churnApplication: Exclude<ChurnApplicationDetails, undefined>
      placementEditable: boolean
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['completed']
    }
)

export type HomeValueType = PrimalValueType | ProfileValueType

/**
 * （掲載申込,公開情報,解約申込）を1(有)/0(無)のタプルで表現すると、それぞれの画面表示は以下
 * （0.0.0） --> 初期画面を表示
 * （0.0.1） --> 初期画面を表示
 * （0.1.0） --> 公開情報のみ表示
 * （0.1.1） --> 公開情報と解約説明を表示
 * （1.0.0） --> 掲載申込のみ表示
 * （1.0.1） --> 初期画面を表示
 * （1.1.0） --> 掲載申込と公開情報をタブ分け表示
 * （1.1.1） --> 公開情報と解約説明を表示
 */
export const getTypeSafeHomeValue = (professional: GetProfessionalSelfResponse): HomeValueType => {
  const { contract, placementApplication, publicInformation, churnApplication, forceChurn } = professional

  if (forceChurn) {
    return {
      homeCondition: 'primal',
      forceChurn,
      placementEditable: false,
      profileChurnStatus: 'terminated',
    }
  }

  if (placementApplication && !publicInformation && !churnApplication) {
    const {
      status: { code },
    } = placementApplication

    return {
      homeCondition: 'only-placement',
      businessCategory: contract.businessCategory,
      placementApplication,
      placementEditable: code === 'accepted' || code === 'declined',
      profileChurnStatus: 'can-apply',
    }
  }

  const contactSuspended: Exclude<ContactSuspended, undefined> = professional.contactSuspended
    ? 'suspended'
    : 'unsuspended'

  if (
    placementApplication &&
    placementApplication.status.code !== 'accepted' &&
    publicInformation &&
    !churnApplication
  ) {
    const {
      status: { code },
    } = placementApplication

    return {
      homeCondition: 'placement-n-public-tab',
      businessCategory: contract.businessCategory,
      placementApplication,
      publicInformation,
      contactSuspended,
      placementEditable: judgePlacementEditable(code),
      profileChurnStatus: 'can-apply',
    }
  }

  // この時点でplacementApplication.status.codeはacceptedで確定
  if (publicInformation && !churnApplication) {
    return {
      homeCondition: 'only-public',
      businessCategory: contract.businessCategory,
      publicInformation,
      contactSuspended,
      // 解約申込がなければ、掲載申込は可能
      placementEditable: true,
      profileChurnStatus: 'can-apply',
    }
  }

  if (
    publicInformation &&
    placementApplication &&
    placementApplication.status.code !== 'accepted' &&
    churnApplication
  ) {
    const {
      status: { code },
    } = placementApplication
    return {
      homeCondition: 'placement-n-churn-tab',
      placementApplication,
      publicInformation,
      businessCategory: contract.businessCategory,
      contactSuspended: professional.contactSuspended ? 'suspended' : 'unsuspended',
      churnApplication,
      placementEditable: judgePlacementEditable(code),
      profileChurnStatus: 'completed',
    }
  }

  if (publicInformation && churnApplication) {
    return {
      homeCondition: 'public-n-churn',
      businessCategory: contract.businessCategory,
      publicInformation,
      contactSuspended,
      churnApplication,
      // [INH-834] 解約申込があっても掲載申し込みは可能
      placementEditable: true,
      profileChurnStatus: 'completed',
    }
  }

  if (churnApplication) {
    return {
      homeCondition: 'primal',
      churnApplication,
      placementEditable: false,
      profileChurnStatus: 'completed',
    }
  }

  return {
    homeCondition: 'primal',
    placementEditable: true,
    profileChurnStatus: 'can-apply',
  }
}

/**
 *
 * 掲載申込を編集可能か判定する
 * 公開情報が停止中の時には、編集可能
 */
export const judgePlacementEditable = (placementStatusCode: PlacementApplicationStatusCode): boolean => {
  return placementStatusCode === 'accepted' || placementStatusCode === 'declined'
}
