// eslint-disable-next-line node/no-extraneous-import
import * as z from 'zod'

export const RepresentativeFormStorageSchema = z.object({
  representativeBirthDate0: z.string().optional(),
  representativePostCodeFirst0: z.string().optional(),
  representativePostCodeSecond0: z.string().optional(),
  representativeAddress0: z.string().optional(),
  representativeBuilding0: z.string().optional(),
  representativeSurname0: z.string().optional(),
  representativeGivenName0: z.string().optional(),
  representativeSurnameKana0: z.string().optional(),
  representativeGivenNameKana0: z.string().optional(),
  representativeSurname1: z.string().optional(),
  representativeGivenName1: z.string().optional(),
  representativeSurnameKana1: z.string().optional(),
  representativeGivenNameKana1: z.string().optional(),
  representativeSurname2: z.string().optional(),
  representativeGivenName2: z.string().optional(),
  representativeSurnameKana2: z.string().optional(),
  representativeGivenNameKana2: z.string().optional(),
  representativeSurname3: z.string().optional(),
  representativeGivenName3: z.string().optional(),
  representativeSurnameKana3: z.string().optional(),
  representativeGivenNameKana3: z.string().optional(),
  representativeSurname4: z.string().optional(),
  representativeGivenName4: z.string().optional(),
  representativeSurnameKana4: z.string().optional(),
  representativeGivenNameKana4: z.string().optional(),
  representativeSurname5: z.string().optional(),
  representativeGivenName5: z.string().optional(),
  representativeSurnameKana5: z.string().optional(),
  representativeGivenNameKana5: z.string().optional(),
  representativeSurname6: z.string().optional(),
  representativeGivenName6: z.string().optional(),
  representativeSurnameKana6: z.string().optional(),
  representativeGivenNameKana6: z.string().optional(),
  representativeSurname7: z.string().optional(),
  representativeGivenName7: z.string().optional(),
  representativeSurnameKana7: z.string().optional(),
  representativeGivenNameKana7: z.string().optional(),
  representativeSurname8: z.string().optional(),
  representativeGivenName8: z.string().optional(),
  representativeSurnameKana8: z.string().optional(),
  representativeGivenNameKana8: z.string().optional(),
  representativeSurname9: z.string().optional(),
  representativeGivenName9: z.string().optional(),
  representativeSurnameKana9: z.string().optional(),
  representativeGivenNameKana9: z.string().optional(),
  representativeSurname10: z.string().optional(),
  representativeGivenName10: z.string().optional(),
  representativeSurnameKana10: z.string().optional(),
  representativeGivenNameKana10: z.string().optional(),
  representativeSurname11: z.string().optional(),
  representativeGivenName11: z.string().optional(),
  representativeSurnameKana11: z.string().optional(),
  representativeGivenNameKana11: z.string().optional(),
  representativeSurname12: z.string().optional(),
  representativeGivenName12: z.string().optional(),
  representativeSurnameKana12: z.string().optional(),
  representativeGivenNameKana12: z.string().optional(),
  representativeSurname13: z.string().optional(),
  representativeGivenName13: z.string().optional(),
  representativeSurnameKana13: z.string().optional(),
  representativeGivenNameKana13: z.string().optional(),
  representativeSurname14: z.string().optional(),
  representativeGivenName14: z.string().optional(),
  representativeSurnameKana14: z.string().optional(),
  representativeGivenNameKana14: z.string().optional(),
  representativeSurname15: z.string().optional(),
  representativeGivenName15: z.string().optional(),
  representativeSurnameKana15: z.string().optional(),
  representativeGivenNameKana15: z.string().optional(),
  representativeSurname16: z.string().optional(),
  representativeGivenName16: z.string().optional(),
  representativeSurnameKana16: z.string().optional(),
  representativeGivenNameKana16: z.string().optional(),
  representativeSurname17: z.string().optional(),
  representativeGivenName17: z.string().optional(),
  representativeSurnameKana17: z.string().optional(),
  representativeGivenNameKana17: z.string().optional(),
  representativeSurname18: z.string().optional(),
  representativeGivenName18: z.string().optional(),
  representativeSurnameKana18: z.string().optional(),
  representativeGivenNameKana18: z.string().optional(),
  representativeSurname19: z.string().optional(),
  representativeGivenName19: z.string().optional(),
  representativeSurnameKana19: z.string().optional(),
  representativeGivenNameKana19: z.string().optional(),
})
