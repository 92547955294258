import { PhoneOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, Form, Input } from 'antd'
import { ReactNode } from 'react'
import { getRules, RULES } from '../../form'

const styles = {
  container: css`
    text-align: center;
    padding-bottom: 48px;
  `,
  header: css`
    font-size: 20px;
    line-height: 28px;
    margin: 42px 0;
  `,
  description: css`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  `,
  formItem: css`
    display: inline-block;
    text-align: left;
  `,
  input: css`
    width: 360px;
    height: 40px;
  `,
  button: css`
    margin-top: 40px;
  `,
}

export const PhoneNumberLoginLayout = (props: {
  header: ReactNode
  onSubmit?: (values: { phoneNumber: string }) => void
  onChange?: (value: string) => void
  loading?: boolean
  phoneInputDescription: ReactNode
}) => {
  const [form] = Form.useForm<{ phoneNumber: string }>()

  return (
    <>
      {props.header}
      <div css={styles.container}>
        <h4 css={styles.header}>電話番号を入力してください</h4>
        <p css={styles.description}>{props.phoneInputDescription}</p>
        <Form name="phoneNumberLogin" form={form} onFinish={props.onSubmit}>
          <div>
            <Form.Item name="phoneNumber" rules={getRules([RULES.requireEntry, RULES.mobileTel])} css={styles.formItem}>
              <Input
                css={styles.input}
                prefix={<PhoneOutlined />}
                placeholder="090 1234 5678"
                onChange={(e) => props.onChange?.(e.target.value)}
              />
            </Form.Item>
          </div>

          <Form.Item css={styles.button}>
            <Button type="primary" htmlType="submit" loading={props.loading}>
              SMSに確認コードを送信する
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
