import { getPath } from '@/router'
import { SingleTextInputForm } from 'inheritance-components'
import { css } from '@emotion/react'
import { Typography, Form, Divider, Button, Input, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FormInstance } from 'antd/lib/form'
import { CheckCircleFilled } from '@ant-design/icons'
import { useState } from 'react'
import { getRules, RULES } from 'inheritance-components'
import { useRpcContext } from '@/rpc/RpcContext'
import { getErrMsg } from '@/share/error'
import { splitByNewline } from 'inheritance-utils'
import { composePlacementEndDateDescription, PlacementEndDate } from '@/share/domain/placement/placementEndDate'
import { useDesignToken } from '@/share/components/design-system/token'

const useStyles = () => {
  const { themeColor } = useDesignToken()

  return {
    contentBody: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      max-width: 600px;
      padding-top: 40px;
    `,
    description: css`
      display: block;
      text-align: center;
    `,
    form: css`
      width: 100%;
      margin-top: 32px;
    `,
    divider: css`
      margin: 40px 0 32px;
    `,
    buttonArea: css`
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
      gap: 32px;
    `,
    checkedCircle: css`
      color: ${themeColor.text.success};
      font-size: 80px;
    `,
  }
}

const formNames = {
  churnReason: 'churnReason',
} as const

type Form = {
  [formNames.churnReason]: string
}

const formProps = {
  [formNames.churnReason]: {
    name: formNames.churnReason,
    label: '解約理由',
    placeholder: '解約理由をご記入ください',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    autoSize: { minRows: 2 },
  },
}

export const ChurnApplicationForm = () => {
  const [form] = useForm<Form>()
  const [stepProps, setStepProps] = useState<
    { step: 'edit' | 'confirm' } | { step: 'completed'; placementEndDate: PlacementEndDate }
  >({ step: 'edit' })
  const { callPutProfessionalChurn } = useRpcContext()

  const onSubmit = async () => {
    const res = await callPutProfessionalChurn({
      reason: splitByNewline(form.getFieldValue(formNames.churnReason)),
    })

    if (res.error) {
      notification.open({
        message: '解約申し込みに失敗しました',
        description: getErrMsg({ error: res.error }),
        placement: 'top',
      })
      return
    }

    setStepProps({ step: 'completed', placementEndDate: res.endDate })
  }

  const { step } = stepProps

  const styles = useStyles()

  return (
    <section css={styles.contentBody}>
      {step === 'edit' && <EditForm form={form} onNext={() => setStepProps({ step: 'confirm' })} />}
      {step === 'confirm' && (
        <ConfirmForm form={form} onBack={() => setStepProps({ step: 'edit' })} onSubmit={onSubmit} />
      )}
      {step === 'completed' && <Completed placementEndDate={stepProps.placementEndDate} />}
    </section>
  )
}

const EditForm = ({ form, onNext }: { form: FormInstance<Form>; onNext: () => void }) => {
  const styles = useStyles()
  return (
    <>
      <Typography.Text>解約理由をご記入の上、</Typography.Text>
      <Typography.Text>解約希望日の1ヶ月前までにお申し込みください。</Typography.Text>
      <Typography.Text>解約日は解約申し込み日の翌月末となります。</Typography.Text>
      <Form form={form} labelAlign="left" css={styles.form} onFinish={onNext}>
        <SingleTextInputForm {...formProps['churnReason']} multiple />
        <Divider css={styles.divider} />
        <div css={styles.buttonArea}>
          <Button href={getPath('home')}>キャンセル</Button>
          <Button type="primary" htmlType="submit">
            広告掲載の解約を申し込む
          </Button>
        </div>
      </Form>
    </>
  )
}

const ConfirmForm = ({
  form,
  onBack,
  onSubmit,
}: {
  form: FormInstance<Form>
  onBack: () => void
  onSubmit: () => Promise<void>
}) => {
  const [mutating, setMutating] = useState(false)
  const { name, label } = formProps['churnReason']

  const onFinish = async () => {
    setMutating(true)
    await onSubmit()
    setMutating(false)
  }

  const styles = useStyles()

  return (
    <>
      <Typography.Text>解約理由をご記入の上、</Typography.Text>
      <Typography.Text>解約希望日の1ヶ月前までにお申し込みください。</Typography.Text>
      <Typography.Text>解約日は解約申し込み日の翌月末となります。</Typography.Text>
      <Form form={form} labelAlign="left" css={styles.form} onFinish={onFinish}>
        <Form.Item name={name} label={label} labelCol={{ offset: 2 }}>
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            value={form.getFieldValue(formNames.churnReason)}
            bordered={false}
            readOnly
          />
        </Form.Item>
        <Divider css={styles.divider} />
        <div css={styles.buttonArea}>
          <Button onClick={onBack}>キャンセル</Button>
          <Button type="primary" htmlType="submit" loading={mutating}>
            広告掲載の解約を確定する
          </Button>
        </div>
      </Form>
    </>
  )
}

const Completed = ({ placementEndDate }: { placementEndDate: PlacementEndDate }) => {
  const styles = useStyles()
  return (
    // src/share/components/result-screen/ResultScreen.tsx の子コンポーネント化を検討する
    <>
      <CheckCircleFilled css={styles.checkedCircle} />
      <Typography.Title level={4} css={styles.divider}>
        解約申込を完了しました
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        {composePlacementEndDateDescription(placementEndDate)}
      </Typography.Paragraph>
      <Button type="primary" href={getPath('home')} css={styles.divider}>
        掲載情報に戻る
      </Button>
    </>
  )
}
