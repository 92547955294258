import { Rule } from 'antd/lib/form'
import { FormLabel } from './FormLabel'
import { judgeRequired } from './judgeRequired'
import { css } from '@emotion/react'

const useStyle = () => ({
  label: css`
    flex-direction: column;
    flex: auto;
    height: auto;
    margin-bottom: 5%;
    padding-bottom: 20%;
  `,
  guideText: css`
    font-size: 12px;
    color: #5a5a5a;
    opacity: 0.85;
    margin-top: 8px;
    display: block;
    overflow: visible;
    white-space: normal;
    word-break: normal;
  `,
})

type FormLabelWithGuideTextProps = {
  label: string
  guideText: string
  rules?: Rule[]
  required?: boolean
}

export const FormLabelWithGuideText = ({ label, guideText, rules, required = true }: FormLabelWithGuideTextProps) => {
  const style = useStyle()
  const isRequired = rules ? judgeRequired(rules) : required

  return (
    <div css={style.label}>
      <div>
        <FormLabel label={`${label} :`} required={isRequired} />
      </div>
      {guideText && <div css={style.guideText}>{guideText}</div>}
    </div>
  )
}
