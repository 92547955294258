import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react'
import { css } from '@emotion/react'
import { Input } from 'antd'

const toHalfWidth = (value: string) => {
  return value.replace(/[！-～]/g, (str) => String.fromCharCode(str.charCodeAt(0) - 0xfee0))
}

const styles = {
  container: css`
    display: inline-block;
  `,
  input: css`
    width: 30px;
    margin-right: 24px;
    padding: 4px 0;
    text-align: center;
    ime-mode: inactive;
    &:last-child {
      margin-right: 0;
    }
  `,
}

interface PinInputProps {
  digits: number
  onInputComplete: (pin: string) => void
}

export const PinInput = (props: PinInputProps) => {
  const [pin, setPin] = useState<string[]>([...Array(props.digits)].map(() => ''))

  // 指定の桁のinputをfocusします
  const autoFocus = (nextId: number): void => {
    if (nextId <= props.digits) {
      const element = document.querySelector(`[data-pin-input-id="${nextId}"]`) as HTMLInputElement
      element.focus()
    } else {
      const element = document.querySelector(`[data-pin-input-id="${nextId - 1}"]`) as HTMLInputElement
      element.blur()
    }
  }

  // 1桁の正の整数かどうかを返却します
  const checkIsNumber = (value: string): boolean => {
    return String(value).match(/^[0-9]+$/) ? true : false
  }

  // pinコードが入力完了しているかどうかを返却します
  const checkIsInputCompleted = (pin: string[]): boolean => {
    // 全ての桁が入力されているかチェック
    let isAllInputFilled = true
    pin.forEach((e) => {
      if (!checkIsNumber(e)) isAllInputFilled = false
    })
    if (!isAllInputFilled) return false

    // 最後の桁がフォーカスされているかチェック
    const lastInput = document.querySelectorAll(`[data-pin-input-id]`)[props.digits - 1]
    return lastInput === document.activeElement
  }

  // pinコード入力時のハンドリング
  const onInput = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement
    const input = target.value.slice(-1)
    const inputId = Number(target.getAttribute('data-pin-input-id'))

    // 全角を半角に置換
    const newCode = toHalfWidth(input)
    // 数字以外の入力は何もしない
    if (!checkIsNumber(newCode) && newCode !== '') {
      return
    }
    // pinを更新
    const newCodeArray = pin.map((v, i) => (i === inputId - 1 ? newCode : v))
    setPin(newCodeArray)
    // 入力完了していればpropsの完了イベント呼び出し
    // useStateのpinはレンダリング後まで更新されないのでnewCodeArrayを使っています
    if (checkIsInputCompleted(newCodeArray)) {
      props.onInputComplete(newCodeArray.join(''))
    }

    // 削除以外の更新であれば次の桁に移動
    if (newCode !== '') {
      autoFocus(inputId + 1)
    }
  }

  // キー入力による送信イベント
  const onKeyDown = (e: KeyboardEvent) => {
    const key = ['Enter', 'Tab']
    const isTargetKey = key.includes(e.key) && !e.shiftKey
    if (isTargetKey && checkIsInputCompleted(pin)) {
      props.onInputComplete(pin.join(''))
    }
  }

  // 初回レンダリング
  useEffect(() => {
    autoFocus(1)
    // FIXME: remove eslint-disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div css={styles.container} onKeyDown={onKeyDown}>
      {[...Array(props.digits)].map((_, i) => (
        <Input
          key={i}
          type="tel"
          css={styles.input}
          value={pin[i]}
          onChange={onInput}
          data-pin-input-id={i + 1}
          autoComplete="off"
        />
      ))}
    </div>
  )
}
