import { css } from '@emotion/react'
import { Guideline } from '../../guideline'
import { ContactSuspended } from './type'

const styles = {
  guideline: css`
    margin-bottom: 24px;
  `,
}

export const ContactSuspendedGuideline = ({ contactSuspended }: { contactSuspended: ContactSuspended }) => {
  if (contactSuspended !== 'suspended') return null

  return <Guideline rootCss={styles.guideline} texts={['現在問い合わせ受付を停止しています。']} />
}
