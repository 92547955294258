import { useRpcContext } from '@/rpc/RpcContext'
import { supportAreaMaster } from 'inheritance-utils'
import {
  BusinessCategory,
  ChurnApplicationDetails,
  PlacementApplicationDetails,
  PublicInformation,
} from 'inheritance-api'
import { ChurnDetail, ContactSuspended, PlacementDetail } from 'inheritance-components'

export type ProfileDetailProps =
  | {
      kind: 'placement'
      businessCategory?: BusinessCategory
      placementDetail: PlacementApplicationDetails
    }
  | {
      kind: 'public'
      businessCategory?: BusinessCategory
      publicDetail: PublicInformation
      contactSuspended: Exclude<ContactSuspended, undefined>
    }
  | {
      kind: 'churn'
      churnDetail: ChurnApplicationDetails
    }

export const ProfileDetail = (props: ProfileDetailProps) => {
  const { placementImageFetcher } = useRpcContext()

  switch (props.kind) {
    case 'placement':
      return (
        <PlacementDetail
          kind="api-schema"
          businessCategory={props.businessCategory}
          placementDetail={props.placementDetail}
          supportAreaMaster={supportAreaMaster}
          placementImageFetcher={placementImageFetcher}
          contactSuspended={undefined} // 公開情報は未定義
        />
      )
    case 'public':
      return (
        <PlacementDetail
          kind="api-schema"
          businessCategory={props.businessCategory}
          placementDetail={props.publicDetail.content}
          supportAreaMaster={supportAreaMaster}
          placementImageFetcher={placementImageFetcher}
          contactSuspended={props.contactSuspended}
        />
      )
    case 'churn':
      return <ChurnDetail kind="schema" churnDetail={props.churnDetail} />
  }
}
