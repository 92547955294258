import { ReactNode, useEffect, useState } from 'react'
import { RecaptchaVerifier, Authenticator, updateLastAuthenticatedTimeCookie, UserCredential } from 'inheritance-auth'
import { PinInput, PinInputLayout, PinInputLayoutProps, PhoneNumberLoginLayout } from 'inheritance-components'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { useAuthContext } from '@/auth/AuthContext'
import { useHandleFirebaseError, ConfirmationResult } from '@/auth'
import { makeE164FormatPhoneNumber, removeSpace } from 'inheritance-utils'

const recaptchaContainerId = 'recaptcha-phone-login-container'

type AuthState = {
  authenticator?: Authenticator
  confirmationResult?: ConfirmationResult
  recaptchaVerifier?: RecaptchaVerifier
  recaptchaWidgetId?: number
  isRecaptchaVerified: boolean
}

type PhoneNumberLoginFormProps = {
  headerTitle: string
  phoneInputDescription: ReactNode
  pinInputButtonArea: PinInputLayoutProps['buttonArea']
  onSuccessCallback: () => void
}

export const PhoneNumberLoginForm = ({
  headerTitle,
  phoneInputDescription,
  pinInputButtonArea,
  onSuccessCallback,
}: PhoneNumberLoginFormProps) => {
  const { getAuthenticator } = useAuthContext()
  const { handleFirebaseError } = useHandleFirebaseError()
  const [authState, setAuthState] = useState<AuthState>({
    authenticator: undefined,
    confirmationResult: undefined,
    recaptchaVerifier: undefined,
    recaptchaWidgetId: undefined,
    isRecaptchaVerified: false,
  })
  const [loading, setLoading] = useState(false)

  const authenticator = getAuthenticator('phoneNumber')
  if (!authenticator) throw new Error('failed to get authenticator')

  const onSubmitLogin = async ({ phoneNumber }: { phoneNumber: string }): Promise<void> => {
    try {
      if (!authState.authenticator) throw new Error('authenticator undefined')
      if (!authState.recaptchaVerifier) throw new Error('recaptchaVerifier undefined')
      if (authState.recaptchaWidgetId === undefined) throw new Error('recaptchaWidgetId undefined')

      const e164FormatPhoneNumber = makeE164FormatPhoneNumber(removeSpace(phoneNumber))
      if (!e164FormatPhoneNumber) throw new Error('invalid phone number format')

      setLoading(true)

      const confirmationResult = await authState.authenticator.signinWithPhoneNumber(
        e164FormatPhoneNumber,
        authState.recaptchaVerifier,
        authState.recaptchaWidgetId
      )

      if (confirmationResult) {
        setAuthState((prev) => ({ ...prev, confirmationResult, isRecaptchaVerified: true }))
      } else {
        throw new Error('confirmationResult undefined')
      }
    } catch (error) {
      await handleFirebaseError({ action: 'signin', error })
    } finally {
      setLoading(false)
    }
  }

  const onCompletePinInput = (pin: string) => {
    if (!authState.confirmationResult)
      throw new Error('failed to submit pin code because confirmationResult is undefined')

    authState.confirmationResult
      .confirm(pin)
      .then((res: UserCredential) => {
        const lastLoginTime = res.user.metadata.lastSignInTime
        const lastLoginDate = lastLoginTime ? new Date(lastLoginTime) : new Date()
        updateLastAuthenticatedTimeCookie(lastLoginDate)

        onSuccessCallback()
      })
      .catch(async (error) => {
        await handleFirebaseError({ action: 'signin', error })
      })
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authenticator.signOut()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const recaptchaVerifier = authenticator.initRecaptchaVerifier({ recaptchaContainerId })
    if (!recaptchaVerifier) throw new Error('failed to init recaptchaVerifier')

    recaptchaVerifier
      .render()
      .then((recaptchaWidgetId: number) => {
        setAuthState((prev) => ({ ...prev, authenticator, recaptchaVerifier, recaptchaWidgetId }))
      })
      .catch(() => {
        throw new Error('failed to render recaptcha')
      })
  }, [authenticator])

  return (
    <>
      {!authState.isRecaptchaVerified && (
        <PhoneNumberLoginLayout
          header={<ContentTitleHeader title={headerTitle} />}
          onSubmit={onSubmitLogin}
          loading={loading}
          phoneInputDescription={phoneInputDescription}
        />
      )}
      {authState.isRecaptchaVerified && (
        <PinInputLayout
          header={<ContentTitleHeader title="認証コードフォーム入力" />}
          pinInput={<PinInput digits={6} onInputComplete={onCompletePinInput} />}
          buttonArea={pinInputButtonArea}
        />
      )}
      <div id={recaptchaContainerId}></div>
    </>
  )
}
