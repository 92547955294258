import {
  PRIMAL_AND_CHURN_PROPS,
  PLACEMENT_PROPS,
  ProfileChurnStatus,
  ProfilePlacementStatus,
  ProfilePublicStatus,
  PUBLIC_PROPS,
} from '@/share/components/PlacementForm/profileStatus'
import { useDesignToken } from '@/share/components/design-system/token'
import { PlacementEndDate } from '@/share/domain/placement/placementEndDate'
import { css } from '@emotion/react'
import { Typography } from 'antd'
import { TextsWithNewline } from 'inheritance-components'

export type ProfileStatusDescriptionProps =
  | {
      kind: 'placement'
      status: Exclude<ProfilePlacementStatus, 'nothing'>
      screenNgComment?: string[]
    }
  | {
      kind: 'public'
      status: Exclude<ProfilePublicStatus, 'nothing'>
    }
  | {
      kind: 'churn'
      status: Exclude<ProfileChurnStatus, 'can-apply'>
      placementEndDate: PlacementEndDate
    }

const convertProfileStatusDescriptionProps = (
  props: ProfileStatusDescriptionProps
): { description: string; alert: boolean } => {
  switch (props.kind) {
    case 'placement':
      if (props.status === 'declined') {
        return {
          description:
            '申請した掲載情報が棄却されました。' +
            '\n' +
            '審査NG理由：' +
            PLACEMENT_PROPS[props.status].description(props.screenNgComment ?? []),
          alert: PLACEMENT_PROPS[props.status].alert,
        }
      }

      return PLACEMENT_PROPS[props.status]
    case 'public':
      return PUBLIC_PROPS[props.status]
    case 'churn':
      return {
        description: PRIMAL_AND_CHURN_PROPS[props.status].description(props.placementEndDate),
        alert: PRIMAL_AND_CHURN_PROPS[props.status].alert,
      }
  }
}

const useStyles = () => {
  const { themeColor } = useDesignToken()

  return {
    alert: css`
      color: ${themeColor.text.alert1};
    `,
  }
}

/**
 *
 * ステータスの説明をするコンポーネント
 */
export const ProfileStatusDescription = (props: ProfileStatusDescriptionProps) => {
  const { description, alert } = convertProfileStatusDescriptionProps(props)
  const styles = useStyles()

  return (
    <Typography.Paragraph css={alert ? styles.alert : undefined}>
      <TextsWithNewline text={description} />
    </Typography.Paragraph>
  )
}
