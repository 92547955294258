import { getPath } from '@/router'
import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'
import { Button, Typography } from 'antd'

export const CreateAccountExists = () => {
  return (
    <SuccessScreen
      kind="composition"
      title="既にアカウントを作成済みです"
      content={
        <>
          <Typography.Paragraph type="secondary">作成したアカウントでログインしてください。</Typography.Paragraph>
          <Button type="primary" href={getPath('login')}>
            ログイン画面に戻る
          </Button>
        </>
      }
    />
  )
}
