import { ImageProps } from 'inheritance-utils'
import { useCallback } from 'react'
import { ImageLoader } from './ImageLoader'

export type PlacementImageFetcher = (
  placementImageId: string,
  notFoundErrorCallback?: () => void
) => Promise<File | undefined>

export const PlacementImageLoader = ({
  placementImageId,
  imageProps,
  placementImageFetcher,
  notFoundErrorCallback,
}: {
  placementImageId: string
  imageProps: ImageProps
  placementImageFetcher: PlacementImageFetcher
  notFoundErrorCallback?: () => void
}) => {
  const imageFetcher = useCallback(
    async () => placementImageFetcher(placementImageId, notFoundErrorCallback),
    [placementImageFetcher, placementImageId, notFoundErrorCallback]
  )
  return <ImageLoader imageProps={imageProps} imageFetcher={imageFetcher} />
}
