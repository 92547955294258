export const splitByNewline = (str?: string): string[] => {
  return str?.split(/\r\n|\n/) || []
}

export const removeSpace = (str: string): string => {
  return str.replace(/\s/g, '')
}

export const base64ToUint8Array = (str: string) => {
  return new Uint8Array([...atob(str)].map((s) => s.charCodeAt(0)))
}

// E.164形式の電話番号に変換する
export const makeE164FormatPhoneNumber = (mobilePhoneNumber: string): string | undefined => {
  if (!mobilePhoneNumber.match(/^0[7-9]0\d{8}$/)) {
    return undefined
  }
  return '+81' + mobilePhoneNumber.substring(1)
}
