import { enableAffiliationOther } from 'inheritance-utils'
import { formProps } from './form'
import { SelectForm, SelectFormItem } from '../form/SelectForm'
import { SingleTextInputForm } from 'inheritance-components'

type AffiliationFormProps = {
  label: string
  placeholder: string
  affiliation?: string
  affiliations: SelectFormItem[]
  onSelect: (affiliation: string) => void
}

export const AffiliationForm = ({ label, placeholder, affiliation, affiliations, onSelect }: AffiliationFormProps) => {
  return (
    <>
      {/* 専門家カテゴリーを選択した場合に対応する所属団体のプルダウンが選択可能となる。 */}
      {affiliations.length > 0 && (
        <SelectForm
          {...formProps['affiliation']}
          label={label}
          items={affiliations}
          onSelect={(affiliation: string | number) => onSelect(affiliation as string)}
          placeholder={placeholder}
          showSearch
        />
      )}

      {/* 「該当する所属団体がない」を選択した場合のみ自由項目を記入できるようにする */}
      {enableAffiliationOther(affiliation) && (
        <SingleTextInputForm {...formProps['affiliationOther']} label={`${label}（自由記入）`} />
      )}
    </>
  )
}
