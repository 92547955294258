import { createContext, ReactNode, useContext } from 'react'
import { designToken } from './designToken'
import { useGlobalDesignToken } from 'inheritance-global-components'

const designTokenCtx = createContext(designToken)

export const ProAndMgmtDesignTokenProvider = ({
  children,
  ctxValue = designToken,
}: {
  children: ReactNode
  ctxValue?: typeof designToken
}) => {
  return <designTokenCtx.Provider value={ctxValue}>{children}</designTokenCtx.Provider>
}

export const useProAndMgmtDesignToken = () => {
  const globalToken = useGlobalDesignToken()
  const token = useContext(designTokenCtx)

  return {
    ...globalToken,
    ...token,
  }
}
