import { css } from '@emotion/react'
import { Alert, Button, Col, Divider, Row } from 'antd'
import { DescriptionItemGroupType, PlacementDetail } from 'inheritance-components'
import { PlacementDescriptionValue } from 'inheritance-components/src/components/description/placement/type'
import { useRpcContext } from '@/rpc/RpcContext'
import { usePreventBrowserClosing } from '@/share/hooks/usePreventBrowserClosing'

const styles = {
  form: css`
    margin: 0 auto 80px;
    max-width: 1000px;
  `,
  description: css`
    display: flex;
    justify-content: center;
    margin: 32px 0;
  `,
  alert: css`
    width: max-content;
  `,
  buttonGroup: css`
    margin: 40px auto;
    text-align: center;
  `,
  editButton: css`
    margin-right: 32px;
  `,
}

const submitButtonText = '掲載を申請する'

type ConfirmFormProps = {
  itemGroups: DescriptionItemGroupType<PlacementDescriptionValue>[]
  onBack: () => void
  onSubmit: () => void
  mutating: boolean
}

export const ConfirmForm = (props: ConfirmFormProps) => {
  usePreventBrowserClosing()
  const { placementImageFetcher } = useRpcContext()
  return (
    <Row>
      <Col offset={2} span={20}>
        <div css={styles.description}>
          <Alert
            css={styles.alert}
            showIcon
            type="warning"
            message={`まだ申し込みは完了していません。下部の「${submitButtonText}」ボタンを押して申し込みを完了してください。`}
          />
        </div>
        <ButtonGroup {...props} />
        <Divider />
        <section css={styles.form}>
          <PlacementDetail
            kind="normalized"
            itemGroups={props.itemGroups}
            placementImageFetcher={placementImageFetcher}
          />
        </section>
        <Divider />
        <ButtonGroup {...props} />
      </Col>
    </Row>
  )
}

const ButtonGroup = (props: ConfirmFormProps) => {
  return (
    <section css={styles.buttonGroup}>
      <Button css={styles.editButton} onClick={props.onBack}>
        修正する
      </Button>
      <Button type="primary" onClick={props.onSubmit} loading={props.mutating}>
        {submitButtonText}
      </Button>
    </section>
  )
}
