export const trueOrFalse: {
  label: string
  value: boolean
}[] = [
  {
    label: 'はい',
    value: true,
  },
  {
    label: 'いいえ',
    value: false,
  },
]

export const trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank: {
  label: string
  value: boolean
}[] = [
  {
    label: 'はい、引き受けていません',
    value: true,
  },
  {
    label: 'いいえ、引き受けています',
    value: false,
  },
]
