import { PlacementFormType } from '../PlacementForm/form'
import { usePreviewPlacementApplication } from '../PlacementForm/hooks'
import { PreviewHeader } from '../layout/PreviewHeader'
import { createPreviewUserUrl } from './utils'
import { FormInstance, notification } from 'antd'
import { ProfessionalCategoryCode } from 'inheritance-api'
import { mergeForm } from './merge'

type Props = {
  form: FormInstance<PlacementFormType>
  loading?: boolean
  professionalCategoryCode: ProfessionalCategoryCode
  hasSummaryCourtLegalRepresentation: boolean
  isCorporation?: boolean
}

export const PreviewHeaderWithActions = ({
  form,
  loading,
  professionalCategoryCode,
  hasSummaryCourtLegalRepresentation,
  isCorporation = false,
}: Props) => {
  const { handlePreviewDetail, handlePreviewList } = useHandler(
    form,
    professionalCategoryCode,
    hasSummaryCourtLegalRepresentation,
    isCorporation
  )

  return (
    <PreviewHeader handleDetailButton={handlePreviewDetail} handleListButton={handlePreviewList} loading={loading} />
  )
}

const useHandler = (
  form: FormInstance<PlacementFormType>,
  professionalCategoryCode: ProfessionalCategoryCode,
  hasSummaryCourtLegalRepresentation: boolean,
  isCorporation: boolean
) => {
  const { mutate: detailMutate } = usePreviewPlacementApplication({
    onSuccess: async (res) => {
      window.open(createPreviewUserUrl({ target: 'pro-search-detail', ...res, professionalCategoryCode }))
    },
  })
  const { mutate: listMutate } = usePreviewPlacementApplication({
    onSuccess: async (res) => {
      window.open(createPreviewUserUrl({ target: 'pro-search-list', ...res, professionalCategoryCode }))
    },
  })

  return {
    handlePreviewDetail: async () => {
      const previewForm = await createPreviewMergedForm(form, hasSummaryCourtLegalRepresentation, isCorporation)
      if (previewForm) await detailMutate(previewForm)
    },
    handlePreviewList: async () => {
      const previewForm = await createPreviewMergedForm(form, hasSummaryCourtLegalRepresentation, isCorporation)
      if (previewForm) await listMutate(previewForm)
    },
  }
}

const openNotification = () => {
  notification.open({
    message: '掲載申込のプレビュー表示ができませんでした',
    description: 'エラーになっているフォームの内容を修正してください',
    placement: 'top',
    style: { width: '100%' },
  })
}

/**
 * プレビュー用のフォームを作成する
 * - 必須項目が空欄の場合に、ダミーテキストを入れる（プレビュー用にしか使わない）
 * @param form
 * @param errorFn
 * @returns プレビュー用のフォーム
 */
const createPreviewMergedForm = async (
  form: FormInstance<PlacementFormType>,
  hasSummaryCourtLegalRepresentation: boolean,
  isCorporation: boolean,
  errorFn = openNotification
): Promise<PlacementFormType | undefined> => {
  // 元の値を待避
  const origin = await stashOriginForm(form)

  const { merged, validationKeyForMerged } = mergeForm(
    form.getFieldsValue(),
    hasSummaryCourtLegalRepresentation,
    isCorporation
  )
  form.setFieldsValue(merged)
  try {
    await form.validateFields(validationKeyForMerged)
  } catch (error) {
    console.error(error)
    errorFn()
    return
  } finally {
    // 元の値を復元
    // 復元しないとフォーム自体の値が変わってしまう
    form.setFieldsValue(origin.values)
    form.setFields(origin.errors.filter((error) => error.errors.length > 0))
  }

  return merged
}

/**
 * プレビュー直前の元のフォームの状態を退避させる
 */
const stashOriginForm = async (form: FormInstance<PlacementFormType>) => {
  try {
    await form.validateFields()
  } catch (error) {
    // プレビュー時には元のフォームのバリデーションエラーを噛み潰す
    console.error(error)
  }

  return {
    values: form.getFieldsValue(),
    errors: form.getFieldsError(),
  }
}
