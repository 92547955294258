import { getPath } from '@/router'
import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'
import { Typography, Button } from 'antd'
import { useQueryParamsOptionally } from 'inheritance-router'

export const LandingPageEmailSent = () => {
  const { email } = useQueryParamsOptionally({ keys: ['email'] })
  return (
    <SuccessScreen
      kind="composition"
      title="メールをご確認ください"
      content={
        <>
          <Typography.Paragraph type="secondary">
            設定したメールアドレス
            {email && `${email}`}
            にメールしました。
            <br />
            メール記載のURLより、メールアドレスの認証を行なってください。
          </Typography.Paragraph>
          <Button type="primary" href={getPath('lp')}>
            トップに戻る
          </Button>
        </>
      }
    />
  )
}
