import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Space } from 'antd'
import { ReactNode, useState } from 'react'
import { useDialogStyle } from './useDialogStyles'
import { css } from '@emotion/react'

export type YesNoDialogBaseProps = {
  open: boolean
  title: string
  iconColor: 'red' | 'purple' | 'yellow'
  content: ReactNode | string
  cancelText?: string
  onCancel: () => void
  okText?: string
  onOk: () => Promise<void>
  okButtonProps?: {
    danger: boolean
  }
}

export const YesNoDialogBase = ({
  open,
  title,
  iconColor,
  content,
  cancelText = 'キャンセル',
  onCancel,
  okText = 'OK',
  onOk,
  okButtonProps = {
    danger: false,
  },
}: YesNoDialogBaseProps) => {
  const { dialogStyle } = useDialogStyle()

  const [mutating, setMutating] = useState(false)

  const onOkClick = async () => {
    setMutating(true)
    await onOk()
    setMutating(false)
  }

  const cancelButtonStyle = css`
    /* キャンセルボタンだけ不要な為、常時ローダーを隠す */
    .ant-btn-loading-icon {
      display: none;
    }
  `

  const titleIconStyles = {
    red: dialogStyle.titleRedIcon,
    purple: dialogStyle.titlePurpleIcon,
    yellow: dialogStyle.titleYellowIcon,
  }

  const titleIconStyle = titleIconStyles[iconColor]

  return (
    <Modal open={open} closable={false} footer={false}>
      <div css={dialogStyle.title}>
        <ExclamationCircleOutlined css={titleIconStyle} />
        {title}
      </div>
      <p css={dialogStyle.content}>{content}</p>
      <Space css={dialogStyle.buttonSpace}>
        <Button css={cancelButtonStyle} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button type="primary" loading={mutating} onClick={onOkClick} danger={okButtonProps.danger}>
          {okText}
        </Button>
      </Space>
    </Modal>
  )
}
