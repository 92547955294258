import { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorScreen } from './share/components/result-screen/ResultScreen'
import { getErrObjForLogging, getErrMsgArray } from './share/error'
import { callPostProfessionalError } from './rpc/professionalError/function'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  messages?: string[]
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, messages: [...getErrMsgArray({ error })] }
  }

  public async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errObjForLogging = getErrObjForLogging(error)
    console.log({ errObjForLogging })

    console.error('Uncaught error:', error, errorInfo)

    await callPostProfessionalError({ data: errObjForLogging })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen kind="default" title="エラーが発生しました" description={this.state.messages ?? ''} />
    }

    return this.props.children
  }
}
