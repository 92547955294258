import { OfficeAddress } from 'inheritance-api'
import { PREFECTURE, supportAreaMaster } from 'inheritance-utils'

export const getOfficeAddressStringFromApiSchema = (officeAddress: OfficeAddress): string => {
  const prefectureName = officeAddress.prefecture
    ? PREFECTURE.find((p) => p.code.slice(-2) === officeAddress.prefecture?.prefectureCode)?.name ?? ''
    : ''
  const cityName = officeAddress.city
    ? supportAreaMaster.find((s) => s.areaCode === officeAddress.city?.areaCode)?.areaName ?? ''
    : ''
  const cityString = officeAddress.city?.otherAreaName ?? cityName

  const officeAddressString = prefectureName + cityString + officeAddress.townAndStreetAddress
  return officeAddressString
}
