import React from 'react'
import { AppBaseError } from './AppBaseError'
import {
  BadRequestError,
  InternalServerError,
  NetworkError,
  SystemError,
  EmailAlreadyInUseError,
  AuthenticationError,
  ConflictError,
} from './AppErrors'
import { SWR_KEYS } from '@/rpc/keys'

type ApiKey = keyof typeof SWR_KEYS

// 汎用エラーメッセージ
export const errToMsg = {
  [BadRequestError.name]: ['不正なパラメーターが送信されました'],
  [EmailAlreadyInUseError.name]: ['既にお申し込みされております'],
  [InternalServerError.name]: ['サーバーエラーが発生しました'],
  [SystemError.name]: ['システムエラーが発生しました'],
  [NetworkError.name]: ['ネットワークエラーが発生しました'],
  [AuthenticationError.name]: ['有効な認証情報が取得できませんでした。', '再度ログインを実施してください。'],
  [ConflictError.name]: ['既にデータが存在しています'],
} as const

// 特定のAPI向けのエラーメッセージ
export const errToMsgByApiKey = ({ error, apiKey }: { error: AppBaseError; apiKey: ApiKey }): string[] | undefined => {
  switch (apiKey) {
    case SWR_KEYS.putProfessionalPlacement:
      if (error.name === ConflictError.name) {
        return ['既に審査中の掲載申し込みデータがあります', '審査完了までお待ちください']
      }
      break
    default:
      return undefined
  }
}

export const getErrMsgArray = ({ error, apiKey }: { error: unknown; apiKey?: ApiKey }) => {
  if (!(error instanceof AppBaseError)) {
    return errToMsg[SystemError.name]
  }

  if (apiKey) {
    const msgByApiKey = errToMsgByApiKey({ error, apiKey })
    if (msgByApiKey) return msgByApiKey
  }

  return errToMsg[error.name] ?? errToMsg[SystemError.name]
}

export const getErrMsg = (arg: { error: unknown; apiKey?: ApiKey }) => {
  const msg = getErrMsgArray(arg)

  return msg.map((text, index) => (
    <React.Fragment key={index}>
      {text}
      {msg.length - 1 !== index && <br />}
    </React.Fragment>
  ))
}
