import { CONSULTATION_FEE_TYPE, isConsultationFeeTypeTimeLimit } from 'inheritance-utils'
import { PlacementDescriptionValue } from '../type'
import { ConsultationFeeDetails } from 'inheritance-api'
import { DescriptionItemTypeWithOrder } from '../../generic/type'

export const CONSULTATION_FEE_LABEL = {
  consultationFeeType: '相談料',
  consultationFreeTimeLimit: '初回相談の時間',
  consultationFeeNote: '相談料に関する備考',
} as const

export const getConsultationFeeItem = ({
  consultationFeeType,
  consultationFreeTimeLimit,
  consultationFeeNote,
}: ConsultationFeeDetails): DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] => {
  const items: DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] = []
  items.push({
    label: CONSULTATION_FEE_LABEL.consultationFeeType,
    value: {
      kind: 'text',
      text: consultationFeeType ? CONSULTATION_FEE_TYPE[consultationFeeType] : '',
    },
    itemOrder: 1,
  })
  if (isConsultationFeeTypeTimeLimit(consultationFeeType)) {
    items.push({
      label: CONSULTATION_FEE_LABEL.consultationFreeTimeLimit,
      value: { kind: 'text', text: `${consultationFreeTimeLimit}分` },
      itemOrder: 2,
    })
  }
  items.push({
    label: CONSULTATION_FEE_LABEL.consultationFeeNote,
    value: { kind: 'text', text: consultationFeeNote?.join('\n') ?? '' },
    itemOrder: 3,
  })
  return items
}
