import { css } from '@emotion/react'

export const EnvIndicator = ({ isDev, env }: { isDev?: boolean; env?: string }) => {
  if (env === 'prod') return null

  const prop = {
    color: '',
    label: '',
  }

  if (isDev) {
    // localはimport.meta.env.DEVで判定する
    prop.label = 'LOCAL'
    prop.color = '#8B3200'
  } else if (env === 'debug') {
    // local以外はimport.meta.env.ENVで判定する
    prop.label = 'DEBUG'
    prop.color = '#898B00'
  } else if (env === 'dev') {
    prop.label = 'DEV'
    prop.color = '#008B2F'
  } else if (env === 'stage') {
    prop.label = 'STG'
    prop.color = '#0064C0'
  }

  const style = css`
    position: fixed;
    bottom: 16px;
    right: 13px;
    width: 122px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background-color: ${prop.color};
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    opacity: 0.6;
    z-index: 9999999999;
  `

  return prop.label ? <div css={style}>{prop.label}</div> : null
}
