import { createContext, ReactNode, useContext } from 'react'
import { getAuthenticator } from '.'

const defaultValue = {
  getAuthenticator,
}

type AuthContextValue = typeof defaultValue

const AuthContext = createContext(defaultValue)

export const AuthContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode
  value?: Partial<AuthContextValue>
}) => {
  if (!props.value) {
    return <AuthContext.Provider value={defaultValue}>{children}</AuthContext.Provider>
  }

  const specifiedFuncs = props.value as { [key: string]: unknown }

  const value = Object.keys(defaultValue).reduce((acc, cur) => {
    if (specifiedFuncs[cur]) {
      return { ...acc, [cur]: specifiedFuncs[cur] }
    }
    return acc
  }, defaultValue)
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
