import { useApiVersion } from '@/rpc/apiVersion/useSWR'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { callApiVersion } from '@/rpc/apiVersion/function'
import { useVerifyApiVersionAndReloadIfInvalid } from 'inheritance-api/src/utils/apiVersion/hooks'
import { VerificationInterceptedPath } from 'inheritance-api/src/utils/apiVersion/types'

type ApiVersionInterceptorProps = {
  children?: React.ReactNode
} & Pick<VerificationInterceptedPath, 'interceptedRoutes'>

export const ApiVersionInterceptor = ({ children, interceptedRoutes }: ApiVersionInterceptorProps) => {
  const origin = useApiVersion()
  const latestFn = async () => callApiVersion()

  useVerifyApiVersionAndReloadIfInvalid({
    origin,
    latestFn,
    interceptedRoutes,
    ...useLocation(),
  })

  return <>{children}</>
}
