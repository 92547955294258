import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'
import { Typography, Button } from 'antd'
import { css } from '@emotion/react'
import { getPath } from '@/router'

export const PlacementApplicationCompleted = () => {
  return (
    <SuccessScreen
      kind="composition"
      title="掲載情報を受付しました"
      content={
        <>
          <Typography.Paragraph type="secondary">
            掲載情報の審査を行いますので、公開までしばらくお待ちください。
          </Typography.Paragraph>
          <Button
            type="primary"
            href={getPath('home')}
            css={css`
              margin-top: 16px;
            `}
          >
            掲載情報に戻る
          </Button>
        </>
      }
    />
  )
}
