import { css } from '@emotion/react'
import { createContext, ReactNode, useContext, useMemo, useState, useCallback, useEffect } from 'react'
import { responsiveUtils as initialCtx, TResponsiveUtils } from './responsiveUtil'
import { useGlobalDesignToken } from '../token'

export const reponsiveUtilCtx = createContext<TResponsiveUtils>(initialCtx)

export const ResponsiveUtilProvider = ({ children }: { children: ReactNode }) => {
  const { breakpoint, mediaQuery } = useGlobalDesignToken()
  const [viewMode, setViewMode] = useState({
    /**
     * 初期値だけpc/spともにfalseにしている。
     * Nextでは、SSR時にどちらかに判定してしまうと、
     * クライアントレンダリング時にSP→PCの画面切り替えが発生してしまうため。
     */
    pc: false,
    sp: false,
  })

  const context: TResponsiveUtils = useMemo(
    () => ({
      hidden: css`
        display: none;
      `,
      spHidden: css`
        ${mediaQuery.sp} {
          display: none;
        }
      `,
      pcHidden: css`
        ${mediaQuery.pc} {
          display: none;
        }
      `,
      viewMode: {
        pc: viewMode.pc,
        sp: viewMode.sp,
      },
    }),
    [mediaQuery, viewMode]
  )

  const updateViewMode = useCallback(() => {
    const width = window.innerWidth
    setViewMode({
      pc: width >= breakpoint,
      sp: width <= breakpoint - 1,
    })
  }, [setViewMode, breakpoint])

  useEffect(() => {
    updateViewMode()
    window.addEventListener('resize', updateViewMode)
    return () => window.removeEventListener('resize', updateViewMode)
  }, [updateViewMode])

  return <reponsiveUtilCtx.Provider value={context}>{children}</reponsiveUtilCtx.Provider>
}

export const useResponsiveUtil = () => {
  return useContext(reponsiveUtilCtx)
}
