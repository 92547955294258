import React from 'react'
import { getVerificationInterceptedPath, verifyApiVersion } from './verification'
import { GetApiVersionResponse } from '../../api'
import { VerificationInterceptedPath } from './types'

type UseVerifyApiVersionAndReloadIfInvalid = {
  /**
   * 初期のAPIバージョン
   */
  origin: GetApiVersionResponse
  /**
   * hook内で最新のAPIバージョンを取得する
   */
  latestFn: () => Promise<GetApiVersionResponse>
} & VerificationInterceptedPath

/**
 * 初回レンダリング時のAPIバージョンと、関数が呼ばれた時のAPIバージョンがズレるとリロードする。
 * ただし、初回レンダリング時のAPIバージョンや関数が呼ばれた時のAPIバージョンが存在しなかったら何もしない
 */
export const useVerifyApiVersionAndReloadIfInvalid = (
  { pathname, search, interceptedRoutes, origin, latestFn }: UseVerifyApiVersionAndReloadIfInvalid,
  ms = 3000
) => {
  const verificationPath = React.useMemo(
    () => getVerificationInterceptedPath({ pathname, search, interceptedRoutes }),
    [pathname, search, interceptedRoutes]
  )

  React.useEffect(() => {
    let reloadTimeout: NodeJS.Timeout | undefined = undefined

    const verify = async () => {
      const latest = await latestFn()
      if (verifyApiVersion(origin, latest)) {
        // APIバージョンが一致
        return
      }

      // APIバージョンがズレた場合はリロード
      reloadTimeout = setTimeout(() => window.location.reload(), ms)
    }

    if (verificationPath) {
      void verify()
    }
    return () => {
      clearTimeout(reloadTimeout)
    }
    // verificationPath パスが変わった時に再レンダーする
  }, [ms, origin, latestFn, verificationPath])
}
