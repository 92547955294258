import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'

export const useGetProfessionalUserVisitData = () => {
  const { callGetProfessionalUserVisitData } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.userVisitData, () => callGetProfessionalUserVisitData())
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return { ...data }
}
