import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPath } from '@/router'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { PasswordResetRequestLayout } from 'inheritance-components'
import { useRpcContext } from '@/rpc/RpcContext'
import { css } from '@emotion/react'
import { RecaptchaV2Invisible, getRecaptchaToken, RecaptchaCallbackArg } from 'inheritance-recaptcha'
import { resetRecaptcha } from 'inheritance-recaptcha/src/RecaptchaV2Invisible'
import { ENV } from '@/config/environment'
import { notification } from 'antd'
import { getErrMsg } from '@/share/error'
import { useHandleRecaptchaError } from '@/share/recaptcha/hook'

const styles = {
  recaptcha: css`
    position: fixed;
    top: 100px;
    left: 0;
  `,
}

type FormValue = {
  email: string
  emailRecaptchaCallbackValue: string
}

const useProfessionalPasswordResetSendEmail = () => {
  const navigate = useNavigate()
  const { callPostProfessionalPasswordResetSendEmail } = useRpcContext()
  const [mutating, setMutating] = useState(false)

  const mutate = async (recaptchaToken: string, email: string) => {
    setMutating(true)

    const { error } = await callPostProfessionalPasswordResetSendEmail({ emailAddress: email, recaptchaToken })
    setMutating(false)

    resetRecaptcha()

    if (error) {
      notification.open({
        message: 'メールの送信に失敗しました',
        description: getErrMsg({ error }),
        placement: 'top',
      })
      return
    }

    navigate(getPath('passwordResetRequestSent'), { state: { email } })
  }
  return { mutating, mutate }
}

export const PasswordResetRequest = () => {
  const { handleRecaptchaError } = useHandleRecaptchaError()
  const { mutate, mutating } = useProfessionalPasswordResetSendEmail()
  const [formValue, setFormValue] = useState<FormValue>({
    email: '',
    emailRecaptchaCallbackValue: '',
  })

  const onSubmit = async (values: { email: string }) => {
    setFormValue({ ...formValue, emailRecaptchaCallbackValue: values.email })
    const token = await getRecaptchaToken()
    if (!token) return
    await mutate(token, values.email)
  }

  const recaptchaCallback = async (arg: RecaptchaCallbackArg) => {
    if (arg.type === 'verified') {
      await mutate(arg.token, formValue.emailRecaptchaCallbackValue)
      return
    }
    await handleRecaptchaError(arg.error)
  }

  return (
    <AppLayout>
      <PasswordResetRequestLayout
        header={<ContentTitleHeader title="パスワードリセット" />}
        onSubmit={onSubmit}
        loading={mutating}
      />
      <div css={styles.recaptcha}>
        <RecaptchaV2Invisible callback={recaptchaCallback} siteKey={ENV.RECAPTCHA_SITE_KEY} />
      </div>
    </AppLayout>
  )
}
