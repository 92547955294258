import { initializeApp, FirebaseError } from 'firebase/app'
import {
  Auth,
  User as _User,
  getAuth,
  getMultiFactorResolver,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  updatePassword as _updatePassword,
  UserCredential,
  setPersistence,
  browserSessionPersistence,
  signInWithPhoneNumber,
  ConfirmationResult,
} from 'firebase/auth'
import { ErrorMessage, errors, Action, ERROR_CODES } from './errors'
export { ERROR_CODES, type ErrorMessage, type Action } from './errors'
export type { Auth }
export { FirebaseError } from 'firebase/app'
export { RecaptchaVerifier, type User as _User, type ConfirmationResult, type UserCredential } from 'firebase/auth'

export type ProvidorType = 'email' | 'phoneNumber'

import Cookies from 'universal-cookie'
const cookies = new Cookies()
const lastAuthenticatedTimeCookie = 'lastAuthenticatedTime'

export class User {
  user_credential: UserCredential

  constructor(user_credential: UserCredential) {
    this.user_credential = user_credential
  }

  async getToken(): Promise<string> {
    return this.user_credential.user.getIdToken()
  }
}

export class CurrentUser {
  constructor(readonly user: _User) {}

  get email(): string | null {
    return this.user.email
  }
}

export class Authenticator {
  auth: Auth
  constructor(apiKey: string, authDomain: string, tennantId: string | null) {
    const firebaseConfig = {
      apiKey: apiKey,
      authDomain: authDomain,
    }
    const app = initializeApp(firebaseConfig)
    this.auth = getAuth(app)
    this.auth.languageCode = 'ja'
    this.auth.tenantId = tennantId
    void setPersistence(this.auth, browserSessionPersistence)
  }

  /**
   * MFA サインインを実行します
   *
   * https://firebase.google.com/docs/auth/web/multi-factor
   *
   * @param email - メールアドレス
   * @param password - パスワード
   * @param recaptchaVerifiyCallback - SMSへ認証コードを送信するための reCAPTCHA を実行する RecaptchaVerifier を取得する為のコールバック
   * @param verificationCodeCallback - 一段階目の認証が完了し、SMSで送られた確認コードを要求する時に呼び出されるコールバック
   * @returns 認証されたユーザー
   *
   *
   */
  async signin(
    email: string,
    password: string,
    recaptchaVerifiyCallback: (auth: Auth) => Promise<RecaptchaVerifier>,
    verificationCodeCallback: (lastErrorCode?: string) => Promise<string>
  ): Promise<User | FirebaseError | string> {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
      return new User(userCredential)
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        if (e.code == 'auth/multi-factor-auth-required') {
          return await this.mfa(e as MultiFactorError, recaptchaVerifiyCallback, verificationCodeCallback)
        }
      }
      throw e
    }
  }

  async mfa(
    multiFactorError: MultiFactorError,
    recaptchaVerifiyCallback: (auth: Auth) => Promise<RecaptchaVerifier>,
    verificationCodeCallback: (lastErrorCode?: string) => Promise<string>
  ): Promise<User | FirebaseError | string> {
    const resolver = getMultiFactorResolver(this.auth, multiFactorError)
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    }
    const phoneAuthProvider = new PhoneAuthProvider(this.auth)
    const recaptchaVerifier = await recaptchaVerifiyCallback(this.auth)

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)

    let lastErrorCode = undefined

    for (let retryCount = 0; retryCount < 3; retryCount++) {
      const verificationCode = await verificationCodeCallback(lastErrorCode)
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential)
      try {
        const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
        const lastLoginTime = userCredential.user.metadata.lastSignInTime
        const lastLoginDate = lastLoginTime ? new Date(lastLoginTime) : new Date()
        updateLastAuthenticatedTimeCookie(lastLoginDate)

        return new User(userCredential)
      } catch (e: unknown) {
        if (e instanceof FirebaseError) {
          lastErrorCode = e.code
        }
      }
    }

    lastErrorCode = ERROR_CODES.mfaRetryCountExceeded
    throw lastErrorCode
  }

  initRecaptchaVerifier({ recaptchaContainerId }: { recaptchaContainerId: string }) {
    return new RecaptchaVerifier(recaptchaContainerId, { size: 'invisible' }, this.auth)
  }

  async signinWithPhoneNumber(
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier,
    widgetId: number
  ): Promise<ConfirmationResult> {
    try {
      const confirmationResult = await signInWithPhoneNumber(this.auth, phoneNumber, recaptchaVerifier)
      return confirmationResult
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const grecaptcha = (window as any).grecaptcha
      if (!grecaptcha) throw new Error('failed to load grecaptcha')
      // ユーザーが再試行できるように reCAPTCHA をリセットする
      grecaptcha.reset(widgetId)

      throw e
    }
  }

  async signOut(): Promise<void> {
    deleteLastAuthenticatedTimeCookie()
    return await signOut(this.auth)
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return await sendPasswordResetEmail(this.auth, email)
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return await confirmPasswordReset(this.auth, code, newPassword)
  }
}

export const getIdTokenByUser = async (user: _User): Promise<string | undefined> => {
  const lastAuthenticatedTime = cookies.get(lastAuthenticatedTimeCookie)
  if (!lastAuthenticatedTime) return undefined

  const nowDate = new Date()
  const isWithin1Hour = nowDate.getTime() - Number(lastAuthenticatedTime) <= 3600000 // 1 hour in milliseconds

  if (isWithin1Hour) {
    // refresh cookie
    updateLastAuthenticatedTimeCookie(nowDate)
    // get and refresh idToken
    return await user.getIdToken(true)
  } else {
    cookies.remove(lastAuthenticatedTimeCookie)
    return undefined
  }
}

export const updateLastAuthenticatedTimeCookie = (date: Date) => {
  cookies.set(lastAuthenticatedTimeCookie, date.getTime(), {
    path: '/',
    expires: new Date(date.setHours(date.getHours() + 1)),
    secure: true,
    sameSite: 'lax',
  })
}

export const deleteLastAuthenticatedTimeCookie = () => {
  cookies.remove(lastAuthenticatedTimeCookie)
}

export const updatePassword = async (user: _User, newPassword: string) => {
  return await _updatePassword(user, newPassword)
}

export const getErrorMessage = (errorCode: string, action: Action): ErrorMessage => {
  return (
    errors[action][errorCode] ?? {
      message: 'エラーが発生しました',
    }
  )
}
