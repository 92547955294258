import { ENV } from '@/config/environment'
import { Api } from 'inheritance-api'
import { getAuthenticator, getIdTokenByUser } from '@/auth'
import { getPath } from '@/router'
import { AuthenticationError } from '@/share/error/AppErrors'

const basePath = '/api'
const baseURL = ENV.SERVER_URL ? new URL(basePath, ENV.SERVER_URL).toString() : basePath
export const baseApiClint = new Api({ baseURL: baseURL.toString() })
export const apiClient = baseApiClint.professional
export const apiVersionClient = baseApiClint.version

const baseApiAuthClint = new Api({
  baseURL: baseURL.toString(),
  securityWorker: (idToken) => ({ headers: { Authorization: `Bearer ${idToken}` } }),
})
export const getIdToken = async (): Promise<string> => {
  const authenticator = getAuthenticator()
  if (!authenticator) throw new Error('failed to getAuthenticator')
  const user = authenticator.auth.currentUser

  if (!user) {
    await authenticator.signOut()
    throw (window.location.href = getPath('login'))
  }

  let idToken: string | undefined

  try {
    idToken = await getIdTokenByUser(user)
  } catch (e) {
    throw new AuthenticationError({ cause: e, message: 'failed to getIdTokenByUser' })
  }

  if (!idToken) {
    await authenticator.signOut()
    throw (window.location.href = getPath('login'))
  }
  return idToken
}
export const getPhoneAuthIdToken = async (): Promise<string> => {
  const authenticator = getAuthenticator()
  if (!authenticator) throw new Error('failed to getAuthenticator')
  const user = authenticator.auth.currentUser

  if (!user) {
    await authenticator.signOut()
    throw new Error('認証情報の取得に失敗しました')
  }

  let idToken: string | undefined

  try {
    idToken = await getIdTokenByUser(user)
  } catch (e) {
    await authenticator.signOut()
    throw new Error('認証情報の取得に失敗しました')
  }

  if (!idToken) {
    await authenticator.signOut()
    throw new Error('failed to get idToken')
  }
  return idToken
}

const getApiAuthClientAll = async () => {
  const idToken = await getIdToken()
  baseApiAuthClint.setSecurityData(idToken)
  return baseApiAuthClint
}
export const getApiAuthProfessionalClient = async () => {
  const allClient = await getApiAuthClientAll()
  return allClient.professional
}
export const getApiPhoneAuthProfessionalClient = async () => {
  const idToken = await getPhoneAuthIdToken()
  baseApiAuthClint.setSecurityData(idToken)
  return baseApiAuthClint.professional
}
export const getApiAuthMasterClient = async () => {
  const allClient = await getApiAuthClientAll()
  return allClient.master
}
export const getApiAuthManagementClient = async () => {
  const allClient = await getApiAuthClientAll()
  return allClient.management
}
