import { AppLayout } from '@/share/components/layout/AppLayout'
import { PhoneNumberLoginForm } from '@/share/components/PhoneNumberLoginForm/PhoneNumberLoginForm'
import { useGetProfessionalPasswordResetValidate } from '@/rpc/professionalPasswordReset/useSWR'
import { useQueryParamsDefinitely } from 'inheritance-router'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPath } from '@/router'

export const PasswordResetAuthentication = () => {
  const navigate = useNavigate()
  const { passwordResetToken } = useQueryParamsDefinitely({
    keys: ['passwordResetToken'],
    errorWhenFailed: new Error('無効なURLです'),
  })

  const { result } = useGetProfessionalPasswordResetValidate({ passwordResetToken })

  useEffect(() => {
    if (!result) {
      navigate(getPath('passwordResetAuthenticationURLExpired'))
    }
  }, [result, navigate])

  const onSuccessCallback = () => {
    navigate(getPath('passwordResetConfirm') + `?passwordResetToken=${passwordResetToken}`)
  }

  return (
    <AppLayout>
      <PhoneNumberLoginForm
        headerTitle="パスワードリセット"
        onSuccessCallback={onSuccessCallback}
        phoneInputDescription={
          <>
            パスワードを再設定します。ご登録いただいている電話番号を入力し、
            <br />
            SMSに確認コードを送信するボタンをおしてください。
          </>
        }
        pinInputButtonArea={{
          description: '認証コードをお忘れの場合はパスワード再設定を最初からやり直し、認証コードを再送信してください。',
          button: {
            label: 'パスワード再設定を最初からやり直す',
            to: getPath('passwordResetRequest'),
          },
        }}
      />
    </AppLayout>
  )
}
