import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'

export const ContractApplicationCompleted = () => {
  return (
    <SuccessScreen
      kind="default"
      title="利用の申し込みを申請しました"
      description={['審査後メールをご返信いたします。', 'しばらくお待ちください。']}
    />
  )
}
