import { css } from '@emotion/react'
import { Divider } from 'antd'

const styles = {
  header: css`
    padding: 23px 24px 22px;
  `,
  title: css`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  `,
  divider: css`
    margin: 0;
  `,
}

export const ContentTitleHeader = (props: { title: string }) => {
  return (
    <>
      <header css={styles.header}>
        <h3 css={styles.title}>{props.title}</h3>
      </header>
      <Divider css={styles.divider} />
    </>
  )
}
