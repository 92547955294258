const antdThemeColor = {
  token: {
    colorBgLayout: '#F0F2F5',
  },
} as const

const themeColor = {
  text: {
    black: '#000',
    yellow: '#444139',
    blue: '#dce0e6',
    primary: '#1890ff',
    success: '#52c41a',
    alert1: '#ff4d4f',
    alert2: '#fe4d4f',
    warning: '#faad14',
  },
  border: {
    red: '#d9d9d9',
    yellow: '#ffe58f',
  },
  background: {
    red: '#fafafa',
    yellow: '#fffbe6',
  },
} as const

const layout = {
  globalHeaderHeight: 64,
  minWidth: 768,
  contentMaxWidth: 1200,
} as const

const topBottomLayout = {
  /**
   * コンテンツ枠と画面枠のマージン
   * (画面幅 - コンテンツ幅) / 2 だが、最小値は16px
   */
  sideMargin: `max(calc((100% - ${layout.contentMaxWidth}px) / 2), 16px)`,
}

const customZIndex = {
  /**
   * antd default z-index
   * - tooltip: 1070 (https://ant.design/components/tooltip#design-token)
   * - notification: 1050 (https://ant.design/components/notification#design-token)
   * - tour: 1001 (https://ant.design/components/tour#tour)
   * - drawer: 1000 (https://ant.design/components/drawer#design-token)
   * - modal: 1000 (https://ant.design/components/modal#design-token)
   */
  header: 100,
} as const

export const designToken = {
  antdThemeColor,
  themeColor,
  layout,
  topBottomLayout,
  customZIndex,
} as const
