import { RULES } from 'inheritance-components'
import { PlacementFormType, composeAreaOption, formNames, formProps } from '../PlacementForm/form'
import { AFFILIATION_OTHER_TEXT, enableAffiliationOther, supportAreaMaster } from 'inheritance-utils'
import { kv } from '@/share/utils/object-utils'
import { ConsultationFeeType, RegularHoliday } from 'inheritance-api'
// eslint-disable-next-line node/no-extraneous-import
import dayjs from 'dayjs'

export const DEFAULT_PREVIEW_FORM_VALUES = {
  postCode: {
    first: '100',
    second: '0000',
  },
  prefecture: '13',
  city: '131016',
  townAndStreetAddress: '千代田',
  representativeSurname: '代表者名',
  representativeGivenName: 'が表示されます',
  reception: {
    receptionHours: [dayjs().hour(9).second(0), dayjs().hour(17).second(0)],
    regularHoliday: ['sunday'] as RegularHoliday,
  },
  consultationFee: {
    consultationFeeType: 'freeForFirstTime' as ConsultationFeeType,
  },
  summaryCourtLegalRepresentation: {
    specifiedMember: {
      surname: '特定社員',
      givenName: 'が表示されます',
    },
    certificationNumber: '00000000',
  },
  contactSurname: '担当者名',
  contactGivenName: 'が表示されます',
  phoneNumber: '1234567890',
  emailAddress: 'sample@example.com',
  supportArea: {
    isWholeArea: true,
    prefecture: 'tokyo_13',
    isWholeCity: {
      // 全域
      whole: 'whole',
      // 個別指定
      pick: 'pick',
    },
  },
  kana: 'カナ',
  affiliation: AFFILIATION_OTHER_TEXT,
  affiliationOther: '所属団体が表示されます',
} as const

export const MULTI_FORM_KEYS = {
  prefecture: 'prefecture_',
  city: 'city_',
  isWholeCity: 'isWholeCity_',
  kana: /kana/i,
  representativeName: /representative(sur|given)name[0-9]+$/i,
} as const

type MergeFormProps = {
  merged: PlacementFormType
  /**
   * - 対応エリアは選択されている前提でマージしているので、除外されている
   * - 除外しないと、 `RULES.requireSelection` が動いてしまうため
   */
  validationKeyForMerged: string[]
}
export const mergeForm = (
  form: PlacementFormType,
  hasSummaryCourtLegalRepresentation: boolean,
  isCorporation: boolean = false
): MergeFormProps => {
  // 個別対応
  const previewFormOriginal = getDefaultValues(form, hasSummaryCourtLegalRepresentation, isCorporation)
  // 必須項目対応
  const previewFormRequired = getRequiredDummyText(form)

  // 個別対応 > 必須項目対応 > 元のform値 という優先順にマージする
  const mightNoMultiKeys = kv(
    Object.entries(form).map(([key, value]) => {
      if (
        (key.startsWith(MULTI_FORM_KEYS.prefecture) ||
          key.startsWith(MULTI_FORM_KEYS.city) ||
          key.startsWith(MULTI_FORM_KEYS.isWholeCity)) &&
        !previewFormOriginal[key]
      ) {
        return
      }
      return [key, value]
    })
  ) as PlacementFormType
  const merged = { ...mightNoMultiKeys, ...previewFormRequired, ...previewFormOriginal }

  const validationKeyForMerged = Object.keys(form).filter(
    (key) =>
      !key.startsWith(MULTI_FORM_KEYS.prefecture) &&
      !key.startsWith(MULTI_FORM_KEYS.city) &&
      !key.startsWith(MULTI_FORM_KEYS.isWholeCity)
  )

  return {
    merged,
    validationKeyForMerged,
  }
}

/**
 * 指定した values は内部で変更されず、新たな変数として返す
 */
const getDefaultValues = (
  values: PlacementFormType,
  hasSummaryCourtLegalRepresentation: boolean,
  isCorporation: boolean
) => {
  // 動的なキーが使えないので、手動で対応
  const previewForm: {
    [key: string]: string | string[] | readonly [dayjs.Dayjs, dayjs.Dayjs] | RegularHoliday | boolean | boolean[]
  } = {}

  // 住所が未入力の場合
  if (
    !values.postCodeFirst ||
    !values.postCodeSecond ||
    !values.prefecture ||
    !values.city ||
    !values.townAndStreetAddress
  ) {
    previewForm[formNames.postCode.first] = DEFAULT_PREVIEW_FORM_VALUES.postCode.first
    previewForm[formNames.postCode.second] = DEFAULT_PREVIEW_FORM_VALUES.postCode.second
    previewForm[formNames.prefecture] = DEFAULT_PREVIEW_FORM_VALUES.prefecture
    previewForm[formNames.city] = DEFAULT_PREVIEW_FORM_VALUES.city
    previewForm[formNames.townAndStreetAddress] = DEFAULT_PREVIEW_FORM_VALUES.townAndStreetAddress
  }

  // 受付時間
  if (!values.receptionHours || values.receptionHours.length !== 2) {
    previewForm[formNames.reception.receptionHours] = DEFAULT_PREVIEW_FORM_VALUES.reception.receptionHours
  }
  if (!values.regularHoliday || values.regularHoliday.length === 0) {
    previewForm[formNames.reception.regularHoliday] = DEFAULT_PREVIEW_FORM_VALUES.reception.regularHoliday
  }

  // 相談料
  if (!values.consultationFeeType) {
    previewForm[formNames.consultationFee.consultationFeeType] =
      DEFAULT_PREVIEW_FORM_VALUES.consultationFee.consultationFeeType
  }

  // 代表者
  // representativeSurname, representativeGivenName は掲載申し込みで使っていない
  multipleKeyIndex(values, MULTI_FORM_KEYS.representativeName).forEach((index) => {
    const surnameKey = formTypeKey(`representativeSurname${index}`)
    const givenNameKey = formTypeKey(`representativeGivenName${index}`)
    if (!values[surnameKey] || !values[givenNameKey]) {
      previewForm[surnameKey] = `${DEFAULT_PREVIEW_FORM_VALUES.representativeSurname}${index + 1}`
      previewForm[givenNameKey] = DEFAULT_PREVIEW_FORM_VALUES.representativeGivenName
    }
  })

  // 簡裁訴訟代理等関係業務（司法書士の場合）
  if (values.professionalCategory === 'judicialScrivener' && hasSummaryCourtLegalRepresentation) {
    // 代表者
    if (!values.summaryCourtLegalRepresentationSurname || !values.summaryCourtLegalRepresentationGivenName) {
      previewForm[formNames.summaryCourtLegalRepresentation.specifiedMember.surname] =
        formProps.summaryCourtLegalRepresentation.specifiedMember(isCorporation).label
      previewForm[formNames.summaryCourtLegalRepresentation.specifiedMember.givenName] =
        DEFAULT_PREVIEW_FORM_VALUES.summaryCourtLegalRepresentation.specifiedMember.givenName
    }
    // 簡裁代理認定番号
    if (!values.summaryCourtLegalRepresentationCertificationNumber) {
      previewForm[formNames.summaryCourtLegalRepresentation.certificationNumber] =
        DEFAULT_PREVIEW_FORM_VALUES.summaryCourtLegalRepresentation.certificationNumber
    }
  }

  // 担当者
  if (!values.contactSurname || !values.contactGivenName) {
    previewForm[formNames.contactSurname] = DEFAULT_PREVIEW_FORM_VALUES.contactSurname
    previewForm[formNames.contactGivenName] = DEFAULT_PREVIEW_FORM_VALUES.contactGivenName
  }

  // 所属団体
  if (!values.affiliation && !values.affiliationOther) {
    // 所属団体、所属団体その他が未入力の場合
    previewForm[formNames.affiliation] = DEFAULT_PREVIEW_FORM_VALUES.affiliation
    previewForm[formNames.affiliationOther] = DEFAULT_PREVIEW_FORM_VALUES.affiliationOther
  } else if (enableAffiliationOther(values.affiliation) && !values.affiliationOther) {
    // 所属団体が「該当する所属団体がない」かつ所属団体その他が未入力の場合
    previewForm[formNames.affiliationOther] = DEFAULT_PREVIEW_FORM_VALUES.affiliationOther
  }

  // 電話番号
  if (!values.phoneNumber) {
    previewForm[formNames.phoneNumber] = DEFAULT_PREVIEW_FORM_VALUES.phoneNumber
  }

  // メールアドレス
  if (!values.emailAddress) {
    previewForm[formNames.emailAddress] = DEFAULT_PREVIEW_FORM_VALUES.emailAddress
  }

  // 対応エリア
  if (!values.isWholeArea) {
    const supportAreaKeys = Object.keys(values).filter((key) => key.startsWith(MULTI_FORM_KEYS.prefecture))
    supportAreaKeys.forEach((key) => {
      const index = key.split('_')[1]

      const isWholeCityKey = formTypeKey(`${MULTI_FORM_KEYS.isWholeCity}${index}`)
      const prefectureKey = formTypeKey(`${MULTI_FORM_KEYS.prefecture}${index}`)
      const cityKey = formTypeKey(`${MULTI_FORM_KEYS.city}${index}`)

      const isWholeCity = values[isWholeCityKey]
      const prefecture = values[prefectureKey]
      const city = values[cityKey]

      if (isWholeCity) previewForm[isWholeCityKey] = isWholeCity
      if (prefecture) previewForm[prefectureKey] = prefecture
      if (city) previewForm[cityKey] = city

      if (!prefecture) {
        // 都道府県（全国指定とする）
        previewForm[formNames.isWholeArea] = true
        delete previewForm[isWholeCityKey]
        delete previewForm[prefectureKey]
        delete previewForm[cityKey]
      } else if (
        isWholeCity === DEFAULT_PREVIEW_FORM_VALUES.supportArea.isWholeCity.pick &&
        Array.isArray(city) &&
        city.length === 0
      ) {
        // 市区町村（全域とする）
        previewForm[isWholeCityKey] = DEFAULT_PREVIEW_FORM_VALUES.supportArea.isWholeCity.whole
        previewForm[cityKey] =
          composeAreaOption(supportAreaMaster)
            .find((v) => v.prefecture.value === prefecture)
            ?.cities.map((c) => c.value) || []
      }
    })
  }

  // カナ
  Object.keys(values)
    .filter((v) => MULTI_FORM_KEYS.kana.test(v))
    .forEach((key) => {
      if (values[formTypeKey(key)]) return
      previewForm[key] = DEFAULT_PREVIEW_FORM_VALUES.kana
    })

  return previewForm
}

const requireKeys = Object.entries(formProps)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .filter(([_, value]) => 'rules' in value && value.rules.includes(RULES.requireEntry))
  .map(([key]) => key)

const requireLabel = kv(
  Object.entries(formProps).map(([key, value]) => {
    if (!('label' in value)) return undefined
    return [key, value.label]
  })
)

/**
 * 指定した values は内部で変更されず、新たな変数として返す
 */
const getRequiredDummyText = (values: PlacementFormType) =>
  kv(
    Object.entries(values).map(([key, value]) => {
      if (value) return
      if (requireKeys.includes(key)) {
        return [key, `${requireLabel[key]}が表示されます`]
      }
    })
  )

const multipleKeyRegex = /[0-9]+/

/**
 * 複数項目キーのインデックスを取得する
 * @param formType
 * @param key 複数項目キー
 */
const multipleKeyIndex = (formType: PlacementFormType, key: RegExp) => {
  return Array.from(
    new Set(
      Object.keys(formType)
        .filter((v) => key.test(v))
        .map((v) => v.match(multipleKeyRegex)?.at(0))
    )
  )
    .filter(Boolean)
    .map(Number)
    .sort()
}

const force = <T>(value: string): keyof T => value as keyof T
export const formTypeKey = (value: string) => force<PlacementFormType>(value)
