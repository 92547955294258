// eslint-disable-next-line node/no-extraneous-import
import { format, parse, fromUnixTime, parseISO } from 'date-fns'
import ja from 'date-fns/locale/ja'
export * as dateFns from 'date-fns'

export const DATE_FORMAT = {
  'yyyy/M/d': 'yyyy/M/d',
  yyyy年M月d日: 'yyyy年M月d日',
  yyyy年M月: 'yyyy年M月',
} as const

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

/**
 * @param date Date
 * @returns yyyy-MM-dd HH:mm:ss
 */
export const dateObjectToDateTime = (date: Date) => {
  return format(date, DATE_TIME_FORMAT, { locale: ja })
}

/**
 * @param unixtime エポック秒
 * @returns yyyy-MM-dd HH:mm:ss
 */
export const unixTimeToDateTime = (unixtime: number) => {
  return format(fromUnixTime(unixtime), DATE_TIME_FORMAT, { locale: ja })
}

/**
 * @param dateString yyyy-MM-dd または yyyy-M-d 形式の文字列
 * @param dateFormat 返却値の日付形式
 */
export const dateStringToDate = (dateString: string, dateFormat: keyof typeof DATE_FORMAT) => {
  return format(parse(dateString, 'yyyy-MM-dd', new Date()), dateFormat, {
    locale: ja,
  })
}

/**
 * @param dateTimeString yyyy-MM-ddTHH:mm:ss.SSSZ 形式 (ISO8601)の文字列
 * @param dateFormat 返却値の日付形式
 */
export const isoDateToDate = (dateTimeString: string, dateFormat: keyof typeof DATE_FORMAT) => {
  return format(parseISO(dateTimeString), dateFormat, {
    locale: ja,
  })
}
