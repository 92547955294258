import { css } from '@emotion/react'
import { Typography } from 'antd'
import { EmailForm, EmailFormProps } from './EmailForm'
import { Wave } from './Wave'

const styles = {
  wrap: css`
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  heading: css`
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    margin-bottom: 12px;
  `,
  texts: css`
    width: 540px;
    margin: 0 auto;
  `,
  text: css`
    display: block;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 12px;
  `,
  list: css`
    text-align: left;
    margin-bottom: 12px;
  `,
  listText: css`
    display: block;
    font-size: 12px;
    line-height: 20px;
  `,
  date: css`
    display: block;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    margin-top: 24px;
  `,
  emailForm: css`
    width: 408px;
    margin: 64px auto 32px;
  `,
}

export const FooterForm = (props: { emailFormProps: EmailFormProps }) => {
  return (
    <div css={styles.wrap}>
      <Wave type="gray" direction="down" />
      <EmailForm {...props.emailFormProps} rootCss={styles.emailForm} showInquiryLink />
      <div css={styles.texts}>
        <Typography.Text css={styles.heading}>申し込みにあたってのご留意事項</Typography.Text>
        <Typography.Text css={styles.text}>
          そうぞくガイドへの広告掲載は有料となります。詳細は、上記にてお申し込みいただいた方に直接ご案内いたします。
        </Typography.Text>
        <Typography.Text css={styles.text}>
          そうぞくガイドへの広告掲載には、当行の個別審査がございます。そうぞくガイドへの広告掲載にあたっては、以下の条件を満たしていただく必要がございます。尚、現時点において、三菱ＵＦＪ銀行と預金取引がない場合は、三菱ＵＦＪ銀行の口座を開設いただいてから、申し込みをお願いいたします。
        </Typography.Text>
        <div css={styles.list}>
          <Typography.Text css={styles.listText}>・国内居住者に該当すること</Typography.Text>
          <Typography.Text css={styles.listText}>・当行と預金取引があること</Typography.Text>
        </div>
        <Typography.Text css={styles.text}>
          そうぞくガイドは、専門家（弁護士・税理士・司法書士）事務所情報を検索いただけるWebサービスとなります。三菱ＵＦＪ銀行は、そうぞくガイドを通じて、法律等で禁止されている弁護士・司法書士の周旋等、および、専門家とお客さまとのやり取りには一切関与いたしません。
        </Typography.Text>
        <Typography.Text css={styles.text}>
          画面はイメージです。画面のデザイン・仕様は今後予告なく変更となる場合があります。
        </Typography.Text>
        <Typography.Text css={styles.date}>2024年3月5日現在</Typography.Text>
      </div>
    </div>
  )
}
