import { getPath, getSettingPath } from '@/router'
import { useNavigate, Navigate } from 'react-router-dom'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { css } from '@emotion/react'
import { Button, Divider, Tabs, TabsProps } from 'antd'
import { AccessInformationContainer } from './containers/AccessInformation'
import { ContractInformationContainer } from './containers/ContractInformationContainer'
import { PasswordChangeContainer } from './containers/PasswordChange'
import { ChurnApplicationForm } from './containers/ChurnApplicationForm'
import { useQueryParamsOptionally } from 'inheritance-router'
import { AGREEMENTPROPS } from '@/share/components/Agreements/AgreementProps'
import { AgreementContent } from '@/share/components/Agreements/AgreementContent'
import { useProfessionalSelf } from '@/rpc/professionalSelf/useSWR'
import { GetProfessionalSelfResponse } from 'inheritance-api'
import { ChurnStatus } from '@/pages//Home/components/profile-parts/ChurnStatus'
import { getTypeSafeHomeValue } from '@/pages/Home/homeValue'
import { NotChurnableMessage } from './containers/NotChurnableMessage'

const styles = {
  contentBody: css`
    margin: 0 auto;
    max-width: 800px;
    padding-top: 40px;
  `,
  footer: css`
    text-align: center;
  `,
  devider: css`
    margin: 40px 0;
  `,
  backButton: css`
    margin-bottom: 40px;
  `,
  churnStatus: css`
    text-align: center;
    margin-top: 24px;
  `,
}

export const SETTING_KEY = {
  'contract-information': 'contract-information',
  'password-change': 'password-change',
  'access-information': 'access-information',
  'privacy-policy': 'privacy-policy',
  terms: 'terms',
  'churn-application': 'churn-application',
} as const

type SettinKey = keyof typeof SETTING_KEY

const isSettingKey = (key?: string): key is SettinKey => {
  return Object.keys(SETTING_KEY).some((k) => k === key)
}

const useTabs = (professional: GetProfessionalSelfResponse): TabsProps['items'] => {
  const homeValue = getTypeSafeHomeValue(professional)
  return [
    { key: SETTING_KEY['contract-information'], label: '利用申込情報', children: <ContractInformationContainer /> },
    { key: SETTING_KEY['password-change'], label: 'パスワード変更', children: <PasswordChangeContainer /> },
    {
      key: SETTING_KEY['access-information'],
      label: 'アクセス情報',
      children: <AccessInformationContainer />,
    },

    {
      key: SETTING_KEY['privacy-policy'],
      label: 'プライバシーポリシー',
      children: <AgreementContent agreementProps={AGREEMENTPROPS.PRIVACYPOLICY} />,
    },
    {
      key: SETTING_KEY['terms'],
      label: '利用規約',
      children: <AgreementContent agreementProps={AGREEMENTPROPS.TERMSOFSERVICE} />,
    },
    {
      key: SETTING_KEY['churn-application'],
      label: '解約申し込み',
      children:
        professional.isChurnable === false ? (
          <NotChurnableMessage />
        ) : homeValue.profileChurnStatus === 'can-apply' ? (
          <ChurnApplicationForm />
        ) : (
          <ChurnStatus
            profileChurnStatus={homeValue.profileChurnStatus}
            placementEndDate={
              homeValue.profileChurnStatus === 'terminated'
                ? homeValue.forceChurn.endDate
                : homeValue.churnApplication.endDate
            }
            rootCss={styles.churnStatus}
          />
        ),
    },
  ]
}

export const Setting = () => {
  const navigate = useNavigate()
  const { professional } = useProfessionalSelf()
  const { key } = useQueryParamsOptionally({ keys: ['key'] })
  const tabs = useTabs(professional)

  const onTabClick = (key: string) => {
    if (!isSettingKey(key)) return
    navigate(getSettingPath(key))
  }

  if (!isSettingKey(key)) {
    // keyが不正な場合は設定:利用申込情報にリダイレクト
    return <Navigate to={getSettingPath('contract-information')} />
  }

  return (
    <AppLayout>
      <ContentTitleHeader title="設定" />
      <section css={styles.contentBody}>
        <Tabs activeKey={key} centered items={tabs} onTabClick={onTabClick} />
        {key !== 'churn-application' && (
          <footer css={styles.footer}>
            <Divider css={styles.devider} />
            <Button css={styles.backButton} htmlType="button" href={getPath('home')}>
              専門家情報に戻る
            </Button>
          </footer>
        )}
      </section>
    </AppLayout>
  )
}
