import { apiClient, getApiPhoneAuthProfessionalClient } from '../client'
import { buildRethrowingError } from '../buildRethrowingError'
import { AxiosError } from 'axios'

export const callGetProfessionalCreateAccountValidate = async ({
  createAccountToken,
}: {
  createAccountToken: string
}) => {
  try {
    const { data: validation } = await apiClient.getProfessionalCreateAccountValidate({
      createAccountToken: createAccountToken,
    })

    return { validation }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}

export const callPutProfessionalCreateAccount = async (param: {
  accountInfo: {
    password: string
  }
  createAccountToken: string
  'g-recaptcha-response': string
}): Promise<{ error?: Error }> => {
  try {
    const apiClient = await getApiPhoneAuthProfessionalClient()
    await apiClient.putProfessionalCreateAccount(param)
    return {}
  } catch (e) {
    if (e instanceof AxiosError && e.response && e.response.status === 401) {
      return { error: e }
    }
    return { error: buildRethrowingError(e) }
  }
}
