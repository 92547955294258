import { getApiAuthProfessionalClient } from '../client'
import type { PutProfessionalPlacementRequest } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
export type { GetProfessionalPlacementDefaultsResponse } from 'inheritance-api'

export const callGetProfessionalPlacementDefaults = async () => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    const { data: placementApplicationFormValue } = await apiClient.getProfessionalPlacementDefaults({
      secure: true,
    })
    return { placementApplicationFormValue }
  } catch (e) {
    throw buildRethrowingError
  }
}

export const callPutProfessionalPlacement = async ({
  data,
}: {
  data: PutProfessionalPlacementRequest
}): Promise<{ error?: Error }> => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    await apiClient.putProfessionalPlacement(data, { secure: true })
    return {}
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
