import { useEffect } from 'react'

/**
 * ブラウザを閉じたり、更新する際にブラウザ実装の確認ダイアログを表示するためのhook
 */
export const usePreventBrowserClosing = () => {
  useEffect(() => {
    const beforeunloadHandler = (e: Event) => {
      // Cancel the event as stated by the standard.
      e.preventDefault()

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // Chrome requires returnValue to be set.
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', beforeunloadHandler)
    return () => window.removeEventListener('beforeunload', beforeunloadHandler)
  }, [])
}
