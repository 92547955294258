import areaJson from '../json/support_area.json'
import { SupportAreaMaster } from '../types'

export const supportAreaMaster: SupportAreaMaster[] = areaJson.map((a) => ({
  areaCode: a.area_code,
  prefectureCode: a.prefecture_code,
  areaName: a.area_name,
}))

export const PREFECTURE: {
  code: string
  name: string
}[] = [
  { code: 'hokkaido_01', name: '北海道' },
  { code: 'aomori_02', name: '青森県' },
  { code: 'iwate_03', name: '岩手県' },
  { code: 'miyagi_04', name: '宮城県' },
  { code: 'akita_05', name: '秋田県' },
  { code: 'yamagata_06', name: '山形県' },
  { code: 'fukushima_07', name: '福島県' },
  { code: 'ibaraki_08', name: '茨城県' },
  { code: 'tochigi_09', name: '栃木県' },
  { code: 'gunma_10', name: '群馬県' },
  { code: 'saitama_11', name: '埼玉県' },
  { code: 'chiba_12', name: '千葉県' },
  { code: 'tokyo_13', name: '東京都' },
  { code: 'kanagawa_14', name: '神奈川県' },
  { code: 'niigata_15', name: '新潟県' },
  { code: 'toyama_16', name: '富山県' },
  { code: 'ishikawa_17', name: '石川県' },
  { code: 'fukui_18', name: '福井県' },
  { code: 'yamanashi_19', name: '山梨県' },
  { code: 'nagano_20', name: '長野県' },
  { code: 'gifu_21', name: '岐阜県' },
  { code: 'shizuoka_22', name: '静岡県' },
  { code: 'aichi_23', name: '愛知県' },
  { code: 'mie_24', name: '三重県' },
  { code: 'shiga_25', name: '滋賀県' },
  { code: 'kyoto_26', name: '京都府' },
  { code: 'osaka_27', name: '大阪府' },
  { code: 'hyogo_28', name: '兵庫県' },
  { code: 'nara_29', name: '奈良県' },
  { code: 'wakayama_30', name: '和歌山県' },
  { code: 'tottori_31', name: '鳥取県' },
  { code: 'shimane_32', name: '島根県' },
  { code: 'okayama_33', name: '岡山県' },
  { code: 'hiroshima_34', name: '広島県' },
  { code: 'yamaguchi_35', name: '山口県' },
  { code: 'tokushima_36', name: '徳島県' },
  { code: 'kagawa_37', name: '香川県' },
  { code: 'ehime_38', name: '愛媛県' },
  { code: 'kouchi_39', name: '高知県' },
  { code: 'fukuoka_40', name: '福岡県' },
  { code: 'saga_41', name: '佐賀県' },
  { code: 'nagasaki_42', name: '長崎県' },
  { code: 'kumamoto_43', name: '熊本県' },
  { code: 'oita_44', name: '大分県' },
  { code: 'miyazaki_45', name: '宮崎県' },
  { code: 'kagoshima_46', name: '鹿児島県' },
  { code: 'okinawa_47', name: '沖縄県' },
]
