import { RangePickerForm, SingleTextInputForm } from 'inheritance-components'
import { formProps } from './form'
import { CheckboxForm } from '../form/CheckboxForm'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { css } from '@emotion/react'
import React from 'react'
import {
  RECEPTION_CALL_24_HOURS_AVAILABLE_OPTIONS,
  REGULAR_HOLIDAY_NOT_APPLICABLE,
  REGULAR_HOLIDAY_OPTIONS,
} from 'inheritance-utils'

const useStyle = () => {
  return {
    textArea: css`
      margin-bottom: 24px;
    `,
  }
}

type ReceptionHoursProps = {
  /**
   * 定休日についての仕様
   * - 「定休日なし」が既に選ばれている状態は、「定休日なし」を消す必要がある
   * - 「定休日なし」が選ばれた場合は、「定休日なし」以外の定休日を消す必要がある
   * @description
   * - antd の Form で State の管理をする設計のため、実際のデータ反映は `setRegularHolidayForm` で更新させる
   */
  setRegularHolidayForm: (value: CheckboxValueType[]) => void
}

export const ReceptionHoursRelations = ({ setRegularHolidayForm }: ReceptionHoursProps) => {
  const handleChangeRegularHoliday = useRegularHolidayHandler({ setRegularHolidayForm })
  const style = useStyle()

  return (
    <>
      {/* 受付時間（日本時間） */}
      <RangePickerForm {...formProps['reception']['receptionHours']} style={{ width: '100%' }} />

      {/* 24時間電話受付可否 */}
      <CheckboxForm
        {...formProps['reception']['receptionCall24HoursAvailable']}
        options={RECEPTION_CALL_24_HOURS_AVAILABLE_OPTIONS}
      />

      {/* 受付時間に関する備考 */}
      <SingleTextInputForm {...formProps['reception']['receptionHoursNote']} rootCss={style.textArea} />

      {/* 定休日 */}
      <CheckboxForm
        {...formProps['reception']['regularHoliday']}
        options={REGULAR_HOLIDAY_OPTIONS}
        onChange={handleChangeRegularHoliday}
      />
    </>
  )
}

/**
 * 定休日についての仕様
 * - 「定休日なし」が既に選ばれている状態は、「定休日なし」を消す必要がある
 * - 「定休日なし」が選ばれた場合は、「定休日なし」以外の定休日を消す必要がある
 *
 * @description
 * - antd の `Checkbox.Group` は選択順を保持してくれないため、チェックボックスをコントロールする処理に関しては React#useState で管理する
 * - antd の Form で State の管理をする設計のため、実際のデータ反映は `setRegularHolidayForm` で更新させる
 */
const useRegularHolidayHandler = ({ setRegularHolidayForm }: ReceptionHoursProps) => {
  const [regularHoliday, setRegularHoliday] = React.useState<CheckboxValueType[]>([])

  const handleChange = React.useCallback(
    (e: CheckboxValueType[]) => {
      if (regularHoliday.includes(REGULAR_HOLIDAY_NOT_APPLICABLE)) {
        // 「定休日なし」が既に選ばれている状態は、「定休日なし」を消す必要がある
        const closed = e.filter((v) => v !== REGULAR_HOLIDAY_NOT_APPLICABLE)
        setRegularHoliday(closed)
        setRegularHolidayForm(closed)
      } else if (e.includes(REGULAR_HOLIDAY_NOT_APPLICABLE)) {
        // 「定休日なし」が選ばれた場合は、「定休日なし」以外の定休日を消す必要がある
        setRegularHoliday([REGULAR_HOLIDAY_NOT_APPLICABLE])
        setRegularHolidayForm([REGULAR_HOLIDAY_NOT_APPLICABLE])
      }
      // それ以外の場合は、そのまま何もせずに値を渡す
    },
    [regularHoliday, setRegularHolidayForm]
  )
  return handleChange
}
