import { css } from '@emotion/react'
import { Button, Col, Divider, Row, Space } from 'antd'
import { HomeValueType, ProfileValueType } from '../../homeValue'
import { ProfileStatusDescription, ProfileStatusDescriptionProps } from './ProfileStatusDescription'
import { ProfileDetail, ProfileDetailProps } from './ProfileDetail'
import {
  ContactSuspendEventHandler,
  PublicInfoContactSuspendedButton,
} from './publicInfo/PublicInfoContactSuspendedButton'
import { ContactSuspendedGuideline } from 'inheritance-components'

const styles = {
  description: css`
    text-align: center;
    margin-top: 48px;
    margin-bottom: 32px;
  `,
  form: css`
    margin: 0 auto;
    max-width: 1000px;
  `,
}

const judgePlacementEditButtonVisible = ({
  placementEditable,
  homeCondition,
  tabKind,
}: {
  placementEditable: boolean
  homeCondition: HomeValueType['homeCondition']
  tabKind: TabKind
}) => {
  /* 2タブの場合、編集画面への遷移は申請済み掲載情報からのみ可能とする */
  if (homeCondition === 'placement-n-public-tab' || homeCondition === 'placement-n-churn-tab') {
    return tabKind === 'placement' && placementEditable
  }

  return placementEditable
}

type TabKind = 'placement' | 'public'

export const ProfileTabContent = ({
  tabKind,
  homeValues,
  detail,
  statusDescriptionProps,
  onEditClick,
  onContactSuspend,
}: {
  tabKind: TabKind
  homeValues: ProfileValueType
  statusDescriptionProps: ProfileStatusDescriptionProps
  detail: ProfileDetailProps
  onEditClick: (usePublicContent: boolean) => void
  onContactSuspend: ContactSuspendEventHandler
}) => {
  const { homeCondition } = homeValues

  const onEdit = () => {
    onEditClick(homeCondition === 'placement-n-public-tab' && homeValues.publicInformation.suspended)
  }

  return (
    <Row>
      <Col offset={2} span={20}>
        <div css={styles.description}>
          <ProfileStatusDescription {...statusDescriptionProps} />
        </div>
        <section css={styles.form}>
          {detail.kind === 'public' && <ContactSuspendedGuideline contactSuspended={detail.contactSuspended} />}
          <ProfileDetail {...detail} />
          <BottomButtonGroup
            tabKind={tabKind}
            homeValues={homeValues}
            detail={detail}
            onContactSuspendedButtonClick={onContactSuspend}
            onPlacementEditButtonClick={onEdit}
          />
        </section>
      </Col>
    </Row>
  )
}

const useBottomButtonStyle = () => {
  return {
    button: css`
      padding-top: 30px;
      padding-bottom: 30px;
    `,
    buttonWrapper: css`
      margin: 0 auto;
      padding-top: 120px;
      text-align: center;
    `,

    divider: css`
      margin: 0;
    `,
  }
}

const BottomButtonGroup = ({
  tabKind,
  homeValues,
  detail,
  onContactSuspendedButtonClick: onContactSuspnededButtonClick,
  onPlacementEditButtonClick,
}: {
  tabKind: TabKind
  homeValues: ProfileValueType
  detail: ProfileDetailProps
  onPlacementEditButtonClick: () => void
  onContactSuspendedButtonClick: ContactSuspendEventHandler
}) => {
  const style = useBottomButtonStyle()
  const { homeCondition, placementEditable } = homeValues

  const isPlacementEditButtonVisibe = judgePlacementEditButtonVisible({ placementEditable, homeCondition, tabKind })
  const isContactSuspendedButtonVisible = detail.kind === 'public'

  return (
    <div css={style.buttonWrapper}>
      <Divider css={style.divider} />
      {(isPlacementEditButtonVisibe || isContactSuspendedButtonVisible) && (
        <div css={style.button}>
          <Space>
            {isContactSuspendedButtonVisible && (
              <PublicInfoContactSuspendedButton
                contactSuspended={detail.contactSuspended}
                onContactSuspend={onContactSuspnededButtonClick}
              />
            )}
            {isPlacementEditButtonVisibe && (
              <Button type="primary" onClick={onPlacementEditButtonClick}>
                掲載情報を修正する
              </Button>
            )}
          </Space>
        </div>
      )}
    </div>
  )
}
