import { apiClient, getApiPhoneAuthProfessionalClient } from '../client'
import { buildRethrowingError } from '../buildRethrowingError'
import { GetProfessionalPasswordResetValidateResponse } from 'inheritance-api'
import { AxiosError } from 'axios'

export const callPostProfessionalPasswordResetSendEmail = async ({
  emailAddress,
  recaptchaToken,
}: {
  emailAddress: string
  recaptchaToken: string
}) => {
  try {
    await apiClient.postProfessionalPasswordResetSendEmail({
      emailAddress,
      'g-recaptcha-response': recaptchaToken,
    })

    return { error: undefined }
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}

export const callGetProfessionalPasswordResetValidate = async ({
  passwordResetToken,
}: {
  passwordResetToken: string
}): Promise<GetProfessionalPasswordResetValidateResponse> => {
  try {
    const { data } = await apiClient.getProfessionalPasswordResetValidate({ passwordResetToken })

    return data
  } catch (e) {
    throw buildRethrowingError(e)
  }
}

export const callPutProfessionalPasswordResetConfirm = async ({
  password,
  passwordResetToken,
  recaptchaToken,
}: {
  password: string
  passwordResetToken: string
  recaptchaToken: string
}) => {
  try {
    const apiClient = await getApiPhoneAuthProfessionalClient()
    await apiClient.putProfessionalPasswordResetConfirm(
      {
        password,
        passwordResetToken,
        'g-recaptcha-response': recaptchaToken,
      },
      { secure: true }
    )

    return { error: undefined }
  } catch (e) {
    if (e instanceof AxiosError && e.response && e.response.status === 401) {
      return { error: e }
    }
    return { error: buildRethrowingError(e) }
  }
}
