import { css, SerializedStyles } from '@emotion/react'
import { VerticalDescription } from '../text'
import { useProAndMgmtDesignToken } from '../design-system/token'

const useStyles = () => {
  const { themeColor } = useProAndMgmtDesignToken()

  return {
    root: css`
      text-align: center;
    `,
    paragraph: css`
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      padding: 9px 16px;
      margin: 0;
      background: ${themeColor.background.red};
      border: 1px solid ${themeColor.border.red};
      border-radius: 2px;
    `,
  }
}

export const Guideline = ({
  rootCss,
  paragraphCss,
  texts,
}: {
  rootCss?: SerializedStyles
  paragraphCss?: SerializedStyles
  texts: string[]
}) => {
  const styles = useStyles()

  return (
    <div css={css(styles.root, rootCss)}>
      <p css={css(styles.paragraph, paragraphCss)}>
        <VerticalDescription description={texts} />
      </p>
    </div>
  )
}
