import { css } from '@emotion/react'
import { Typography } from 'antd'
import { Separator } from './Separator'
import { useLandingPageDesignToken } from './design-system/useLandingPageDesignToken'

const useStyles = () => {
  const { color } = useLandingPageDesignToken()
  return {
    wrap: css`
      text-align: center;
      padding: 48px 0 24px;
      background-color: ${color.background.purple};
    `,
    content: css`
      display: inline-block;
    `,
    heading: css`
      font-size: 30px;
      line-height: 48px;
      font-weight: bold;
      margin: 8px 0 20px;
      em {
        color: ${color.accent.red.default};
      }
    `,
    text: css`
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    `,
  }
}

export const Demands = () => {
  const styles = useStyles()

  return (
    <div css={styles.wrap}>
      <div css={styles.content}>
        <h2 css={styles.heading}>
          このような <em>お悩み・ご要望</em> はございませんか？
        </h2>
        <Separator />
        <Typography.Text css={styles.text}>新しい集客チャネルを探している</Typography.Text>
        <Separator />
        <Typography.Text css={styles.text}>相続分野に注力していることをアピールしたい</Typography.Text>
        <Separator />
        <Typography.Text css={styles.text}>相続でお悩みのお客さまにアプローチしたい</Typography.Text>
        <Separator />
      </div>
    </div>
  )
}
