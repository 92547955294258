import { css } from '@emotion/react'
import { Layout } from 'antd'
import { ReactNode } from 'react'
import { designToken, useProAndMgmtDesignToken } from '../design-system/token'

const layoutCss = css`
  min-width: ${designToken.layout.minWidth}px;
`

const contentMargin = {
  top: 40,
  bottom: 40,
}

/**
 * 上下(垂直方向)マージンの和
 */
const contentVerticalMarginSum = contentMargin.top + contentMargin.bottom

const contentMinHeightCss = css`
  min-height: calc(100vh - ${designToken.layout.globalHeaderHeight}px - ${contentVerticalMarginSum}px);
`

const contentCss = css`
  max-width: ${designToken.layout.contentMaxWidth}px;

  margin-top: ${contentMargin.top}px;
  margin-bottom: ${contentMargin.bottom}px;
  margin-left: ${designToken.topBottomLayout.sideMargin};
  margin-right: ${designToken.topBottomLayout.sideMargin};

  ${contentMinHeightCss}
`

const useInnerBoxCss = () => {
  const { color } = useProAndMgmtDesignToken()

  return css`
    background-color: ${color.background.white};
    ${contentMinHeightCss}
  `
}

/**
 * using Top-Bottom Layout
 * @see: https://ant.design/docs/spec/layout#2.-Top-Bottom-Layout
 * */
export const TopBottomLayout = (props: { children: ReactNode; globalHeader: ReactNode }) => {
  return (
    <Layout css={layoutCss}>
      {props.globalHeader}
      <Layout.Content css={contentCss}>
        <div css={useInnerBoxCss()}>{props.children}</div>
      </Layout.Content>
    </Layout>
  )
}
