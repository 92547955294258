import { Form, Checkbox, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { css } from '@emotion/react'
import { FormLabel, FormLabelWithGuideText } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'

export const CheckboxForm = ({
  name,
  label,
  rules,
  options,
  row = false,
  itemsPerRow = 1,
  onChange = undefined,
  annotation,
  guideText,
  colon,
}: {
  name: string
  label: string
  rules: Rule[]
  options?: {
    label: string
    value: string | boolean
  }[]
  row?: boolean
  itemsPerRow?: 1 | 3
  onChange?: (v: CheckboxValueType[]) => void
  annotation?: string
  guideText?: string
  colon?: boolean
}) => {
  const styles = {
    group: css`
      display: ${row ? 'inline-block' : 'inline-flex'};
      flex-direction: ${row ? 'inherit' : 'column'};
      label {
        width: ${itemsPerRow === 1 ? '100%' : `${100 / 3}%`};
        margin: 0;
        // antdスタイル打ち消し
        margin-inline-start: 0 !important;
      }
    `,
    annotation: css`
      display: block;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      margin-top: 4px;
    `,
  }
  return (
    <Form.Item
      hasFeedback
      name={name}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      // labelでrequiredを表現するため「*」は不要
      rules={rules}
      required={false}
      extra={annotation && <Typography.Text css={styles.annotation}>{annotation}</Typography.Text>}
      colon={colon}
    >
      <Checkbox.Group options={options} css={styles.group} onChange={onChange} />
    </Form.Item>
  )
}
