import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * @param keys
 * クエリパラメーターから取得したいキーの名前を指定する
 * Union型の配列で渡す
 *
 * @param errorWhenFailed
 * クエリパラメーターの取得に失敗した時のエラー
 *
 * @returns
 * keysに渡した値をkeyとするオブジェクトを型安全に返す
 *
 * @example
 *
 * ```
 * const { email, token } = useQueryParamsDefinitely({ keys: ['email','token'], errorWhenFailed: new Error('無効なURLです') })
 * ```
 */
export const useQueryParamsDefinitely = <U extends string>({
  keys,
  errorWhenFailed,
}: {
  keys: U[]
  errorWhenFailed: Error
}): { [key in U]: string } => {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const getParam = (name: string) => {
      const param = params.get(name)
      if (!param) {
        throw errorWhenFailed
      }
      return param
    }

    return keys.reduce((acc, cur) => {
      return { ...acc, [cur]: getParam(cur) }
    }, {}) as { [key in U]: string }
  }, [errorWhenFailed, keys, search])
}
