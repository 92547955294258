import { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Button, FormInstance, Typography, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { DateForm } from '@/share/components/form/DateForm'
import { DoubleTextInputForm } from '@/share/components/form/DoubleTextInputForm'
import { SingleTextInputForm } from 'inheritance-components'
import { representativeFormNames, formProps, MAX_REPRESENTATIVE_NUMBER } from './form'
import { ContractFormType } from '@/pages/ContractApplicationForm/form'
import { PlacementFormType } from '@/share/components/PlacementForm/form'
import { RepresentativeItem, GetProfessionalPlacementDefaultsResponse } from 'inheritance-api'
import { useDesignToken } from '../design-system/token'
import { PlacementFormStorageData } from '@/share/components/PlacementForm/functions/storage'
import { ContractFormStorageData } from '@/pages/ContractApplicationForm/functions/storage/types'

const useStyle = () => {
  const { themeColor } = useDesignToken()

  return {
    groupTitle: css`
      margin-bottom: 16px;
    `,
    button: css`
      text-align: center;
      margin-top: 16px;
    `,
    additionalHeader: css`
      display: flex;
      align-items: flex-start;
      gap: 10px;
    `,
    remove: css`
      color: ${themeColor.text.alert2};
      font-size: 20px;
      padding: 1px;
      cursor: pointer;
    `,
    maxLengthNote: css`
      color: rgba(0, 0, 0, 0.45);
      margin-top: 8px;
    `,
  }
}

const defaultRepresentativeName: RepresentativeItem['name'] = {
  surname: '',
  givenName: '',
  surnameKana: '',
  givenNameKana: '',
}

const defaultRepresentativeItem: RepresentativeItem = {
  name: defaultRepresentativeName,
  birthDate: '',
  address: {
    postCode: '',
    address: '',
    building: '',
  },
}

const defaultRepresentativeItemFromStorage = (
  storageData: ContractFormStorageData | PlacementFormStorageData
): RepresentativeItem[] => {
  if (!storageData) return [defaultRepresentativeItem]

  const filledFieleds = [...Array(MAX_REPRESENTATIVE_NUMBER)]
    .map((_, i) => i)
    .filter((index) => {
      return (
        /* @ts-expect-error appendix form key */
        storageData[`representativeSurname${index}`] ||
        /* @ts-expect-error appendix form key */
        storageData[`representativeGivenName${index}`] ||
        /* @ts-expect-error appendix form key */
        storageData[`representativeSurnameKana${index}`] ||
        /* @ts-expect-error appendix form key */
        storageData[`representativeGivenNameKana${index}`]
      )
    })

  const lastFilledIndex = Math.max(...filledFieleds)

  const items: RepresentativeItem[] = []

  for (let index = 0; index <= lastFilledIndex; index++) {
    items.push({
      name: {
        /* @ts-expect-error appendix form key */
        surname: storageData[`representativeSurname${index}`],
        /* @ts-expect-error appendix form key */
        givenName: storageData[`representativeGivenName${index}`],
        /* @ts-expect-error appendix form key */
        surnameKana: storageData[`representativeSurnameKana${index}`],
        /* @ts-expect-error appendix form key */
        givenNameKana: storageData[`representativeGivenNameKana${index}`],
      },
    })
  }

  return !items.length ? [defaultRepresentativeItem] : items
}

/**
 * @param fullForm 住所、生年月日の入力欄を表示するかどうか
 */
export const RepresentativeForm = ({
  form,
  formStorageData,
  fullForm,
  placementApplicationDefaults,
  handleRemoveCallback,
}: {
  form: FormInstance<ContractFormType | PlacementFormType>
  formStorageData?: ContractFormStorageData | PlacementFormStorageData
  fullForm: boolean
  placementApplicationDefaults?: GetProfessionalPlacementDefaultsResponse
  handleRemoveCallback?: () => void
}) => {
  const [representatives, setRepresentatives] = useState<RepresentativeItem[]>(() => {
    // localStorageに値があれば優先してセット
    if (formStorageData) {
      return defaultRepresentativeItemFromStorage(formStorageData)
    }
    // APIの取得値があればセット
    if (placementApplicationDefaults) {
      return placementApplicationDefaults.representatives.map(() => defaultRepresentativeItem)
    }
    // 初期値なし
    return [defaultRepresentativeItem]
  })

  const handleAdd = () => {
    setRepresentatives((r) => [...r, defaultRepresentativeItem])
  }

  const handleRemove = (index: number) => {
    representatives.forEach((_, i) => {
      if (i >= index && i < representatives.length) {
        form.setFieldValue(
          `${representativeFormNames.representativeSurname}${i}`,
          form.getFieldValue(`${representativeFormNames.representativeSurname}${i + 1}`)
        )
        form.setFieldValue(
          `${representativeFormNames.representativeGivenName}${i}`,
          form.getFieldValue(`${representativeFormNames.representativeGivenName}${i + 1}`)
        )
        form.setFieldValue(
          `${representativeFormNames.representativeSurnameKana}${i}`,
          form.getFieldValue(`${representativeFormNames.representativeSurnameKana}${i + 1}`)
        )
        form.setFieldValue(
          `${representativeFormNames.representativeGivenNameKana}${i}`,
          form.getFieldValue(`${representativeFormNames.representativeGivenNameKana}${i + 1}`)
        )
      }
    })
    setRepresentatives((r) => [...r.filter((_, i) => i !== index)])
    handleRemoveCallback?.()
  }

  const onCheckDuplication = async () => {
    await form
      .validateFields(representatives.map((_, i) => [`${representativeFormNames.representativeSurname}${i}`]).flat())
      .catch((err) => console.debug(err))
  }

  const style = useStyle()

  useEffect(() => {
    if (placementApplicationDefaults && !formStorageData) {
      placementApplicationDefaults.representatives.map((r, i) => {
        form.setFieldValue(`representativeSurname${i}`, r.name.surname)
        form.setFieldValue(`representativeGivenName${i}`, r.name.givenName)
        form.setFieldValue(`representativeSurnameKana${i}`, r.name.surnameKana)
        form.setFieldValue(`representativeGivenNameKana${i}`, r.name.givenNameKana)
        // NOTE: 住所・生年月日は利用申込でしか入力しない（初期値は常に無い）のでセットしない
      })
    }
  }, [placementApplicationDefaults, form, formStorageData])

  return (
    <>
      {representatives.map((_, i) => (
        <RepresentativeFormItem
          index={i}
          key={i}
          fullForm={fullForm}
          onRemove={handleRemove}
          onCheckDuplication={onCheckDuplication}
        />
      ))}
      <div css={style.button}>
        <Button onClick={handleAdd} disabled={representatives.length >= MAX_REPRESENTATIVE_NUMBER}>
          代表者を追加
        </Button>
        {representatives.length >= MAX_REPRESENTATIVE_NUMBER && (
          <Typography.Paragraph css={style.maxLengthNote}>
            代表者は最大{MAX_REPRESENTATIVE_NUMBER}人まで入力できます
          </Typography.Paragraph>
        )}
      </div>
    </>
  )
}

type RepresentativeFormItem = {
  index: number
  fullForm: boolean
  onRemove: (index: number) => void
  onCheckDuplication: () => void
}

const RepresentativeFormItem = ({ index, fullForm, onRemove, onCheckDuplication }: RepresentativeFormItem) => {
  // NOTE: 利用申込の一人目の代表者のみ住所・生年月日を表示（掲載申込で代表者の順番が変わる可能性があるが、掲載申込では住所・生年月日は表示しない）
  const showFullForm: boolean = index === 0 && fullForm
  const style = useStyle()

  return (
    <>
      {index > 0 && (
        <Col css={style.additionalHeader}>
          <Typography.Paragraph>追加の代表者</Typography.Paragraph>
          <CloseCircleOutlined css={style.remove} onClick={() => onRemove(index)} />
        </Col>
      )}

      {showFullForm && (
        <>
          <DoubleTextInputForm
            {...formProps['representativePostCode']}
            first={{ ...formProps['representativePostCode'].first, name: `representativePostCodeFirst${index}` }}
            second={{ ...formProps['representativePostCode'].second, name: `representativePostCodeSecond${index}` }}
          />
          <SingleTextInputForm {...formProps['representativeAddress']} name={`representativeAddress${index}`} />
          <SingleTextInputForm {...formProps['representativeBuilding']} name={`representativeBuilding${index}`} />
        </>
      )}

      <DoubleTextInputForm
        {...formProps['representativeName']}
        first={{
          name: `${representativeFormNames.representativeSurname}${index}`,
          placeholder: formProps['representativeName']['representativeSurname'].placeholder,
          rules: formProps['representativeName']['representativeSurname'].rules(index),
        }}
        second={{
          ...formProps['representativeName']['representativeGivenName'],
          name: `${representativeFormNames.representativeGivenName}${index}`,
        }}
        onInput={onCheckDuplication}
      />

      <DoubleTextInputForm
        {...formProps['representativeNameKana']}
        first={{
          ...formProps['representativeNameKana']['representativeSurnameKana'],
          name: `${representativeFormNames.representativeSurnameKana}${index}`,
        }}
        second={{
          ...formProps['representativeNameKana']['representativeGivenNameKana'],
          name: `${representativeFormNames.representativeGivenNameKana}${index}`,
        }}
      />

      {showFullForm && (
        <DateForm
          {...formProps['representativeBirthDate']}
          name={`${representativeFormNames.representativeBirthDate}${index}`}
        />
      )}
    </>
  )
}
