export const ENV = {
  SERVER_URL: import.meta.env.VITE_SERVER_URL as string,
  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY as string,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN as string,
  GIP_TENANT_ID: import.meta.env.VITE_APP_GIP_TENANT_ID as string,
  RECAPTCHA_SITE_KEY: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY as string,
  FRONTEND_HOST_USER: import.meta.env.VITE_FRONTEND_HOST_USER as string,
  ENV: import.meta.env.VITE_APP_ENV as string,
  DEV: import.meta.env.DEV as boolean,
} as const
