import { css } from '@emotion/react'
import { Typography } from 'antd'
import { type AgreementProps, type TTypography, type TTerm } from './AgreementProps'

const styles = {
  header: css`
    margin: 32px 0;
    text-align: center;
  `,
  title: css`
    padding-top: 20px;
    margin-top: 0 !important;
  `,
  footer: css`
    text-align: right;
    margin: 32px 0;
  `,
  terms: {
    item: (indent: number) => css`
      display: flex;
      font-size: 14px;
      line-height: 22px;
      margin-left: ${indent * 16}px;
    `,
    index: css`
      display: inline-block;
      width: 20px;
      text-align: right;
      margin-right: 8px;
      white-space: nowrap;
    `,
    text: css`
      flex: 1;
    `,
  },
}

const Term = ({ item, indent }: { item: TTerm; indent: number }) => {
  return (
    <div css={styles.terms.item(indent)}>
      {item.index && <span css={styles.terms.index}>{item.index}</span>}
      <p css={styles.terms.text}>{item.text}</p>
    </div>
  )
}

const AgreementTypography = ({ item }: { item: TTypography }) => {
  return (
    <>
      {item.title && (
        <Typography.Title level={5} css={styles.title}>
          {item.title}
        </Typography.Title>
      )}
      <div>
        {item.content.map((child1) => {
          if (Array.isArray(child1)) {
            return child1.map((child2) => {
              if (Array.isArray(child2)) {
                return child2.map((child3) => <Term key={child3.text} item={child3} indent={2} />)
              } else {
                return <Term key={child2.text} item={child2} indent={1} />
              }
            })
          } else {
            return <Term key={child1.text} item={child1} indent={0} />
          }
        })}
      </div>
    </>
  )
}

export const AgreementContent = ({ agreementProps }: { agreementProps: AgreementProps }) => {
  return (
    <>
      <section css={styles.header}>
        <Typography.Title>{agreementProps.englishTitle}</Typography.Title>
        <Typography>{agreementProps.title}</Typography>
        <Typography>{agreementProps.subTitle}</Typography>
      </section>

      {agreementProps.typography &&
        agreementProps.typography.map((item, index) => <AgreementTypography key={index} item={item} />)}

      {agreementProps.node && agreementProps.node}

      {agreementProps.footer && (
        <section css={styles.footer}>
          <Typography>{agreementProps.footer.date}</Typography>
          <Typography>{agreementProps.footer.houshin}</Typography>
          <Typography>{agreementProps.footer.toriatsukai}</Typography>
        </section>
      )}
    </>
  )
}
