import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'
import {
  callPostProfessionalPasswordResetSendEmail,
  callGetProfessionalPasswordResetValidate,
  callPutProfessionalPasswordResetConfirm,
} from './function'

export const useProfessionalPasswordResetSendEmail = (
  apiParams: Parameters<typeof callPostProfessionalPasswordResetSendEmail>[0]
) => {
  const { callPostProfessionalPasswordResetSendEmail } = useRpcContext()
  const { error } = useSWR(SWR_KEYS.postProfessionalPasswordResetSendEmail, () =>
    callPostProfessionalPasswordResetSendEmail(apiParams)
  )

  if (error) throw error
}

export const useGetProfessionalPasswordResetValidate = (
  apiParams: Parameters<typeof callGetProfessionalPasswordResetValidate>[0]
) => {
  const { callGetProfessionalPasswordResetValidate } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.getProfessionalPasswordResetValidate, () =>
    callGetProfessionalPasswordResetValidate(apiParams)
  )

  if (error) throw error
  return { result: data?.result }
}

export const useProfessionalPasswordResetConfirm = (
  apiParams: Parameters<typeof callPutProfessionalPasswordResetConfirm>[0]
) => {
  const { callPutProfessionalPasswordResetConfirm } = useRpcContext()
  const { error } = useSWR(SWR_KEYS.postProfessionalPasswordResetSendEmail, () =>
    callPutProfessionalPasswordResetConfirm(apiParams)
  )

  if (error) throw error
}
