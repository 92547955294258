import { css } from '@emotion/react'
import { theme } from 'antd'
import { ReactNode } from 'react'

const useStyles = () => {
  const {
    token: { colorTextDescription, fontSizeHeading1, fontSize },
  } = theme.useToken()
  const styles = {
    decoration: css`
      height: 110px;
    `,
    title: css`
      text-align: center;
    `,
    serviceName: css`
      font-size: ${fontSizeHeading1}px;
      display: inline;
    `,
    serviceOverview: css`
      color: ${colorTextDescription};
      margin: 16px 0 40px;
      text-align: center;
      font-size: ${fontSize}px;
    `,
    formName: css`
      display: block;
      color: ${colorTextDescription};
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 14px;
      text-align: center;
    `,
    formContainer: css`
      margin: 0 auto 64px;
      max-width: 360px;
    `,
  }

  return { styles }
}

type Props = {
  header: {
    decoration?: ReactNode
    title: string
    serviceOverview: string
  }
  loginForm: ReactNode
}

export const LoginLayout = (props: Props) => {
  const { styles } = useStyles()

  return (
    <>
      <header>
        <div css={styles.decoration}>{props.header.decoration ?? null}</div>
        <div css={styles.title}>
          <h1 css={styles.serviceName}>{props.header.title}</h1>
        </div>
        <p css={styles.serviceOverview}>{props.header.serviceOverview}</p>
      </header>
      <span css={styles.formName}>ログイン</span>
      <div css={styles.formContainer}>{props.loginForm}</div>
    </>
  )
}
