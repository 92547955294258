import {
  ContractApplicationDetails,
  ContractApplicationScreeningQuestionDetails,
  InvitationCode,
} from 'inheritance-api'
import { DescriptionItemGroupType, DescriptionItemType } from '../generic'
import { ContractDescriptionValue } from './type'
import { getOfficeAddressStringFromApiSchema } from '../util'
import {
  dateStringToDate,
  DATE_FORMAT,
  AFFILIATION_LABEL,
  BUSINESS_CATEOGRY_OPTION,
  getScreeningQuestionLabel,
  trueOrFalse,
  hyphenatePostCode,
  trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank,
} from 'inheritance-utils'

export const contractApiSchemaToItemGroup = (
  value: ContractApplicationDetails,
  screeningQuestionValue: ContractApplicationScreeningQuestionDetails,
  invitationCode?: InvitationCode
): DescriptionItemGroupType<ContractDescriptionValue>[] => {
  return [
    { title: '利用申込情報', items: [{ label: '利用申込ID', value: value.contractApplicationId }] },
    {
      title: '事務所情報',
      items: [
        { label: '専門家カテゴリー', value: value.professionalCategory.name },
        { label: '業態', value: BUSINESS_CATEOGRY_OPTION.find((b) => b.value === value.businessCategory)?.label ?? '' },
        { label: '法人名・事務所名', value: value.corporate.name },
        { label: '法人名・事務所名（カナ）', value: value.corporate.nameKana },
        {
          label: '設立年月日',
          value: dateStringToDate(value.corporate.dateOfEstablishment, DATE_FORMAT['yyyy/M/d']),
        },
        {
          label: '郵便番号',
          value: hyphenatePostCode(value.corporate.officeAddress.postCode),
        },
        getOfficeAddressItem(value),
        { label: '建物名', value: value.corporate.officeAddress.building ?? '' },
        { label: 'ホームページURL', value: value.corporate.url ?? '' },
        { label: AFFILIATION_LABEL[value.professionalCategory.code], value: value.corporate.affiliation },
      ],
    },
    getRepresentativeItem(value),
    {
      title: '担当者情報',
      items: [
        { label: '担当者名', value: `${value.contact.name.surname} ${value.contact.name.givenName}` },
        {
          label: '担当者名（カナ）',
          value: `${value.contact.name.surnameKana} ${value.contact.name.givenNameKana}`,
        },
        { label: 'アカウント作成用メールアドレス', value: value.login.emailAddress },
        { label: 'アカウント作成用携帯電話番号', value: value.login.phoneNumber },
        { label: '銀行連絡用メールアドレス', value: value.contact.emailAddress },
        { label: '銀行連絡用電話番号', value: value.contact.phoneNumber },
        { label: '備考', value: value.note ? value.note.join('\n') : '' },
      ],
    },
    {
      title: '紹介コード',
      items: [{ label: '紹介コード', value: invitationCode ?? '' }],
    },
    {
      title: '確認事項',
      items: getScreeningQuestionItemGroup(value, screeningQuestionValue),
    },
  ]
}

const getOfficeAddressItem = (value: ContractApplicationDetails): DescriptionItemType<string> => {
  const officeAddressString = getOfficeAddressStringFromApiSchema(value.corporate.officeAddress)

  return {
    label: '法人住所',
    value: officeAddressString,
  }
}

const getRepresentativeItem = (
  value: ContractApplicationDetails
): DescriptionItemGroupType<ContractDescriptionValue> => {
  return {
    title: '代表者情報',
    items: value.representatives
      .map((r) => {
        const items = []

        if (r.address) {
          items.push({
            label: '郵便番号',
            value: hyphenatePostCode(r.address.postCode),
          })
          items.push({ label: '代表者住所', value: r.address.address })
          if (r.address.building) {
            items.push({ label: '建物名', value: r.address.building })
          }
        }

        items.push({ label: '代表者名', value: `${r.name.surname} ${r.name.givenName}` })
        items.push({ label: '代表者名（カナ）', value: `${r.name.surnameKana} ${r.name.givenNameKana}` })

        if (r.birthDate) {
          items.push({ label: '代表者生年月日', value: dateStringToDate(r.birthDate, DATE_FORMAT['yyyy/M/d']) })
        }

        return items
      })
      .flat(),
  }
}

const getScreeningQuestionItemGroup = (
  value: ContractApplicationDetails,
  screeningQuestionValue: ContractApplicationScreeningQuestionDetails
): DescriptionItemType<string>[] => {
  const screeningQuestionLabel = getScreeningQuestionLabel({ isForm: false, bizCategory: value.businessCategory })

  const {
    howManyInquiriesAnticipateFromUsersPerYear,
    howManyProfessionalsMoreThan3YearsOfExperience,
    howManyCasesHandledSoFar,
    isNotUndertakenAnyLitigationAgainstMUFGBank,
    isIntendedToHandleSummaryCourtLegalRepresentation,
    isBusinessOpenOverseasUnderNameOfAdvertiser,
  } = screeningQuestionValue

  const items: DescriptionItemType<string>[] = [
    {
      label: screeningQuestionLabel.howManyInquiriesAnticipateFromUsersPerYear,
      value:
        howManyInquiriesAnticipateFromUsersPerYear !== undefined
          ? howManyInquiriesAnticipateFromUsersPerYear + '件'
          : '',
    },
    {
      label: screeningQuestionLabel.howManyProfessionalsMoreThan3YearsOfExperience,
      value:
        howManyProfessionalsMoreThan3YearsOfExperience !== undefined
          ? howManyProfessionalsMoreThan3YearsOfExperience + '人'
          : '',
    },
    {
      label: screeningQuestionLabel.howManyCasesHandledSoFar,
      value: howManyCasesHandledSoFar !== undefined ? howManyCasesHandledSoFar + '件' : '',
    },
    {
      label: screeningQuestionLabel.isNotUndertakenAnyLitigationAgainstMUFGBank,
      value:
        trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank.find(
          (y) => y.value === isNotUndertakenAnyLitigationAgainstMUFGBank
        )?.label ?? '',
    },
  ]
  if (value.professionalCategory.code === 'judicialScrivener') {
    items.push({
      label: screeningQuestionLabel.isIntendedToHandleSummaryCourtLegalRepresentation,
      value: trueOrFalse.find((y) => y.value === isIntendedToHandleSummaryCourtLegalRepresentation)?.label ?? '',
    })
  }
  if (value.businessCategory === 'individual') {
    items.push({
      label: screeningQuestionLabel.isBusinessOpenOverseasUnderNameOfAdvertiser,
      value: trueOrFalse.find((y) => y.value === isBusinessOpenOverseasUnderNameOfAdvertiser)?.label ?? '',
    })
  }
  return items
}
