import { Form } from 'antd'
import { FormLabel } from 'inheritance-components'

export const DummyStyledForm = ({ label, text }: { label: string; text: string }) => {
  return (
    <Form.Item label={<FormLabel label={label} required={true} labelHidden={true} />}>
      <span>{text}</span>
    </Form.Item>
  )
}
