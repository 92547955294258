import axios, { AxiosError } from 'axios'
import { ENV } from '@/config/environment'
import { getApiAuthProfessionalClient, getIdToken } from '../client'
import type { PutProfessionalPlacementImageResponse } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
export type ImagePosition = 'profile1Image' | 'profile2Image1' | 'profile2Image2' | 'profile2Image3' | 'profile3Image'

// TODO exportを解除
export const callGetProfessionalPlacementImage = async ({
  placementImageId,
  notFoundErrorCallback,
}: {
  placementImageId: string
  notFoundErrorCallback?: () => void
}): Promise<{ file: File | undefined }> => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    const { data: file } = await apiClient.getProfessionalPlacementImage(placementImageId, { secure: true })

    return { file }
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      notFoundErrorCallback?.()
      return { file: undefined }
    }
    throw buildRethrowingError(e)
  }
}

export const placementImageFetcher = async (
  placementImageId: string,
  notFoundErrorCallback?: () => void
): Promise<File | undefined> => {
  const { file } = await callGetProfessionalPlacementImage({ placementImageId, notFoundErrorCallback })
  return file
}

export const callPutProfessionalPlacementImage = async ({
  image,
  imagePosition,
}: {
  image: File
  imagePosition: ImagePosition
}): Promise<{ data?: PutProfessionalPlacementImageResponse; error?: Error }> => {
  try {
    const idToken = await getIdToken()
    const { data } = await axios.put(`${ENV.SERVER_URL}/api/professional/placement/images/${imagePosition}`, image, {
      headers: {
        'Content-Type': image.type,
        Authorization: `Bearer ${idToken}`,
      },
    })
    return { data }
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
