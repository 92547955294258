// eslint-disable-next-line node/no-extraneous-import
import * as z from 'zod'
// eslint-disable-next-line node/no-extraneous-import
import dayjs from 'dayjs'
import { GenericStorageManager } from 'inheritance-utils'
import { ContractFormType, formNames } from '@/pages/ContractApplicationForm/form'
import { ContractFormStorageData, FormStorageSchema } from './types'
import { RepresentativeFormType, representativeFormNamesWithKey } from '@/share/components/RepresentativeForm/form'

type ContractFormStorage = {
  loginEmailAddress: string
  data: ContractFormStorageData
}

const formStorage = new GenericStorageManager<ContractFormStorage>({
  storageKey: 'contractApplication',
  validator: (arg) => {
    const result = schemaToDomain(arg)
    if (!result) return { success: false }
    return { success: true, result: result }
  },
})

export type ContractApplicationStorageHandler = {
  get: (loginEmailAddress: string) => ContractFormStorageData | undefined
  set: (loginEmailAddress: string, values: ContractFormType) => void
  remove: () => void
}

export const contractApplicationStorageHandler = (): ContractApplicationStorageHandler => {
  const storageGetter = (loginEmailAddress: string) => {
    const result = formStorage.get()
    // NOTE: loginEmailAddress が使用中のアカウントのものと一致しない場合、storageのデータを取得せず破棄する（内部の動作確認等で複数のアカウントを使い分けている場合）
    if (result?.loginEmailAddress !== loginEmailAddress) {
      formStorage.remove()
      return undefined
    }
    return result.data
  }

  const storageSetter = (loginEmailAddress: string, values: ContractFormType) => {
    const newValues = domainToStorage(loginEmailAddress, values)
    formStorage.set(newValues)
  }

  return {
    get: storageGetter,
    set: storageSetter,
    remove: () => formStorage.remove(),
  }
}

const representativeItems = (
  values: ContractFormType | z.infer<typeof FormStorageSchema.shape.data>
): RepresentativeFormType => {
  return {
    representativeBirthDate0: values[representativeFormNamesWithKey.representativeBirthDate0]
      ? dayjs(values[representativeFormNamesWithKey.representativeBirthDate0])
      : undefined,
    representativePostCodeFirst0: values[representativeFormNamesWithKey.representativePostCode0.first0],
    representativePostCodeSecond0: values[representativeFormNamesWithKey.representativePostCode0.second0],
    representativeAddress0: values[representativeFormNamesWithKey.representativeAddress0],
    representativeBuilding0: values[representativeFormNamesWithKey.representativeBuilding0],

    representativeSurname0: values[representativeFormNamesWithKey.representativeSurname0],
    representativeGivenName0: values[representativeFormNamesWithKey.representativeGivenName0],
    representativeSurnameKana0: values[representativeFormNamesWithKey.representativeSurnameKana0],
    representativeGivenNameKana0: values[representativeFormNamesWithKey.representativeGivenNameKana0],
    representativeSurname1: values[representativeFormNamesWithKey.representativeSurname1],
    representativeGivenName1: values[representativeFormNamesWithKey.representativeGivenName1],
    representativeSurnameKana1: values[representativeFormNamesWithKey.representativeSurnameKana1],
    representativeGivenNameKana1: values[representativeFormNamesWithKey.representativeGivenNameKana1],
    representativeSurname2: values[representativeFormNamesWithKey.representativeSurname2],
    representativeGivenName2: values[representativeFormNamesWithKey.representativeGivenName2],
    representativeSurnameKana2: values[representativeFormNamesWithKey.representativeSurnameKana2],
    representativeGivenNameKana2: values[representativeFormNamesWithKey.representativeGivenNameKana2],
    representativeSurname3: values[representativeFormNamesWithKey.representativeSurname3],
    representativeGivenName3: values[representativeFormNamesWithKey.representativeGivenName3],
    representativeSurnameKana3: values[representativeFormNamesWithKey.representativeSurnameKana3],
    representativeGivenNameKana3: values[representativeFormNamesWithKey.representativeGivenNameKana3],
    representativeSurname4: values[representativeFormNamesWithKey.representativeSurname4],
    representativeGivenName4: values[representativeFormNamesWithKey.representativeGivenName4],
    representativeSurnameKana4: values[representativeFormNamesWithKey.representativeSurnameKana4],
    representativeGivenNameKana4: values[representativeFormNamesWithKey.representativeGivenNameKana4],
    representativeSurname5: values[representativeFormNamesWithKey.representativeSurname5],
    representativeGivenName5: values[representativeFormNamesWithKey.representativeGivenName5],
    representativeSurnameKana5: values[representativeFormNamesWithKey.representativeSurnameKana5],
    representativeGivenNameKana5: values[representativeFormNamesWithKey.representativeGivenNameKana5],
    representativeSurname6: values[representativeFormNamesWithKey.representativeSurname6],
    representativeGivenName6: values[representativeFormNamesWithKey.representativeGivenName6],
    representativeSurnameKana6: values[representativeFormNamesWithKey.representativeSurnameKana6],
    representativeGivenNameKana6: values[representativeFormNamesWithKey.representativeGivenNameKana6],
    representativeSurname7: values[representativeFormNamesWithKey.representativeSurname7],
    representativeGivenName7: values[representativeFormNamesWithKey.representativeGivenName7],
    representativeSurnameKana7: values[representativeFormNamesWithKey.representativeSurnameKana7],
    representativeGivenNameKana7: values[representativeFormNamesWithKey.representativeGivenNameKana7],
    representativeSurname8: values[representativeFormNamesWithKey.representativeSurname8],
    representativeGivenName8: values[representativeFormNamesWithKey.representativeGivenName8],
    representativeSurnameKana8: values[representativeFormNamesWithKey.representativeSurnameKana8],
    representativeGivenNameKana8: values[representativeFormNamesWithKey.representativeGivenNameKana8],
    representativeSurname9: values[representativeFormNamesWithKey.representativeSurname9],
    representativeGivenName9: values[representativeFormNamesWithKey.representativeGivenName9],
    representativeSurnameKana9: values[representativeFormNamesWithKey.representativeSurnameKana9],
    representativeGivenNameKana9: values[representativeFormNamesWithKey.representativeGivenNameKana9],
    representativeSurname10: values[representativeFormNamesWithKey.representativeSurname10],
    representativeGivenName10: values[representativeFormNamesWithKey.representativeGivenName10],
    representativeSurnameKana10: values[representativeFormNamesWithKey.representativeSurnameKana10],
    representativeGivenNameKana10: values[representativeFormNamesWithKey.representativeGivenNameKana10],
    representativeSurname11: values[representativeFormNamesWithKey.representativeSurname11],
    representativeGivenName11: values[representativeFormNamesWithKey.representativeGivenName11],
    representativeSurnameKana11: values[representativeFormNamesWithKey.representativeSurnameKana11],
    representativeGivenNameKana11: values[representativeFormNamesWithKey.representativeGivenNameKana11],
    representativeSurname12: values[representativeFormNamesWithKey.representativeSurname12],
    representativeGivenName12: values[representativeFormNamesWithKey.representativeGivenName12],
    representativeSurnameKana12: values[representativeFormNamesWithKey.representativeSurnameKana12],
    representativeGivenNameKana12: values[representativeFormNamesWithKey.representativeGivenNameKana12],
    representativeSurname13: values[representativeFormNamesWithKey.representativeSurname13],
    representativeGivenName13: values[representativeFormNamesWithKey.representativeGivenName13],
    representativeSurnameKana13: values[representativeFormNamesWithKey.representativeSurnameKana13],
    representativeGivenNameKana13: values[representativeFormNamesWithKey.representativeGivenNameKana13],
    representativeSurname14: values[representativeFormNamesWithKey.representativeSurname14],
    representativeGivenName14: values[representativeFormNamesWithKey.representativeGivenName14],
    representativeSurnameKana14: values[representativeFormNamesWithKey.representativeSurnameKana14],
    representativeGivenNameKana14: values[representativeFormNamesWithKey.representativeGivenNameKana14],
    representativeSurname15: values[representativeFormNamesWithKey.representativeSurname15],
    representativeGivenName15: values[representativeFormNamesWithKey.representativeGivenName15],
    representativeSurnameKana15: values[representativeFormNamesWithKey.representativeSurnameKana15],
    representativeGivenNameKana15: values[representativeFormNamesWithKey.representativeGivenNameKana15],
    representativeSurname16: values[representativeFormNamesWithKey.representativeSurname16],
    representativeGivenName16: values[representativeFormNamesWithKey.representativeGivenName16],
    representativeSurnameKana16: values[representativeFormNamesWithKey.representativeSurnameKana16],
    representativeGivenNameKana16: values[representativeFormNamesWithKey.representativeGivenNameKana16],
    representativeSurname17: values[representativeFormNamesWithKey.representativeSurname17],
    representativeGivenName17: values[representativeFormNamesWithKey.representativeGivenName17],
    representativeSurnameKana17: values[representativeFormNamesWithKey.representativeSurnameKana17],
    representativeGivenNameKana17: values[representativeFormNamesWithKey.representativeGivenNameKana17],
    representativeSurname18: values[representativeFormNamesWithKey.representativeSurname18],
    representativeGivenName18: values[representativeFormNamesWithKey.representativeGivenName18],
    representativeSurnameKana18: values[representativeFormNamesWithKey.representativeSurnameKana18],
    representativeGivenNameKana18: values[representativeFormNamesWithKey.representativeGivenNameKana18],
    representativeSurname19: values[representativeFormNamesWithKey.representativeSurname19],
    representativeGivenName19: values[representativeFormNamesWithKey.representativeGivenName19],
    representativeSurnameKana19: values[representativeFormNamesWithKey.representativeSurnameKana19],
    representativeGivenNameKana19: values[representativeFormNamesWithKey.representativeGivenNameKana19],
  }
}

// UIのformから取得したデータをlocalstorageに保存するデータに変換する
const domainToStorage = (loginEmailAddress: string, values: ContractFormType): ContractFormStorage => {
  const zodValue: ContractFormStorageData = {
    [formNames.professionalCategory]: values.professionalCategory,
    [formNames.businessCategory]: values.businessCategory,
    [formNames.corporateName]: values.corporateName,
    [formNames.corporateNameKana]: values.corporateNameKana,
    [formNames.dateOfEstablishment]: values.dateOfEstablishment ? dayjs(values.dateOfEstablishment) : undefined,
    [formNames.corporatePostCode.first]: values.corporatePostCodeFirst,
    [formNames.corporatePostCode.second]: values.corporatePostCodeSecond,
    [formNames.prefecture]: values.prefecture,
    [formNames.city]: values.city,
    [formNames.otherArea]: values.otherArea,
    [formNames.townAndStreetAddress]: values.townAndStreetAddress,
    [formNames.corporateBuilding]: values.corporateBuilding,
    [formNames.corporateUrl]: values.corporateUrl,
    [formNames.affiliation]: values.affiliation,
    [formNames.affiliationOther]: values.affiliationOther,
    [formNames.contactSurname]: values.contactSurname,
    [formNames.contactGivenName]: values.contactGivenName,
    [formNames.contactSurnameKana]: values.contactSurnameKana,
    [formNames.contactGivenNameKana]: values.contactGivenNameKana,
    [formNames.contactEmailAddress]: values.contactEmailAddress,
    [formNames.contactPhoneNumber]: values.contactPhoneNumber,
    // [formNames.loginEmailAddress]: values.loginEmailAddress,
    [formNames.loginPhoneNumber]: values.loginPhoneNumber,
    [formNames.note]: values.note,
    [formNames.invitationCode]: values.invitationCode,
    [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]: values.isNotUndertakenAnyLitigationAgainstMUFGBank,
    [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]:
      values.isIntendedToHandleSummaryCourtLegalRepresentation,
    [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]: values.isBusinessOpenOverseasUnderNameOfAdvertiser,
    ...representativeItems(values),
  }

  if (values.howManyInquiriesAnticipateFromUsersPerYear) {
    zodValue.howManyInquiriesAnticipateFromUsersPerYear = Number(values.howManyInquiriesAnticipateFromUsersPerYear)
  }
  if (values.howManyProfessionalsMoreThan3YearsOfExperience) {
    zodValue.howManyProfessionalsMoreThan3YearsOfExperience = Number(
      values.howManyProfessionalsMoreThan3YearsOfExperience
    )
  }
  if (values.howManyCasesHandledSoFar) {
    zodValue.howManyCasesHandledSoFar = Number(values.howManyCasesHandledSoFar)
  }

  return {
    loginEmailAddress,
    data: zodValue,
  }
}

// localstorageから取得したany型のデータを、zodのparseを通してUIのフォームで利用するデータに変換する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const schemaToDomain = (object: any): ContractFormStorage | undefined => {
  let result = FormStorageSchema.safeParse(object)

  // localstorageの保存データと最新のフォームデータの型が異なる場合、型が異なる項目のみ保存データのみ復元の対象外とし、型が正しい項目は復元する
  if (!result.success) {
    const reducedObject = JSON.parse(JSON.stringify(object))
    result.error.issues.forEach((issue) => {
      const errorKey = issue.path[0]
      delete reducedObject[errorKey]
    })
    result = FormStorageSchema.safeParse(reducedObject)
  }
  if (!result.success) return undefined
  const loginEmailAddress = result.data.loginEmailAddress
  const data = result.data.data

  return {
    loginEmailAddress,
    data: {
      [formNames.professionalCategory]: data.professionalCategory,
      [formNames.businessCategory]: data.businessCategory,
      [formNames.corporateName]: data.corporateName,
      [formNames.corporateNameKana]: data.corporateNameKana,
      [formNames.dateOfEstablishment]: data.dateOfEstablishment ? dayjs(data.dateOfEstablishment) : undefined,
      [formNames.corporatePostCode.first]: data.corporatePostCodeFirst,
      [formNames.corporatePostCode.second]: data.corporatePostCodeSecond,
      [formNames.prefecture]: data.prefecture,
      [formNames.city]: data.city,
      [formNames.otherArea]: data.otherArea,
      [formNames.townAndStreetAddress]: data.townAndStreetAddress,
      [formNames.corporateBuilding]: data.corporateBuilding,
      [formNames.corporateUrl]: data.corporateUrl,
      [formNames.affiliation]: data.affiliation,
      [formNames.affiliationOther]: data.affiliationOther,
      [formNames.contactSurname]: data.contactSurname,
      [formNames.contactGivenName]: data.contactGivenName,
      [formNames.contactSurnameKana]: data.contactSurnameKana,
      [formNames.contactGivenNameKana]: data.contactGivenNameKana,
      [formNames.contactEmailAddress]: data.contactEmailAddress,
      [formNames.contactPhoneNumber]: data.contactPhoneNumber,
      // [formNames.loginEmailAddress]: data.loginEmailAddress,
      [formNames.loginPhoneNumber]: data.loginPhoneNumber,
      [formNames.note]: data.note,
      [formNames.invitationCode]: data.invitationCode,
      [formNames.howManyInquiriesAnticipateFromUsersPerYear]: data.howManyInquiriesAnticipateFromUsersPerYear,
      [formNames.howManyProfessionalsMoreThan3YearsOfExperience]: data.howManyProfessionalsMoreThan3YearsOfExperience,
      [formNames.howManyCasesHandledSoFar]: data.howManyCasesHandledSoFar,
      [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]: data.isNotUndertakenAnyLitigationAgainstMUFGBank,
      [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]:
        data.isIntendedToHandleSummaryCourtLegalRepresentation,
      [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]: data.isBusinessOpenOverseasUnderNameOfAdvertiser,
      ...representativeItems(data),
    },
  }
}
