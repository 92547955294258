import { Form, Radio, Space, ColProps, SpaceProps } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormLabel, FormLabelWithGuideText } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'

export const RadioForm = ({
  name,
  label,
  rules,
  items,
  initialValue,
  direction = 'vertical',
  labelCol,
  wrapperCol,
  colon,
  onChange,
  guideText,
}: {
  name: string
  label: string
  rules: Rule[]
  items: {
    label: string
    value: string | boolean
  }[]
  initialValue?: string | boolean
  direction?: SpaceProps['direction']
  labelCol?: ColProps
  wrapperCol?: ColProps
  colon?: boolean
  onChange?: (v: string | boolean) => void
  guideText?: string
}) => {
  return (
    <Form.Item
      hasFeedback
      name={name}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      // labelでrequiredを表現するため「*」は不要
      rules={rules}
      required={false}
      initialValue={initialValue}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      colon={colon}
      style={guideText ? { marginBottom: 0 } : {}}
    >
      <Radio.Group onChange={(v) => onChange?.(v.target.value)}>
        <Space direction={direction}>
          {items.map(({ value, label }) => (
            <Radio value={value} key={label}>
              {label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}
