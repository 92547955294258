import { ConfigProvider } from 'antd'
import { ReactNode } from 'react'
import './reset.css'
import { ProAndMgmtDesignTokenProvider, useProAndMgmtDesignToken } from './components/design-system/token'

type Props = {
  children: ReactNode
}

export const AntdThemeProvider = ({ children }: Props) => {
  return (
    <ProAndMgmtDesignTokenProvider>
      <InnerProviders>{children}</InnerProviders>
    </ProAndMgmtDesignTokenProvider>
  )
}

const InnerProviders = ({ children }: Props) => {
  const { color, antdThemeColor } = useProAndMgmtDesignToken()

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryActive: color.accent.purple[700],
          colorPrimaryTextActive: color.accent.purple[700],

          colorPrimary: color.accent.purple.default,
          colorPrimaryText: color.accent.purple.default,

          colorPrimaryHover: color.accent.purple[500],
          colorPrimaryTextHover: color.accent.purple[500],

          colorPrimaryBorderHover: color.accent.purple[400],

          colorPrimaryBorder: color.accent.purple[300],

          colorPrimaryBgHover: color.accent.purple[200],

          colorPrimaryBg: color.accent.purple[100],
          colorBgLayout: antdThemeColor.token.colorBgLayout,
        },
        components: {
          Layout: {
            // グローバルヘッダーの背景色
            headerBg: color.background.white,
            // グローバルヘッダーのテキスト色
            colorText: color.text.white,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  )
}
