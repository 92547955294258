import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'
import { callGetProfessionalCreateAccountValidate } from './function'

export const useProfessionalCreateAccountValidate = (
  apiParams: Parameters<typeof callGetProfessionalCreateAccountValidate>[0]
) => {
  const { callGetProfessionalCreateAccountValidate } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.createAccount, () => callGetProfessionalCreateAccountValidate(apiParams))

  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return { result: data.validation.result, alreadyExists: data.validation.alreadyExists }
}
