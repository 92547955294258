import { getPath } from '@/router'
import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { css } from '@emotion/react'
import { Button, Typography } from 'antd'

const style = {
  description: css`
    margin-bottom: 32px !important;
  `,
}

export const PasswordResetAuthenticationURLExpired = () => {
  return (
    <ErrorScreen
      kind="composition"
      title="認証URLの有効期限が切れています。"
      content={
        <>
          <Typography.Paragraph type="secondary" css={style.description}>
            パスワードの再設定を行うには、初めからやり直してください。
          </Typography.Paragraph>
          <Button type="primary" href={getPath('passwordResetRequest')}>
            パスワードリセット画面に戻る
          </Button>
        </>
      }
    />
  )
}
