import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { css } from '@emotion/react'
import { Button, FormInstance, Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { CloseCircleOutlined } from '@ant-design/icons'
import { SelectForm } from '@/share/components/form/SelectForm'
import { CheckboxForm } from '@/share/components/form/CheckboxForm'
import { RadioForm } from '@/share/components/form/RadioForm'
import { formProps, PlacementFormType, formNames, SelectedArea, AreaOption } from '../form'
import { checkCanAddArea, MAX_SUPPORT_AREA_NUMBER } from '../functions'
import { getRules, RULES } from 'inheritance-components'
import { useDesignToken } from '../../design-system/token'

export const AREA_FORM_PREFIX = {
  prefecture: 'prefecture_',
  city: 'city_',
  isWholeCity: 'isWholeCity_',
} as const

const useStyles = () => {
  const { themeColor } = useDesignToken()

  return {
    formFullWidth: css`
      width: 100% !important;
    `,
    areaAddButton: css`
      text-align: center;
      margin: 24px 0;
    `,
    areaAddButtonNote: css`
      color: rgba(0, 0, 0, 0.65);
      margin-top: 8px;
    `,
    areaForm: css`
      position: relative;
    `,
    areaRemove: css`
      position: absolute;
      top: 6px;
      right: -32px;
      color: ${themeColor.text.alert2};
      font-size: 20px;
      cursor: pointer;
    `,
    hidden: css`
      display: none;
    `,
  }
}

export type SelectType = 'whole' | 'pick' | undefined

type SelectedAreaState = SelectedArea & {
  selectType: SelectType
}

type OptionType = {
  label: string
  value: string
}

type AreaFormProps = {
  area: SelectedAreaState
  index: number
  areaOption: AreaOption | undefined
  canBeRemoved: boolean
  prefectureOptionsResolver: (areaOption: AreaOption | undefined) => OptionType[]
  onSelectPrefecture: (value: string, index: number) => void
  onRemoveArea: (index: number) => void
  onToggleIsWholeCity: (value: SelectType, index: number, areaOption: AreaOption) => void
  onSelectCity: (value: string[], index: number) => void
}

const AreaFormItem = ({
  area,
  index,
  areaOption,
  canBeRemoved,
  prefectureOptionsResolver,
  onSelectPrefecture,
  onRemoveArea,
  onToggleIsWholeCity,
  onSelectCity,
}: AreaFormProps) => {
  const isWholeCityOption = useMemo<OptionType[]>(
    () => [
      {
        label: '全域',
        value: 'whole',
      },
      {
        label: '個別指定',
        value: 'pick',
      },
    ],
    []
  )

  const handleSelectPrefecture = useCallback(
    (value: string | number) => {
      onSelectPrefecture(String(value), index)
    },
    [onSelectPrefecture, index]
  )

  const handleRemoveArea = useCallback(() => {
    onRemoveArea(index)
  }, [onRemoveArea, index])

  const handleToggleIsWholeCity = useCallback(
    (value: string | boolean) => {
      if (areaOption) {
        onToggleIsWholeCity(value === 'whole' ? 'whole' : 'pick', index, areaOption)
      }
    },
    [onToggleIsWholeCity, index, areaOption]
  )

  const handleSelectCity = useCallback(
    (value: CheckboxValueType[]) => {
      onSelectCity(value.map(String), index)
    },
    [onSelectCity, index]
  )

  const prefectureOptions = useMemo(
    () => prefectureOptionsResolver(areaOption),
    [prefectureOptionsResolver, areaOption]
  )

  const isWholeCityValue = useMemo(() => {
    if (areaOption) {
      return area.cities.length === areaOption.cities.length ? 'whole' : 'pick'
    }

    return undefined
  }, [area, areaOption])

  const cityOptions = useMemo(() => areaOption?.cities ?? [], [areaOption])

  const styles = useStyles()

  return (
    <div>
      <div css={styles.areaForm}>
        <SelectForm
          name={`${AREA_FORM_PREFIX.prefecture}${index}`}
          label={formProps['supportArea']['prefecture'].label}
          rules={getRules([RULES.requireSelection])}
          placeholder="都道府県"
          items={prefectureOptions}
          css={styles.formFullWidth}
          onSelect={handleSelectPrefecture}
          guideText={formProps['supportArea']['prefecture'].guideText}
          colon={formProps['supportArea']['prefecture'].colon}
        />
        {canBeRemoved && <CloseCircleOutlined css={styles.areaRemove} onClick={handleRemoveArea} />}
      </div>
      <div css={!area.prefecture && styles.hidden}>
        <RadioForm
          name={`${AREA_FORM_PREFIX.isWholeCity}${index}`}
          label="市区町村選択"
          rules={getRules([RULES.requireSelection])}
          items={isWholeCityOption}
          initialValue={isWholeCityValue}
          onChange={handleToggleIsWholeCity}
        />
        <div css={(area.selectType === undefined || area.selectType === 'whole') && styles.hidden}>
          <CheckboxForm
            name={`${AREA_FORM_PREFIX.city}${index}`}
            label="市区町村"
            rules={getRules([RULES.requireSelection])}
            options={cityOptions}
            row={true}
            itemsPerRow={3}
            onChange={handleSelectCity}
          />
        </div>
      </div>
    </div>
  )
}

export const AreaForm = ({
  form,
  areaOption,
  initialSupportAreaValue,
  stateSetterCallback,
}: {
  form: FormInstance<PlacementFormType>
  areaOption: AreaOption[]
  initialSupportAreaValue: SelectedArea[]
  stateSetterCallback: (values: PlacementFormType) => void
}) => {
  const isWholeAreaOption = useMemo(
    () => [
      {
        label: '全国指定',
        value: true,
      },
      {
        label: '都道府県指定',
        value: false,
      },
    ],
    []
  )

  const createDefaultInput = useCallback(() => {
    return {
      prefecture: null,
      cities: [],
      selectType: undefined,
    }
  }, [])

  const initialSelectedArea = (): SelectedAreaState[] => {
    // 全国選択
    if (form.getFieldValue(formNames.isWholeArea) === true) {
      return []
    }
    // 都道府県選択
    return initialSupportAreaValue.length
      ? // 初期値あり: 2回目以降の申込
        initialSupportAreaValue.map((area) => {
          const cityOption = areaOption.find((a) => a.prefecture.value === area.prefecture)
          const isWholeCitySelected = cityOption ? cityOption.cities.length === area.cities.length : false
          return {
            ...area,
            selectType: isWholeCitySelected ? 'whole' : 'pick',
          }
        })
      : // 初期値なし: 初回申込
        [createDefaultInput()]
  }

  const [selectedArea, setSelectedArea] = useState<SelectedAreaState[]>(initialSelectedArea())
  const [isWholeArea, setIsWholeArea] = useState<boolean>(form.getFieldValue(formNames.isWholeArea) === true)

  const onToggleIsWholeArea = useCallback(
    (value: string | boolean) => {
      const isWholeArea_ = value === true

      setIsWholeArea(isWholeArea_)

      if (isWholeArea_) {
        setSelectedArea([])
      } else {
        setSelectedArea((selectedArea_) => (selectedArea_.length === 0 ? [createDefaultInput()] : selectedArea_))
      }
    },
    [createDefaultInput]
  )

  const onToggleIsWholeCity = useCallback(
    (selectType: SelectType, index: number, areaOpt: AreaOption) => {
      setSelectedArea((selectedArea_) =>
        selectedArea_.map((area, i) => {
          return i === index
            ? {
                ...area,
                cities: selectType === 'whole' ? areaOpt.cities.map((c) => c.value) : [],
                selectType,
              }
            : area
        })
      )
      const cityCodes = selectType === 'whole' ? areaOpt.cities.map((c) => c.value) : []
      form.setFieldValue(`${AREA_FORM_PREFIX.city}${index}`, cityCodes)
      stateSetterCallback(form.getFieldsValue())
    },
    [form, stateSetterCallback]
  )

  const onAddArea = useCallback(() => {
    setSelectedArea((selectedArea_) => [...selectedArea_, createDefaultInput()])
  }, [createDefaultInput])

  const onRemoveArea = useCallback(
    (areaId: number) => {
      setSelectedArea((selectedArea_) => selectedArea_.filter((_, i) => i !== areaId))

      form.setFieldValue(`${AREA_FORM_PREFIX.city}${areaId}`, [])
      stateSetterCallback(form.getFieldsValue())
    },
    [form, stateSetterCallback]
  )

  const onSelectPrefecture = useCallback(
    (value: string, index: number) => {
      setSelectedArea((selectedArea_) =>
        selectedArea_.map((e, i) => {
          return i !== index ? e : { ...createDefaultInput(), prefecture: value }
        })
      )
    },
    [createDefaultInput]
  )

  const onSelectCity = useCallback((cities: string[], index: number) => {
    setSelectedArea((selectedArea_) =>
      selectedArea_.map((e, i) => {
        return i !== index ? e : { ...e, cities, selectType: 'pick' }
      })
    )
  }, [])

  const prefectureOptionsResolver = useCallback(
    (areaOpt: AreaOption | undefined) => {
      const includeSelf = (a: AreaOption) => areaOpt?.prefecture.value === a.prefecture.value
      const excludeSelected = (a: AreaOption) => !selectedArea.find((sa) => a.prefecture.value === sa.prefecture)

      return areaOption.filter((a) => includeSelf(a) || excludeSelected(a)).map((a) => a.prefecture)
    },
    [areaOption, selectedArea]
  )

  const beforeSelectedLengthRef = useRef<number>(selectedArea.length)

  useEffect(() => {
    Array(Math.max(beforeSelectedLengthRef.current, selectedArea.length))
      .fill(null)
      .forEach((_, i) => {
        const selected: SelectedAreaState | undefined = selectedArea[i]

        if (selected) {
          form.setFieldValue(`${AREA_FORM_PREFIX.prefecture}${i}`, selected.prefecture)
          form.setFieldValue(`${AREA_FORM_PREFIX.city}${i}`, [...selected.cities])
          form.setFieldValue(`${AREA_FORM_PREFIX.isWholeCity}${i}`, selected.selectType)
        } else {
          form.resetFields([
            `${AREA_FORM_PREFIX.prefecture}${i}`,
            `${AREA_FORM_PREFIX.city}${i}`,
            `${AREA_FORM_PREFIX.isWholeCity}${i}`,
          ])
        }
      })

    beforeSelectedLengthRef.current = selectedArea.length
  }, [form, selectedArea])

  const styles = useStyles()

  useEffect(() => {
    form.setFieldValue('isWholeArea', isWholeArea)
  }, [form, isWholeArea])

  return (
    <>
      <RadioForm
        {...formProps['supportArea']['isWholeArea']}
        items={isWholeAreaOption}
        onChange={onToggleIsWholeArea}
      />
      {selectedArea.map((area, i) => (
        <AreaFormItem
          key={i}
          area={area}
          index={i}
          areaOption={areaOption.find((a) => a.prefecture.value === area.prefecture)}
          canBeRemoved={selectedArea.length > 1}
          prefectureOptionsResolver={prefectureOptionsResolver}
          onSelectPrefecture={onSelectPrefecture}
          onRemoveArea={onRemoveArea}
          onToggleIsWholeCity={onToggleIsWholeCity}
          onSelectCity={onSelectCity}
        />
      ))}

      {selectedArea.length > 0 && (
        <div css={styles.areaAddButton}>
          <Button onClick={onAddArea} disabled={!checkCanAddArea(selectedArea).canBeAdded}>
            エリアを追加
          </Button>
          {selectedArea.length >= MAX_SUPPORT_AREA_NUMBER && (
            <Typography.Paragraph
              css={styles.areaAddButtonNote}
            >{`都道府県指定は最大${MAX_SUPPORT_AREA_NUMBER}ヶ所まで入力できます。`}</Typography.Paragraph>
          )}
        </div>
      )}
    </>
  )
}
