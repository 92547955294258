import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { getPath } from '@/router'
import { Button, Form, notification, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ERROR_CODES } from 'inheritance-auth'
import { getRules, RULES, SingleTextInputForm, SingleTextInputFormProps } from 'inheritance-components'
import { _User, Authenticator, FirebaseError, updatePassword } from '@/auth'
import { useAuthContext } from '@/auth/AuthContext'
import { useHandleFirebaseError } from '@/auth'
import { PasswordGuideline } from 'inheritance-components/src/components/guideline/PasswordGuideline'

const styles = {
  guideline: css`
    margin: 16px 0 42px;
  `,
  form: css`
    text-align: left;
    max-width: 680px;
    margin: 0 auto 40px;
  `,
  button1: css`
    text-align: center;
    margin-top: 64px;
  `,
}

const useFormProps = (form: FormInstance): Record<string, SingleTextInputFormProps> => {
  // パスワード入力時、パスワード（確認）が入力されていたら検証する
  const onChangePassword = async () => {
    const passwordConfirmValue = form.getFieldValue('passwordConfirm')
    if (passwordConfirmValue) {
      await form.validateFields(['passwordConfirm'])
    }
  }

  return {
    password: {
      name: 'password',
      label: '新規パスワード',
      rules: getRules([RULES.requireEntry, RULES.passwordPattern]),
      renderInput: () => <Input.Password onChange={onChangePassword} />,
    },
    passwordConfirm: {
      name: 'passwordConfirm',
      label: '新規パスワード（確認）',
      rules: getRules([RULES.requireEntry, RULES.passwordConfirm]),
      renderInput: () => <Input.Password />,
    },
  }
}

export const PasswordChangeContainer = () => {
  const [currentUser, setCurrentUser] = useState<_User | undefined>(undefined)
  const [authenticator, setAuthenticator] = useState<Authenticator | undefined>(undefined)
  const [form] = Form.useForm()
  const formProps = useFormProps(form)
  const { getAuthenticator } = useAuthContext()
  const { handleFirebaseError } = useHandleFirebaseError()

  useEffect(() => {
    const authenticator = getAuthenticator()
    if (!authenticator) return
    setAuthenticator(authenticator)

    const unsubscribe = authenticator.auth.onAuthStateChanged((user) => {
      if (user) setCurrentUser(user)
      unsubscribe()
    })

    return unsubscribe()
  }, [getAuthenticator])

  const signOut = async (key: string) => {
    await authenticator?.signOut()
    notification.destroy(key)
    window.location.href = getPath('login')
  }

  const onFinish = async (value: { password: string }) => {
    if (!currentUser) return
    try {
      await updatePassword(currentUser, value.password)
      form.resetFields()
      notification.open({
        message: 'パスワードを変更しました',
        placement: 'top',
      })
    } catch (error) {
      if (error instanceof FirebaseError && error.code === ERROR_CODES.requiresRecentLogin) {
        const key = `notification_${Date.now()}`
        const notificationButton = {
          key,
          button: (
            <Button type="primary" size="small" onClick={() => signOut(key)}>
              ログアウト
            </Button>
          ),
        }
        await handleFirebaseError({ action: 'updatePasswordReset', error, notificationButton })
      } else {
        await handleFirebaseError({ action: 'updatePasswordReset', error })
      }
    }
  }

  return (
    <>
      <PasswordGuideline rootCss={styles.guideline} />
      <Form css={styles.form} form={form} name="login" labelAlign="left" labelCol={{ span: 8 }} onFinish={onFinish}>
        <SingleTextInputForm {...formProps['password']} />
        <SingleTextInputForm {...formProps['passwordConfirm']} />

        <Form.Item css={styles.button1}>
          <Button type="primary" htmlType="submit">
            パスワードを変更する
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
