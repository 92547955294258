import brandHeader from '@/assets/brand-header.svg'
import { css } from '@emotion/react'

const styles = {
  wrapper: css`
    display: flex;
    justify-content: center;
    overflow: hidden;
  `,
}
export const ContentBrandHeader = () => {
  return (
    <div css={styles.wrapper}>
      <img src={brandHeader} />
    </div>
  )
}
