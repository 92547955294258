import { css } from '@emotion/react'
import { Divider } from 'antd'
import * as React from 'react'
import { GenericDescriptionGroup } from './GenericDescriptionGroup'
import { DescriptonItemGroupList } from './type'

const Spacer = () => (
  <Divider
    css={css`
      border: none;
    `}
  />
)

export const GenericDescriptionGroupList = <ValueType,>(props: DescriptonItemGroupList<ValueType>) => {
  return (
    <>
      {props.diffItemGroups
        ? props.diffItemGroups.map((itemGroup, index) => (
            <React.Fragment key={itemGroup.title}>
              <GenericDescriptionGroup
                group={itemGroup}
                isDiff={true}
                renderDescriptionItem={props.renderDescriptionItem}
                needsHighlightForDiff={props.needsHighlightForDiff}
              />
              {index !== props.itemGroups.length - 1 && <Spacer />}
            </React.Fragment>
          ))
        : props.itemGroups.map((itemGroup, index) => (
            <React.Fragment key={itemGroup.title}>
              <GenericDescriptionGroup
                group={itemGroup}
                isDiff={false}
                renderDescriptionItem={props.renderDescriptionItem}
              />
              {index !== props.itemGroups.length - 1 && <Spacer />}
            </React.Fragment>
          ))}
    </>
  )
}
