import { PutProfessionalContactSuspendRequest } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
import { getApiAuthProfessionalClient } from '../client'

export const callUpdateProfessionalContactSuspended = async (
  arg: PutProfessionalContactSuspendRequest
): Promise<{ error?: Error }> => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    await apiClient.putProfessionalContactSuspend(arg)
    return {}
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
