/**
 * 実態はサブドメが同じなので内部リンク
 */
export const EXTERNAL_LINK_URL = {
  goriyouniatatte: 'https://www.bk.mufg.jp/ippan/site/',
  kojinjouhou: 'https://www.bk.mufg.jp/kojinjouhou/index.html',
  kojinjouhouHoushin: 'https://www.bk.mufg.jp/kojinjouhou/houshin.html',
  kojinjouhouToriatsukai: 'https://www.bk.mufg.jp/kojinjouhou/toriatsukai.html',
  kitei: 'https://www.bk.mufg.jp/nl/sozoku_guide/kitei/index.html',
  faq: 'https://faq01.bk.mufg.jp/category/show/289?site_domain=default',
  estateLiquidationBanner:
    'https://www.bk.mufg.jp/sonaeru/souzoku/isanseiri/index.html?link_id=isanseiri-banner_yarukoto_sg',
  testamentBanner:
    'https://www.bk.mufg.jp/sonaeru/souzoku/igonsintaku/index.html?link_id=igonshintaku-banner_yarukoto_sg',
  myFamilyNotebookBanner: 'https://www.tr.mufg.jp/lp/wagayanote/index.html?link_id=sozoku-guide_yarukoto_pd_wagayanote',
  excellentClubBanner1:
    'https://www.bk.mufg.jp/sonaeru/secondlife/excellent_club/index.html?link_id=sozoku_guide_seizen_task_list1_banner',
  excellentClubBanner2:
    'https://www.bk.mufg.jp/sonaeru/secondlife/excellent_club/index.html?link_id=sozoku_guide_seizen_task_list2_banner',
} as const
