import { isEqualArray } from 'inheritance-utils'
import {
  PlacementDescriptionDiff,
  PlacementDescriptionImagesDiff,
  PlacementDescriptionLabelsDiff,
  PlacementDescriptionTextDiff,
  PlacementDescriptionValue,
  PlacementDescriptionValueImages,
  PlacementDescriptionValueLabels,
  PlacementDescriptionValueText,
} from './type'
import { MAX_GROUP_ORDER } from './convert'
import { DescriptionItemGroupTypeWithOrder, DescriptionItemTypeWithOrder } from '../generic/type'

const buildDiffTrueItem = (
  appliedOrAcceptedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValue>,
  isEmpty: boolean
): DescriptionItemTypeWithOrder<PlacementDescriptionDiff> => {
  if (appliedOrAcceptedItem.value.kind === 'text') {
    return {
      label: appliedOrAcceptedItem.label,
      value: {
        kind: appliedOrAcceptedItem.value.kind,
        text: appliedOrAcceptedItem.value.text,
        differs: true,
        empty: isEmpty,
      },
      itemOrder: appliedOrAcceptedItem.itemOrder,
    }
  } else if (appliedOrAcceptedItem.value.kind === 'labels') {
    return {
      label: appliedOrAcceptedItem.label,
      value: {
        kind: appliedOrAcceptedItem.value.kind,
        labels: appliedOrAcceptedItem.value.labels,
        differs: true,
        empty: isEmpty,
      },
      itemOrder: appliedOrAcceptedItem.itemOrder,
    }
  } else if (appliedOrAcceptedItem.value.kind === 'images') {
    return {
      label: appliedOrAcceptedItem.label,
      value: {
        kind: appliedOrAcceptedItem.value.kind,
        images: appliedOrAcceptedItem.value.images.map((image) => ({
          id: image.id,
          imageProps: image.imageProps,
          differs: true,
          empty: isEmpty,
        })),
      },
      itemOrder: appliedOrAcceptedItem.itemOrder,
    }
  }
  throw new Error('invalid kind')
}

const findMatchingImage = (targetImages: PlacementDescriptionValueImages['images'], searchImageId?: string) => {
  return targetImages.find((targetImage) => targetImage.id === searchImageId) ?? undefined
}

const getGroupsByGroupOrder = (
  targetGroups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
  groupOrder: number
) => {
  return targetGroups.filter((group) => group.groupOrder === groupOrder)
}

// itemsにある最大のorderを取得する関数を作成する
const getMaxItemOrder = (items: DescriptionItemTypeWithOrder<PlacementDescriptionValue>[]) => {
  return items.reduce((max, item) => (item.itemOrder > max ? item.itemOrder : max), 1)
}

const getItemsByItemOrder = (
  targetItems: DescriptionItemTypeWithOrder<PlacementDescriptionValue>[],
  itemOrder: number
) => {
  return targetItems.filter((item) => item.itemOrder === itemOrder)
}

// 「代表者名」「代表者名（カナ）」itemの差分を取得する
const buildRepresentativeItemDiff = (
  appliedItems: DescriptionItemTypeWithOrder<PlacementDescriptionValueText>[],
  acceptedItems: DescriptionItemTypeWithOrder<PlacementDescriptionValueText>[]
): DescriptionItemTypeWithOrder<PlacementDescriptionDiff>[] => {
  const diffItems: DescriptionItemTypeWithOrder<PlacementDescriptionDiff>[] = []

  // 「代表者名」「代表者名（カナ）」の順番に並んでいる
  // 単純に先頭から順番に比較していく
  // acceptedが多い場合にはempty=trueでセットする
  // appliedが多い場合にはempty=falseでセットする
  for (let i = 0; i < appliedItems.length; i++) {
    if (i >= acceptedItems.length) {
      diffItems.push(buildDiffTrueItem(appliedItems[i], false))
    } else {
      diffItems.push({
        label: appliedItems[i].label,
        value: {
          kind: 'text',
          text: appliedItems[i].value.text,
          differs: appliedItems[i].value.text !== acceptedItems[i].value.text,
          empty: false,
        },
        itemOrder: appliedItems[i].itemOrder,
      })
    }
  }
  for (let i = appliedItems.length; i < acceptedItems.length; i++) {
    diffItems.push(buildDiffTrueItem(acceptedItems[i], true))
  }

  return diffItems
}

const findMatchingAreaGroup = (
  targetGroups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
  searchPrefText: string
) => {
  for (const target of targetGroups) {
    if ((target.items[0].value as PlacementDescriptionValueText).text === searchPrefText) {
      return target
    }
  }
  return undefined
}

// 「エリア」groupの差分を取得する
const buildAreaGroupDiff = (
  appliedGroup: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
  acceptedGroup: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[]
): DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] => {
  const diffGroups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] = []

  // 「エリア」のパターン
  //
  // パターン1
  // Group 1: 「都道府県」=「全国」
  // Group 2: 「市区町村」=「-」
  //
  // パターン2
  // Group 1: 「都道府県」=「北海道」
  // Group 2: 「市区町村」=「札幌市」
  // Group 3: 「都道府県」=「東京都」
  // Group 4: 「市区町村」=「渋谷区」「新宿区」

  // appliedが「青森」「東京」で、acceptedが「東京」「大阪」の場合
  // 青森：差分あり(empty=false)
  // 東京：itemの差分を取得する
  // 大阪：差分あり(empty=true) -> 一番下に配置する

  // appliedのitemsの都道府県のsetを取得する
  const appliedItemSet = new Set(
    appliedGroup.map((group) => (group.items[0].value as PlacementDescriptionValueText).text)
  )
  // acceptedのitemsの都道府県のsetを取得する
  const acceptedItemSet = new Set(
    acceptedGroup.map((group) => (group.items[0].value as PlacementDescriptionValueText).text)
  )

  for (let i = 0; i < appliedGroup.length; i++) {
    const applied = appliedGroup[i]

    // appliedのみにある都道府県の場合は差分あり(empty=false)とする
    if (!acceptedItemSet.has((appliedGroup[i].items[0].value as PlacementDescriptionValueText).text)) {
      diffGroups.push({
        title: applied.title,
        items: applied.items.map((appliedItem) => buildDiffTrueItem(appliedItem, false)),
        groupOrder: appliedGroup[i].groupOrder,
      })
    } else {
      // appliedとacceptedの両方が1つだけ存在する。itemの差分を取得する。
      const accepted = findMatchingAreaGroup(
        acceptedGroup,
        (applied.items[0].value as PlacementDescriptionValueText).text // 都道府県
      ) as DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>
      if (!accepted || (applied.items.length !== 2 && accepted.items.length !== 2)) {
        throw new Error('invalid area group items')
      }
      const groupDiff: DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff> = {
        title: applied.title,
        items: [],
        groupOrder: applied.groupOrder,
      }
      groupDiff.items.push({
        label: applied.items[0].label,
        value: {
          kind: 'text',
          text: (applied.items[0].value as PlacementDescriptionValueText).text,
          differs:
            (applied.items[0].value as PlacementDescriptionValueText).text !==
            (accepted.items[0].value as PlacementDescriptionValueText).text,
          empty: false,
        },
        itemOrder: applied.items[0].itemOrder,
      })
      groupDiff.items.push({
        label: applied.items[1].label,
        value: {
          kind: 'labels',
          labels: (applied.items[1].value as PlacementDescriptionValueLabels).labels,
          differs: !isEqualArray(
            (applied.items[1].value as PlacementDescriptionValueLabels).labels,
            (accepted.items[1].value as PlacementDescriptionValueLabels).labels
          ),
          empty: false,
        },
        itemOrder: applied.items[1].itemOrder,
      })
      diffGroups.push(groupDiff)
    }
  }

  // acceptedのみに存在するものを一番下に配置する
  for (let i = 0; i < acceptedGroup.length; i++) {
    if (!appliedItemSet.has((acceptedGroup[i].items[0].value as PlacementDescriptionValueText).text)) {
      diffGroups.push({
        title: acceptedGroup[i].title,
        items: acceptedGroup[i].items.map((acceptedItem) => buildDiffTrueItem(acceptedItem, true)),
        groupOrder: acceptedGroup[i].groupOrder,
      })
    }
  }

  return diffGroups
}

const buildTextItemDiff = (
  appliedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueText>,
  acceptedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueText>
): DescriptionItemTypeWithOrder<PlacementDescriptionTextDiff> => {
  return {
    label: appliedItem.label,
    value: {
      kind: 'text',
      text: appliedItem.value.text,
      differs: appliedItem.value.text !== acceptedItem.value.text,
      empty: false,
    },
    itemOrder: appliedItem.itemOrder,
  }
}

const buildLabelsItemDiff = (
  appliedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueLabels>,
  acceptedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueLabels>
): DescriptionItemTypeWithOrder<PlacementDescriptionLabelsDiff> => {
  return {
    label: appliedItem.label,
    value: {
      kind: 'labels',
      labels: appliedItem.value.labels,
      differs: !isEqualArray(appliedItem.value.labels, acceptedItem.value.labels),
      empty: false,
    },
    itemOrder: appliedItem.itemOrder,
  }
}

const buildImagesItemDiff = (
  appliedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueImages>,
  acceptedItem: DescriptionItemTypeWithOrder<PlacementDescriptionValueImages>
): DescriptionItemTypeWithOrder<PlacementDescriptionImagesDiff> => {
  // NOTE: imageは最大数が決まっている, 位置が変わる場合はアップロードしなおしなのでIDが変わる

  const diffItem = {
    label: appliedItem.label,
    value: {
      kind: 'images',
      images: appliedItem.value.images.map((image) => {
        const acceptedImage = findMatchingImage(
          (acceptedItem.value as PlacementDescriptionValueImages).images,
          image.id
        )
        if (acceptedImage === undefined)
          return {
            id: image.id,
            imageProps: image.imageProps,
            differs: true,
            empty: false,
          }
        // imagePropsはシステム設定のため比較せず、常にfalseとする
        return {
          id: image.id,
          imageProps: image.imageProps,
          differs: false,
          empty: false,
        }
      }),
    },
    itemOrder: appliedItem.itemOrder,
  } as DescriptionItemTypeWithOrder<PlacementDescriptionImagesDiff>

  // acceptedが多い分には差分あり(true, empty=true)とする。
  for (let i = appliedItem.value.images.length; i < acceptedItem.value.images.length; i++) {
    diffItem.value.images.push({
      id: acceptedItem.value.images[i].id,
      imageProps: acceptedItem.value.images[i].imageProps,
      differs: true,
      empty: true,
    })
  }
  return diffItem
}

// 単体group同士の差分を取得する
const buildSingleGroupDiff = (
  appliedGroup: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>,
  acceptedGroup: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>
): DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff> => {
  const diff: DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff> = {
    title: appliedGroup.title,
    items: [],
    groupOrder: appliedGroup.groupOrder,
  }

  const appliedAllItems = appliedGroup.items
  const acceptedAllItems = acceptedGroup.items

  const appliedItemMaxOrder = getMaxItemOrder(appliedAllItems)
  const acceptedItemMaxOrder = getMaxItemOrder(acceptedAllItems)

  for (let iItem = 1; iItem <= Math.max(appliedItemMaxOrder, acceptedItemMaxOrder); iItem++) {
    // appliedItems, acceptedItemsは同じorderに大抵1つだけだが、複数ある場合（「代表者名」「代表者名（カナ））もあるため配列で取得する
    const appliedItems = getItemsByItemOrder(appliedAllItems, iItem)
    const acceptedItems = getItemsByItemOrder(acceptedAllItems, iItem)

    if (appliedItems.length === 0 && acceptedItems.length === 0) {
      continue
    }

    // appliedにはなくacceptedにある場合は差分あり(true, empty=true)とする。
    if (acceptedItems.length > 0 && appliedItems.length === 0) {
      diff.items.push(...acceptedItems.map((acceptedItem) => buildDiffTrueItem(acceptedItem, true)))
      continue
    }

    // appliedにあってacceptedにない場合は差分あり(true, empty=false)とする。
    if (acceptedItems.length === 0 && appliedItems.length > 0) {
      diff.items.push(...appliedItems.map((appliedItem) => buildDiffTrueItem(appliedItem, false)))
      continue
    }

    // appliedとacceptedの両方が1つだけ存在する。itemの差分を取得する。
    if (appliedItems.length === 1 && acceptedItems.length === 1) {
      const appliedItem = appliedItems[0]
      const acceptedItem = acceptedItems[0]
      if (appliedItem.value.kind === 'text') {
        diff.items.push(
          buildTextItemDiff(
            appliedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueText>,
            acceptedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueText>
          )
        )
      }
      if (appliedItem.value.kind === 'labels') {
        diff.items.push(
          buildLabelsItemDiff(
            appliedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueLabels>,
            acceptedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueLabels>
          )
        )
      }
      if (appliedItem.value.kind === 'images') {
        diff.items.push(
          buildImagesItemDiff(
            appliedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueImages>,
            acceptedItem as DescriptionItemTypeWithOrder<PlacementDescriptionValueImages>
          )
        )
      }
    }

    // 「代表者名」「代表者名（カナ）」の場合
    if (appliedItems.length > 1 || acceptedItems.length > 1) {
      // 現在、必ず「代表者名」「代表者名（カナ）」のはず
      const representativeDiff = buildRepresentativeItemDiff(
        appliedItems as DescriptionItemTypeWithOrder<PlacementDescriptionValueText>[],
        acceptedItems as DescriptionItemTypeWithOrder<PlacementDescriptionValueText>[]
      )
      diff.items.push(...representativeDiff)
    }
  }

  return diff
}

/**
 * appliedGroupとacceptedGroupの差分を取得する
 *
 * 差分は `DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>` の配列に設定する。
 * 差分の配列はappliedGroupの順番に合わせる。
 *
 * 前提知識
 * - appliedGroupとacceptedGroupはgroup/itemの構造をとる。
 * - group/itemはそれぞれorderを持つ。
 *   - orderは表示時の順番を保持する。これはappliedGroupとacceptedGroupを突き合わせたときに片方しかないものがあることがため必要
 * - groupには同じorderで複数存在するものとして「エリア」がある。
 * - itemには同じorderで複数存在するものとして「代表者名」「代表者名（カナ）」がある。
 *
 * 差分の取得方法
 * - appliedGroupとacceptedGroupが同じ場合は差分なしとする。
 * - appliedGroupにあってacceptedGroupにない場合は差分ありとする。
 * - appliedGroupになくacceptedGroupにある場合は差分ありとする。
 *   - この場合、acceptedGroupだけにあるものは表示時には空欄で表示するためempty=trueとする。
 *   - また、acceptedGroupだけにあるgroup/itemは差分としてgroupの一番下に配置する。
 *     - このとき「代表者名」「代表者名（カナ）」のようなペアがあるため順番を崩さず配置する。
 * - appliedGroupとacceptedGroupの両方にある場合は、各項目の差分を取得する。
 *
 * @param appliedGroups 色付けしたい掲載申込を想定
 * @param acceptedGroups 比較したい審査OKの掲載申込を想定
 * @returns
 */
export const buildItemGroupsDiffList = (
  appliedGroups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
  acceptedGroups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[]
): DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] => {
  const diffList: DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] = []

  const buildDiffTrueGroupList = (
    groups: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
    empty: boolean
  ): DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] => {
    const diffList: DescriptionItemGroupTypeWithOrder<PlacementDescriptionDiff>[] = []
    for (let i = 0; i < groups.length; i++) {
      diffList.push({
        title: groups[i].title,
        items: groups[i].items.map((item) => buildDiffTrueItem(item, empty)),
        groupOrder: groups[i].groupOrder,
      })
    }
    return diffList
  }

  // groupOrder 1 ~ MAX_GROUP_ORDERまでループする
  for (let iGroup = 1; iGroup < MAX_GROUP_ORDER; iGroup++) {
    const appliedGroup = getGroupsByGroupOrder(appliedGroups, iGroup)
    const acceptedGroup = getGroupsByGroupOrder(acceptedGroups, iGroup)
    if (appliedGroup.length === 0 && acceptedGroup.length === 0) {
      continue
    }

    // appliedGroupにはなくacceptedGroupにある場合は差分あり(empty=true)とする。
    if (acceptedGroup.length > 0 && appliedGroup.length === 0) {
      diffList.push(...buildDiffTrueGroupList(acceptedGroup, true))
      continue
    }

    // appliedGroupにあってacceptedGroupにない場合は差分あり(empty=false)とする。
    if (acceptedGroup.length === 0 && appliedGroup.length > 0) {
      diffList.push(...buildDiffTrueGroupList(appliedGroup, false))
      continue
    }

    // appliedGroupとacceptedGroupの両方に1つずつある場合は、各itemの差分を取得する。
    if (appliedGroup.length === 1 && acceptedGroup.length === 1) {
      const groupDiff = buildSingleGroupDiff(appliedGroup[0], acceptedGroup[0])
      diffList.push(groupDiff)
    }

    // 「エリア」の場合
    if (appliedGroup.length > 1 || acceptedGroup.length > 1) {
      // 現在、必ず「エリア」のはず
      const areaGroupDiff = buildAreaGroupDiff(appliedGroup, acceptedGroup)
      diffList.push(...areaGroupDiff)
    }
  }

  return diffList
}
