import {
  PRIMAL_AND_CHURN_PROPS,
  PLACEMENT_PROPS,
  ProfileChurnStatus,
  ProfilePlacementStatus,
  ProfilePublicStatus,
  PUBLIC_PROPS,
} from '@/share/components/PlacementForm/profileStatus'
import { css } from '@emotion/react'
import { Tag } from 'antd'

const styles = {
  title: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  tag: css`
    margin-left: 8px;
  `,
}

const StatusTag = ({ label, color }: { label: string; color?: string }) => {
  return (
    <Tag color={color} css={styles.tag}>
      {label}
    </Tag>
  )
}

const convertProfileTitleProps = (
  props: ProfileTitleProps
): { tagColor?: string; tagLabel?: string; title: string } => {
  switch (props.kind) {
    case 'primal':
      return PRIMAL_AND_CHURN_PROPS[props.churnStatus]
    case 'placement':
      return PLACEMENT_PROPS[props.status]
    case 'public':
      return PUBLIC_PROPS[props.status]
    case 'churn':
      return PRIMAL_AND_CHURN_PROPS[props.status]
  }
}

export type ProfileTitleProps =
  | {
      kind: 'primal'
      churnStatus: ProfileChurnStatus
    }
  | {
      kind: 'placement'
      status: Exclude<ProfilePlacementStatus, 'nothing'>
    }
  | {
      kind: 'public'
      status: Exclude<ProfilePublicStatus, 'nothing'>
    }
  | {
      kind: 'churn'
      status: Exclude<ProfileChurnStatus, 'can-apply'>
    }

export const ProfileTitle = (props: ProfileTitleProps): React.JSX.Element => {
  const { tagColor, tagLabel, title } = convertProfileTitleProps(props)

  return (
    <h3 css={styles.title}>
      {title}
      {tagLabel && <StatusTag label={tagLabel} color={tagColor} />}
    </h3>
  )
}
