import { SWR_KEYS } from '@/rpc/keys'
import { ImagePosition } from '@/rpc/professionalPlacementImage/function'
import { useRpcContext } from '@/rpc/RpcContext'
import { getErrMsg } from '@/share/error'
import { FormInstance, notification } from 'antd'
import { PutProfessionalPlacementImageResponse, PutProfessionalPlacementPreviewResponse } from 'inheritance-api'
import { useCallback, useState } from 'react'
import { PlacementFormType, composePlacementApplicationReqModel } from './form'

type UseUploadImageHandlerParams = {
  form: FormInstance<PlacementFormType>
}

export type UploadImageFunctionParams = {
  image: File
  imagePosition: ImagePosition
  onSuccess?: (
    data: PutProfessionalPlacementImageResponse | undefined,
    imagePosition: ImagePosition
  ) => void | Promise<void>
  onError?: (error: Error) => void | Promise<void>
}

export type UploadImage = (params: UploadImageFunctionParams) => Promise<void>

export const useUploadImageHandler = ({ form }: UseUploadImageHandlerParams) => {
  const { callPutProfessionalPlacementImage } = useRpcContext()
  const [loading, setLoading] = useState(false)

  const uploadImage = useCallback<UploadImage>(
    async ({ image, imagePosition, onSuccess, onError }: UploadImageFunctionParams) => {
      setLoading(true)

      const { data, error } = await callPutProfessionalPlacementImage({ image, imagePosition })

      if (error) {
        notification.open({
          message: '画像のアップロードに失敗しました',
          description: error.message,
          placement: 'top',
        })

        form.resetFields([imagePosition])

        setLoading(false)

        await onError?.(error)

        throw error
      }

      form.setFieldValue(imagePosition, data?.placementImageId)

      setLoading(false)

      await onSuccess?.(data, imagePosition)
    },
    [form, callPutProfessionalPlacementImage]
  )

  return {
    uploadImage,
    loading,
  }
}

export type UsePlacementApplicationParams = {
  onSuccess?: () => Promise<void>
}

export const usePlacementApplication = ({ onSuccess }: UsePlacementApplicationParams) => {
  const { callPutProfessionalPlacement } = useRpcContext()
  const [mutating, setMutating] = useState(false)

  const mutate = async (formValues: PlacementFormType) => {
    setMutating(true)
    const { error } = await callPutProfessionalPlacement({
      data: composePlacementApplicationReqModel(formValues),
    })
    setMutating(false)

    if (error) {
      notification.open({
        message: '掲載申込に失敗しました',
        description: getErrMsg({ error, apiKey: SWR_KEYS.putProfessionalPlacement }),
        placement: 'top',
      })
      return
    }

    await onSuccess?.()
  }

  return { mutating, mutate }
}

export type UsePreviewPlacementApplicationParams = {
  onSuccess?: (data: PutProfessionalPlacementPreviewResponse) => Promise<void>
}

export const usePreviewPlacementApplication = ({ onSuccess }: UsePreviewPlacementApplicationParams) => {
  const { callPutProfessionalPreviewPlacement } = useRpcContext()
  const [mutating, setMutating] = useState(false)

  const mutate = async (formValues: PlacementFormType) => {
    setMutating(true)
    const { error, data } = await callPutProfessionalPreviewPlacement({
      data: composePlacementApplicationReqModel(formValues),
    })
    setMutating(false)

    if (error) {
      notification.open({
        message: '掲載申込のプレビュー表示に失敗しました',
        description: getErrMsg({ error }),
        placement: 'top',
      })
      return
    }

    if (data) await onSuccess?.(data)
  }

  return { mutating, mutate }
}
