import { getSettingPath, isEmailAuthRoute, getPath } from '@/router'
import { Link, useLocation } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Layout, Dropdown, MenuProps } from 'antd'
import { ReactNode } from 'react'
import logo from '@/assets/logo.svg'
import { useDesignToken } from '../design-system/token'

const useMenuItemCss = () => {
  const { color } = useDesignToken()

  return css`
    list-style-type: none;
    color: ${color.accent.gray.default};
    width: 98px;
    :hover {
      background: rgba(115, 115, 115, 0.1); // #737373: color.accent.gray.default
    }
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
  `
}

const iconMargin = css`
  margin-left: 5px;
`

const MenuItem = ({ title, icon, items }: { title: string; icon: ReactNode; items: MenuProps['items'] }) => {
  return (
    <li css={useMenuItemCss()}>
      <Dropdown menu={{ items }} placement="bottom" overlayStyle={{ whiteSpace: 'nowrap' }}>
        <div>
          {icon}
          <span css={iconMargin}>{title}</span>
        </div>
      </Dropdown>
    </li>
  )
}

const menuCss = css`
  display: flex;
  height: 100%;
`

const Menu = (props: { children: ReactNode[] }) => {
  return <ul css={menuCss}>{props.children}</ul>
}

const useHeaderCss = () => {
  const { layout, topBottomLayout } = useDesignToken()

  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${layout.globalHeaderHeight}px;

    padding-left: ${topBottomLayout.sideMargin};
    padding-right: ${topBottomLayout.sideMargin};
  `
}

const useHeaderLogo = () => {
  const { color } = useDesignToken()

  return css`
    display: flex;
    align-items: center;
    gap: 16px;
    color: ${color.accent.gray.default};
    text-decoration: none;
    :hover {
      color: ${color.accent.gray.default};
    }
    img {
      width: 160px;
    }
  `
}

const titleCss = css`
  font-weight: bold;
  font-size: 18px;
`

export const GlobalHeader = () => {
  const location = useLocation()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={getSettingPath('contract-information')}>利用申込情報</Link>,
    },
    {
      key: '2',
      label: <Link to={getSettingPath('password-change')}>パスワード変更</Link>,
    },
    {
      key: '3',
      label: <Link to={getSettingPath('access-information')}>アクセス情報</Link>,
    },
    {
      key: '4',
      label: <Link to={getSettingPath('privacy-policy')}>プライバシーポリシー</Link>,
    },
    {
      key: '5',
      label: <Link to={getSettingPath('terms')}>利用規約</Link>,
    },
    {
      key: '6',
      label: <Link to={getSettingPath('churn-application')}>解約申し込み</Link>,
    },
  ]

  const HeaderLogoElement = () => {
    return (
      <>
        <img src={logo} alt="そうぞくガイド" />
        <h3 css={titleCss}>専門家サービス</h3>
      </>
    )
  }

  const style = useHeaderLogo()

  return (
    <Layout.Header css={useHeaderCss()}>
      {location.pathname === getPath('login') ? (
        <div css={style}>
          <HeaderLogoElement />
        </div>
      ) : (
        /* 利用/掲載申込画面でのクリック時にbeforeunloadを有効にするためrouter-linkではなくaタグを使っている */
        <a href={getPath('home')} css={style}>
          <HeaderLogoElement />
        </a>
      )}

      {isEmailAuthRoute(location.pathname) && (
        <Menu>
          <></>
          <MenuItem title="メニュー" icon={<MenuOutlined />} items={items} />
        </Menu>
      )}
    </Layout.Header>
  )
}
