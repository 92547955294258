import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'
import { callValidateEmailToken } from './function'

export const useEmailTokenValidation = (apiParams: Parameters<typeof callValidateEmailToken>[0]) => {
  const { callValidateEmailToken } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.contractApplicationEmailValidation, () => callValidateEmailToken(apiParams))

  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return { result: data.result }
}
