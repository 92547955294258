import { Login } from './pages/Login/Login'
import { PasswordResetRequest } from './pages/PasswordReset/PasswordResetRequest'
import { PasswordResetRequestSent } from './pages/PasswordReset/PasswordResetRequestSent'
import { PasswordResetAuthentication } from './pages/PasswordReset/PasswordResetAuthentication'
import { PasswordResetAuthenticationURLExpired } from './pages/PasswordReset/PasswordResetAuthenticationURLExpired'
import { PasswordResetConfirm } from './pages/PasswordReset/PasswordResetConfirm'
import { PasswordResetComplete } from './pages/PasswordReset/PasswordResetComplete'
import { LandingPage } from './pages/LandingPage/LandingPage'
import { ContractApplicationForm } from './pages/ContractApplicationForm/ContractApplicationForm'
import { ErrorBoundary } from './ErrorBoundary'
import { AuthGuard } from './auth/AuthGuard'
import { RouterCustomize } from './RouterCustomize/RouterCustomize'
import { LandingPageEmailSent } from './pages/LandingPageEmailSent/LandingPageEmailSent'
import { ContractApplicationURLExpired } from './pages/ContractApplicationURLExpired/ContractApplicationURLExpired'
import { PlacementApplicationForm } from './pages/PlacementApplicationForm/PlacementApplicationForm'
import { PlacementApplicationCompleted } from './pages/PlacementApplicationForm/PlacementApplicationCompleted'
import { PlacementApplicationExists } from './pages/PlacementApplicationForm/PlacementApplicationExists'
import { Home } from './pages/Home/Home'
import { Setting, SETTING_KEY } from './pages/Setting/Setting'
import { ContractApplicationCompleted } from './pages/ContractApplicationForm/ContractApplicationCompleted'
import { CreateAccountAuthentication } from './pages/CreateAccountForm/CreateAccountAuthentication'
import { CreateAccountURLExpired } from './pages/CreateAccountForm/CreateAccountURLExpired'
import { CreateAccountCompleted } from './pages/CreateAccountForm/CreateAccountCompleted'
import { CreateAccountExists } from './pages/CreateAccountForm/CreateAccountExists'
import {
  createBrowserRouter,
  ElementWrapperProp,
  LoginPageKey,
  PasswordResetConfirmPageKey,
  CreateAccountConfirmPageKey,
} from 'inheritance-router'

import { NotFoundScreen } from './share/components/result-screen/ResultScreen'
import { CreateAccountForm } from './pages/CreateAccountForm/CreateAccountForm'
import { ContractApplicationEmailAlreadyInUse } from './pages/ContractApplicationEmailAlreadyInUse/ContractApplicationEmailAlreadyInUse'
import { ApiVersionInterceptor } from './share/components/Interceptor/ApiVersionInterceptor'
import { generatePath } from 'react-router-dom'
import { ENV } from '@/config/environment'
// eslint-disable-next-line node/no-extraneous-import
import { EnvIndicator } from 'inheritance-global-components'

export const ROUTE_PATHS = {
  [LoginPageKey]: '/',
  passwordResetRequest: '/password-reset/request',
  passwordResetRequestSent: '/password-reset/request/sent',
  passwordResetAuthentication: '/password-reset/authentication',
  passwordResetAuthenticationURLExpired: '/password-reset/authentication/expired',
  [PasswordResetConfirmPageKey]: '/password-reset/confirm',
  passwordResetComplete: '/password-reset/complete',
  firebaseAction: '/fb-action',
  lp: '/lp',
  lpEmailSent: '/lp/email-sent',
  contractApplicationURLExpired: '/contract-application/expired',
  contractApplicationEmailAlreadyInUse: '/contract-application/email-already-in-use',
  contractApplication: '/contract-application',
  contractApplicationCompleted: '/contract-application/completed',
  createAccountAuthentication: '/create-account/authentication',
  createAccount: '/create-account',
  [CreateAccountConfirmPageKey]: '/create-account/confirm',
  createAccountURLExpired: '/create-account/expired',
  createAccountCompleted: '/create-account/completed',
  createAccountAlreadyExists: '/create-account/exists',
  home: '/home',
  placementApplication: '/placement-application',
  placementApplicationCompleted: '/placement-application/completed',
  placementApplicationExists: '/placement-application/exists',
  setting: '/setting',
} as const satisfies { [key: string]: string }

export const publicRoutes = {
  [LoginPageKey]: { path: ROUTE_PATHS[LoginPageKey], element: <Login /> },
  passwordResetRequest: {
    path: ROUTE_PATHS.passwordResetRequest,
    element: <PasswordResetRequest />,
  },
  passwordResetRequestSent: {
    path: ROUTE_PATHS.passwordResetRequestSent,
    element: <PasswordResetRequestSent />,
  },
  passwordResetAuthentication: {
    path: ROUTE_PATHS.passwordResetAuthentication,
    element: <PasswordResetAuthentication />,
  },
  passwordResetAuthenticationURLExpired: {
    path: ROUTE_PATHS.passwordResetAuthenticationURLExpired,
    element: <PasswordResetAuthenticationURLExpired />,
  },
  passwordResetComplete: {
    path: ROUTE_PATHS.passwordResetComplete,
    element: <PasswordResetComplete />,
  },
  // NOTE: パスワードリセット経路変更（INH-157）により使われなくなったページ。仕様変更前に受信したメールリンクを開いた場合のエラー画面として残している。
  firebaseAction: {
    path: ROUTE_PATHS.firebaseAction,
    element: <PasswordResetAuthenticationURLExpired />,
  },
  lp: {
    path: ROUTE_PATHS.lp,
    element: <LandingPage />,
  },
  lpEmailSent: {
    path: ROUTE_PATHS.lpEmailSent,
    element: <LandingPageEmailSent />,
  },
  contractApplicationURLExpired: {
    path: ROUTE_PATHS.contractApplicationURLExpired,
    element: <ContractApplicationURLExpired />,
  },
  contractApplicationEmailAlreadyInUse: {
    path: ROUTE_PATHS.contractApplicationEmailAlreadyInUse,
    element: <ContractApplicationEmailAlreadyInUse />,
  },
  contractApplication: {
    path: ROUTE_PATHS.contractApplication,
    element: <ContractApplicationForm />,
  },
  contractApplicationCompleted: {
    path: ROUTE_PATHS.contractApplicationCompleted,
    element: <ContractApplicationCompleted />,
  },
  createAccountAuthentication: {
    path: ROUTE_PATHS.createAccountAuthentication,
    element: <CreateAccountAuthentication />,
  },
  // NOTE: パスワードリセット経路変更（INH-157）により使われなくなったページ。仕様変更前に受信したメールリンクを開いた場合のエラー画面として残している。
  createAccount: {
    path: ROUTE_PATHS.createAccount,
    element: <CreateAccountURLExpired />,
  },
  createAccountURLExpired: {
    path: ROUTE_PATHS.createAccountURLExpired,
    element: <CreateAccountURLExpired />,
  },
  createAccountCompleted: {
    path: ROUTE_PATHS.createAccountCompleted,
    element: <CreateAccountCompleted />,
  },
  createAccountAlreadyExists: {
    path: ROUTE_PATHS.createAccountAlreadyExists,
    element: <CreateAccountExists />,
  },
  notFound: {
    path: '*',
    element: <NotFoundScreen />,
  },
}

export const emailAuthRoutes = {
  home: {
    path: ROUTE_PATHS.home,
    element: <Home />,
  },
  placementApplication: {
    path: ROUTE_PATHS.placementApplication,
    element: <PlacementApplicationForm />,
  },
  placementApplicationCompleted: {
    path: ROUTE_PATHS.placementApplicationCompleted,
    element: <PlacementApplicationCompleted />,
  },
  placementApplicationExists: {
    path: ROUTE_PATHS.placementApplicationExists,
    element: <PlacementApplicationExists />,
  },
  setting: {
    path: ROUTE_PATHS.setting,
    element: <Setting />,
  },
}

const phoneAuthRoutes = {
  [PasswordResetConfirmPageKey]: {
    path: ROUTE_PATHS[PasswordResetConfirmPageKey],
    element: <PasswordResetConfirm />,
  },
  [CreateAccountConfirmPageKey]: {
    path: ROUTE_PATHS[CreateAccountConfirmPageKey],
    element: <CreateAccountForm />,
  },
}

export const authRoutes = {
  ...emailAuthRoutes,
  ...phoneAuthRoutes,
}

/**
 * APIバージョンの差分によってインターセプトされるルーター
 */
const interceptedRoutesWithInvalidApiVersion = [
  ROUTE_PATHS.passwordResetRequestSent,
  ROUTE_PATHS.passwordResetComplete,
  ROUTE_PATHS.lp,
  ROUTE_PATHS.lpEmailSent,
  ROUTE_PATHS.contractApplicationURLExpired,
  ROUTE_PATHS.contractApplicationEmailAlreadyInUse,
  ROUTE_PATHS.contractApplicationCompleted,
  ROUTE_PATHS.createAccountURLExpired,
  ROUTE_PATHS.createAccountCompleted,
  ROUTE_PATHS.createAccountAlreadyExists,
  ROUTE_PATHS.home,
  ROUTE_PATHS.placementApplicationCompleted,
  ROUTE_PATHS.placementApplicationExists,
] as const

export const routes = { ...publicRoutes, ...authRoutes }

const ElementWrapper = (props: ElementWrapperProp) => {
  // 認証不要のページでは認証をスキップする（ログインページはログイン済みの場合にホームへリダイレクトさせるためスキップ対象から除外）
  const skipAuthentication = props.isPublic && !props.isLoginPage
  return (
    <>
      <ErrorBoundary>
        <RouterCustomize>
          <ApiVersionInterceptor interceptedRoutes={interceptedRoutesWithInvalidApiVersion}>
            {skipAuthentication && props.children}
            {!skipAuthentication && (
              <AuthGuard
                isLoginPage={props.isLoginPage}
                isPasswordResetConfirmPage={props.isPasswordResetConfirmPage}
                isCreateAccountConfirmPage={props.isCreateAccountConfirmPage}
              >
                {props.children}
              </AuthGuard>
            )}
          </ApiVersionInterceptor>
        </RouterCustomize>
      </ErrorBoundary>
      <EnvIndicator isDev={ENV.DEV} env={ENV.ENV} />
    </>
  )
}

export const getPath = (key: keyof typeof ROUTE_PATHS, params?: Parameters<typeof generatePath>[1]) =>
  generatePath(ROUTE_PATHS[key] as string, params)

/** パスが対象のリストに含まれるかを返却する */
export const isPathInRoutes = (path: string, routes: typeof authRoutes | typeof emailAuthRoutes) => {
  const pathSplit = path.slice(-1) === '/' ? path.slice(0, -1).split('/') : path.split('/')
  const targetRoutes = Object.values(routes)

  for (const route of targetRoutes) {
    const routeSplit = route.path.split('/')
    if (routeSplit.length !== pathSplit.length) continue
    route.path.split('/').forEach((authPath, index) => {
      if (authPath[0] === ':') {
        routeSplit[index] = ''
        pathSplit[index] = ''
      }
    })
    if (pathSplit.join('/') === routeSplit.join('/')) return true
  }
  return false
}

/** メールアドレス認証もしくは電話番号認証を必要なページかどうかを返却する */
export const isAuthRoute = (path: string): boolean => {
  return isPathInRoutes(path, authRoutes)
}

/** メールアドレス認証が必要なページかどうかを返却する */
export const isEmailAuthRoute = (path: string): boolean => {
  return isPathInRoutes(path, emailAuthRoutes)
}

export const router = createBrowserRouter(publicRoutes, authRoutes, ElementWrapper)

export const getSettingPath = (key: keyof typeof SETTING_KEY) => {
  return getPath('setting') + `?key=${key}`
}
