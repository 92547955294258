import { DatePicker, Form } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Rule } from 'antd/lib/form'
import { FormLabel } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'
import { FormLabelWithGuideText } from 'inheritance-components'

const dateFormat = 'YYYY/MM/DD'

export const DateForm = ({
  label,
  name,
  placeholder,
  rules,
  guideText,
  colon,
}: {
  label: string
  name: string
  placeholder: string
  rules: Rule[]
  guideText?: string
  colon?: boolean
}) => {
  return (
    <Form.Item
      hasFeedback
      name={name}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      rules={rules}
      // labelでrequiredを表現するため「*」は不要
      required={false}
      colon={colon}
      style={guideText ? { marginBottom: 0 } : {}}
    >
      <DatePicker locale={locale} format={dateFormat} placeholder={placeholder} />
    </Form.Item>
  )
}
