import { css, SerializedStyles } from '@emotion/react'
import { ProfileStatusDescription } from './ProfileStatusDescription'
import { ProfileChurnStatus } from '@/share/components/PlacementForm/profileStatus'
import { ProfileDeleteIcon } from '@/share/components/icons/ProfileIcon'
import { PlacementEndDate } from '@/share/domain/placement/placementEndDate'
import { useDesignToken } from '@/share/components/design-system/token'

const useStyle = () => {
  const { themeColor } = useDesignToken()

  return {
    content: css`
      text-align: center;
      color: ${themeColor.text.black};
      line-height: 24px;
      margin-top: 28px;
    `,
  }
}

export const ChurnStatus = ({
  profileChurnStatus,
  placementEndDate,
  rootCss,
}: {
  profileChurnStatus: Exclude<ProfileChurnStatus, 'can-apply'>
  placementEndDate: PlacementEndDate
  rootCss?: SerializedStyles
}) => {
  return (
    <div css={rootCss}>
      <ProfileDeleteIcon />
      <div css={useStyle().content}>
        <ProfileStatusDescription kind="churn" status={profileChurnStatus} placementEndDate={placementEndDate} />
      </div>
    </div>
  )
}
