import { css } from '@emotion/react'
import { Form, Input, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import { SelectFormItem } from '@/share/components/form/SelectForm'
import { FormLabel } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'
import { FormLabelWithGuideText } from 'inheritance-components'

const styles = {
  halfInput: css`
    display: inline-block;
    width: calc(50% - 8px);
  `,
  doubleForm: css`
    margin-bottom: 0;
  `,
}

export const SelectAndTextForm = ({
  label,
  select,
  text,
  guideText,
  colon,
}: {
  label: string
  select: {
    name: string
    placeholder: string
    rules: Rule[]
    option: SelectFormItem[]
    onSelect?: (value: string) => void
  }
  text: { show: boolean; name: string; placeholder: string; rules?: Rule[] }
  guideText?: string
  colon?: boolean

  onInput?: () => void
}) => {
  const required = judgeRequired([...(select.rules ?? []), ...(text.rules ?? [])])

  return (
    <Form.Item
      css={styles.doubleForm}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} required={required} />
        ) : (
          <FormLabel label={label} required={required} />
        )
      }
      colon={colon}
    >
      <Form.Item css={styles.halfInput} name={select.name} hasFeedback rules={select.rules}>
        <Select
          placeholder={select.placeholder}
          onSelect={(value: string | number) => select.onSelect?.(value as string)}
        >
          {select.option.map((o) => (
            <Select.Option key={o.value} value={o.value}>
              {o.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {text.show && (
        <Form.Item css={styles.halfInput} style={{ margin: '0 8px' }} name={text.name} hasFeedback rules={text.rules}>
          <Input placeholder={text.placeholder} />
        </Form.Item>
      )}
    </Form.Item>
  )
}
