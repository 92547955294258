export const BUSINESS_CATEOGRY = {
  individual: 'individual',
  corporation: 'corporation',
} as const

export type TBusinessCategory = keyof typeof BUSINESS_CATEOGRY

export const BUSINESS_CATEOGRY_OPTION = [
  {
    value: BUSINESS_CATEOGRY.individual,
    label: '個人',
  },
  {
    value: BUSINESS_CATEOGRY.corporation,
    label: '法人',
  },
] as const

export const toBusinessCategoryCode = (maybeCategoryCode: string | undefined): TBusinessCategory | undefined => {
  switch (maybeCategoryCode) {
    case 'individual':
      return 'individual'
    case 'corporation':
      return 'corporation'
    default:
      return undefined
  }
}
