import React from 'react'

type State = {
  initialHasSummaryCourtLegalRepresentation?: boolean
  hasSummaryCourtLegalRepresentation: boolean
  setHasSummaryCourtLegalRepresentation: React.Dispatch<React.SetStateAction<boolean>>
}

const initialValues: State = {
  initialHasSummaryCourtLegalRepresentation: false,
  hasSummaryCourtLegalRepresentation: false,
  setHasSummaryCourtLegalRepresentation: () => {
    // nothing
  },
}
export const buildPlacementFormState = (initialHasSummaryCourtLegalRepresentation?: boolean): State => ({
  ...initialValues,
  initialHasSummaryCourtLegalRepresentation,
})

const placementFormContext = React.createContext<State | undefined>(undefined)

type Props = { children: React.ReactNode; state?: State }
export const PlacementFormProvider = ({ children, state }: Props) => {
  const [hasSummaryCourtLegalRepresentation, setHasSummaryCourtLegalRepresentation] = React.useState<boolean>(
    state?.initialHasSummaryCourtLegalRepresentation || false
  )

  const value = React.useMemo(
    () => ({
      ...state,
      hasSummaryCourtLegalRepresentation,
      setHasSummaryCourtLegalRepresentation,
    }),
    [hasSummaryCourtLegalRepresentation, state]
  )
  return <placementFormContext.Provider value={value}>{children}</placementFormContext.Provider>
}

export const usePlacementFormContext = () => {
  const context = React.useContext(placementFormContext)
  if (!context) throw new Error('usePlacementFormContext must be used within a PlacementFormProvider')
  return context
}
