import { useLocation } from 'react-router-dom'
import { getPath } from '@/router'
import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'
import { PasswordResetSentContent, PASSWORD_RESET_SENT_TITLE } from 'inheritance-components'

export const PasswordResetRequestSent = () => {
  const state = useLocation().state as null | { email: string | undefined }
  const email = state?.email
  return (
    <SuccessScreen
      kind="composition"
      title={PASSWORD_RESET_SENT_TITLE}
      content={<PasswordResetSentContent toLoginLink={getPath('login')} email={email} />}
    />
  )
}
