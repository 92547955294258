import { css } from '@emotion/react'
import { Alert, Button, Col, Divider, Row } from 'antd'
import { ItemGroup } from '../form'
import { ContractDetail } from 'inheritance-components'
import { usePreventBrowserClosing } from '@/share/hooks/usePreventBrowserClosing'

const styles = {
  form: css`
    margin: 0 auto;
    padding-bottom: 32px;
    max-width: 1000px;
  `,
  title: css`
    font-weight: normal;
  `,
  description: css`
    display: flex;
    justify-content: center;
    margin: 32px 0;
  `,
  alert: css`
    width: max-content;
  `,
  footerDivider: css`
    margin: 40px 0 32px;
  `,
  buttonGroup: css`
    text-align: center;
  `,
  editButton: css`
    margin-right: 32px;
  `,
}

const submitButtonText = '利用申込情報を送信する'

type ConfirmFormProps = {
  itemGroups: ItemGroup[]
  onBack: () => void
  onSubmit: () => void
  mutating: boolean
}

export const ConfirmForm = (props: ConfirmFormProps) => {
  usePreventBrowserClosing()
  return (
    <Row>
      <Col offset={2} span={20}>
        <div css={styles.description}>
          <Alert
            css={styles.alert}
            showIcon
            type="warning"
            message={`まだ申し込みは完了していません。下部の「${submitButtonText}」ボタンを押して申し込みを完了してください。`}
          />
        </div>
        <ButtonGroup {...props} />
        <Divider />
        <section css={styles.form}>
          <ContractDetail itemGroups={props.itemGroups} kind="normalized" />
          <Divider css={styles.footerDivider} />
          <ButtonGroup {...props} />
        </section>
      </Col>
    </Row>
  )
}

const ButtonGroup = (props: ConfirmFormProps) => {
  return (
    <div css={styles.buttonGroup}>
      <Button css={styles.editButton} onClick={props.onBack}>
        修正する
      </Button>
      <Button type="primary" onClick={props.onSubmit} loading={props.mutating}>
        {submitButtonText}
      </Button>
    </div>
  )
}
