// eslint-disable-next-line node/no-extraneous-import
import * as z from 'zod'
import { formNames } from '@/share/components/PlacementForm/form'
import { RepresentativeFormStorageSchema } from './representativeTypes'
import { RepresentativeFormTypeOnlyName } from '@/share/components/RepresentativeForm/form'
import { AreaFormType } from '@/share/components/PlacementForm/AreaForm'
import { RegularHoliday } from 'inheritance-api'
import { CONSULTATION_FEE_TYPE_VALUES } from 'inheritance-utils'
export * from './representativeTypes'
// eslint-disable-next-line node/no-extraneous-import
import dayjs from 'dayjs'

export type PlacementFormStorageData = {
  [formNames.profile1Image]?: string
  [formNames.description1]?: string
  [formNames.profile2Image1]?: string
  [formNames.profile2Image2]?: string
  [formNames.profile2Image3]?: string
  [formNames.professionalCategory]?: string
  [formNames.officeName]?: string
  [formNames.officeNameKana]?: string
  [formNames.description2]?: string
  [formNames.detail2]?: string
  [formNames.profile3Image]?: string
  [formNames.description3]?: string
  [formNames.detail3]?: string
  [formNames.additionalInfo]?: string[]
  [formNames.phoneNumber]?: string
  [formNames.phoneContactDisabled]?: boolean
  [formNames.reception.receptionHours]?: [dayjs.Dayjs, dayjs.Dayjs] | []
  [formNames.reception.receptionCall24HoursAvailable]?: boolean[]
  [formNames.reception.receptionHoursNote]?: string
  [formNames.reception.regularHoliday]?: RegularHoliday
  [formNames.access]?: string
  [formNames.url]?: string
  [formNames.postCode.first]?: string
  [formNames.postCode.second]?: string
  [formNames.prefecture]?: string
  [formNames.city]?: string
  [formNames.otherArea]?: string
  [formNames.townAndStreetAddress]?: string
  [formNames.building]?: string
  [formNames.isWholeArea]?: boolean
  [formNames.areas]?: string[]
  [formNames.supportServices]?: string[]
  [formNames.emailAddress]?: string
  [formNames.affiliation]?: string
  [formNames.affiliationOther]?: string
  [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]?: string
  [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]?: string
  [formNames.summaryCourtLegalRepresentation.certificationNumber]?: string
  [formNames.consultationFee.consultationFeeType]?: CONSULTATION_FEE_TYPE_VALUES
  [formNames.consultationFee.consultationFreeTimeLimit]?: string
  [formNames.consultationFee.consultationFeeNote]?: string
  [formNames.mobilizationFee]?: string
  [formNames.reward]?: string
  [formNames.contactSurname]?: string
  [formNames.contactGivenName]?: string
  [formNames.contactSurnameKana]?: string
  [formNames.contactGivenNameKana]?: string
} & RepresentativeFormTypeOnlyName &
  AreaFormType

export const FormStorageSchema = z.object({
  loginEmailAddress: z.string(),
  data: z
    .object({
      [formNames.profile1Image]: z.string().optional(),
      [formNames.description1]: z.string().optional(),
      [formNames.profile2Image1]: z.string().optional(),
      [formNames.profile2Image2]: z.string().optional(),
      [formNames.profile2Image3]: z.string().optional(),
      [formNames.professionalCategory]: z.string().optional(),
      [formNames.officeName]: z.string().optional(),
      [formNames.officeNameKana]: z.string().optional(),
      [formNames.description2]: z.string().optional(),
      [formNames.detail2]: z.string().optional(),
      [formNames.profile3Image]: z.string().optional(),
      [formNames.description3]: z.string().optional(),
      [formNames.detail3]: z.string().optional(),
      [formNames.additionalInfo]: z.array(z.string()).optional(),
      [formNames.phoneNumber]: z.string().optional(),
      [formNames.phoneContactDisabled]: z.boolean().optional(),
      [formNames.reception.receptionHours]: z.array(z.any()).optional(),
      [formNames.reception.receptionCall24HoursAvailable]: z.array(z.boolean()).optional(),
      [formNames.reception.receptionHoursNote]: z.string().optional(),
      [formNames.reception.regularHoliday]: z.array(z.string()).optional(),
      [formNames.access]: z.string().optional(),
      [formNames.url]: z.string().optional(),
      [formNames.postCode.first]: z.string().optional(),
      [formNames.postCode.second]: z.string().optional(),
      [formNames.prefecture]: z.string().optional(),
      [formNames.city]: z.string().optional(),
      [formNames.otherArea]: z.string().optional(),
      [formNames.townAndStreetAddress]: z.string().optional(),
      [formNames.building]: z.string().optional(),
      [formNames.isWholeArea]: z.boolean().optional(),
      [formNames.areas]: z.array(z.string()).optional(),
      [formNames.supportServices]: z.array(z.string()).optional(),
      [formNames.emailAddress]: z.string().optional(),
      [formNames.affiliation]: z.string().optional(),
      [formNames.affiliationOther]: z.string().optional(),
      [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]: z.string().optional(),
      [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]: z.string().optional(),
      [formNames.summaryCourtLegalRepresentation.certificationNumber]: z.string().optional(),
      [formNames.consultationFee.consultationFeeType]: z.string().optional(),
      [formNames.consultationFee.consultationFreeTimeLimit]: z.string().optional(),
      [formNames.consultationFee.consultationFeeNote]: z.string().optional(),
      [formNames.mobilizationFee]: z.string().optional(),
      [formNames.reward]: z.string().optional(),
      [formNames.contactSurname]: z.string().optional(),
      [formNames.contactGivenName]: z.string().optional(),
      [formNames.contactSurnameKana]: z.string().optional(),
      [formNames.contactGivenNameKana]: z.string().optional(),
    })
    .merge(RepresentativeFormStorageSchema),
})
