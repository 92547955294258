import { useEffect } from 'react'

const SCRIPT_ID = 'google-recaptcha-v3'
const JS_BASE_URL = 'https://www.google.com/recaptcha/api.js'

const isScriptInjected = () => !!document.querySelector(`#${SCRIPT_ID}`)

const injectReCaptchaScript = ({ onLoad }: { onLoad: () => void }) => {
  if (isScriptInjected()) {
    onLoad()

    return
  }

  const js = document.createElement('script')
  js.id = SCRIPT_ID
  js.src = JS_BASE_URL

  js.defer = true
  js.async = true
  js.onload = onLoad

  const head = document.getElementsByTagName('head')[0]

  head.appendChild(js)
}

const cleanRecaptchaScript = () => {
  const script = document.querySelector(`#${SCRIPT_ID}`)
  if (script) {
    script.remove()
  }
}

const callbackKeys = {
  verified: 'verified',
  error: 'error',
} as const

const recaptchaCallbackName = 'verifyCallback'
const recaptchaErrorCallbackName = 'recaptchaErrorCallback'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(window as any)[recaptchaCallbackName] = (token: string) => {
  storage.execCallback('verified', token)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(window as any)[recaptchaErrorCallbackName] = () => {
  storage.execCallback('error')
}

export type RecaptchaCallbackArg = { type: 'verified'; token: string } | { type: 'error'; error: Error }
type RecaptchaExecute = () => Promise<string>
type RecaptchaCallback = (arg: RecaptchaCallbackArg) => void

class RecaptchaStorage {
  private recaptchaExecute?: RecaptchaExecute = undefined
  private recaptchaReset?: () => void = undefined
  private token: string | null = null
  private callback?: RecaptchaCallback

  async getToken() {
    if (this.token) return this.token

    if (!this.recaptchaExecute) throw Error('recaptchaExecute does not exist')

    this.token = await this.recaptchaExecute()
    return this.token
  }

  setFunctions({
    recaptchaExecute,
    recaptchaReset,
  }: {
    recaptchaExecute?: RecaptchaExecute
    recaptchaReset?: () => void
  }) {
    this.recaptchaExecute = recaptchaExecute
    this.recaptchaReset = recaptchaReset
  }

  setCallback(func?: RecaptchaCallback) {
    this.callback = func
  }

  execCallback(callbackKey: keyof typeof callbackKeys, token?: string) {
    if (!this.callback) {
      throw Error(`${recaptchaCallbackName} does not exist`)
    }
    if (callbackKey === 'verified') {
      if (!token) {
        this.callback({ type: 'error', error: Error(`${recaptchaCallbackName} token does not exist`) })
        return
      }

      this.token = token
      this.callback({ type: 'verified', token })
    } else if (callbackKey === 'error') {
      this.callback({ type: 'error', error: Error(`${recaptchaErrorCallbackName} is called`) })
    }
  }

  reset() {
    this.clearToken()

    if (!this.recaptchaReset) throw Error('recaptchaReset does not exist')

    this.recaptchaReset()
  }

  private clearToken() {
    this.token = null
  }

  clear() {
    this.clearToken()
    this.setFunctions({ recaptchaExecute: undefined, recaptchaReset: undefined })
  }
}

const storage = new RecaptchaStorage()

export const getRecaptchaToken = () => storage.getToken()
export const resetRecaptcha = () => storage.reset()

type RecaptchaV2InvisibleProps = {
  siteKey: string
  callback: RecaptchaCallback
}

/**
 * WARN:
 * このコンポーネントは1画面に複数配置されることを想定していない。
 * 別ページであれば、遷移時にstorage.execCallbackが上書きされるため問題ない。
 */
export const RecaptchaV2Invisible = (props: RecaptchaV2InvisibleProps) => {
  useEffect(() => {
    const onLoad = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const grecaptcha = (window as any).grecaptcha

      if (!grecaptcha) throw new Error('failed to load grecaptcha')

      grecaptcha.ready(() => {
        storage.setFunctions({
          recaptchaExecute: grecaptcha.execute,
          recaptchaReset: grecaptcha.reset,
        })
      })
    }

    injectReCaptchaScript({
      onLoad,
    })

    return () => {
      storage.clear()
      cleanRecaptchaScript()
    }
  }, [])

  useEffect(() => {
    storage.setCallback(props.callback)
    return () => {
      storage.setCallback(undefined)
    }
  }, [props.callback])

  return (
    <div
      className="g-recaptcha"
      data-sitekey={props.siteKey}
      data-callback={recaptchaCallbackName}
      data-error-callback={recaptchaErrorCallbackName}
      data-size="invisible"
    ></div>
  )
}
