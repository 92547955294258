import { ReactNode } from 'react'
import { FirebaseError, getErrorMessage, Action } from 'inheritance-auth'
import { notification } from 'antd'
import { useRpcContext } from '@/rpc/RpcContext'

// NOTE: 管理ポータル/専門家サービスで認証周りの挙動が分かれるケースを一応想定して共通ソースではなく分けています

type NotificationButton = {
  key: string
  button: ReactNode
}

type HandleFirebaseErrorArg = {
  /** firebaseのアクション種別 */
  action: Action
  /** catchしたエラーもしくはfirebaseのエラーコード */
  error: unknown | string
  /** エラーダイアログの閉じるボタン */
  notificationButton?: NotificationButton
}

export const useHandleFirebaseError = () => {
  const { callPostProfessionalError } = useRpcContext()

  /** Firebaseエラー発生時に、ダイアログUIの表示とバックエンドAPIへのログ送信を行う */
  const handleFirebaseError = async ({ action, error, notificationButton }: HandleFirebaseErrorArg) => {
    if (typeof error === 'string') {
      openNotification(action, error, notificationButton)
    } else if (error instanceof FirebaseError) {
      openNotification(action, error.code, notificationButton)
    } else {
      console.error('unknown error type', error)
    }

    await callPostProfessionalError({ data: error })
  }

  return {
    handleFirebaseError,
  }
}

const openNotification = (action: Action, errorCode: string, notificationButton?: NotificationButton) => {
  const message = getErrorMessage(errorCode, action)

  notification.open({
    message: message.message,
    description: message.description,
    placement: 'top',
    key: notificationButton?.key,
    btn: notificationButton?.button,
  })
}
