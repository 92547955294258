import { GetApiVersionResponse } from '../../api'
import { VerificationInterceptedPath } from './types'

export const getVerificationInterceptedPath = ({
  pathname,
  search = '',
  interceptedRoutes,
}: VerificationInterceptedPath) => {
  if (!pathname) return undefined

  const isVerifyPath = interceptedRoutes.find((path) => {
    if (typeof path === 'string') return pathname === path
    return pathname.match(path)
  })

  if (isVerifyPath) return `${pathname}${search}`
}

/**
 * @param origin 初期レンダリング時のAPIバージョン
 * @param latest 最新のAPIバージョン
 * @returns 与えられたAPIバージョンの値が無ければ `true` を返すが、APIバージョンにズレがある場合は `false` を返す
 */
export const verifyApiVersion = (origin?: GetApiVersionResponse, latest?: GetApiVersionResponse) => {
  if (!origin?.apiVersion || origin.apiVersion.length <= 0) return true
  if (!latest?.apiVersion || latest.apiVersion.length <= 0) return true
  if (origin.apiVersion === latest.apiVersion) return true
  return false
}
