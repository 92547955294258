import { AppLayout } from '@/share/components/layout/AppLayout'
import { PhoneNumberLoginForm } from '@/share/components/PhoneNumberLoginForm/PhoneNumberLoginForm'
import { useProfessionalCreateAccountValidate } from '@/rpc/professionalCreateAccount/useSWR'
import { useQueryParamsDefinitely } from 'inheritance-router'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPath } from '@/router'

export const CreateAccountAuthentication = () => {
  const { createAccountToken } = useQueryParamsDefinitely({
    keys: ['createAccountToken'],
    errorWhenFailed: new Error('無効なURLです'),
  })

  const { result, alreadyExists } = useProfessionalCreateAccountValidate({
    createAccountToken: createAccountToken,
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (!result) navigate(getPath('createAccountURLExpired'))
    if (alreadyExists) navigate(getPath('createAccountAlreadyExists'))
  }, [alreadyExists, navigate, result])

  const onSuccessCallback = () => {
    navigate(getPath('createAccountConfirm') + `?createAccountToken=${createAccountToken}`)
  }

  return (
    <AppLayout>
      <PhoneNumberLoginForm
        headerTitle="アカウント作成"
        onSuccessCallback={onSuccessCallback}
        phoneInputDescription={
          <>
            アカウント作成をします。ご登録いただいている電話番号を入力し、
            <br />
            SMSに確認コードを送信するボタンをおしてください。
          </>
        }
        pinInputButtonArea={{
          description: '認証コードをお忘れの場合は電話番号入力をやり直し、認証コードを再送信してください。',
          button: {
            label: '電話番号入力をやり直す',
            // NOTE: 電話番号入力とSMSコード入力は同じURLなので再読込で電話番号入力表示に戻す
            to: window.location.href,
          },
        }}
      />
    </AppLayout>
  )
}
