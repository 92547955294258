import { useDesignToken } from '@/share/components/design-system/token'
import { css } from '@emotion/react'
import { useLandingPageDesignToken } from './design-system/useLandingPageDesignToken'

export const RevisedAdvertiserAgreementAlert = () => {
  const style = useRevisedAdvertiserAgreementAlertStyle()
  return (
    <div css={style.content}>
      【お詫びと訂正】「そうぞくガイド」広告主規約改定のお知らせ
      <br />
      12月5日にご案内した「そうぞくガイド」広告主規約の一部改定につき、下記の通り改定日を訂正いたします。
      <br />
      ご迷惑をおかけしますこと、謹んでお詫び申し上げます。
      <br />
      （訂正前）2023年12月19日（火）より改定
      <br />
      （訂正後）2024年1月16日（火）より改定
      <br />
      尚、改定内容についての変更はございません。（
      <NewPage href="/static/notice_agreement_20231218.pdf" text="新旧対比表" />
      ）
      <br />
      12月5日のお知らせ内容については、
      <NewPage href="/" text="こちら" />
      をご確認ください。
      <br />
      <div css={style.date}>（2023年12月18日現在）</div>
    </div>
  )
}

const useRevisedAdvertiserAgreementAlertStyle = () => {
  const { spacing, mediaQuery } = useLandingPageDesignToken()
  return {
    content: css`
      margin: ${spacing(4)} auto;
      width: 741px;
      line-height: 22px;

      ${mediaQuery.small} {
        width: 590px;
      }
    `,
    date: css`
      text-align: right;
    `,
  }
}

const NewPage = ({ href, text }: { href: string; text: string }) => {
  const style = useNewPageStyle()
  return (
    <a href={href} css={style.link} target="_blank" rel="noreferrer noopener">
      {text}
    </a>
  )
}
const useNewPageStyle = () => {
  const { color } = useDesignToken()
  return {
    link: css`
      color: ${color.accent.purple.default};
      text-decoration: underline;
      text-underline-position: under;
    `,
  }
}
