import { DescriptionItemGroupType, getRules, RULES, DescriptionItemType } from 'inheritance-components'
import {
  IMAGEPROPS,
  ImageProps,
  affiliationLabelFromString,
  removeSpace,
  splitByNewline,
  SupportAreaMaster,
  PREFECTURE,
  supportAreaMaster,
  getAffiliation,
  getAffiliationData,
  enableAffiliationOther,
  isConsultationFeeTypeTimeLimit,
  CONSULTATION_FEE_TYPE_VALUES,
} from 'inheritance-utils'
import {
  PutProfessionalPlacementRequest,
  GetProfessionalPlacementDefaultsResponse,
  AdditionalInfo,
  SupportService,
  ProfessionalCategory,
  RepresentativeItem,
  ContractApplicationDetails,
  ProfessionalCategoryCode,
  Reception,
  RegularHoliday,
  ConsultationFee,
} from 'inheritance-api'
import { PlacementDescriptionValue } from 'inheritance-components/src/components/description/placement/type'
// eslint-disable-next-line node/no-extraneous-import
import dayjs from 'dayjs'
import {
  representativeFormNames,
  representativeFormNamesWithKey,
  RepresentativeFormTypeOnlyName,
} from '@/share/components/RepresentativeForm/form'
import { AreaFormType, AREA_FORM_PREFIX } from './AreaForm'
import { PlacementFormStorageData } from './functions/storage'
import { getReceptionDetailsItems } from 'inheritance-components/src/components/description/placement/formItems/getReceptionHours'
import { getConsultationFeeItem } from 'inheritance-components/src/components/description/placement/formItems/getConsultationFee'

export const formNames = {
  /** 掲載情報1 */
  profile1Image: 'profile1Image',
  description1: 'description1',
  /** 掲載情報2 */
  profile2Image1: 'profile2Image1',
  profile2Image2: 'profile2Image2',
  profile2Image3: 'profile2Image3',
  professionalCategory: 'professionalCategory',
  officeName: 'officeName',
  officeNameKana: 'officeNameKana',
  description2: 'description2',
  detail2: 'detail2',
  /** 掲載情報3 */
  profile3Image: 'profile3Image',
  description3: 'description3',
  detail3: 'detail3',
  additionalInfo: 'additionalInfo',
  /** 基本情報 */
  phoneNumber: 'phoneNumber',
  phoneContactDisabled: 'phoneContactDisabled',
  reception: {
    receptionHours: 'receptionHours',
    receptionCall24HoursAvailable: 'receptionCall24HoursAvailable',
    receptionHoursNote: 'receptionHoursNote',
    regularHoliday: 'regularHoliday',
  },
  access: 'access',
  url: 'url',
  postCode: {
    first: 'postCodeFirst',
    second: 'postCodeSecond',
  },
  prefecture: 'prefecture',
  city: 'city',
  otherArea: 'otherArea',
  townAndStreetAddress: 'townAndStreetAddress',
  building: 'building',
  emailAddress: 'emailAddress',
  affiliation: 'affiliation',
  affiliationOther: 'affiliationOther',
  // 代表者情報
  ...representativeFormNamesWithKey,

  /** 簡裁訴訟代理等関係業務 */
  summaryCourtLegalRepresentation: {
    summaryCourtLegalRepresentationRadio: 'summaryCourtLegalRepresentationRadio',
    specifiedMember: {
      surname: 'summaryCourtLegalRepresentationSurname',
      givenName: 'summaryCourtLegalRepresentationGivenName',
    },
    certificationNumber: 'summaryCourtLegalRepresentationCertificationNumber',
  },

  /** 対応エリア */
  isWholeArea: 'isWholeArea',
  areas: 'areas',
  /** 対応サービス */
  supportServices: 'supportServices',
  /** 料金 */
  consultationFee: {
    consultationFeeType: 'consultationFeeType',
    consultationFreeTimeLimit: 'consultationFreeTimeLimit',
    consultationFeeNote: 'consultationFeeNote',
  },
  mobilizationFee: 'mobilizationFee',
  reward: 'reward',
  /** 氏名 */
  contactSurname: 'contactSurname',
  contactGivenName: 'contactGivenName',
  contactSurnameKana: 'contactSurnameKana',
  contactGivenNameKana: 'contactGivenNameKana',

  termsOfUseAgreement: 'termsOfUseAgreement',
} as const

export const formProps = {
  /** 掲載情報1 */
  [formNames.profile1Image]: {
    name: formNames.profile1Image,
    label: '掲載写真1',
    rules: getRules([]),
    guideText:
      '検索結果一覧に表示する画像を選択してください。画像の選択がない場合は、任意のストック画像が適用されます。',
    colon: false,
  },
  [formNames.description1]: {
    name: formNames.description1,
    label: '事務所説明1',
    rules: getRules([RULES.requireEntry, RULES.notBlank]),
    placeholder: '全国オフィスでの経験と実績を活かして\n依頼者様のより良い解決を目指します。',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 38,
    guideText: '検索結果一覧に表示される事務所の説明です。事務所の特長やアピールポイントを入力してください。',
    colon: false,
  },
  /** 掲載情報2 */
  profile2Image: {
    label: '掲載写真2',
    [formNames.profile2Image1]: {
      name: formNames.profile2Image1,
    },
    [formNames.profile2Image2]: {
      name: formNames.profile2Image2,
    },
    [formNames.profile2Image3]: {
      name: formNames.profile2Image3,
    },
    rules: getRules([]),
    guideText:
      '法人・個人事務所のプロフィールページで公開される画像を選択してください。「くわしくみる」を押した後、詳細画面の最初に表示されます。画像の選択がない場合は、任意のストック画像が適用されます。',
    colon: false,
  },
  [formNames.professionalCategory]: {
    name: formNames.professionalCategory,
    label: '専門家カテゴリー',
    rules: getRules([]),
    placeholder: '未選択',
  },
  [formNames.officeName]: {
    name: formNames.officeName,
    label: '事務所名',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: 'XXX事務所',
    guideText: 'プロフィールページに表示される事務所名です。',
    colon: false,
  },
  [formNames.officeNameKana]: {
    name: formNames.officeNameKana,
    label: '事務所名（カナ）',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen(), RULES.corporateNameKana]),
    placeholder: 'ソウゾクジムショ',
    annotation: '※全角カタカナ',
  },
  [formNames.description2]: {
    name: formNames.description2,
    label: '事務所説明2',
    rules: getRules([RULES.requireEntry, RULES.notBlank]),
    placeholder: 'そうぞく事務所は、東京メトロ・都営地下鉄「日本橋駅」から徒歩3分の位置にある法律事務所です。',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 46,
    guideText: 'プロフィールページに表示される事務所の説明です。事務所の概要を入力してください。',
    colon: false,
  },
  [formNames.detail2]: {
    name: formNames.detail2,
    label: '詳細説明2',
    rules: getRules([RULES.requireEntry, RULES.notBlank]),
    placeholder:
      '平日の9時から20時までご相談を受け付けております。\n初回のご相談は無料にて承りますので、ぜひお気軽にご相談ください。',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 1000,
    guideText: '詳細な事務所の特徴やアピールポイントを1,000字以内で入力してください。',
    colon: false,
  },
  /** 掲載情報3 */
  [formNames.profile3Image]: {
    name: formNames.profile3Image,
    label: '掲載写真3',
    rules: getRules([]),
    guideText:
      '法人・個人事務所のプロフィールページ箇所に表示する写真を選択してください。「詳細説明2」の後に表示されます。',
    colon: false,
  },
  [formNames.description3]: {
    name: formNames.description3,
    label: '事務所説明3',
    rules: getRules([RULES.notBlank]),
    placeholder: '依頼者様の精神的なご負担を軽減しつつ、\n円滑に相続問題を解決できるように尽力いたします。',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 46,
    guideText: 'プロフィールページに表示される事務所の説明です。事務所の概要を入力してください。',
    colon: false,
  },
  [formNames.detail3]: {
    name: formNames.detail3,
    label: '詳細説明3',
    rules: getRules([RULES.notBlank]),
    placeholder:
      '依頼者様のご意向を踏まえつつ、納得を得られるような解決を目指して参ります。相続トラブルにお悩みの方は、ぜひ当法人にご相談ください。',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 1000,
    guideText: '「事務所説明3」で入力した概要を、より詳細に入力してください。',
    colon: false,
  },
  [formNames.additionalInfo]: {
    name: formNames.additionalInfo,
    label: '付属情報',
    rules: getRules([]),
    annotation: '※ 複数選択可能',
    guideText:
      '事務所の特長として、プロフィールページにタグ表示されます。また「そうぞくガイド」訪問者が「こだわり条件」で絞り込む際の選択肢にもなり、該当すると検索結果に事務所が表示されます。',
    colon: false,
  },

  /** 基本情報 */
  [formNames.phoneNumber]: {
    name: formNames.phoneNumber,
    label: '電話番号',
    rules: getRules([RULES.requireEntry, RULES.tel]),
    placeholder: '090 1234 5678',
    annotation: '※ ハイフンなし 半角数字',
    guideText:
      'ご利用者さまからのお問い合わせ受付用の電話番号を入力してください。固定の代表電話番号がない場合には、お問い合わせを受ける担当者の携帯電話番号を入力してください。',
    colon: false,
  },
  [formNames.phoneContactDisabled]: {
    name: formNames.phoneContactDisabled,
    label: '電話番号掲載設定',
    rules: getRules([RULES.requireSelection]),
    items: [
      { label: '電話番号を掲載する', value: false },
      { label: '電話番号を掲載しない', value: true },
    ],
    initialValues: false,
  },
  reception: {
    [formNames.reception.receptionHours]: {
      name: formNames.reception.receptionHours,
      label: '受付時間',
      rules: getRules([RULES.requireEntry, RULES.notBlank]),
      placeholder: {
        from: '9:00',
        to: '17:00',
      },
      minuteStep: 5,
      format: 'HH:mm',
      guideText: '受付時間は事務所に連絡が繋がる時間です。',
      annotation: '※ 時間を選択後にOKを押して確定してください',
      colon: false,
    } as const,
    [formNames.reception.receptionCall24HoursAvailable]: {
      name: formNames.reception.receptionCall24HoursAvailable,
      label: '電話受付設定',
      rules: getRules([]),
    },
    [formNames.reception.receptionHoursNote]: {
      name: formNames.reception.receptionHoursNote,
      label: '受付時間に関する備考',
      rules: getRules([]),
      placeholder: '土日は17:00まで、水曜は午前のみ。\n年末年始は休業いたします。',
      multiple: true,
      autoSize: { minRows: 3 },
      showCount: true,
      maxLength: 255,
      guideText: '基本の受付時間以外の情報を入力してください。',
      colon: false,
    },
    [formNames.reception.regularHoliday]: {
      name: formNames.reception.regularHoliday,
      label: '定休日',
      rules: getRules([RULES.requireEntry]),
      annotation: '※ 複数選択可能',
    },
  },
  [formNames.access]: {
    name: formNames.access,
    label: 'アクセス',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: 'JR 東京駅徒歩5分 八重洲出入口ICから5分',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 255,
    guideText:
      '最寄り駅、最寄り駅の出口、出口からの距離（かかる時間）など法人・個人事務所までのアクセスを入力してください。',
    colon: false,
  },
  [formNames.url]: {
    name: formNames.url,
    label: 'ホームページURL',
    placeholder: 'https://example.com',
    rules: getRules([RULES.maxLen(), RULES.url]),
    guideText: '法人・個人事務所の公式ホームページのURLをhttp://またはhttps://から略さずに入力してください。',
    colon: false,
  },
  postCode: {
    label: '郵便番号',
    first: {
      name: formNames.postCode.first,
      rules: getRules([RULES.requireEntry, RULES.postCodeFirst]),
      placeholder: '000',
      annotation: '※ 半角数字',
    },
    second: {
      name: formNames.postCode.second,
      rules: getRules([RULES.requireEntry, RULES.postCodeSecond]),
      placeholder: '0000',
    },
  },
  [formNames.prefecture]: {
    name: formNames.prefecture,
    label: '住所1（都道府県）',
    rules: getRules([RULES.requireEntry]),
    placeholder: '東京都',
  },

  [formNames.city]: {
    name: formNames.city,
    label: '住所2（市区町村）',
    rules: getRules([RULES.requireEntry]),
    placeholder: '千代田区',
  },

  [formNames.otherArea]: {
    name: formNames.otherArea,
    label: '',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '〇〇町、XX村',
  },

  [formNames.townAndStreetAddress]: {
    name: formNames.townAndStreetAddress,
    label: '住所3（町名・番地）',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '丸の内1-2-3',
  },
  [formNames.building]: {
    name: formNames.building,
    label: '住所4（建物名）',
    rules: getRules([RULES.notBlank, RULES.maxLen()]),
    placeholder: '日本橋マンション101',
    guideText: '住所に建物名や部屋番号がある場合のみ、入力してください。',
    colon: false,
  },
  [formNames.emailAddress]: {
    name: formNames.emailAddress,
    label: 'メールアドレス',
    rules: getRules([RULES.requireEntry, RULES.email, RULES.emailMaxLen]),
    placeholder: 'taro.yamada@xxx.com',
    guideText:
      'ご利用者さまからの連絡が届くメールアドレスです。複数名で対応される場合には、info@やcontact@などの代表メールアドレスを利用することを推奨しています。',
    colon: false,
  },
  [formNames.affiliation]: {
    name: formNames.affiliation,
    label: '所属団体',
    placeholder: 'XXX弁護士会',
    rules: getRules([RULES.requireSelection]),
  },
  [formNames.affiliationOther]: {
    name: formNames.affiliationOther,
    label: '所属団体（自由記入）',
    placeholder: '',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
  },
  /** 代表者 */
  representativeName: {
    label: '代表者名',
    [representativeFormNames.representativeSurname]: {
      name: representativeFormNames.representativeSurname,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '山田',
    },
    [representativeFormNames.representativeGivenName]: {
      name: representativeFormNames.representativeGivenName,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '太郎',
    },
  },
  representativeNameKana: {
    label: '代表者名（カナ）',
    [representativeFormNames.representativeSurnameKana]: {
      name: representativeFormNames.representativeSurnameKana,
      placeholder: 'ヤマダ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
    [representativeFormNames.representativeGivenNameKana]: {
      name: representativeFormNames.representativeGivenNameKana,
      placeholder: 'タロウ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
  },

  /** 簡裁訴訟代理等関係業務 */
  summaryCourtLegalRepresentation: {
    [formNames.summaryCourtLegalRepresentation.summaryCourtLegalRepresentationRadio]: {
      name: formNames.summaryCourtLegalRepresentation.summaryCourtLegalRepresentationRadio,
      label: '簡裁訴訟代理等関係業務',
      rules: getRules([RULES.requireSelection]),
    },
    specifiedMember: (isCorporation: boolean) => ({
      label: isCorporation ? '特定社員' : '認定司法書士',
      first: {
        name: formNames.summaryCourtLegalRepresentation.specifiedMember.surname,
        rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
        placeholder: '山田',
      },
      second: {
        name: formNames.summaryCourtLegalRepresentation.specifiedMember.givenName,
        rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
        placeholder: '太郎',
      },
    }),
    [formNames.summaryCourtLegalRepresentation.certificationNumber]: {
      name: formNames.summaryCourtLegalRepresentation.certificationNumber,
      label: '簡裁代理認定番号',
      rules: getRules([RULES.requireEntry, RULES.maxLen(8), RULES.requireNumber]),
      placeholder: '12345678',
    },
  },

  /** 対応エリア */
  supportArea: {
    [formNames.isWholeArea]: {
      name: formNames.isWholeArea,
      label: 'エリア選択',
      rules: getRules([RULES.requireEntry]),
      guideText:
        '全国で対応している場合には「全国指定」を選択してください。一部地域で対応している場合には、「都道府県指定」を選択してください。複数の都道府県で対応している場合には、「エリアを追加」をクリックすることで、複数の都道府県（最大10ヶ所）を指定することができます。',
      colon: false,
    },
    [formNames.prefecture]: {
      name: formNames.prefecture,
      label: '都道府県',
      guideText: '都道府県指定を選択した方は「都道府県」から該当する都道府県を選択してください。',
      colon: false,
    },
  },
  /** 対応サービス */
  [formNames.supportServices]: {
    name: formNames.supportServices,
    label: '対応サービス',
    rules: getRules([]),
    guideText:
      '対応しているサービスをすべて選択してください。プロフィールページに表示されます。また「そうぞくガイド」訪問者が「相談内容」で絞り込む際の選択肢にもなり、該当すると検索結果に事務所が表示されます。',
    colon: false,
    annotation: '※ 複数選択可能',
  },
  consultationFee: {
    [formNames.consultationFee.consultationFeeType]: {
      name: formNames.consultationFee.consultationFeeType,
      label: '相談料',
      rules: getRules([RULES.requireSelection]),
      placeholder: '未選択',
      initialValues: undefined,
      guideText: '法人・個人事務所の相談料について選択してください。',
      colon: false,
    },
    [formNames.consultationFee.consultationFreeTimeLimit]: {
      name: formNames.consultationFee.consultationFreeTimeLimit,
      label: '初回相談の時間',
      rules: getRules([RULES.requireEntry, RULES.requireNumber, RULES.maxNumber(1440)]), // 1440 = 24H * 60m
      placeholder: '60',
      suffix: '分',
      size: 'middle',
    } as const,
    [formNames.consultationFee.consultationFeeNote]: {
      name: formNames.consultationFee.consultationFeeNote,
      label: '相談料に関する備考',
      rules: getRules([]),
      placeholder: '料金追加で延長可能。\n相談内容によって、異なる場合がございます。',
      multiple: true,
      autoSize: { minRows: 3 },
      showCount: true,
      maxLength: 200,
      guideText: '相談料に関する補足事項を入力してください。料金を記載する場合には税込価格で入力してください。',
      colon: false,
    },
  },
  [formNames.mobilizationFee]: {
    name: formNames.mobilizationFee,
    label: '着手金',
    rules: getRules([RULES.requireEntry, RULES.notBlank]),
    placeholder:
      '相続手続全般 / 33万円～(税込)別途諸経費3万2,000円(税込)\n※交渉、調停、審判、訴訟などの手続きにより相違あり',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 400,
    guideText: '料金は税込価格で入力してください。',
    colon: false,
  },
  [formNames.reward]: {
    name: formNames.reward,
    label: '報酬金',
    rules: getRules([RULES.requireEntry, RULES.notBlank]),
    placeholder: '相続手続全般　経済的利益の11％～（税込）\n最低成功報酬33万円（税込）',
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: 400,
    guideText: '料金は税込価格で入力してください。',
    colon: false,
  },

  /** 担当者情報 */
  contactName: {
    label: '担当者名',
    first: {
      name: formNames.contactSurname,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '山田',
    },
    second: {
      name: formNames.contactGivenName,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '太郎',
    },
    guideText: '実際にアカウント作成やアカウント運用を行う担当者の名前になります。',
    colon: false,
  },

  contactNameKana: {
    label: '担当者名（カナ）',
    first: {
      name: formNames.contactSurnameKana,
      placeholder: 'ヤマダ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
      annotation: '※全角カタカナ',
    },
    second: {
      name: formNames.contactGivenNameKana,
      placeholder: 'タロウ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
    annotation: '※全角カタカナ',
  },
}

export type PlacementFormType = {
  [formNames.profile1Image]: string
  [formNames.description1]: string
  [formNames.profile2Image1]: string
  [formNames.profile2Image2]: string
  [formNames.profile2Image3]: string
  [formNames.professionalCategory]: string
  [formNames.officeName]: string
  [formNames.officeNameKana]: string
  [formNames.description2]: string
  [formNames.detail2]: string
  [formNames.profile3Image]: string
  [formNames.description3]: string
  [formNames.detail3]: string
  [formNames.additionalInfo]: string[]
  [formNames.phoneNumber]: string
  [formNames.phoneContactDisabled]: boolean
  [formNames.reception.receptionHours]: [dayjs.Dayjs, dayjs.Dayjs] | []
  [formNames.reception.receptionCall24HoursAvailable]: boolean[]
  [formNames.reception.receptionHoursNote]: string
  [formNames.reception.regularHoliday]: RegularHoliday
  [formNames.access]: string
  [formNames.url]: string
  [formNames.postCode.first]: string
  [formNames.postCode.second]: string
  [formNames.prefecture]: string
  [formNames.city]: string
  [formNames.otherArea]?: string
  [formNames.townAndStreetAddress]: string
  [formNames.building]: string
  [formNames.isWholeArea]: boolean
  [formNames.supportServices]: string[]
  [formNames.emailAddress]: string
  [formNames.affiliation]: string
  [formNames.affiliationOther]: string
  [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]: string
  [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]: string
  [formNames.summaryCourtLegalRepresentation.certificationNumber]: string
  [formNames.consultationFee.consultationFeeType]: CONSULTATION_FEE_TYPE_VALUES
  [formNames.consultationFee.consultationFreeTimeLimit]: string
  [formNames.consultationFee.consultationFeeNote]: string
  [formNames.mobilizationFee]: string
  [formNames.reward]: string
  [formNames.contactSurname]: string
  [formNames.contactGivenName]: string
  [formNames.contactSurnameKana]: string
  [formNames.contactGivenNameKana]: string
} & RepresentativeFormTypeOnlyName &
  AreaFormType

export const initialValues = (values: GetProfessionalPlacementDefaultsResponse): PlacementFormType => {
  const { affiliation, affiliationOther } = getAffiliationData(values.affiliation, values.professionalCategory.code)
  return {
    [formNames.profile1Image]: values.images?.profile1Image?.placementImageId ?? '',
    [formNames.description1]: values.description1 ? values.description1.join('\n') : '',
    [formNames.profile2Image1]: values.images?.profile2Image1?.placementImageId ?? '',
    [formNames.profile2Image2]: values.images?.profile2Image2?.placementImageId ?? '',
    [formNames.profile2Image3]: values.images?.profile2Image3?.placementImageId ?? '',
    [formNames.professionalCategory]: values.professionalCategory.code ?? '',
    [formNames.officeName]: values.officeName ?? '',
    [formNames.officeNameKana]: values.officeNameKana ?? '',
    [formNames.description2]: values.description2 ? values.description2.join('\n') : '',
    [formNames.detail2]: values.detail2 ? values.detail2.join('\n') : '',
    [formNames.profile3Image]: values.images?.profile3Image?.placementImageId ?? '',
    [formNames.description3]: values.description3 ? values.description3.join('\n') : '',
    [formNames.detail3]: values.detail3 ? values.detail3.join('\n') : '',
    [formNames.additionalInfo]: values.additionalInfo?.map((a) => a.code) ?? [],
    [formNames.phoneNumber]: values.phoneNumber ?? '',
    [formNames.phoneContactDisabled]: values.phoneContactDisabled,
    [formNames.reception.receptionHours]:
      values.reception.receptionHoursStart && values.reception.receptionHoursEnd
        ? [
            // 日付もUIには使われないが必要なので適当な値をセットしている
            dayjs(`2024-01-01 ${values.reception.receptionHoursStart}`),
            dayjs(`2024-01-01 ${values.reception.receptionHoursEnd}`),
          ]
        : [],
    [formNames.reception.receptionCall24HoursAvailable]: !values.reception.receptionCall24HoursAvailable
      ? []
      : [values.reception.receptionCall24HoursAvailable],
    [formNames.reception.receptionHoursNote]: values.reception.receptionHoursNote
      ? values.reception.receptionHoursNote.join('\n')
      : '',
    [formNames.reception.regularHoliday]: values.reception.regularHoliday ?? [],
    [formNames.access]: values.access ? values.access.join('\n') : '',
    [formNames.url]: values.url ?? '',
    [formNames.postCode.first]: values.officeAddress.postCode ? values.officeAddress.postCode.slice(0, 3) : '',
    [formNames.postCode.second]: values.officeAddress.postCode ? values.officeAddress.postCode.slice(3, 7) : '',
    [formNames.prefecture]: values.officeAddress.prefecture?.prefectureCode ?? '',
    [formNames.city]: values.officeAddress.city?.areaCode ?? '',
    [formNames.otherArea]: values.officeAddress.city?.otherAreaName ?? '',
    [formNames.townAndStreetAddress]: values.officeAddress.townAndStreetAddress ?? '',
    [formNames.building]: values.officeAddress.building ?? '',
    [formNames.isWholeArea]: values.supportArea?.isWholeArea ?? true,
    [formNames.supportServices]: values.supportServices?.map((a) => a.code) ?? [],
    [formNames.emailAddress]: values.emailAddress ?? '',
    [formNames.affiliation]: affiliation,
    [formNames.affiliationOther]: affiliationOther,
    [formNames.representativeSurname0]: '',
    [formNames.representativeGivenName0]: '',
    [formNames.representativeSurnameKana0]: '',
    [formNames.representativeGivenNameKana0]: '',
    [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]:
      values.summaryCourtLegalRepresentation?.specifiedMember?.surname ?? '',
    [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]:
      values.summaryCourtLegalRepresentation?.specifiedMember?.givenName ?? '',
    [formNames.summaryCourtLegalRepresentation.certificationNumber]:
      values.summaryCourtLegalRepresentation?.certificationNumber ?? '',
    [formNames.consultationFee.consultationFeeType]: values.fee?.consultationFee.consultationFeeType ?? undefined,
    [formNames.consultationFee.consultationFreeTimeLimit]: values.fee?.consultationFee.consultationFreeTimeLimit
      ? values.fee.consultationFee.consultationFreeTimeLimit.toString()
      : '',
    [formNames.consultationFee.consultationFeeNote]: values.fee?.consultationFee.consultationFeeNote
      ? values.fee.consultationFee.consultationFeeNote.join('\n')
      : '',
    [formNames.mobilizationFee]: values.fee?.mobilizationFee ? values.fee.mobilizationFee.join('\n') : '',
    [formNames.reward]: values.fee?.reward ? values.fee.reward.join('\n') : '',
    [formNames.contactSurname]: values.contactName.surname ?? '',
    [formNames.contactGivenName]: values.contactName.givenName ?? '',
    [formNames.contactSurnameKana]: values.contactName.surnameKana ?? '',
    [formNames.contactGivenNameKana]: values.contactName.givenNameKana ?? '',
  }
}

export interface SelectedArea {
  prefecture: string | null
  cities: string[]
}

export const initialSupportAreaValue = (
  supportAreaMaster: SupportAreaMaster[],
  selectedAreaCodes: string[]
): SelectedArea[] => {
  const target = supportAreaMaster.filter((a) => selectedAreaCodes.find((s) => s === a.areaCode))
  const initialValue: SelectedArea[] = []
  target.forEach((t) => {
    const index = initialValue.findIndex((e) => e.prefecture === t.prefectureCode)
    if (index > -1) {
      initialValue[index].cities.push(t.areaCode)
    } else {
      initialValue.push({
        prefecture: t.prefectureCode,
        cities: [t.areaCode],
      })
    }
  })
  return initialValue
}

export const initialImages = ({
  placementApplicationDefaults,
  storageData,
}:
  | { placementApplicationDefaults: GetProfessionalPlacementDefaultsResponse; storageData?: undefined }
  | { placementApplicationDefaults?: undefined; storageData: PlacementFormStorageData }) => {
  if (placementApplicationDefaults) {
    return {
      profile1ImageId: placementApplicationDefaults.images?.profile1Image?.placementImageId,
      profile2ImageId1: placementApplicationDefaults.images?.profile2Image1?.placementImageId,
      profile2ImageId2: placementApplicationDefaults.images?.profile2Image2?.placementImageId,
      profile2ImageId3: placementApplicationDefaults.images?.profile2Image3?.placementImageId,
      profile3ImageId: placementApplicationDefaults.images?.profile3Image?.placementImageId,
    }
  } else {
    return {
      profile1ImageId: storageData.profile1Image,
      profile2ImageId1: storageData.profile2Image1,
      profile2ImageId2: storageData.profile2Image2,
      profile2ImageId3: storageData.profile2Image3,
      profile3ImageId: storageData.profile3Image,
    }
  }
}

type Value = {
  text?: string
  labels?: string[]
  images?: {
    id?: string
    imageProps: ImageProps
  }[]
}

type Item = {
  label: string
  value: Value
}

export type ItemGroup = {
  title: string
  items: Item[]
}

export const convertFormValuesToItemGroups = (
  form: PlacementFormType,
  additionalInfo: AdditionalInfo[],
  supportServices: SupportService[],
  supportAreaMaster: SupportAreaMaster[],
  professionalCategories: ProfessionalCategory[],
  businessCategory?: ContractApplicationDetails['businessCategory']
): DescriptionItemGroupType<PlacementDescriptionValue>[] => {
  const professionalCategory = professionalCategories.find((category) => category.code === form.professionalCategory)
  const professionalCategoryCode = professionalCategory?.code ?? ''
  const professionalCategoryName = professionalCategory?.name ?? ''

  const additionalInfoLabels = form.additionalInfo.map(
    (value) => additionalInfo.find((option) => option.code === value)?.description
  ) as string[]
  const supportServiceLabels = form.supportServices.map(
    (value) => supportServices.find((option) => option.code === value)?.name
  ) as string[]

  const items: DescriptionItemGroupType<PlacementDescriptionValue>[] = [
    {
      title: '掲載情報1',
      items: [
        {
          label: formProps.profile1Image.label,
          value: { kind: 'images', images: [{ id: form.profile1Image, imageProps: IMAGEPROPS.PORTRAIT }] },
        },
        { label: formProps.description1.label, value: { kind: 'text', text: form.description1 } },
      ],
    },
    {
      title: '掲載情報2',
      items: [
        {
          label: formProps.profile2Image.label,
          value: {
            kind: 'images',
            images: [
              { id: form.profile2Image1, imageProps: IMAGEPROPS.SQUARE },
              { id: form.profile2Image2, imageProps: IMAGEPROPS.SQUARE },
              { id: form.profile2Image3, imageProps: IMAGEPROPS.SQUARE },
            ],
          },
        },
        { label: formProps.professionalCategory.label, value: { kind: 'text', text: professionalCategoryName } },
        { label: formProps.officeName.label, value: { kind: 'text', text: form.officeName } },
        { label: formProps.officeNameKana.label, value: { kind: 'text', text: form.officeNameKana } },
        { label: formProps.description2.label, value: { kind: 'text', text: form.description2 } },
        { label: formProps.detail2.label, value: { kind: 'text', text: form.detail2 } },
      ],
    },
    {
      title: '掲載情報3',
      items: [
        {
          label: formProps.profile3Image.label,
          value: {
            kind: 'images',
            images: [{ id: form.profile3Image, imageProps: IMAGEPROPS.LANDSCAPE }],
          },
        },
        { label: formProps.description3.label, value: { kind: 'text', text: form.description3 } },
        { label: formProps.detail3.label, value: { kind: 'text', text: form.detail3 } },
        { label: formProps.additionalInfo.label, value: { kind: 'labels', labels: additionalInfoLabels } },
      ],
    },
    {
      title: '基本情報',
      items: [
        { label: formProps.phoneNumber.label, value: { kind: 'text', text: form.phoneNumber } },
        {
          label: formProps.phoneContactDisabled.label,
          value: { kind: 'text', text: `電話番号を掲載${form.phoneContactDisabled ? 'しない' : 'する'}` },
        },
        ...getReceptionHoursRelations(form),
        { label: formProps.access.label, value: { kind: 'text', text: form.access } },
        { label: formProps.url.label, value: { kind: 'text', text: form.url } },
        {
          label: formProps.postCode.label,
          value: { kind: 'text', text: `${form.postCodeFirst} ${form.postCodeSecond}` },
        },
        getOfficeAddressItem(form),
        { label: formProps.building.label, value: { kind: 'text', text: form.building } },
        { label: formProps.emailAddress.label, value: { kind: 'text', text: form.emailAddress } },
        ...getAffiliationItem(form, professionalCategoryCode as ProfessionalCategoryCode),
        ...getRepresentativeItemGroup(form),
        ...summaryCourtLegalRepresentationForm(form, businessCategory),
      ],
    },
    ...getAreaItemGroup(form, supportAreaMaster),
    {
      title: '対応サービス',
      items: [{ label: formProps.supportServices.label, value: { kind: 'labels', labels: supportServiceLabels } }],
    },
    {
      title: '料金',
      items: [
        ...getConsultationFee(form),
        { label: formProps.mobilizationFee.label, value: { kind: 'text', text: form.mobilizationFee } },
        { label: formProps.reward.label, value: { kind: 'text', text: form.reward } },
      ],
    },
    {
      title: '担当者情報',
      items: [
        {
          label: formProps.contactName.label,
          value: { kind: 'text', text: `${form.contactSurname} ${form.contactGivenName}` },
        },
        {
          label: formProps.contactNameKana.label,
          value: { kind: 'text', text: `${form.contactSurnameKana} ${form.contactGivenNameKana}` },
        },
      ],
    },
  ]

  return items
}

const getConsultationFee = (form: PlacementFormType): DescriptionItemType<PlacementDescriptionValue>[] => {
  return getConsultationFeeItem({
    consultationFeeType: form.consultationFeeType,
    // Number.isNaN(form.consultationFreeTimeLimit) && Number(form.consultationFreeTimeLimit) とほぼ同じだが、0の場合にもtrueになる。
    // 0はバリデーション上も入力可能であるため許容する。
    consultationFreeTimeLimit: !Number.isNaN(Number(form.consultationFreeTimeLimit))
      ? Number(form.consultationFreeTimeLimit)
      : undefined,
    consultationFeeNote: form.consultationFeeNote ? [form.consultationFeeNote] : undefined,
  })
}

const getReceptionHoursRelations = (form: PlacementFormType): DescriptionItemType<PlacementDescriptionValue>[] => {
  const hasReceptionHours = form.receptionHours && form.receptionHours.length === 2
  return getReceptionDetailsItems({
    receptionHoursStart: hasReceptionHours ? form.receptionHours?.[0]?.format('HH:mm') : undefined,
    receptionHoursEnd: hasReceptionHours ? form.receptionHours?.[1]?.format('HH:mm') : undefined,
    receptionCall24HoursAvailable: form.receptionCall24HoursAvailable?.some(Boolean),
    receptionHoursNote: form.receptionHoursNote ? [form.receptionHoursNote] : undefined,
    regularHoliday: form.regularHoliday,
  })
}

const getOfficeAddressItem = (form: PlacementFormType): DescriptionItemType<PlacementDescriptionValue> => {
  const prefectureName = PREFECTURE.find((p) => p.code.slice(-2) === form.prefecture)?.name
  if (!prefectureName) throw 'prefecture item not found'
  const cityName = supportAreaMaster.find((s) => s.areaCode === form.city)?.areaName
  if (!cityName) throw 'city item not found'
  const cityString = form.otherArea ?? cityName

  const officeAddressString = prefectureName + cityString + form.townAndStreetAddress
  return {
    label: '住所',
    value: { kind: 'text', text: officeAddressString },
  }
}

const getAffiliationItem = (
  form: PlacementFormType,
  professionalCategoryCode: ProfessionalCategoryCode
): DescriptionItemType<PlacementDescriptionValue>[] => {
  const label = affiliationLabelFromString(professionalCategoryCode)

  if (enableAffiliationOther(form.affiliation)) {
    return [
      {
        label,
        value: { kind: 'text', text: form.affiliation },
      },
      {
        label: `${label}（自由記入）`,
        value: { kind: 'text', text: form.affiliationOther },
      },
    ]
  }

  return [
    {
      label,
      value: { kind: 'text', text: form.affiliation },
    },
  ]
}

const getRepresentativeItemGroup = (form: PlacementFormType): DescriptionItemType<PlacementDescriptionValue>[] => {
  const items: DescriptionItemType<PlacementDescriptionValue>[] = []
  getRepresentativeFormValue(form).map((v) => {
    items.push({
      label: formProps.representativeName.label,
      value: { kind: 'text', text: `${v.name.surname} ${v.name.givenName}` },
    })
    items.push({
      label: formProps.representativeNameKana.label,
      value: { kind: 'text', text: `${v.name.surnameKana} ${v.name.givenNameKana}` },
    })
  })

  return items
}

const getRepresentativeFormValue = (
  form: PlacementFormType
): // 掲載申込では、生年月日と住所の入力は不要なので、nameだけを抽出する
Pick<RepresentativeItem, 'name'>[] => {
  const formKeys = Object.keys(form)
    .filter((e) => e.startsWith(representativeFormNames.representativeSurnameKana))
    .map((str) => str.replace(representativeFormNames.representativeSurnameKana, ''))

  return formKeys.map((key) => {
    return {
      name: {
        // @ts-expect-error appendix form key
        surname: `${form[`${representativeFormNames.representativeSurname}${key}`]}`,
        // @ts-expect-error appendix form key
        givenName: `${form[`${representativeFormNames.representativeGivenName}${key}`]}`,
        // @ts-expect-error appendix form key
        surnameKana: `${form[`${representativeFormNames.representativeSurnameKana}${key}`]}`,
        // @ts-expect-error appendix form key
        givenNameKana: `${form[`${representativeFormNames.representativeGivenNameKana}${key}`]}`,
      },
    }
  })
}

const summaryCourtLegalRepresentationForm = (
  form: PlacementFormType,
  businessCategory?: ContractApplicationDetails['businessCategory']
): DescriptionItemType<PlacementDescriptionValue>[] => {
  if (form.professionalCategory !== 'judicialScrivener') return []
  if (!businessCategory) return []

  const { specifiedMember, summaryCourtLegalRepresentationCertificationNumber } =
    formProps.summaryCourtLegalRepresentation

  const isCorporation = businessCategory === 'corporation'

  if (
    !form.summaryCourtLegalRepresentationSurname ||
    !form.summaryCourtLegalRepresentationGivenName ||
    !form.summaryCourtLegalRepresentationCertificationNumber
  ) {
    return [
      { label: specifiedMember(isCorporation).label, value: { kind: 'text', text: '' } },
      { label: summaryCourtLegalRepresentationCertificationNumber.label, value: { kind: 'text', text: '' } },
    ]
  }

  return [
    {
      label: specifiedMember(isCorporation).label,
      value: {
        kind: 'text',
        text: `${form.summaryCourtLegalRepresentationSurname} ${form.summaryCourtLegalRepresentationGivenName}`,
      },
    },
    {
      label: summaryCourtLegalRepresentationCertificationNumber.label,
      value: { kind: 'text', text: form.summaryCourtLegalRepresentationCertificationNumber },
    },
  ]
}

const createSummaryCourtLegalRepresentation = ({
  summaryCourtLegalRepresentationSurname,
  summaryCourtLegalRepresentationGivenName,
  summaryCourtLegalRepresentationCertificationNumber,
}: PlacementFormType): PutProfessionalPlacementRequest['summaryCourtLegalRepresentation'] => {
  if (
    !summaryCourtLegalRepresentationSurname ||
    !summaryCourtLegalRepresentationGivenName ||
    !summaryCourtLegalRepresentationCertificationNumber
  ) {
    return undefined
  }

  return {
    specifiedMember: {
      surname: summaryCourtLegalRepresentationSurname,
      givenName: summaryCourtLegalRepresentationGivenName,
    },
    certificationNumber: summaryCourtLegalRepresentationCertificationNumber,
  }
}

const getReceptionOfPut = (form: PlacementFormType): Reception => {
  const props = {
    receptionCall24HoursAvailable: form.receptionCall24HoursAvailable?.some(Boolean) ?? false,
    receptionHoursNote: form.receptionHoursNote ? splitByNewline(form.receptionHoursNote) : undefined,
    regularHoliday: form.regularHoliday,
  }
  if (form.receptionHours && form.receptionHours.length === 2) {
    const [from, to] = form.receptionHours
    return {
      receptionHoursStart: from.format('HH:mm'),
      receptionHoursEnd: to.format('HH:mm'),
      ...props,
    }
  }
  return {
    receptionHoursStart: '',
    receptionHoursEnd: '',
    ...props,
  }
}

const getConsultationFeeOfPut = (form: PlacementFormType): ConsultationFee => {
  if (form.consultationFeeType === undefined) throw 'consultationFeeType is undefined'

  const consultationFreeTimeLimit = isConsultationFeeTypeTimeLimit(form.consultationFeeType)
    ? Number(form.consultationFreeTimeLimit)
    : undefined
  return {
    consultationFeeType: form.consultationFeeType,
    consultationFreeTimeLimit,
    consultationFeeNote: form.consultationFeeNote ? splitByNewline(form.consultationFeeNote) : undefined,
  }
}

export const composePlacementApplicationReqModel = (form: PlacementFormType): PutProfessionalPlacementRequest => {
  const prefecture = PREFECTURE.find((p) => p.code.slice(-2) === form.prefecture)
  if (!prefecture) throw 'prefecture item not found'
  const city = supportAreaMaster.find((s) => s.areaCode === form.city)
  if (!city) throw 'city item not found'

  return {
    images: {
      profile1Image: form.profile1Image,
      profile2Image1: form.profile2Image1,
      profile2Image2: form.profile2Image2,
      profile2Image3: form.profile2Image3,
      profile3Image: form.profile3Image,
    },
    description1: splitByNewline(form.description1),
    officeName: form.officeName,
    officeNameKana: form.officeNameKana,
    description2: splitByNewline(form.description2),
    detail2: splitByNewline(form.detail2),
    description3: form.description3 ? splitByNewline(form.description3) : undefined,
    detail3: form.detail3 ? splitByNewline(form.detail3) : undefined,
    additionalInfo: form.additionalInfo,
    phoneNumber: removeSpace(form.phoneNumber),
    reception: getReceptionOfPut(form),
    access: splitByNewline(form.access),
    url: form.url || undefined,
    officeAddress: {
      postCode: form.postCodeFirst + form.postCodeSecond,
      prefecture: {
        prefectureCode: form.prefecture,
        prefectureName: prefecture.name,
      },
      city: {
        areaCode: city.areaCode,
        areaName: city.areaName,
        otherAreaName: form.otherArea,
      },
      townAndStreetAddress: form.townAndStreetAddress,
      building: form.building || undefined,
    },
    emailAddress: form.emailAddress,
    supportArea: {
      isWholeArea: form.isWholeArea,
      areas: getAreaCodes(form),
    },
    supportServices: form.supportServices,
    fee: {
      consultationFee: getConsultationFeeOfPut(form),
      mobilizationFee: splitByNewline(form.mobilizationFee),
      reward: splitByNewline(form.reward),
    },
    contactName: {
      surname: form.contactSurname,
      givenName: form.contactGivenName,
      surnameKana: form.contactSurnameKana,
      givenNameKana: form.contactGivenNameKana,
    },
    affiliation: getAffiliation(form.affiliation, form.affiliationOther),
    representatives: getRepresentativeFormValue(form),
    summaryCourtLegalRepresentation: createSummaryCourtLegalRepresentation(form),
    phoneContactDisabled: form.phoneContactDisabled,
  }
}

export type AreaOption = {
  prefecture: {
    label: string
    value: string
  }
  cities: {
    label: string
    value: string
  }[]
}

export const composeAreaOption = (area: SupportAreaMaster[]): AreaOption[] => {
  return PREFECTURE.map((p) => {
    return {
      prefecture: {
        label: p.name,
        value: p.code,
      },
      cities: area
        .filter((a) => a.prefectureCode === p.code)
        .map((a) => {
          return {
            label: a.areaName,
            value: a.areaCode,
          }
        }),
    }
  })
}

const getAreaItemGroup = (
  form: PlacementFormType,
  supportAreaMaster: SupportAreaMaster[]
): DescriptionItemGroupType<PlacementDescriptionValue>[] => {
  if (form.isWholeArea) {
    return [
      {
        title: '対応エリア',
        items: [
          {
            label: '都道府県',
            value: { kind: 'text', text: '全国' },
          },
          {
            label: '市区町村',
            value: {
              kind: 'labels',
              labels: ['-'],
            },
          },
        ],
      },
    ]
  }

  const formKeys = Object.keys(form)
    .filter((e) => e.startsWith(AREA_FORM_PREFIX.prefecture))
    .map((str) => str.split('_')[1])
  // @ts-expect-error appendix form key
  const prefs: string[] = formKeys.map((key) => form[`${AREA_FORM_PREFIX.prefecture}${key}`])
  // @ts-expect-error appendix form key
  const cities = formKeys.map((key) => form[`${AREA_FORM_PREFIX.city}${key}`]).flat()

  const filteredOption = composeAreaOption(supportAreaMaster)
    .filter((e) => prefs.find((p) => p === e.prefecture.value))
    .map((e) => {
      return {
        ...e,
        cities: e.cities.filter((c) => cities.indexOf(c.value) > -1),
      }
    })
  return filteredOption.map((e, i) => {
    return {
      title: i === 0 ? '対応エリア' : '',
      items: [
        {
          label: '都道府県',
          value: { kind: 'text', text: e.prefecture.label },
        },
        {
          label: '市区町村',
          value: { kind: 'labels', labels: e.cities.map((c) => c.label) },
        },
      ],
    }
  })
}

export const getAreaCodes = (form: PlacementFormType): string[] => {
  return Object.entries(form)
    .filter((x): x is [string, string[]] => x[0].startsWith(AREA_FORM_PREFIX.city))
    .flatMap(([, v]) => v)
    .filter((e, i, arr) => arr.indexOf(e) === i)
}
