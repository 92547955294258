import { css } from '@emotion/react'
import { Typography } from 'antd'
import { Separator } from './Separator'
import { useLandingPageDesignToken } from './design-system/useLandingPageDesignToken'

const useServiceFeatureStyles = () => {
  const { color, mediaQuery } = useLandingPageDesignToken()

  return {
    wrap: css`
      position: relative;
      text-align: center;
      padding: 54px 0 88px;
      background-color: ${color.background.gray};
    `,
    heading: css`
      font-size: 30px;
      line-height: 40px;
      font-weight: bold;
      margin-bottom: 16px;
    `,
    cards: css`
      display: flex;
      justify-content: center;
      gap: 22px;
      max-width: 1024px;
      margin: 0 auto;
      /* padding: 0 16px; */
      padding: 0;
      box-sizing: border-box;
      ${mediaQuery.small} {
        max-width: 540px;
        flex-direction: column;
        gap: 24px;
      }
    `,
  }
}

export const ServiceFeature = () => {
  const styles = useServiceFeatureStyles()

  return (
    <div css={styles.wrap}>
      <h2 css={styles.heading}>そうぞくガイドの特徴</h2>
      <div css={styles.cards}>
        <Card
          index="1"
          label={['三菱ＵＦＪ銀行の顧客基盤']}
          text="年間11万件超（2022年度実績）の相続受付を行う、三菱ＵＦＪ銀行の顧客接点を活かし、専門家への相談ニーズの高いお客さまの利用拡大を目指します。"
        />
        <Card
          index="2"
          label={['メガバンク初の相続に特化した', '専門家検索サイト']}
          text="三菱ＵＦＪ銀行が運営する専門家検索サイトとして、広告主さまの認知向上や信頼獲得をサポートします。"
        />
        <Card
          index="3"
          label={['相続で悩んでいるお客さま向けの機能']}
          text="「そうぞく診断」「やることリスト」の機能があるため、相続対策を検討しているお客さまや相続に直面しているお客さまから、具体的な相談が来ることが期待できます。"
        />
      </div>
    </div>
  )
}

const useCardStyle = () => {
  const { color, mediaQuery } = useLandingPageDesignToken()

  return {
    wrap: css`
      width: calc(100% / 3);
      padding: 24px;
      background-color: ${color.background.white};
      border-radius: 16px;
      ${mediaQuery.small} {
        width: 100%;
      }
    `,
    index: css`
      position: relative;
      display: inline-block;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      padding: 0 6px;
      margin-bottom: 8px;
      ::before,
      ::after {
        position: absolute;
        top: 50%;
        content: '';
        width: 16px;
        height: 2px;
        border-top: 2px solid ${color.text.black01};
      }
      ::before {
        left: -16px;
      }
      ::after {
        right: -16px;
      }
    `,
    label: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 48px;
    `,
    labelText: css`
      color: ${color.text.black01};
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
    `,
    text: css`
      display: inline-block;
      color: ${color.accent.gray[700]};
      font-size: 14px;
      line-height: 22px;
      text-align: left;
    `,
    separator: css`
      margin: 8px 0 16px;
    `,
  }
}

const Card = (prop: { index: string; label: string[]; text: string }) => {
  const style = useCardStyle()

  return (
    <div css={style.wrap}>
      <Typography.Text css={style.index}>POINT{prop.index}</Typography.Text>
      <div css={style.label}>
        {prop.label.map((l) => (
          <Typography.Text key={l} css={style.labelText}>
            {l}
          </Typography.Text>
        ))}
      </div>
      <Separator rootCss={style.separator} />
      <Typography.Text css={style.text}>{prop.text}</Typography.Text>
    </div>
  )
}
