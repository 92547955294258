import { ReactNode } from 'react'
import { css } from '@emotion/react'
import { Typography } from 'antd'
import { EXTERNAL_LINK_URL } from 'inheritance-utils'

export type TTerm = {
  index?: string
  text: string
}

export type TTypography = {
  title?: string
  content: Array<TTerm | TTerm[] | Array<TTerm | TTerm[]>>
}

export type AgreementProps = {
  title: string
  englishTitle: string
  subTitle: string
  footer?: {
    date: string
    houshin: ReactNode
    toriatsukai: ReactNode
  }
  typography?: TTypography[]
  node?: ReactNode
}

const privacyPolicy: AgreementProps = {
  title: 'プライバシポリシー',
  englishTitle: 'Privacy Policy',
  subTitle: '個人情報保護方針',
  node: (
    <div
      css={css`
        text-align: center;
        margin-top: 40px;
      `}
    >
      <Typography.Link
        target="_blank"
        href={EXTERNAL_LINK_URL.kojinjouhou}
        css={css`
          margin-right: 2px;
        `}
      >
        プライバシーポリシー
      </Typography.Link>
      からご確認ください。
    </div>
  ),
}

const terms: AgreementProps = {
  title: '「そうぞくガイド」広告主規約 ',
  englishTitle: 'Terms Of Service',
  subTitle: '',
  footer: {
    date: '以上（2024年1月16日現在）',
    houshin: (
      <>
        個人情報保護方針については
        <a href={EXTERNAL_LINK_URL.kojinjouhouHoushin} target="_blank" rel="noreferrer">
          こちら
        </a>
      </>
    ),
    toriatsukai: (
      <>
        個人情報のお取り扱いについては
        <a href={EXTERNAL_LINK_URL.kojinjouhouToriatsukai} target="_blank" rel="noreferrer">
          こちら
        </a>
      </>
    ),
  },
  typography: [
    {
      content: [
        {
          text: 'この規約（以下「本規約」といいます。）は、株式会社三菱UFJ銀行（以下「当行」といいます。）が「そうぞくガイド」（以下「本サービス」といいます。）の名称で提供するウェブサービスにおける権利・義務、その他諸条件等について、当行と広告主（第1条で定義します。）の間で定めるものです。広告主は、本規約その他の関連規約に同意頂いたうえで、本サービスを利用できるものとします。本規約に定めのない事項については、関連規約の定めが適用されるものとします。',
        },
      ],
    },
    {
      title: '第1条（定義）',
      content: [
        {
          text: '本規約において、次に掲げる用語は、それぞれ次に掲げる意味で用います。',
        },
        {
          index: '1.',
          text: '「本サービス」とは、当行が、「そうぞくガイド」の名称で提供するウェブサービスを指します。',
        },
        {
          index: '2.',
          text: '「広告主」とは、本サービスにおいて一般ユーザー（第3項で定義します。）向けに広告掲載等を行う、弁護士法人、弁護士事務所、法律事務所、弁護士個人、税理士法人、税理士事務所、会計事務所、税理士個人、司法書士法人、司法書士事務所、司法書士個人を指します。',
        },
        {
          index: '3.',
          text: '「一般ユーザー」とは、本サービスを利用する個人を指します。',
        },
        {
          index: '4.',
          text: '「そうぞく診断」とは、一般ユーザーが所定の質問に答えることにより、相続における「やることリスト」が作成される機能を指します。',
        },
        {
          index: '5.',
          text: '「やることリスト」とは、一般ユーザーに対して、相続発生時に実施すべき項目を明示したリストを指します。',
        },
        {
          index: '6.',
          text: '「専門家検索」とは、一般ユーザーが、広告主を検索し、直接お問い合わせができる機能を指します。',
        },
        {
          index: '7.',
          text: '「専門家サービス」とは、広告主が広告の掲載申込や解約申込等の手続きを行うことができる機能を指します。',
        },
        {
          index: '8.',
          text: '「広告掲載契約」とは、広告主と当行の間において、本規約その他の関連規約の内容を合意内容として成立する契約を指します。',
        },
        {
          index: '9.',
          text: '「知的財産」とは、発明、考案、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、産業上の利用可能性があるものを含む。）、商標、商号その他事業活動に用いられる商品または役務を表示するものおよび営業秘密その他の事業活動に有用な技術上または営業上の情報、ノウハウ等を意味します。',
        },
      ],
    },
    {
      title: '第2条（主なサービス内容）',
      content: [
        {
          index: '1.',
          text: '本サービスでは、広告主に対して下記機能を提供します。',
        },
        [
          {
            index: '(1)',
            text: '専門家検索上での一般ユーザー向け広告出稿',
          },
          {
            index: '(2)',
            text: '専門家サービス上での広告掲載成果の確認',
          },
          {
            index: '(3)',
            text: 'その他前各号に関連・付随するサービス',
          },
        ],
        {
          index: '2.',
          text: '一般ユーザー向けの「そうぞく診断」及び「やることリスト」に広告主が関与することはありません。',
        },
        {
          index: '3.',
          text: '当行は、第1項に定める本サービスおよびその内容をいつでも追加、変更、廃止することができるものとします。当行がこの追加、変更、廃止を行う場合、第16条（本規約の変更）に従うものとします。',
        },
      ],
    },
    {
      title: '第3条（利用料金及び支払方法等）',
      content: [
        {
          index: '1.',
          text: '広告主は、当行が別途定める利用料金を負担することで、第2条第1項に定めるサービスを利用することができます。',
        },
        {
          index: '2.',
          text: '当行は、利用料金に関する請求書の発行、利用料金の回収および管理にあたって、株式会社Biz Forwardが提供する、商取引の決済等サービス「SEIKYU+ powered by Biz Forward」を利用します。',
        },
        {
          index: '3.',
          text: '前項のサービスの利用にあたり、当行は、当行が広告主に対して有する利用料金に関する一切の債権（税金等に関する請求債権を含みます。）を株式会社Biz　Forwardに譲渡します。',
        },
        {
          index: '4.',
          text: '請求書は毎月10日に、株式会社Biz Forwardよりメール送信もしくはメール送信と郵送の両方にて発行されます。発行方法は広告主が選択することができます。',
        },
        {
          index: '5.',
          text: '広告主は、支払い方法について、銀行振込もしくは口座振替のいずれかを選択することができます。',
        },
        {
          index: '6.',
          text: '第5項において銀行振込を選択した場合、広告主は下記に従い支払いを行うものとします。',
        },
        [
          {
            index: '(1)',
            text: '支払先口座：請求書に記載の株式会社Biz Forwardの銀行口座',
          },
          {
            index: '(2)',
            text: '銀行振込手数料：広告主の負担',
          },
          {
            index: '(3)',
            text: '広告掲載料発生開始月：広告掲載開始日の属する月の翌月',
          },
          {
            index: '(4)',
            text: '支払期日：（3）の翌々月20日（土日祝日の場合は前営業日）を初回とし、以降毎月20日（土日祝日の場合は前営業日）',
          },
        ],
        {
          index: '7.',
          text: '第5項において口座振替を選択した場合、広告主は下記に従い支払いを行うものとします。',
        },
        [
          {
            index: '(1)',
            text: '振替元口座：広告主が申請登録した指定口座',
          },
          {
            index: '(2)',
            text: '振替先口座：請求書に記載の株式会社Biz Forwardの銀行口座',
          },
          {
            index: '(3)',
            text: '口座振替手数料：株式会社Biz Forwardの負担',
          },
          {
            index: '(4)',
            text: '広告掲載料発生開始月：広告掲載開始日の属する月の翌月',
          },
          {
            index: '(5)',
            text: '支払期日：（4）の翌々月20日（土日祝日の場合は翌営業日）を初回とし、以降毎月20日（土日祝日の場合は翌営業日）',
          },
        ],
        {
          index: '8.',
          text: '当行が「SEIKYU+ powered by Biz Forward」を利用するにあたっては、請求先の審査があります。第1項の利用料金の請求先となる広告主について審査が通らなかった場合には当該広告主は本サービスを利用することはできません。',
        },
        {
          index: '9.',
          text: '広告主が株式会社Biz Forwardに対する支払を遅延した場合、当行は、契約解除、遅延損害金請求等の対応を行うことがあります。また、広告主は、広告掲載契約日以降、一度も当行に対して広告物を申請しなかった場合や当行からの広告物の修正要望に対して事情等の説明なく一定期間対応がなされなかった場合等、広告主都合により、広告掲載契約日より2ヶ月を経過した時点で広告掲載が開始されていない場合は、当該時点で掲載が開始されたものとみなして利用料金を支払うものとします。',
        },
        {
          index: '10.',
          text: '当行は、当行が「SEIKYU+ powered by Biz Forward」を利用するにあたって必要な広告主の情報（名称・氏名、代表者氏名、請求先住所・担当者氏名等）を、株式会社Biz Forwardならびに同社の関係会社（会社計算規則（平成18年法務省令第13号）第2条第3項第25号に定める関係会社をいいます。以下同じです。）および兄弟会社（当該会社の議決権の過半数を保有する親会社がその議決権の過半数を保有する他の会社をいいます。以下同じです。）に対し開示できるものとします。',
        },
        {
          index: '11.',
          text: '株式会社Biz Forwardは、「SEIKYU+ powered by Biz Forward」の提供・開発・運営等に利用する目的において、また、三菱 UFJファクター株式会社が同社の提供するサービスのために利用する目的において、当行が株式会社Biz Forwardに譲渡した第3項の債権の支払い状況等の情報を、三菱 UFJファクター株式会社ならびに同社の関係会社および兄弟会社に対し開示できるものとします。',
        },
      ],
    },
    {
      title: '第4条（利用申込）',
      content: [
        {
          index: '1.',
          text: '本サービスの利用を希望される方（以下「登録希望者」といいます。）は、本規約その他の関連規約の内容をご了承のうえ、当行所定の方法により申し込みを行うものとします。',
        },
        {
          index: '2.',
          text: '当行は、登録希望者の本人確認や申し込み内容の確認を実施のうえ、申し込みを承諾するときは、当行所定の方法で、登録希望者に通知します。当行が登録希望者に対して承諾する旨の通知を発し、当該通知が登録希望者に到達したときに、当行と登録希望者の間で広告掲載契約が成立し、登録希望者は広告主になります。',
        },
        {
          index: '3.',
          text: '本サービスの申し込みに際しては、国内居住者に該当すること、且つ当行と預金取引があることが必要です。',
        },
        {
          index: '4.',
          text: '当行は、登録希望者の業務内容や当行との取引関係、関係法令の遵守等の事情を総合的に判断の上、申し込みを承諾しない場合があります。尚、個別の申し込み内容について、当行が承諾しない理由については一切開示しません。',
        },
      ],
    },
    {
      title: '第5条（登録情報及びアカウントの管理）',
      content: [
        {
          index: '1.',
          text: '広告主は、自己の責任において、自己の名称、住所、電話番号その他の登録情報の内容を管理し、登録情報に変更が生じた場合は、速やかに当行所定の方法で変更手続きを行うものとします。',
        },
        {
          index: '2.',
          text: '広告主は、当行と広告掲載契約を締結した後、当行所定の方法でアカウントの作成を行うことができます。',
        },
        {
          index: '3.',
          text: '広告主は、自己の責任において、アカウントを管理します。いかなる場合においても、本サービスのアカウント情報について、第三者に譲渡、貸与、売買等を行うことはできません。',
        },
        {
          index: '4.',
          text: '広告主は、アカウントについて、以下要件を充足したパスワードを設定する必要があります。',
        },
        [
          {
            index: '(1)',
            text: '半角英数字記号で8文字以上（数字、大文字、小文字、記号（＠＃＄％？！-_）を含む）',
          },
          {
            index: '(2)',
            text: '住所、電話番号、設立年月日、生年月日など容易に推測できる情報に基づかないパスワード',
          },
          {
            index: '(3)',
            text: 'その他、第三者から推測されやすい要素・情報に基づかないパスワード',
          },
        ],
        {
          index: '5.',
          text: '広告主は、パスワードの失念等により必要が生じた場合、当行所定の方法によりパスワードの再設定をすることができます。',
        },
        {
          index: '6.',
          text: '当行は、不正利用の可能性があるとき、その他第7条に定める内容に該当する可能性があると認めたときに、アカウントを停止する可能性があります。',
        },
      ],
    },
    {
      title: '第6条（データの保存）',
      content: [
        {
          index: '1.',
          text: '登録希望者が本サービスの利用申し込みを行う際に入力した情報、広告主が専門家サービス上での広告の掲載申し込みを行う際に入力した情報は利用する端末機のWebブラウザに一時的に自動保存されます。',
        },
        {
          index: '2.',
          text: '共用の端末機（複数の人が利用するパーソナルコンピューター等）で前項の行為を行う場合、当該端末機内に残ったデータを第三者に閲覧される可能性があります。共用の端末機を通じた本サービスの利用により、登録希望者または広告主が入力等した情報を第三者に知られたとしても、当行は一切責任を負いません。',
        },
      ],
    },
    {
      title: '第7条（解約、利用停止）',
      content: [
        {
          index: '1.',
          text: '当行および広告主のいずれも、本サービス上での広告主の広告掲載を開始した日から起算して6ヶ月後末日の翌日以降、解約希望日の一ヶ月前までに当行所定の方法により通知を行うことで広告掲載契約を解約できるものとします。',
        },
        {
          index: '2.',
          text: '前項に限らず、広告主が、以下のいずれかに該当する場合、当行は、何ら催告なくして、本サービスの利用停止、広告掲載契約の解約等の対応を行うことができるものとします。この場合、当行が広告主にその旨の通知を発信したときに解約されるものとします。',
        },
        [
          {
            index: '(1)',
            text: '本規約その他の関連規約に違反したとき',
          },
          {
            index: '(2)',
            text: '料金等の支払債務の不履行があったとき',
          },
          {
            index: '(3)',
            text: '支払停止または破産手続き開始、会社更生手続き開始、特別清算もしくは民事再生手続開始その他適用ある倒産手続き開始の申出があったとき',
          },
          {
            index: '(4)',
            text: '手形交換所の取引停止処分を受けたとき',
          },
          {
            index: '(5)',
            text: '前3号の定めに限らず、広告主について経営上懸念があると判断される信用情報を当行が得たとき',
          },
          {
            index: '(6)',
            text: 'アカウントの不正利用の可能性があるとき',
          },
          {
            index: '(7)',
            text: '弁護士法または日本弁護士連合会もしくは所属弁護士会が定める規定・指針、税理士法または日本税理士会連合会もしくは所属税理士会が定める規定・指針、司法書士法または日本司法書士会連合会もしくは所属司法書士会が定める規定・指針に違反したと当行が判断したとき',
          },
          {
            index: '(8)',
            text: '当行の預金口座を解約したとき',
          },
          {
            index: '(9)',
            text: '海外居住者に該当したとき',
          },
          {
            index: '(10)',
            text: '本サービス申し込み以降、法人名または法人の代表者（登記上の共同する代表者を含む）の変更、追加が生じたにもかかわらず、契約書に別途定める方法にて、当行に遅滞なく報告しなかったとき',
          },
          {
            index: '(11)',
            text: '法令等（マネー・ローンダリング、テロ資金供与に係る内外法令等を含みます）に違反し、または犯罪等への関与が疑われる等相応の事由があるとき',
          },
          {
            index: '(12)',
            text: 'その他当行が広告掲載先として不適切と判断したとき',
          },
        ],
        {
          index: '3.',
          text: '前項に加えて、広告主が、以下のいずれかに該当し、広告主との取引を継続することが不適切である場合は、当行は直ちに本サービスの利用を停止し、何ら通知なくして広告掲載契約を解約することができるものとします。',
        },
        [
          {
            index: '(1)',
            text: '広告主が虚偽の登録をしたと判明したとき',
          },
          {
            index: '(2)',
            text: '広告主が次のいずれかに該当したことが判明したとき',
          },
          [
            {
              index: '①',
              text: '暴力団',
            },
            {
              index: '②',
              text: '暴力団員',
            },
            {
              index: '③',
              text: '暴力団準構成員',
            },
            {
              index: '④',
              text: '暴力団関係企業',
            },
            {
              index: '⑤',
              text: '総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等',
            },
            {
              index: '⑥',
              text: 'その他前各号に準ずる者',
            },
          ],
          {
            index: '(3)',
            text: '広告主が、自らまたは第三者を利用して次の各号に該当する行為をしたとき',
          },
          [
            {
              index: '①',
              text: '暴力的な要求行為',
            },
            {
              index: '②',
              text: '法的な責任を超えた不当な要求行為',
            },
            {
              index: '③',
              text: '取引に関して、脅迫的な言動をし、または暴力を用いる行為',
            },
            {
              index: '④',
              text: '風説を流布し、偽計を用いまたは威力を用いて当行の信用を棄損し、または当行の業務を妨害する行為',
            },
            {
              index: '⑤',
              text: 'その他前各号に準ずる行為',
            },
          ],
          {
            index: '(4)',
            text: '広告主が、米国OFAC規制、外国為替及び外国貿易法、国連に基づく制裁対象者に該当するとき',
          },
        ],
      ],
    },
    {
      title: '第8条（サービスの停止、中断、終了）',
      content: [
        {
          index: '1.',
          text: '当行は、以下のいずれかに該当する場合には、広告主に事前に通知することなく、本サービスの利用の全部または一部を停止または中断することができるものとします。',
        },
        [
          {
            index: '(1)',
            text: '本サービスに係るコンピュータシステム、ネットワークシステムその他のシステム等について、点検、保守もしくは工事を行うとき、または障害が発生したとき',
          },
          {
            index: '(2)',
            text: '通信事業者が、本サービスの利用に必要な電気通信サービスの提供を中止または制限したとき',
          },
          {
            index: '(3)',
            text: '火災、停電、天災地変などの不可抗力により本サービスの提供が困難になったとき',
          },
          {
            index: '(4)',
            text: 'その他、当行が停止または中断が必要と判断したとき',
          },
        ],
        {
          index: '2.',
          text: '当行は、当行の都合により、本サービスの提供を終了する場合があります。この場合、当行は広告主に事前に通知するものとします。',
        },
        {
          index: '3.',
          text: '当行は、本条に基づき当行が行った措置により広告主に生じた損害について一切の責任を負いません。',
        },
      ],
    },
    {
      title: '第9条（禁止行為）',
      content: [
        {
          text: '広告主は、本サービスを利用するにあたり、以下に定めるいずれの行為も行ってはならないものとします。',
        },
        [
          {
            index: '(1)',
            text: '虚偽の情報その他法令上禁止されている情報を登録する行為',
          },
          {
            index: '(2)',
            text: '本サービスの円滑な運用を妨げるおそれのある行為',
          },
          {
            index: '(3)',
            text: 'アカウントを不正に利用する行為',
          },
          {
            index: '(4)',
            text: '他の広告主、一般ユーザー、当行または第三者の知的財産権、その他の権利または利益を侵害する行為',
          },
          {
            index: '(5)',
            text: '一般ユーザーに誤認や不利益等を生じさせる行為',
          },
          {
            index: '(6)',
            text: '本サービスに係るコンピュータシステム、ネットワークシステムその他のシステム等に対する不正アクセス行為',
          },
          {
            index: '(7)',
            text: '法令等に違反する行為または違反するおそれのある行為',
          },
          {
            index: '(8)',
            text: '所属団体（所属弁護士会、所属税理士会、所属司法書士会）の規則、規定、指針等に違反する行為',
          },
          {
            index: '(9)',
            text: '公序良俗に反する行為',
          },
          {
            index: '(10)',
            text: '政治的あるいは宗教的思想を含む情報を提供する行為',
          },
          {
            index: '(11)',
            text: '暴力や差別等を助長する行為',
          },
          {
            index: '(12)',
            text: '本規約その他の関連規約、広告掲載契約の内容等に違反する行為',
          },
          {
            index: '(13)',
            text: '広告上において、景品類の提供やキャンペーンを謳う行為',
          },
          {
            index: '(14)',
            text: '前各号に定める行為の他、当行が不適切であると判断する行為',
          },
        ],
      ],
    },
    {
      title: '第10条（個人情報の取り扱い）',
      content: [
        {
          text: '当行は、本サービスの利用に際し、広告主が登録した個人情報、当行のサービスを通じて当行に提供した個人情報、その他の個人情報を、当行が定める「個人情報保護方針」および「個人情報のお取り扱いについて」に基づき、適切に取り扱うものとします。',
        },
      ],
    },
    {
      title: '第11条（知的財産権の取り扱い）',
      content: [
        {
          index: '1.',
          text: '本サービスに関する所有権および知的財産権は全て当行または当行にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本サービスに関する当行または当行にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。',
        },
        {
          index: '2.',
          text: '広告主は、いかなる理由によっても当行または当行にライセンスを許諾している者の知的財産権を侵害するおそれのある行為をしないものとします。',
        },
        {
          index: '3.',
          text: '広告主が本サービス上で当行に提供した文章、画像、動画その他のデータについての著作権その他の権利は、広告主から当行に全て譲渡されるものとし、広告主はいかなる権利主張や行使も行わないものとします。',
        },
      ],
    },
    {
      title: '第12条（法令等遵守）',
      content: [
        {
          index: '1.',
          text: '一般ユーザーから広告主に対する相談等（相続に関する相談及びこれに付随する相談を含みますが、これらに限られません。）については、広告主が関係法令及び所属団体（所属弁護士会、所属税理士会、所属司法書士会）の規則、規定、指針等を遵守のうえ、自らの責任において対応することとします。',
        },
        {
          index: '2.',
          text: '当行は、前項の相談等には一切関与しません。',
        },
        {
          index: '3.',
          text: '当行は、一般ユーザーから金銭その他の報酬を一切受領しません。',
        },
      ],
    },
    {
      title: '第13条（免責事項）',
      content: [
        {
          index: '1.',
          text: '広告主の本サービスの利用に関し、本サービスの動作に係る不具合、パーソナルコンピューター等に与える影響、本サービスを利用できないことによる不利益、その他広告主が被ることのある一切の不利益について、当行は責任を負いません。',
        },
        {
          index: '2.',
          text: '次の各号の事由により、本サービスが利用できなかった場合、広告主に損害が生じたときであっても、当行は責任を負いません。',
        },
        [
          {
            index: '(1)',
            text: '災害・事変、裁判所等公的機関の措置等のやむを得ない事由があったとき',
          },
          {
            index: '(2)',
            text: '当行または金融機関等の共同システムの運営体が相当の安全策を講じたにもかかわらず、端末機、通信回線またはコンピューター等に障害が生じたとき',
          },
          {
            index: '(3)',
            text: '当行以外の金融機関等、第三者の責めに帰すべき事由があったとき',
          },
        ],
        {
          index: '3.',
          text: '当行は、本サービスを通じて当行または一般ユーザーが提供する情報について、その正確性、有用性、完全性等を保証するものではなく、広告主による当該情報の利用について一切の責任を負いません。',
        },
        {
          index: '4.',
          text: '広告主が一般ユーザーにサービスを提供する場合の条件等については、広告主と一般ユーザーとの間の契約等により定めるものとし、当行は当該契約等につき一切関知しません。',
        },
        {
          index: '5.',
          text: '当行は、広告主が一般ユーザーに対して提供するサービスの内容等の一切について、何ら保証するものではなく、これらについて一切の責任を負いません。',
        },
        {
          index: '6.',
          text: '広告主と一般ユーザーとの間で生じたクレーム、紛争、訴訟、その他一切のトラブルについて、当行は一切関知するものではなく、また一切の責任を負いません。',
        },
        {
          index: '7.',
          text: '広告主が掲載する広告内容において、法令及び所属団体（所属弁護士会、所属税理士会、所属司法書士会）の規則、規定、指針等の違反があった場合の責任は全て広告主に帰属し、当行は一切の責任を負いません。',
        },
        {
          index: '8.',
          text: '本サービスに申し込みするか否かは、当行との取引（預金・融資等）に影響を及ぼすものではなく、当行はそれらの取引に応じる一切の義務を負わないものとします。',
        },
        {
          index: '9.',
          text: '当行は、本サービスについて、別途の定めがない限り、当行の故意または重過失が存する場合を除き、責任を負いません。',
        },
      ],
    },
    {
      title: '第14条（反社会的勢力への不関与）',
      content: [
        {
          index: '1.',
          text: '広告主は、広告主が、現在、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。',
        },
        [
          {
            index: '①',
            text: '暴力団',
          },
          {
            index: '②',
            text: '暴力団員',
          },
          {
            index: '③',
            text: '暴力団準構成員',
          },
          {
            index: '④',
            text: '暴力団関係企業',
          },
          {
            index: '⑤',
            text: '総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等',
          },
          {
            index: '⑥',
            text: 'その他前各号に準ずる者',
          },
        ],
        {
          index: '2.',
          text: '広告主は、広告主が、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約するものとします。',
        },
        [
          {
            index: '①',
            text: '暴力的な要求行為',
          },
          {
            index: '②',
            text: '法的な責任を超えた不当な要求行為',
          },
          {
            index: '③',
            text: '取引に関して、脅迫的な言動をし、または暴力を用いる行為',
          },
          {
            index: '④',
            text: '風説を流布し、偽計を用いまたは威力を用いて当行の信用を毀損し、または当行の業務を妨害する行為',
          },
          {
            index: '⑤',
            text: 'その他前各号に準ずる行為',
          },
        ],
      ],
    },
    {
      title: '第15条（海外からの利用）',
      content: [
        {
          index: '1.',
          text: '本サービスは、日本国内で利用されることを前提に提供されるサービスであり、日本語により提供されます。',
        },
        {
          index: '2.',
          text: '海外からの利用が判明した場合は、当行からの通知により本サービスの一時利用停止、もしくは広告掲載契約の解約を行う場合があります。',
        },
      ],
    },
    {
      title: '第16条（本規約の変更）',
      content: [
        {
          index: '1.',
          text: '本規約の各条項その他の条件は、金融情勢その他の状況の変化その他相当の事由があると認められる場合には、当行ホームページへの掲載その他相当の方法で公表することにより、変更できるものとします。',
        },
        {
          index: '2.',
          text: '前項の変更は、公表の際に定める相当な期間を経過した日から適用されるものとします。',
        },
      ],
    },
    {
      title: '第17条（分離可能性）',
      content: [
        {
          text: '本規約のいずれかの条項またはその一部が、管轄権を有する裁判所によって法令等に基づき無効または執行不能と判断された場合であっても、当該部分を除く本規約の他の条項、および一部が無効または執行不能と判断された条項のうち分離可能な残りの部分は、継続して完全にその効力を有するものとします。',
        },
      ],
    },
    {
      title: '第18条（準拠法・合意管轄）',
      content: [
        {
          text: '広告掲載契約の契約準拠法は日本法とします。これら規約等に関する訴訟については、東京地方裁判所を管轄裁判所とします。',
        },
      ],
    },
  ],
}

export const AGREEMENTPROPS = {
  TERMSOFSERVICE: terms,
  PRIVACYPOLICY: privacyPolicy,
} as const
