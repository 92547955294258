export abstract class AppBaseError extends Error {
  constructor({ cause, message }: { cause?: unknown; message?: string }) {
    super(message, { cause })

    // クラス名をnameプロパティに代入。継承先のクラス名を代入する
    // getErrMsgでのkeyマッチに用いる
    this.name = this.constructor.name

    // messageの指定がない時には、nameをセットする。
    if (!message) this.message = this.name
  }
}
