import { Form, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormLabel } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'
import { FormLabelWithGuideText } from 'inheritance-components'

export type SelectFormItem = {
  label: string
  value: string
}

export const SelectForm = ({
  name,
  label,
  placeholder,
  rules,
  items,
  disabled,
  formCss,
  onSelect = undefined,
  showSearch = false,
  guideText,
  colon,
}: {
  name: string
  label: string
  placeholder: string
  rules: Rule[]
  items: SelectFormItem[]
  disabled?: boolean
  formCss?: React.CSSProperties
  onSelect?: (v: string | number) => void
  showSearch?: boolean
  guideText?: string
  colon?: boolean
}) => {
  return (
    <Form.Item
      hasFeedback
      name={name}
      // guideTextが指定される場合、FormLabelをguideTextでラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      // labelでrequiredを表現するため「*」は不要
      rules={rules}
      required={false}
      colon={colon}
      style={guideText ? { marginBottom: 0 } : {}}
    >
      <Select style={formCss} placeholder={placeholder} onSelect={onSelect} disabled={disabled} showSearch={showSearch}>
        {items.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
