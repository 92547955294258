import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * @param keys
 * クエリパラメーターから取得したいキーの名前を指定する
 * Union型の配列で渡す
 *
 * @returns
 * keysに渡した値をkeyとするオブジェクトをundefinedとstringのUnion型で返す
 *
 * @example
 *
 * ```
 * const { email, token } = useQueryParamsOptionally({ keys: ['email','token'] })
 * ```
 */
export const useQueryParamsOptionally = <U extends string>({ keys }: { keys: U[] }): { [key in U]?: string } => {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const getParam = (name: string): string | undefined => {
      return params.get(name) || undefined
    }

    return keys.reduce((acc, cur) => {
      return { ...acc, [cur]: getParam(cur) }
    }, {}) as { [key in U]?: string }
  }, [keys, search])
}
