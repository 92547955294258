import * as React from 'react'

export const VerticalDescription = ({ description }: { description: string | string[] }) => {
  if (typeof description === 'string') {
    return <>{description}</>
  }

  return (
    <>
      {description.map((text, index) => (
        <React.Fragment key={index}>
          {text}
          {description.length - 1 !== index && <br />}
        </React.Fragment>
      ))}
    </>
  )
}
