import { getPath } from '@/router'
import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { Button, Typography } from 'antd'

export const ContractApplicationURLExpired = () => {
  return (
    <ErrorScreen
      kind="composition"
      title="有効期限が切れています"
      content={
        <>
          <Typography.Paragraph type="secondary">トップ画面より再度お申し込みをしてください。</Typography.Paragraph>
          <Button type="primary" href={getPath('lp')}>
            トップ画面に戻る
          </Button>
        </>
      }
    />
  )
}
