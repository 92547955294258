import { useRpcContext } from '../RpcContext'
import useSWR from 'swr'
import { SWR_KEYS } from '../keys'

export const useApiVersion = () => {
  const { callApiVersion } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.callApiVersion, () => callApiVersion())
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return data
}
