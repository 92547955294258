import { Descriptions } from 'antd'
import { DescriptionItemType, RenderDescriptionItem } from './type'
import { useProAndMgmtDesignToken } from '../../design-system/token'

type GenericDescriptionListProps<ValueType> = {
  items: DescriptionItemType<ValueType>[] | DescriptionItemType<ValueType>[]
  isDiff?: boolean
  renderDescriptionItem: RenderDescriptionItem<ValueType>
  needsHighlightForDiff?: (value: ValueType) => boolean
}

export const GenericDescriptionList = <ValueType,>(props: GenericDescriptionListProps<ValueType>) => {
  const { color } = useProAndMgmtDesignToken()
  return (
    <Descriptions bordered column={2} labelStyle={{ width: 320, verticalAlign: 'top' }}>
      {props.items.map(({ label, value }) => {
        return (
          <Descriptions.Item
            contentStyle={
              props.isDiff && props.needsHighlightForDiff?.(value) ? { backgroundColor: color.accent.red[200] } : {}
            }
            key={label}
            label={label}
            span={2}
          >
            {props.renderDescriptionItem(value, props.isDiff)}
          </Descriptions.Item>
        )
      })}
    </Descriptions>
  )
}
