import { getAuthenticator } from '@/auth'
import { getPath } from '@/router'
import {
  AppBaseError,
  BadRequestError,
  InternalServerError,
  NetworkError,
  SystemError,
  EmailAlreadyInUseError,
  ConflictError,
} from '@/share/error'
import { AxiosError } from 'axios'
import { ErrorResponse } from 'inheritance-api'

// これからrethrowするエラーをビルドする
export const buildRethrowingError = (e: unknown): AppBaseError => {
  if (e instanceof AxiosError) {
    if (!e.response) {
      return new NetworkError({ cause: e })
    }

    const status = e.response.status
    switch (status) {
      case 400: {
        const data = e.response.data as ErrorResponse | null
        if (data?.error === 'The email address is already in use') {
          return new EmailAlreadyInUseError({ cause: e })
        }

        return new BadRequestError({ cause: e })
      }
      case 401:
        throw authErrorRedirect()
      case 500:
        return new InternalServerError({ cause: e })
      case 409:
        return new ConflictError({ cause: e })
    }
  }

  // Axios以外で発生したAppBaseErrorの子クラスであれば、そのまま返す
  if (e instanceof AppBaseError) return e

  return new SystemError({ cause: e })
}

const authErrorRedirect = async (): Promise<string> => {
  const authenticator = getAuthenticator()
  if (authenticator) {
    await authenticator.signOut()
  }
  return (window.location.href = getPath('login'))
}
