import { css } from '@emotion/react'
import { Divider, Tabs, TabsProps } from 'antd'
import { ProfileTabContent } from '../profile-parts/ProfileTabContent'
import { ProfileTitle } from '../profile-parts/ProfileTitle'
import { ProfileValueType } from '../../homeValue'
import { ContactSuspendEventHandler } from '../profile-parts/publicInfo/PublicInfoContactSuspendedButton'
import { ProfileTitleProps } from '../profile-parts/ProfileTitle'
import { ProfileStatusDescriptionProps } from '../profile-parts/ProfileStatusDescription'

const styles = {
  header: css`
    padding: 23px 24px 22px;
  `,
  divider: css`
    margin: 0;
  `,
}

type ProfileTabsplacementStatus = 'waitForScreening' | 'accepted' | 'declined' | 'terminated'

export const Profile = ({
  homeValues,
  onEditClick,
  onContactSuspend,
}: {
  homeValues: ProfileValueType
  onEditClick: (usePublicContent: boolean) => void
  onContactSuspend: ContactSuspendEventHandler
}) => {
  /**
   * 複数タブを表示するパターンにおいて、子タブを返却する
   * @param profileTitleProps
   * @param placementStatus
   * @param publicStatusDescriptionProps
   * @returns
   */
  const getTabs = (
    profileTitleProps: ProfileTitleProps,
    placementStatus: ProfileTabsplacementStatus,
    publicStatusDescriptionProps: ProfileStatusDescriptionProps
  ): TabsProps['items'] => {
    if (homeValues.homeCondition === 'placement-n-public-tab' || homeValues.homeCondition === 'placement-n-churn-tab')
      return [
        {
          key: '1',
          label: <ProfileTitle {...profileTitleProps} />,
          children: (
            <ProfileTabContent
              tabKind="public"
              homeValues={homeValues}
              detail={{
                kind: 'public',
                businessCategory: homeValues.businessCategory,
                publicDetail: homeValues.publicInformation,
                contactSuspended: homeValues.contactSuspended,
              }}
              statusDescriptionProps={publicStatusDescriptionProps}
              onEditClick={onEditClick}
              onContactSuspend={onContactSuspend}
            />
          ),
        },
        {
          key: '2',
          label: <ProfileTitle kind="placement" status={placementStatus} />,
          children: (
            <ProfileTabContent
              tabKind="placement"
              homeValues={homeValues}
              detail={{
                kind: 'placement',
                businessCategory: homeValues.businessCategory,
                placementDetail: homeValues.placementApplication.content,
              }}
              statusDescriptionProps={{
                kind: 'placement',
                status: placementStatus,
                screenNgComment: homeValues.placementApplication.status.comment,
              }}
              onEditClick={onEditClick}
              onContactSuspend={onContactSuspend}
            />
          ),
        },
      ]
  }

  switch (homeValues.homeCondition) {
    case 'only-placement': {
      const {
        status: { code },
      } = homeValues.placementApplication
      const status = code === 'waitForSecondScreening' ? 'waitForScreening' : code
      return (
        <>
          <header css={styles.header}>
            <ProfileTitle kind="placement" status={status} />
          </header>
          <Divider css={styles.divider} />
          <ProfileTabContent
            tabKind="placement"
            homeValues={homeValues}
            detail={{
              kind: 'placement',
              businessCategory: homeValues.businessCategory,
              placementDetail: homeValues.placementApplication.content,
            }}
            statusDescriptionProps={{
              kind: 'placement',
              status,
              screenNgComment: homeValues.placementApplication.status.comment,
            }}
            onEditClick={onEditClick}
            onContactSuspend={onContactSuspend}
          />
        </>
      )
    }
    case 'only-public': {
      const { suspended } = homeValues.publicInformation
      const status = suspended ? 'suspended' : 'in-public'

      return (
        <>
          <header css={styles.header}>{<ProfileTitle kind="public" status={status} />}</header>
          <Divider css={styles.divider} />
          <ProfileTabContent
            tabKind="public"
            homeValues={homeValues}
            detail={{
              kind: 'public',
              businessCategory: homeValues.businessCategory,
              publicDetail: homeValues.publicInformation,
              contactSuspended: homeValues.contactSuspended,
            }}
            statusDescriptionProps={{
              kind: 'public',
              status,
            }}
            onEditClick={onEditClick}
            onContactSuspend={onContactSuspend}
          />
        </>
      )
    }
    case 'placement-n-public-tab': {
      const { suspended } = homeValues.publicInformation
      const publicStatus = suspended ? 'suspended' : 'in-public'
      const {
        status: { code },
      } = homeValues.placementApplication
      const placementStatus = code === 'waitForSecondScreening' ? 'waitForScreening' : code
      const statusDescriptionProps: ProfileStatusDescriptionProps = {
        kind: 'public',
        status: publicStatus,
      }
      const profileTitleProps: ProfileTitleProps = {
        kind: 'public',
        status: publicStatus,
      }

      const tabs = getTabs(profileTitleProps, placementStatus, statusDescriptionProps)
      return <Tabs css={styles.header} defaultActiveKey="2" items={tabs} />
    }

    case 'public-n-churn': {
      const { profileChurnStatus } = homeValues
      return (
        <>
          <header css={styles.header}>{<ProfileTitle kind="churn" status={profileChurnStatus} />}</header>
          <Divider css={styles.divider} />
          <ProfileTabContent
            tabKind="public"
            homeValues={homeValues}
            detail={{
              kind: 'public',
              businessCategory: homeValues.businessCategory,
              publicDetail: homeValues.publicInformation,
              contactSuspended: homeValues.contactSuspended,
            }}
            statusDescriptionProps={{
              kind: 'churn',
              status: profileChurnStatus,
              placementEndDate: homeValues.churnApplication.endDate,
            }}
            onEditClick={onEditClick}
            onContactSuspend={onContactSuspend}
          />
        </>
      )
    }

    case 'placement-n-churn-tab': {
      const { profileChurnStatus } = homeValues
      const {
        status: { code },
      } = homeValues.placementApplication
      const placementStatus = code === 'waitForSecondScreening' ? 'waitForScreening' : code
      const statusDescriptionProps: ProfileStatusDescriptionProps = {
        kind: 'churn',
        status: profileChurnStatus,
        placementEndDate: homeValues.churnApplication.endDate,
      }
      const profileTitleProps: ProfileTitleProps = {
        kind: 'churn',
        status: profileChurnStatus,
      }

      const tabs = getTabs(profileTitleProps, placementStatus, statusDescriptionProps)
      return <Tabs css={styles.header} defaultActiveKey="2" items={tabs} />
    }
  }
}
