import { SingleTextInputForm } from 'inheritance-components'
import { DoubleTextInputForm } from '../form/DoubleTextInputForm'
import { RadioForm } from '../form/RadioForm'
import { formProps } from './form'
import React from 'react'
import { usePlacementFormContext } from '../contexts/PlacementFormProvider'
import { SerializedStyles } from '@emotion/react'

const items = [
  {
    label: '入力する',
    value: true,
  },
  {
    label: '入力しない',
    value: false,
  },
]

type SummaryCourtLegalRepresentationProps = {
  /**
   * 法人、個人でフォームの挙動を切り替えるためのフラグ
   * - `true`（法人）の場合 `特定社員`
   * - `false`（個人）の場合 `認定司法書士`
   */
  isCorporation: boolean
  rootCss?: SerializedStyles
}

export const SummaryCourtLegalRepresentation = ({ isCorporation, rootCss }: SummaryCourtLegalRepresentationProps) => {
  const { hasSummaryCourtLegalRepresentation, setHasSummaryCourtLegalRepresentation } = usePlacementFormContext()

  const handleToggleFlag = React.useCallback(
    (value: string | boolean) => {
      const flag = value === true

      setHasSummaryCourtLegalRepresentation(flag)
    },
    [setHasSummaryCourtLegalRepresentation]
  )

  return (
    <div css={rootCss}>
      <RadioForm
        {...formProps['summaryCourtLegalRepresentation']['summaryCourtLegalRepresentationRadio']}
        items={items}
        initialValue={hasSummaryCourtLegalRepresentation}
        onChange={handleToggleFlag}
      />
      {hasSummaryCourtLegalRepresentation && (
        <>
          <DoubleTextInputForm {...formProps['summaryCourtLegalRepresentation']['specifiedMember'](isCorporation)} />
          <SingleTextInputForm
            {...formProps['summaryCourtLegalRepresentation']['summaryCourtLegalRepresentationCertificationNumber']}
          />
        </>
      )}
    </div>
  )
}
