import { css } from '@emotion/react'
import { message, Modal } from 'antd'
import { CameraOutlined, PlusCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import { useState, useEffect, DependencyList, useRef, useCallback } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, PercentCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import imageCompression from 'browser-image-compression'
import { base64ToUint8Array, ImageProps } from 'inheritance-utils'
import { PlacementImageLoader } from 'inheritance-components/src/components/image/PlacementImageLoader'
import { useRpcContext } from '@/rpc/RpcContext'
import { useDesignToken } from '../design-system/token'

const maxUploadSizeMB = 0.4

const useStyles = () => {
  const { color, themeColor, additionalColor } = useDesignToken()

  return {
    thumbnail: css`
      display: inline-block;
      position: relative;
    `,
    removeIcon: css`
      position: absolute;
      top: -10px;
      right: -10px;
      color: ${themeColor.text.alert1};
      font-size: 20px;
      background: ${color.background.white};
      border-radius: 100%;
      cursor: pointer;
    `,
    empty: css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${color.text.white};
      background: ${additionalColor.background.red};
      cursor: pointer;
    `,
    emptyContent: css`
      position: relative;
      font-size: 12px;
      white-space: nowrap;
    `,
    cameraIcon: css`
      display: block;
      font-size: 32px;
      margin: 2px 0 4px;
    `,
    addIcon: css`
      position: absolute;
      top: 16px;
      right: 5px;
      color: ${themeColor.text.alert1};
      font-size: 18px;
      background: ${color.background.white};
      border-radius: 100%;
    `,
    contentBody: css`
      margin: 0 auto;
      max-width: 600px;
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
    `,
    inputImage: css`
      margin-bottom: 16px;
    `,
    imageCrop: css`
      max-width: 600px;
    `,
    preview: css`
      margin-top: 16px;
      max-width: 600px;
      object-fit: contain;
    `,
    form: css`
      margin-top: 32px;
    `,
    divider: css`
      margin-top: 40px;
    `,
    inputFile: css`
      border: 1px solid ${themeColor.border.red};
      border-radius: 6px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 16px;
      padding: 4px 11px;
      transition: all 0.2s;
      width: 100%;
      &:hover {
        border-color: ${color.accent.purple[500]};
        border-inline-end-width: 1px;
      }
    `,
  }
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    // アスペクト比に合わせて幅100％でクロップして表示
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const useDebounceEffect = (fn: () => void, waitTime: number, deps?: DependencyList) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn()
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
    // NOTE:
    //   - Do not add fn() to deps
    //   - Deps need to be optional
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitTime, ...(deps ?? [])])
}

// TODO: Memorize callbacks
export const ImageSelect = ({
  imageProps,
  initialPlacementImageId,
  onCropped,
  onDelete,
  notFoundErrorCallback,
}: {
  imageProps: ImageProps
  initialPlacementImageId?: string
  onCropped: (image: File) => void | Promise<void>
  onDelete: () => void
  notFoundErrorCallback?: () => void
}) => {
  const { placementImageFetcher } = useRpcContext()
  const [imageSrc, setImageSrc] = useState('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [croppedImage, setCroppedImage] = useState('')
  const [initialImageId, setInitialImageId] = useState<string | undefined>(initialPlacementImageId)
  const imageRef = useRef<HTMLImageElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  const inputFileRef = useRef<HTMLInputElement>(null)

  const [open, setOpen] = useState(false)

  const thumbnailCss = css`
    width: auto;
    height: ${imageProps.thumbnailHeight}px;
    min-width: ${imageProps.thumbnailWidth}px;
    max-width: ${imageProps.thumbnailWidth}px;
    max-height: ${imageProps.thumbnailHeight}px;
  `

  const openModal = () => {
    clearState()
    setOpen(true)
  }

  const deleteImage = () => {
    clearState()
    onDelete()
    setInitialImageId(undefined)
    onDeleteInputFileRef()
  }

  const clearState = () => {
    setImageSrc('')
    setCrop(undefined)
    setCompletedCrop(undefined)
    setCroppedImage('')
  }

  const notFoundErrorCallbackHandler = () => {
    deleteImage()
    notFoundErrorCallback?.()
  }

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }
    const fileType = e.target.files[0].type
    if (!fileType.match('image/png') && !fileType.match('image/jpeg')) {
      await message.error('画像ファイルはPNGまたはJPEGを選択してください')
      setImageSrc('')
      return
    }

    clearState()
    const reader = new FileReader()
    reader.addEventListener('load', () => setImageSrc(reader.result?.toString() || ''))
    reader.readAsDataURL(e.target.files[0])
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (imageProps.aspect) {
      const { width, height } = e.currentTarget
      // 不正な値の場合は何もしない(Cropperによる影響など?)
      if (width === 0 || height === 0) {
        return
      }
      setCrop(centerAspectCrop(width, height, imageProps.aspect))
    }
  }

  const onChangeCrop = (percentCrop: PercentCrop) => {
    // 画像上をクリックしただけで範囲選択していない時は早期return（0だと誤ドラッグが起きやすいため余裕を持たせ3としている）
    if (percentCrop.width < 3 && percentCrop.height < 3) return
    setCrop(percentCrop)
  }

  const getScale = (naturalWidth: number, naturalHeight: number, width: number, height: number) => {
    return { X: naturalWidth / width, Y: naturalHeight / height }
  }

  const getCenter = (naturalWidth: number, naturalHeight: number) => {
    return { X: naturalWidth / 2, Y: naturalHeight / 2 }
  }

  const getCropCoordinate = (cropX: number, cropY: number, scaleX: number, scaleY: number) => {
    return { X: Math.floor(cropX * scaleX), Y: Math.floor(cropY * scaleY) }
  }

  const getCroppedCanvas = (cropSize: number, scale: number, pixelRatio: number) => {
    return Math.floor(cropSize * scale * pixelRatio)
  }

  const canvasPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) => {
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio
    const src = image.src

    if (!ctx) {
      throw new Error('画像の変換処理に失敗しました')
    }
    const scale = getScale(image.naturalWidth, image.naturalHeight, image.width, image.height)
    const center = getCenter(image.naturalWidth, image.naturalHeight)
    const cropCoordinate = getCropCoordinate(crop.x, crop.y, scale.X, scale.Y)

    canvas.width = getCroppedCanvas(crop.width, scale.X, pixelRatio)
    canvas.height = getCroppedCanvas(crop.height, scale.Y, pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    ctx.save()

    ctx.translate(-cropCoordinate.X, -cropCoordinate.Y)
    ctx.translate(center.X, center.Y)
    ctx.translate(-center.X, -center.Y)
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

    ctx.restore()

    setCroppedImage(canvas.toDataURL(src.split(';')[0].split(':')[1]))
  }

  const canvasToFile = (canvas: HTMLCanvasElement, type: string): File => {
    const data = canvas.toDataURL(type).split(',')
    const decodedData = base64ToUint8Array(data[1])
    const fileName = Math.floor(Math.random() * 100).toString() + new Date().getTime().toString()
    const file = new File([decodedData.buffer], fileName, { type })
    return file
  }

  const onSubmit = () => {
    const image = new Image()
    image.src = croppedImage
    image.onload = async () => {
      const canvas = document.createElement('canvas')

      // 規定（仕様）よりも小さい場合はscaleさせないように画像の実体サイズを設定
      if (image.width < imageProps.maxWidth && image.height < imageProps.maxHeight) {
        canvas.width = image.width
        canvas.height = image.height
      } else {
        canvas.width = imageProps.maxWidth
        canvas.height = imageProps.maxHeight
      }

      const ctx = canvas.getContext('2d')
      if (!ctx) throw new Error('画像の変換処理に失敗しました')

      if (imageProps.aspect >= 1 && image.width > imageProps.maxWidth) {
        const scale = imageProps.maxWidth / image.width
        image.width = imageProps.maxWidth
        image.height = image.height * scale
      } else if (1 >= imageProps.aspect && image.height > imageProps.maxHeight) {
        const scale = imageProps.maxHeight / image.height
        image.height = imageProps.maxHeight
        image.width = image.width * scale
      }

      ctx.drawImage(image, 0, 0, image.width, image.height)
      const type = image.src.split(',')[0].split(';')[0].split(':')[1]
      const file = canvasToFile(canvas, type)

      // 暫定的に容量400KB以下に圧縮する
      const compressed = await imageCompression(file, {
        maxSizeMB: maxUploadSizeMB,
        maxWidthOrHeight: Math.max(image.width, image.height),
      }).catch(() => {
        throw new Error('画像の圧縮処理に失敗しました')
      })

      try {
        await onCropped(compressed)

        setInitialImageId(undefined)
      } catch (e) {
        setCroppedImage('')
        resetInput()

        console.error(e)
      }
    }
    setOpen(false)
  }

  const onCancel = () => {
    deleteImage()
    setOpen(false)
  }

  const onDeleteInputFileRef = () => {
    resetInput()
  }

  const resetInput = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }
  }, [])

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imageRef.current && previewCanvasRef.current) {
        await canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  const styles = useStyles()

  return (
    <>
      {initialImageId || croppedImage ? (
        <div css={styles.thumbnail}>
          {initialImageId ? (
            <PlacementImageLoader
              placementImageId={initialImageId}
              imageProps={imageProps}
              placementImageFetcher={placementImageFetcher}
              notFoundErrorCallback={notFoundErrorCallbackHandler}
            />
          ) : (
            <img css={thumbnailCss} src={croppedImage} />
          )}
          <CloseCircleOutlined css={styles.removeIcon} onClick={deleteImage} />
        </div>
      ) : (
        <div css={[styles.empty, thumbnailCss]} onClick={openModal}>
          <div css={styles.emptyContent}>
            <CameraOutlined css={styles.cameraIcon} />
            <PlusCircleFilled css={styles.addIcon} />
            <span>写真を選択</span>
          </div>
        </div>
      )}

      <Modal
        centered
        open={open}
        width={600}
        title="写真を選択"
        onOk={onSubmit}
        onCancel={onCancel}
        okText="画像を選択"
        cancelText="キャンセル"
        okButtonProps={{ disabled: !completedCrop?.height && !completedCrop?.width }}
      >
        <section css={styles.contentBody}>
          <div>
            <input
              ref={inputFileRef}
              type="file"
              accept="image/jpeg,image/png"
              css={styles.inputFile}
              onChange={onSelectFile}
            />
          </div>
          {!!imageSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => onChangeCrop(percentCrop)}
              onComplete={(comp) => setCompletedCrop(comp)}
              aspect={imageProps.aspect}
            >
              <img ref={imageRef} src={imageSrc} css={styles.imageCrop} onLoad={onImageLoad} />
            </ReactCrop>
          )}
          <div>
            {!!completedCrop && (
              <canvas ref={previewCanvasRef} css={styles.preview} style={{ width: completedCrop?.width }} />
            )}
          </div>
        </section>
      </Modal>
    </>
  )
}
