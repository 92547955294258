import { css, SerializedStyles } from '@emotion/react'
import wave_purple from '@/assets/lp/wave_purple.svg'
import wave_gray from '@/assets/lp/wave_gray.svg'
import wave_picture from '@/assets/lp/wave_picture.svg'

const waveType = {
  purple: wave_purple,
  gray: wave_gray,
  picture: wave_picture,
} as const

type WaveType = keyof typeof waveType

type WaveProps = {
  type: WaveType
  direction?: 'up' | 'down'
  rootCss?: SerializedStyles
}

const useStyle = ({ type, direction }: WaveProps) => {
  return {
    img: css`
      width: 100%;
      height: 56px;
      background-image: url(${waveType[type]});
      background-size: cover;
      background-position: center;
      transform: rotateX(${direction === 'down' ? '180deg' : '0deg'});
    `,
  }
}

export const Wave = (prop: WaveProps) => {
  const style = useStyle(prop)

  return <div css={css(style.img, prop.rootCss)}></div>
}
