import { TBusinessCategory } from './businessCategory'

export const SCREENING_QUESTION = {
  howManyInquiriesAnticipateFromUsersPerYear: 'howManyInquiriesAnticipateFromUsersPerYear',
  howManyProfessionalsMoreThan3YearsOfExperience: 'howManyProfessionalsMoreThan3YearsOfExperience',
  howManyCasesHandledSoFar: 'howManyCasesHandledSoFar',
  isNotUndertakenAnyLitigationAgainstMUFGBank: 'isNotUndertakenAnyLitigationAgainstMUFGBank',
  isIntendedToHandleSummaryCourtLegalRepresentation: 'isIntendedToHandleSummaryCourtLegalRepresentation',
  isBusinessOpenOverseasUnderNameOfAdvertiser: 'isBusinessOpenOverseasUnderNameOfAdvertiser',
} as const

export type TGetScreeningQuestionLabelParam = {
  isForm: boolean
  bizCategory: TBusinessCategory | undefined
}

const noteForCorporation = '複数事務所掲載の場合は、合計人数をご記入ください。'

export const getScreeningQuestionLabel = ({ isForm, bizCategory }: TGetScreeningQuestionLabelParam) => {
  return {
    [SCREENING_QUESTION.howManyInquiriesAnticipateFromUsersPerYear]: `そうぞくガイドにおける一般ユーザからのお問い合わせについて、年間何件程度をご想定されますか。${
      isForm ? '(※1)' : ''
    }`,
    [SCREENING_QUESTION.howManyProfessionalsMoreThan3YearsOfExperience]: `広告掲載予定の事務所において、相続実務${
      isForm ? '(※2)' : ''
    }経験「3年以上」の先生の人数は何人いますか。${bizCategory === 'corporation' ? noteForCorporation : ''}`,
    [SCREENING_QUESTION.howManyCasesHandledSoFar]: `広告掲載予定の事務所において、現在に至るまで、相続業務を何件程度取り扱ってきましたか。${
      isForm ? '(※3)' : ''
    }${bizCategory === 'corporation' ? noteForCorporation : ''}`,
    [SCREENING_QUESTION.isNotUndertakenAnyLitigationAgainstMUFGBank]:
      '現時点において、三菱UFJ銀行を相手とした訴訟案件を引き受けていませんか。',
    [SCREENING_QUESTION.isIntendedToHandleSummaryCourtLegalRepresentation]: `掲載予定の事務所は司法書士法第3条第1項第6号乃至第8号で定める業務（簡裁訴訟代理等関係業務）を行うことを目的としていますか。${
      isForm ? '(※4)' : ''
    }`,
    [SCREENING_QUESTION.isBusinessOpenOverseasUnderNameOfAdvertiser]:
      '広告主名義(事務所名)で外国にて営業を行っていますか。',
  }
}
