import { getApiAuthMasterClient } from '../client'
import { ProfessionalCategoryCode } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
export type { ProfessionalCategoryCode } from 'inheritance-api'

export const callGetAdditionalInfo = async (professionalCategoryCode: ProfessionalCategoryCode) => {
  try {
    const apiClient = await getApiAuthMasterClient()
    const { data: additionalInfo } = await apiClient.getMasterAdditionalInfo(professionalCategoryCode, { secure: true })
    return { additionalInfo }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
