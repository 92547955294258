import { buildRethrowingError } from '../buildRethrowingError'
import { getApiAuthProfessionalClient } from '../client'
export type { GetProfessionalSelfResponse } from 'inheritance-api'

export const callGetProfessionalSelf = async () => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    const { data: professional } = await apiClient.getProfessionalSelf({ secure: true })
    return { professional }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
