import { ENV } from '@/config/environment'
import { ProfessionalCategoryCode, PutProfessionalPlacementPreviewResponse } from 'inheritance-api'

const PreviewPath = {
  'pro-search-list': (professionalCategoryCode: ProfessionalCategoryCode, queries: string) =>
    `https://${ENV.FRONTEND_HOST_USER}/pro-search/${professionalCategoryCode}/public-info-list/preview?${queries}`,
  'pro-search-detail': (professionalId: string, professionalCategoryCode: ProfessionalCategoryCode, queries: string) =>
    `https://${ENV.FRONTEND_HOST_USER}/pro-search/${professionalCategoryCode}/professional/${professionalId}/preview?${queries}`,
} as const

const createPreviewUserQueries = ({
  professionalId,
  previewHash,
  previewToken,
  previewExpireAt,
}: PutProfessionalPlacementPreviewResponse) => {
  const searchParams = new URLSearchParams()
  searchParams.set('professional_id', professionalId)
  searchParams.set('hash', previewHash)
  searchParams.set('token', previewToken)
  searchParams.set('expire', String(previewExpireAt))
  searchParams.set('prefecture', 'tokyo_13')
  return searchParams.toString()
}

export type CreatePreviewUserUrlProps = {
  target: keyof typeof PreviewPath
  professionalCategoryCode: ProfessionalCategoryCode
} & PutProfessionalPlacementPreviewResponse

export const createPreviewUserUrl = ({
  target,
  professionalCategoryCode,
  professionalId,
  previewHash,
  previewToken,
  previewExpireAt,
}: CreatePreviewUserUrlProps) => {
  const queries = createPreviewUserQueries({ professionalId, previewHash, previewToken, previewExpireAt })

  switch (target) {
    case 'pro-search-list':
      return PreviewPath['pro-search-list'](professionalCategoryCode, queries)
    case 'pro-search-detail':
      return PreviewPath['pro-search-detail'](professionalId, professionalCategoryCode, queries)
  }
}
