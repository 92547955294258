import { getPath } from '@/router'
import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { Button, Typography } from 'antd'

export const PlacementApplicationExists = () => {
  return (
    <ErrorScreen
      kind="composition"
      title="掲載申込履歴があります"
      content={
        <>
          <Typography.Paragraph type="secondary">掲載情報は以下のリンクからご確認頂けます。</Typography.Paragraph>
          <Button type="primary" href={getPath('home')}>
            掲載情報を確認
          </Button>
        </>
      }
    />
  )
}
