import { MailOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Button, Form, Input } from 'antd'
import { ReactNode } from 'react'
import { getRules, RULES } from '../../form'

const styles = {
  container: css`
    text-align: center;
    padding-bottom: 48px;
  `,
  header: css`
    font-size: 20px;
    line-height: 28px;
    margin: 42px 0;
  `,
  description: css`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  `,
  formItem: css`
    display: inline-block;
    text-align: left;
  `,
  input: css`
    width: 360px;
    height: 40px;
  `,
  button: css`
    margin-top: 40px;
  `,
}

export const PasswordResetRequestLayout = (props: {
  header: ReactNode
  onSubmit: (values: { email: string }) => void
  loading?: boolean
}) => {
  const [form] = Form.useForm<{ email: string }>()

  return (
    <>
      {props.header}
      <div css={styles.container}>
        <h4 css={styles.header}>パスワードをリセットします</h4>
        <p css={styles.description}>
          パスワード再設定用URLを送信しますので、ご登録いただいているメールアドレスを入力し
          <br />
          「パスワード再設定用メールを送信する」ボタンをクリックしてください。
        </p>
        <Form name="login" form={form} onFinish={props.onSubmit}>
          <div>
            <Form.Item name="email" rules={getRules([RULES.requireEntry, RULES.email])} css={styles.formItem}>
              <Input css={styles.input} prefix={<MailOutlined />} placeholder="Eメールアドレス" />
            </Form.Item>
          </div>

          <Form.Item css={styles.button}>
            <Button type="primary" htmlType="submit" loading={props.loading}>
              パスワード再設定用メールを送信する
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
