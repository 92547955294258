import { Button, Divider, Layout } from 'antd'
import { useDesignToken } from '../design-system/token'
import { css } from '@emotion/react'
import React from 'react'

const styles = {
  divider: css`
    margin: 0;
  `,
}

type Props = {
  handleDetailButton: React.MouseEventHandler<HTMLElement>
  handleListButton: React.MouseEventHandler<HTMLElement>
  loading?: boolean
}

export const PreviewHeader = ({ handleDetailButton, handleListButton, loading }: Props) => {
  return (
    <>
      <Divider css={styles.divider} />
      <Layout.Header css={usePreviewHeaderCss()}>
        <div>
          <h3>専門家情報入力</h3>
          <p className="action">
            <span>プレビュー：</span>
            <Button size="small" onClick={handleDetailButton} loading={loading}>
              詳細画面（新規タブ）
            </Button>
            <Button size="small" onClick={handleListButton} loading={loading}>
              一覧画面（新規タブ）
            </Button>
          </p>
        </div>
      </Layout.Header>
    </>
  )
}

const usePreviewHeaderCss = () => {
  const { topBottomLayout, customZIndex, themeColor } = useDesignToken()

  return css`
    position: sticky;
    top: 0;
    z-index: ${customZIndex.header};

    h3 {
      font-size: 20px;
      color: ${themeColor.text.black};
    }
    span {
      color: ${themeColor.text.black};
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-left: ${topBottomLayout.sideMargin};
      padding-right: ${topBottomLayout.sideMargin};

      .action {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  `
}
