import { useState } from 'react'
import { css } from '@emotion/react'
import { Button, Form, notification, Typography, Input } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { getPath } from '@/router'
import { useNavigate } from 'react-router-dom'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { SingleTextInputForm, SingleTextInputFormProps } from 'inheritance-components'
import { getRules, RULES } from 'inheritance-components'
import { useQueryParamsDefinitely } from 'inheritance-router'
import { useRpcContext } from '@/rpc/RpcContext'
import { useAuthContext } from '@/auth/AuthContext'
import { RecaptchaV2Invisible, getRecaptchaToken, RecaptchaCallbackArg } from 'inheritance-recaptcha'
import { useHandleRecaptchaError } from '@/share/recaptcha/hook'
import { ENV } from '@/config/environment'
import { resetRecaptcha } from 'inheritance-recaptcha/src/RecaptchaV2Invisible'
import { getErrMsg } from '@/share/error'
import { PasswordGuideline } from 'inheritance-components/src/components/guideline/PasswordGuideline'
import { AxiosError } from 'axios'

const styles = {
  guideline: css`
    margin: 16px 0 42px;
  `,
  description: css`
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
  `,
  form: css`
    text-align: left;
    max-width: 680px;
    margin: 0 auto 40px;
  `,
  button: css`
    text-align: center;
    margin-top: 64px;
  `,
}

const useFormProps = (form: FormInstance): Record<string, SingleTextInputFormProps> => {
  // パスワード入力時、パスワード（確認）が入力されていたら検証する
  const onChangePassword = async () => {
    const passwordConfirmValue = form.getFieldValue('passwordConfirm')
    if (passwordConfirmValue) {
      await form.validateFields(['passwordConfirm'])
    }
  }

  return {
    password: {
      name: 'password',
      label: '新規パスワード',
      rules: getRules([RULES.requireEntry, RULES.passwordPattern]),
      renderInput: () => <Input.Password onChange={onChangePassword} />,
    },
    passwordConfirm: {
      name: 'passwordConfirm',
      label: '新規パスワード（確認）',
      rules: getRules([RULES.requireEntry, RULES.passwordConfirm]),
      renderInput: () => <Input.Password />,
    },
  }
}

const useCreateAccount = () => {
  const navigate = useNavigate()
  const { getAuthenticator } = useAuthContext()
  const authenticator = getAuthenticator()
  if (!authenticator) throw new Error('failed to get authenticator')

  const { callPutProfessionalCreateAccount } = useRpcContext()

  const [mutating, setMutating] = useState(false)

  const mutate = async (recaptchaToken: string, password: string, createAccountToken: string) => {
    setMutating(true)

    const { error } = await callPutProfessionalCreateAccount({
      'g-recaptcha-response': recaptchaToken,
      accountInfo: {
        password: password,
      },
      createAccountToken,
    })

    setMutating(false)

    resetRecaptcha()

    if (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        const key = `notification_${Date.now()}`
        notification.open({
          key,
          message: 'アカウント作成に失敗しました',
          description: '認証情報の取得に失敗しました。電話番号認証をやり直して下さい。',
          placement: 'top',
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                notification.destroy(key)
                window.location.reload()
              }}
            >
              電話番号認証をやり直す
            </Button>
          ),
          duration: 10,
        })
      } else {
        notification.open({
          message: 'アカウント作成に失敗しました',
          description: getErrMsg({ error }),
          placement: 'top',
        })
      }
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authenticator.signOut()

    navigate(getPath('createAccountCompleted'))
  }

  return { mutating, mutate }
}

export const CreateAccountForm = () => {
  const { createAccountToken } = useQueryParamsDefinitely({
    keys: ['createAccountToken'],
    errorWhenFailed: new Error('無効なURLです'),
  })
  const { mutate, mutating } = useCreateAccount()
  const [form] = Form.useForm()
  const formProps = useFormProps(form)
  const { handleRecaptchaError } = useHandleRecaptchaError()

  const onFinish = async (value: { password: string }) => {
    const token = await getRecaptchaToken()
    if (!token) {
      // 後続の処理を行わず、callbackを待機
      return
    }
    await mutate(token, value.password, createAccountToken)
  }

  const recaptchaCallback = async (arg: RecaptchaCallbackArg) => {
    if (arg.type === 'verified') {
      await mutate(arg.token, form.getFieldsValue().password, createAccountToken)
      return
    }
    await handleRecaptchaError(arg.error)
  }

  return (
    <>
      <AppLayout>
        <ContentTitleHeader title="アカウント作成" />
        <div css={styles.description}>
          <Typography.Paragraph>
            新しいパスワードを入力し、「アカウントを作成する」ボタンをおしてください。
          </Typography.Paragraph>
        </div>
        <PasswordGuideline rootCss={styles.guideline} />
        <Form css={styles.form} form={form} name="login" labelAlign="left" labelCol={{ span: 8 }} onFinish={onFinish}>
          <SingleTextInputForm {...formProps['password']} />
          <SingleTextInputForm {...formProps['passwordConfirm']} />

          <Form.Item css={styles.button}>
            <Button type="primary" htmlType="submit" loading={mutating}>
              アカウントを作成する
            </Button>
          </Form.Item>
        </Form>
        <RecaptchaV2Invisible callback={recaptchaCallback} siteKey={ENV.RECAPTCHA_SITE_KEY} />
      </AppLayout>
    </>
  )
}
