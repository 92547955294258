import { Form, Input, ColProps, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormLabel } from './FormLabel'
import { judgeRequired } from './judgeRequired'
import { css, SerializedStyles } from '@emotion/react'
import { FormLabelWithGuideText } from './FormLabelWithGuildeText'

type SingleTextInputFormPropsBase = {
  label: string
  name: string
  placeholder?: string
  rules: Rule[]
  initialValue?: string
  labelCol?: ColProps
  wrapperCol?: ColProps
  colon?: boolean
  suffix?: string
  // 入力エリアしたの注釈
  annotation?: string
  rootCss?: SerializedStyles
  size?: 'large' | 'middle' | 'small'
  normalize?: (value: string) => string
  guideText?: string
}

type SingleTextInputFormPropsSingle = SingleTextInputFormPropsBase & {
  type?: string
  multiple?: false
  renderInput?: (params: { type?: string; placeholder?: string }) => React.ReactNode
}

type SingleTextInputFormPropsMultiple = SingleTextInputFormPropsBase & {
  multiple: true
  autoSize?: boolean | object
  showCount?: boolean
  maxLength?: number
  renderInput?: (params: {
    placeholder?: string
    autoSize: boolean | object
    showCount: boolean
    maxLength?: number
  }) => React.ReactNode
}

export type SingleTextInputFormProps = SingleTextInputFormPropsSingle | SingleTextInputFormPropsMultiple

const useStyle = () => {
  return {
    annotation: css`
      display: block;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      margin-top: 4px;
    `,
    textArea: css`
      textarea {
        padding-right: 32px;
      }
    `,
  }
}
/**
 * `SingleTextInputForm`は、単一のテキスト入力を持つForm.Itemをレンダリングするコンポーネント。
 * 単一行と複数行の入力の両方をサポートしている。
 *
 * @param props - コンポーネントのレンダリング方法を定義するプロパティ。
 * @param props.label - Form.Item のラベル。
 * @param props.name - Form.Item の名前。
 * @param props.rules - Form.Item に対するバリデーションのルール。
 * @param props.initialValue - Form.Item の初期値。
 * @param props.labelCol - ラベルのグリッドプロパティ。
 * @param props.wrapperCol - ラッパーのグリッドプロパティ。
 * @param props.colon - ラベルの後にコロンを表示するかどうか。Form.Item の colon はデフォルト true であるため、
 * guideTextを指定する場合（≒ FormLabelWithGuideText を使う場合）は false を指定する必要がある。
 * @param props.suffix - 入力の接尾辞。
 * @param props.annotation - 入力フィールド下に表示される注釈
 * @param props.rootCss - root要素のCSS。
 * @param props.size - 入力のサイズ。
 * @param props.normalize - 入力値を正規化する関数。
 * @param props.guideText - ラベル下に表示される注釈
 * @param props.multiple - 入力が複数行であるかどうか。
 * @param props.autoSize - テキストエリアの高さがその内容に合うかどうか。
 * @param props.showCount - テキストエリアの文字数を表示するかどうか。
 * @param props.maxLength - テキストエリアの最大長。
 * @param props.renderInput - 入力をレンダリングする関数。
 *
 * @returns `SingleTextInputForm`コンポーネント
 */
export const SingleTextInputForm = <T extends SingleTextInputFormProps>(props: T) => {
  const {
    label,
    name,
    rules,
    initialValue,
    labelCol,
    wrapperCol,
    colon,
    suffix,
    annotation,
    rootCss,
    size = 'large',
    normalize,
    guideText,
  } = props
  const style = useStyle()
  const width = size === 'large' ? '100%' : size === 'middle' ? '50%' : '25%'

  return (
    <Form.Item
      hasFeedback
      name={name}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        guideText ? (
          <FormLabelWithGuideText label={label} guideText={guideText} rules={rules} />
        ) : (
          <FormLabel label={label} required={judgeRequired(rules)} />
        )
      }
      rules={rules}
      // labelでrequiredを表現するため「*」は不要
      required={false}
      initialValue={initialValue}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      // guideText の無いコンポーネントに影響が出ないようにするため、colonを指定するよう修正。
      // Form.Item の colon はデフォルト true なので FormLabelWithGuideText を使う場合は
      // 親コンポーネントで false を指定する必要がある。
      colon={colon}
      extra={annotation && <Typography.Text css={style.annotation}>{annotation}</Typography.Text>}
      css={[rootCss, style.textArea]}
      normalize={normalize}
      // FormLabelWithGuideText を使う場合、ラベル下のマージンが広く設定されるため style で0に設定
      // rootCss が指定されている場合はそちらを優先する
      style={guideText && !rootCss ? { marginBottom: 0 } : {}}
    >
      {props.multiple
        ? (props.renderInput ?? ((params) => <Input.TextArea {...params} />))({
            placeholder: props.placeholder,
            autoSize: props.autoSize === true ? { minRows: 2 } : props.autoSize ?? { minRows: 2 },
            showCount: props.showCount ?? false,
            maxLength: props.maxLength,
          })
        : (props.renderInput ?? ((params) => <Input {...params} suffix={suffix} css={{ width }} />))({
            type: props.type,
            placeholder: props.placeholder,
          })}
    </Form.Item>
  )
}
