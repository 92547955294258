import { useDesignToken } from '@/share/components/design-system/token'

export const useLandingPageDesignToken = () => {
  const { breakpoint, mediaQuery, ...token } = useDesignToken()
  const small = mediaQuery.screenMaxW(breakpoint)

  return {
    ...token,
    mediaQuery: {
      small,
    },
  }
}
