import { dateStringToDate, DATE_FORMAT, dateFns } from 'inheritance-utils'

/**
 * 掲載終了日
 * YYYY-MM-DDの形式
 */
export type PlacementEndDate = string

export const composePlacementEndDateDescription = (placementEndDate: PlacementEndDate) => {
  if (isEndOfMonth(placementEndDate)) {
    return `${dateStringToDate(placementEndDate, DATE_FORMAT['yyyy年M月'])}末まで広告は掲載されます。`
  }

  return `${dateStringToDate(placementEndDate, DATE_FORMAT['yyyy年M月d日'])}まで広告は掲載されます。`
}

const isEndOfMonth = (placementEndDate: PlacementEndDate): boolean => {
  const d = dateFns.parse(placementEndDate, 'yyyy-MM-dd', new Date())
  return dateFns.format(dateFns.endOfMonth(d), 'yyyy-MM-dd') === placementEndDate
}
