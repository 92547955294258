import { PlacementImageLoader } from '../../image/PlacementImageLoader'
import type { PlacementImageFetcher } from '../../image/PlacementImageLoader'
import { TextsWithNewline } from '../../text'
import { css } from '@emotion/react'
import {
  PlacementDescriptionImageDiff,
  PlacementDescriptionLabelsDiff,
  PlacementDescriptionTextDiff,
  PlacementDescriptionValue,
} from './type'
import { JSX } from 'react'

const styles = {
  images: css`
    display: flex;
    gap: 16px;
  `,
  labels: css`
    margin-right: 16px;
  `,
}

type PlacementDescriptionItemProps = {
  placementImageFetcher: PlacementImageFetcher
  value: PlacementDescriptionValue
  isDiff: boolean
}

export const PlacementDescriptionItem = ({
  value,
  isDiff,
  placementImageFetcher,
}: PlacementDescriptionItemProps): JSX.Element => {
  switch (value.kind) {
    case 'text':
      return <TextsWithNewline text={isDiff && (value as PlacementDescriptionTextDiff).empty ? '' : value.text} />
    case 'labels':
      return <Labels labels={isDiff && (value as PlacementDescriptionLabelsDiff).empty ? [] : value.labels} />
    case 'images': {
      return (
        <div css={styles.images}>
          {value.images.map(({ id, imageProps }, i) => {
            if (!id) return null
            if (isDiff && (value.images[i] as PlacementDescriptionImageDiff).empty) return null
            return (
              <PlacementImageLoader
                key={id + i}
                placementImageId={id}
                imageProps={imageProps}
                placementImageFetcher={placementImageFetcher}
              />
            )
          })}
        </div>
      )
    }
  }
}

const Labels = ({ labels }: { labels: string[] }) => {
  return (
    <>
      {labels.map((label, key) => (
        <span key={label + key} css={styles.labels}>
          {label}
        </span>
      ))}
    </>
  )
}
