import { css } from '@emotion/react'
import { Guideline } from 'inheritance-components'

const styles = {
  paragraph: css`
    width: 640px;
  `,
}

export const ServiceInformationGuideline = ({ lines }: { lines: string[] }) => {
  return <Guideline paragraphCss={styles.paragraph} texts={lines} />
}
