import { AppBaseError } from './AppBaseError'

type ErrObjForLogging = {
  name: string
  message: string
  stack?: string
  cause?:
    | {
        name: string
        message: string
        stack?: string
      }
    | unknown
}

export const getErrObjForLogging = (e: unknown): ErrObjForLogging | { unknownError: unknown } => {
  if (e instanceof AppBaseError) {
    return {
      name: e.name,
      message: e.message,
      stack: e.stack,
      cause:
        e.cause instanceof Error
          ? {
              name: e.cause.name,
              message: e.cause.message,
              stack: e.cause.stack,
            }
          : e.cause,
    }
  }

  if (e instanceof Error) {
    return {
      name: e.name,
      message: e.message,
      stack: e.stack,
    }
  }

  return { unknownError: e }
}
