import { ProfessionalCategoryCode } from 'inheritance-api'
import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'

export const useGetSupportServiceMaster = (professionalCategoryCode: ProfessionalCategoryCode) => {
  const { callGetSupportServiceMaster } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.supportServicesMaster, () =>
    callGetSupportServiceMaster(professionalCategoryCode)
  )
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return { supportServicesMaster: data.supportServices.items }
}
