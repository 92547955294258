// https://japan-d2.atlassian.net/browse/INH-552?focusedCommentId=45495 に添付の lawyer.txt, taxCounselor.txt, judicialScrivener.txt を元に作成

export const Affiliations = {
  /**
   * https://www.nichibenren.or.jp/jfba_info/bar_association/whole_country.html
   * から `弁護士会` という団体を抽出
   */
  lawyer: [
    '札幌弁護士会',
    '函館弁護士会',
    '旭川弁護士会',
    '釧路弁護士会',
    '仙台弁護士会',
    '福島県弁護士会',
    '山形県弁護士会',
    '岩手弁護士会',
    '秋田弁護士会',
    '青森県弁護士会',
    '東京弁護士会',
    '第一東京弁護士会',
    '第二東京弁護士会',
    '神奈川県弁護士会',
    '埼玉弁護士会',
    '千葉県弁護士会',
    '茨城県弁護士会',
    '栃木県弁護士会',
    '群馬弁護士会',
    '静岡県弁護士会',
    '山梨県弁護士会',
    '長野県弁護士会',
    '新潟県弁護士会',
    '愛知県弁護士会',
    '三重弁護士会',
    '岐阜県弁護士会',
    '福井弁護士会',
    '金沢弁護士会',
    '富山県弁護士会',
    '大阪弁護士会',
    '京都弁護士会',
    '兵庫県弁護士会',
    '奈良弁護士会',
    '滋賀弁護士会',
    '和歌山弁護士会',
    '広島弁護士会',
    '山口県弁護士会',
    '岡山弁護士会',
    '鳥取県弁護士会',
    '島根県弁護士会',
    '香川県弁護士会',
    '徳島弁護士会',
    '高知弁護士会',
    '愛媛弁護士会',
    '福岡県弁護士会',
    '佐賀県弁護士会',
    '長崎県弁護士会',
    '大分県弁護士会',
    '熊本県弁護士会',
    '鹿児島県弁護士会',
    '宮崎県弁護士会',
    '沖縄弁護士会',
  ],
  /**
   * https://www.nichizeiren.or.jp/nichizeiren/location/
   * から `税理士会` という団体を抽出
   */
  taxCounselor: [
    '東京税理士会',
    '東京地方税理士会',
    '千葉県税理士会',
    '関東信越税理士会',
    '近畿税理士会',
    '北海道税理士会',
    '東北税理士会',
    '名古屋税理士会',
    '東海税理士会',
    '北陸税理士会',
    '中国税理士会',
    '四国税理士会',
    '九州北部税理士会',
    '南九州税理士会',
    '沖縄税理士会',
  ],
  /**
   * https://www.shiho-shoshi.or.jp/association/shiho_shoshi_listh/
   * から `司法書士会` という団体を抽出
   */
  judicialScrivener: [
    '札幌司法書士会',
    '函館司法書士会',
    '旭川司法書士会',
    '釧路司法書士会',
    '宮城県司法書士会',
    '福島県司法書士会',
    '山形県司法書士会',
    '岩手県司法書士会',
    '秋田県司法書士会',
    '青森県司法書士会',
    '東京司法書士会',
    '神奈川県司法書士会',
    '埼玉司法書士会',
    '千葉司法書士会',
    '茨城司法書士会',
    '栃木県司法書士会',
    '群馬司法書士会',
    '静岡県司法書士会',
    '山梨県司法書士会',
    '長野県司法書士会',
    '新潟県司法書士会',
    '愛知県司法書士会',
    '三重県司法書士会',
    '岐阜県司法書士会',
    '福井県司法書士会',
    '石川県司法書士会',
    '富山県司法書士会',
    '大阪司法書士会',
    '京都司法書士会',
    '兵庫県司法書士会',
    '奈良県司法書士会',
    '滋賀県司法書士会',
    '和歌山県司法書士会',
    '広島司法書士会',
    '山口県司法書士会',
    '岡山県司法書士会',
    '鳥取県司法書士会',
    '島根県司法書士会',
    '香川県司法書士会',
    '徳島県司法書士会',
    '高知県司法書士会',
    '愛媛県司法書士会',
    '福岡県司法書士会',
    '佐賀県司法書士会',
    '長崎県司法書士会',
    '大分県司法書士会',
    '熊本県司法書士会',
    '鹿児島県司法書士会',
    '宮崎県司法書士会',
    '沖縄県司法書士会',
  ],
} as const
export const AFFILIATION_OTHER_TEXT = '該当する所属団体がない'
export const affiliationSelection = (category: keyof typeof Affiliations) => {
  return Affiliations[category]
    .map((affiliation) => ({ label: affiliation, value: affiliation } as { label: string; value: string }))
    .concat({ label: AFFILIATION_OTHER_TEXT, value: AFFILIATION_OTHER_TEXT })
}
export const enableAffiliationOther = (affiliation?: string) => affiliation === AFFILIATION_OTHER_TEXT
export const getAffiliation = (affiliation: string, affiliationOther: string) =>
  enableAffiliationOther(affiliation) ? affiliationOther : affiliation
export const getAffiliationData = (
  affiliation?: string,
  category?: keyof typeof Affiliations
): {
  affiliation: string
  affiliationOther: string
} => {
  // 存在しない場合は空文字を返す
  if (!affiliation || !category) return { affiliation: '', affiliationOther: '' }

  // 既存の所属団体の場合はそのまま返す
  // has signatures, but none of those signatures are compatible with each other 対策で ReadonlyArray<string> にキャスト
  if ((Affiliations[category] as ReadonlyArray<string>).find((a) => a === affiliation)) {
    return { affiliation, affiliationOther: '' }
  }

  // 既存の所属団体ではない場合は `該当する所属団体がない`、その他の所属団体に一致しない団体名 を返す
  return { affiliation: AFFILIATION_OTHER_TEXT, affiliationOther: affiliation }
}
