import { RegularHoliday } from 'inheritance-api'

const receptionCall24HoursAvailable = '電話受付24時間対応可能な場合はチェックを入れてください'
export const RECEPTION_CALL_24_HOURS_AVAILABLE_OPTIONS = [{ label: receptionCall24HoursAvailable, value: true }]
type RegularHolidaySingle = RegularHoliday[0]
export const REGULAR_HOLIDAY_NOT_APPLICABLE: RegularHolidaySingle = 'notApplicable'
export const REGULAR_HOLIDAY = {
  monday: '月',
  tuesday: '火',
  wednesday: '水',
  thursday: '木',
  friday: '金',
  saturday: '土',
  sunday: '日',
  nationalHoliday: '祝日',
  [REGULAR_HOLIDAY_NOT_APPLICABLE]: '定休日なし',
} as const satisfies Record<RegularHolidaySingle, string>
export const REGULAR_HOLIDAY_OPTIONS = [
  { label: REGULAR_HOLIDAY.monday, value: 'monday' },
  { label: REGULAR_HOLIDAY.tuesday, value: 'tuesday' },
  { label: REGULAR_HOLIDAY.wednesday, value: 'wednesday' },
  { label: REGULAR_HOLIDAY.thursday, value: 'thursday' },
  { label: REGULAR_HOLIDAY.friday, value: 'friday' },
  { label: REGULAR_HOLIDAY.saturday, value: 'saturday' },
  { label: REGULAR_HOLIDAY.sunday, value: 'sunday' },
  { label: REGULAR_HOLIDAY.nationalHoliday, value: 'nationalHoliday' },
  { label: REGULAR_HOLIDAY[REGULAR_HOLIDAY_NOT_APPLICABLE], value: REGULAR_HOLIDAY_NOT_APPLICABLE },
]
