import { Rule } from 'antd/lib/form'

/**
 * rulesに基づいて、対象項目の入力の必須/任意を判定する
 *
 * @example
 * ```
 * judgeRequired([{ required: true, message: '入力してください' }])
 * > true
 * judgeRequired([{ type: 'email', message: 'Eメールアドレスの形式に誤りがあります'}])
 * > false
 * ```
 */
export const judgeRequired = (rules: Rule[]) => {
  if (rules.length === 0) {
    return false
  }

  return rules.some((rule) => (rule as { required: boolean }).required)
}
