/**
 * key, value の配列から undefined を除くオブジェクトを作成する
 *
 * @param arr `[[key1, value1], [key2, value2], [key3, value3], ...]`
 * @returns `{ [key1]: value1, [key2]: value2, [key3]: value3, ... }`
 * @example
 * ```
 * kv([
 *   ['description1', 'foo'],
 *   ['detail2', 'bar'],
 *   ['ng-pattern', 'foo', 'bar', 'buz'],
 *   undefined
 * ]) // { description1: 'foo', detail2: 'bar' }
 * ```
 */
export const kv = <T>(arr: (T[] | undefined)[]) =>
  Object.fromEntries(arr.filter((v) => v?.length === 2).filter((v): v is T[] => v !== undefined)) as {
    [key: string]: T
  }
