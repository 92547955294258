import type { PlacementImageFetcher } from '../../image/PlacementImageLoader'
import { BusinessCategory, PlacementApplicationDetails } from 'inheritance-api'
import { DescriptionItemGroupType, GenericDescriptionGroupList } from '../generic'
import { placementApiSchemaToItemGroup } from './convert'
import { buildItemGroupsDiffList } from './diffConvert'
import {
  ContactSuspended,
  PlacementDescriptionDiff,
  PlacementDescriptionImagesDiff,
  PlacementDescriptionLabelsDiff,
  PlacementDescriptionTextDiff,
  PlacementDescriptionValue,
} from './type'
import { SupportAreaMaster } from 'inheritance-utils'
import { DescriptionItemGroupTypeWithOrder } from '../generic/type'
import { PlacementDescriptionItem } from './PlacementDescriptionItem'

type PlacementDetailProps = (
  | {
      kind: 'normalized'
      itemGroups: DescriptionItemGroupType<PlacementDescriptionValue>[]
    }
  | {
      kind: 'api-schema'
      placementDetail: PlacementApplicationDetails
      lastAcceptedApplicationDetail?: PlacementApplicationDetails
      contactSuspended: ContactSuspended
      supportAreaMaster: SupportAreaMaster[]
      businessCategory?: BusinessCategory
    }
) & {
  placementImageFetcher: PlacementImageFetcher
}

export const PlacementDetail = ({ placementImageFetcher, ...props }: PlacementDetailProps) => {
  const itemGroups =
    props.kind === 'normalized'
      ? props.itemGroups
      : placementApiSchemaToItemGroup({
          value: props.placementDetail,
          contactSuspended: props.contactSuspended,
          supportAreaMaster: props.supportAreaMaster,
          businessCategory: props.businessCategory,
        })

  const diffItemGroups =
    props.kind === 'api-schema' && props.lastAcceptedApplicationDetail
      ? buildItemGroupsDiffList(
          itemGroups as DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[],
          placementApiSchemaToItemGroup({
            value: props.lastAcceptedApplicationDetail,
            contactSuspended: props.contactSuspended, // diffItemGroupsの計算に利用しないため値はなんでも良い
            supportAreaMaster: props.supportAreaMaster,
            businessCategory: props.businessCategory,
          })
        )
      : undefined

  return (
    <GenericDescriptionGroupList<PlacementDescriptionValue | PlacementDescriptionDiff>
      itemGroups={itemGroups}
      diffItemGroups={diffItemGroups}
      renderDescriptionItem={(value, isDiff) => (
        <PlacementDescriptionItem
          value={value as PlacementDescriptionValue | PlacementDescriptionDiff}
          isDiff={isDiff === undefined ? false : isDiff}
          placementImageFetcher={placementImageFetcher}
        />
      )}
      needsHighlightForDiff={(value) => {
        switch (value.kind) {
          case 'text':
            return (value as PlacementDescriptionTextDiff).differs
          case 'labels':
            return (value as PlacementDescriptionLabelsDiff).differs
          case 'images': {
            return !!(value as PlacementDescriptionImagesDiff).images.find((diffItem) => diffItem.differs)
          }
        }
      }}
    />
  )
}
