import { css, SerializedStyles } from '@emotion/react'

export type TResponsiveUtils = {
  readonly hidden: SerializedStyles
  readonly spHidden: SerializedStyles
  readonly pcHidden: SerializedStyles
  /** 画面横幅でのSP/PC分岐用フラグ */
  viewMode: {
    /** 画面横幅1024px以上 */
    pc: boolean
    /** 画面横幅1023px以下 */
    sp: boolean
  }
}

export const responsiveUtils = {
  hidden: css`
    display: none;
  `,
  /** 画面横幅1024px以上 */
  isPcView: false,
  // NOTE: useDesignToken に依存するのでcontext内で定義
  spHidden: css``,
  pcHidden: css``,
  viewMode: {
    pc: false,
    sp: true,
  },
} as const
