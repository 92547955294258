import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'

export const useProfessionalPlacementDefaults = () => {
  const { callGetProfessionalPlacementDefaults } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.placementApplicationFormValue, () => callGetProfessionalPlacementDefaults())
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return data
}
