import { Button, Typography } from 'antd'

export const PASSWORD_RESET_COMPLETE_TITLE = 'パスワードを設定しました'

export const PasswordResetCompleteContent = (props: { toLoginLink: string }) => {
  return (
    <>
      <Typography.Paragraph type="secondary">新しいパスワードでログインしてください</Typography.Paragraph>
      <Button type="primary" htmlType="button" href={props.toLoginLink}>
        ログイン画面に戻る
      </Button>
    </>
  )
}
