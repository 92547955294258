import { buildRethrowingError } from '../buildRethrowingError'
import { baseApiClint } from '../client'

export const callGetProfessionalCategories = async () => {
  try {
    const { data: professionalCategories } = await baseApiClint.master.getMasterProfessionalCategory()
    return { professionalCategories }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
