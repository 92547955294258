import { Spin } from 'antd'
import { css } from '@emotion/react'

const loading = (props: Required<LoadingProps>) => css`
  width: 100%;
  height: ${props.height};
  display: flex;
  align-items: center;
  justify-content: center;
`

type LoadingProps = {
  /**
   * @defaultValue
   * defaultValue is `100vh`
   *
   * @example
   * 160px, 100vh
   */
  height?: string
}
export const Loading = ({ height = '100vh' }: LoadingProps) => {
  return (
    <div css={loading({ height })}>
      <Spin />
    </div>
  )
}
