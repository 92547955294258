import { useState, useMemo, Suspense } from 'react'
import { Form, Col, Row, Skeleton, FormInstance } from 'antd'
import { css } from '@emotion/react'
import { useNavigate, Navigate } from 'react-router-dom'
import { getPath } from '@/router'

import { AppLayout } from '@/share/components/layout/AppLayout'
import { EditForm } from '@/share/components/PlacementForm/EditForm'
import { ConfirmForm } from '@/share/components/PlacementForm/ConfirmForm'
import {
  PlacementFormType,
  composeAreaOption,
  convertFormValuesToItemGroups,
} from '@/share/components/PlacementForm/form'
import { supportAreaMaster } from 'inheritance-utils'
import { useProfessionalCategories } from '@/rpc/professionalCategory/useSWR'
import { useGetSupportServiceMaster } from '@/rpc/supportServiceMaster/useSWR'
import { useAdditionalInfo } from '@/rpc/additionalInfo/useSWR'
import { useProfessionalSelf } from '@/rpc/professionalSelf/useSWR'
import { useProfessionalPlacementDefaults } from '@/rpc/professionalPlacement/useSWR'
import { placementApplicationStorageHandler } from '@/share/components/PlacementForm/functions/storage'
import { useUploadImageHandler, usePlacementApplication, UploadImage } from '@/share/components/PlacementForm/hooks'
import { PreviewHeaderWithActions } from '@/share/components/Preview/PreviewHeaderWithActions'
import { PlacementApplicationDetails } from 'inheritance-api'
import {
  PlacementFormProvider,
  buildPlacementFormState,
  usePlacementFormContext,
} from '@/share/components/contexts/PlacementFormProvider'

const styles = {
  fallbackRow: css`
    margin-top: 60px;
  `,
  editForm: (props: { hidden: boolean }) => css`
    display: ${props.hidden ? 'none' : 'block'};
  `,
}

const scrollToTop = () => window.scrollTo({ top: 0 })

type Props = {
  form: FormInstance<PlacementFormType>
  uploadImage: UploadImage
  uploadingImage: boolean
  pafv: PlacementApplicationDetails
}

const PlacementApplicationFormInner = ({ form, uploadImage, uploadingImage, pafv }: Props) => {
  const navigate = useNavigate()
  const { professional } = useProfessionalSelf()
  const { professionalCategories } = useProfessionalCategories()
  const { additionalInfo } = useAdditionalInfo(pafv.professionalCategory.code)
  const { supportServicesMaster } = useGetSupportServiceMaster(pafv.professionalCategory.code)
  const formStorage = placementApplicationStorageHandler()
  const { mutate, mutating } = usePlacementApplication({
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onSuccess: async () => {
      formStorage.remove()
      navigate(getPath('placementApplicationCompleted'))
    },
  })

  const [isConfirm, setIsConfirm] = useState(false)
  const itemGroups = useMemo(() => {
    if (!isConfirm) {
      return []
    }
    const values = form.getFieldsValue()
    return convertFormValuesToItemGroups(
      values,
      additionalInfo,
      supportServicesMaster,
      supportAreaMaster,
      professionalCategories,
      professional.contract.businessCategory
    )
  }, [
    isConfirm,
    form,
    additionalInfo,
    supportServicesMaster,
    professionalCategories,
    professional.contract.businessCategory,
  ])

  if (professional.placementApplication) {
    // 初回掲載申込ではない場合はエラー画面を表示
    return <Navigate to={getPath('placementApplicationExists')} />
  }

  const areaOption = composeAreaOption(supportAreaMaster)

  const formOptions = {
    professionalCategories: professionalCategories.map(({ name, code }) => ({ label: name, value: code })),
    additionalInfo: additionalInfo.map(({ code, description }) => ({ value: code, label: description })),
    supportServices: supportServicesMaster.map(({ code, name }) => ({ value: code, label: name })),
    areaOption,
  }

  const onNext = () => {
    setIsConfirm(true)
    scrollToTop()
  }

  const onBack = () => {
    setIsConfirm(false)
    scrollToTop()
  }

  const onSubmit = async () => {
    await mutate(form.getFieldsValue())
  }

  return (
    <>
      <section css={styles.editForm({ hidden: isConfirm })}>
        <EditForm
          form={form}
          formStorage={formStorage}
          onNext={onNext}
          onImageUpload={uploadImage}
          uploadingImage={uploadingImage}
          formOptions={formOptions}
          isUpdateView={false}
          businessCategory={professional.contract.businessCategory}
          loginEmailAddress={professional.contract.login.emailAddress}
          placementApplicationDefaults={pafv}
        />
      </section>
      {isConfirm && <ConfirmForm itemGroups={itemGroups} onBack={onBack} onSubmit={onSubmit} mutating={mutating} />}
    </>
  )
}

const PlacementApplicationFormInnerWrapper = ({
  pafv,
  isCorporation,
}: {
  pafv: PlacementApplicationDetails
  isCorporation: boolean
}) => {
  const [form] = Form.useForm<PlacementFormType>()
  const { uploadImage, loading: uploadingImage } = useUploadImageHandler({ form })
  const { hasSummaryCourtLegalRepresentation } = usePlacementFormContext()

  return (
    <AppLayout
      pageHeader={
        <PreviewHeaderWithActions
          form={form}
          loading={uploadingImage}
          professionalCategoryCode={pafv.professionalCategory.code}
          hasSummaryCourtLegalRepresentation={hasSummaryCourtLegalRepresentation}
          isCorporation={isCorporation}
        />
      }
    >
      <Suspense
        fallback={
          <Row css={styles.fallbackRow}>
            <Col offset={2} span={20}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </Row>
        }
      >
        <PlacementApplicationFormInner
          form={form}
          uploadImage={uploadImage}
          uploadingImage={uploadingImage}
          pafv={pafv}
        />
      </Suspense>
    </AppLayout>
  )
}

export const PlacementApplicationForm = () => {
  const { placementApplicationFormValue } = useProfessionalPlacementDefaults()
  // TODO: useProfessionalPlacementDefaults の必要性を検討（useProfessionalSelf の取得値に含まれるデータと重複している）
  const { professional } = useProfessionalSelf()
  return (
    <PlacementFormProvider
      state={buildPlacementFormState(placementApplicationFormValue.summaryCourtLegalRepresentationInputDefault)}
    >
      <PlacementApplicationFormInnerWrapper
        pafv={placementApplicationFormValue}
        isCorporation={professional.contract.businessCategory === 'corporation'}
      />
    </PlacementFormProvider>
  )
}
