import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { AntdThemeProvider } from 'inheritance-components/src/AntdThemeProvider'

import { SWRConfig } from 'swr'
import { RpcContextProvider } from './rpc/RpcContext'
import { AuthContextProvider } from './auth/AuthContext'
import { DesignTokenProvider } from './share/components/design-system/token'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SWRConfig
      value={{
        suspense: true,
      }}
    >
      <AntdThemeProvider>
        <DesignTokenProvider>
          <AuthContextProvider>
            <RpcContextProvider>
              <RouterProvider router={router} />
            </RpcContextProvider>
          </AuthContextProvider>
        </DesignTokenProvider>
      </AntdThemeProvider>
    </SWRConfig>
  </React.StrictMode>
)
