import moment from 'moment'

import {
  ContractApplication,
  ContractApplicationScreeningQuestion,
  ProfessionalCategory,
  ProfessionalCategoryCode,
  RepresentativeItem,
  InvitationCode,
} from 'inheritance-api'
import { getRules, RULES, EIGHT_BIT_STRING_MAX_LEN } from 'inheritance-components'
import {
  affiliationLabelFromString,
  removeSpace,
  splitByNewline,
  trueOrFalse,
  getScreeningQuestionLabel,
  TGetScreeningQuestionLabelParam,
  SCREENING_QUESTION,
  TBusinessCategory,
  BUSINESS_CATEOGRY_OPTION,
  PREFECTURE,
  supportAreaMaster,
  toProfessionalCategoryCode,
  toBusinessCategoryCode,
  trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank,
  getAffiliation,
  enableAffiliationOther,
} from 'inheritance-utils'
import {
  representativeFormNames,
  representativeFormNamesWithKey,
  RepresentativeFormType,
} from '@/share/components/RepresentativeForm/form'
import { Rule } from 'antd/lib/form'
// eslint-disable-next-line node/no-extraneous-import
import { Dayjs } from 'dayjs'

export const formNames = {
  professionalCategory: 'professionalCategory',
  businessCategory: 'businessCategory',
  corporateName: 'corporateName',
  corporateNameKana: 'corporateNameKana',
  dateOfEstablishment: 'dateOfEstablishment',

  /** 事務所情報 */
  corporatePostCode: {
    first: 'corporatePostCodeFirst',
    second: 'corporatePostCodeSecond',
  },

  prefecture: 'prefecture',
  city: 'city',
  otherArea: 'otherArea',
  townAndStreetAddress: 'townAndStreetAddress',
  corporateBuilding: 'corporateBuilding',
  corporateUrl: 'corporateUrl',
  affiliation: 'affiliation',
  affiliationOther: 'affiliationOther',

  // 代表者情報
  ...representativeFormNamesWithKey,

  /** 担当者情報 */
  contactSurname: 'contactSurname',
  contactGivenName: 'contactGivenName',
  contactSurnameKana: 'contactSurnameKana',
  contactGivenNameKana: 'contactGivenNameKana',
  contactEmailAddress: 'contactEmailAddress',
  contactPhoneNumber: 'contactPhoneNumber',
  loginEmailAddress: 'loginEmailAddress',
  loginPhoneNumber: 'loginPhoneNumber',
  note: 'note',
  termsOfUseAgreement: 'termsOfUseAgreement',
  privacyPolicy: 'privacyPolicy',

  /** 紹介コード */
  invitationCode: 'invitationCode',

  /** 確認事項 */
  ...SCREENING_QUESTION,
} as const

export const formProps = {
  [formNames.professionalCategory]: {
    name: formNames.professionalCategory,
    label: '専門家カテゴリー',
    rules: getRules([RULES.requireEntry]),
    placeholder: '未選択',
    guideText: '「税理士」「弁護士」「司法書士」から自社の専門カテゴリーを1つ選択してください。',
    colon: false,
  },

  [formNames.businessCategory]: {
    name: formNames.businessCategory,
    label: '業態',
    rules: getRules([RULES.requireEntry]),
    placeholder: '未選択',
    guideText: '「法人」「個人」から業態を1つ選択してください。',
    colon: false,
  },

  [formNames.corporateName]: {
    name: formNames.corporateName,
    label: '法人名・事務所名',
    rules: ({ proCategoryLabel, bizCategory }: { proCategoryLabel?: string; bizCategory?: TBusinessCategory }) => {
      const baseRules = getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()])

      if (bizCategory === 'corporation' && proCategoryLabel) {
        const additionalRule: Rule = {
          pattern: new RegExp(`${proCategoryLabel}法人`),
          message: `「${proCategoryLabel}法人」をつけてください。`,
        }
        return [...baseRules, additionalRule]
      }

      return baseRules
    },
    placeholder: ({
      proCategoryLabel,
      bizCategory,
    }: {
      proCategoryLabel?: string
      bizCategory?: TBusinessCategory
    }) => {
      if (proCategoryLabel && bizCategory === 'corporation') {
        return `${proCategoryLabel}法人〇〇事務所`
      }

      return '〇〇事務所'
    },
    guideText: '正式名称で入力してください。複数の事務所（支店）を持つ場合、法人名＋支店名で入力してください。',
    colon: false,
  },

  [formNames.corporateNameKana]: {
    name: formNames.corporateNameKana,
    label: '法人名・事務所名（カナ）',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.corporateNameKana, RULES.maxLen()]),
    placeholder: 'マルマルジムショ',
    annotation: '※全角カタカナ',
    guideText: '弁護士法人や税理士法人、司法書士法人など法人格も省略せずに入力してください。',
    colon: false,
  },

  [formNames.dateOfEstablishment]: {
    name: formNames.dateOfEstablishment,
    label: '設立年月日',
    rules: getRules([RULES.requireEntry]),
    placeholder: '1990/01/01',
  },

  /** 事務所情報 */
  corporatePostCode: {
    label: '郵便番号',
    first: {
      name: formNames.corporatePostCode.first,
      rules: getRules([RULES.requireEntry, RULES.postCodeFirst]),
      placeholder: '000',
      annotation: '※半角数字',
    },
    second: {
      name: formNames.corporatePostCode.second,
      rules: getRules([RULES.requireEntry, RULES.postCodeSecond]),
      placeholder: '0000',
    },
    annotation: '※半角数字',
  },

  [formNames.prefecture]: {
    name: formNames.prefecture,
    label: '住所1（都道府県）',
    rules: getRules([RULES.requireEntry]),
    placeholder: '東京都',
  },

  [formNames.city]: {
    name: formNames.city,
    label: '住所2（市区町村）',
    rules: getRules([RULES.requireEntry]),
    placeholder: '千代田区',
  },

  [formNames.otherArea]: {
    name: formNames.otherArea,
    label: '',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '〇〇町、XX村',
  },

  [formNames.townAndStreetAddress]: {
    name: formNames.townAndStreetAddress,
    label: '住所3（町名・番地）',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '丸の内1-2-3',
  },

  [formNames.corporateBuilding]: {
    name: formNames.corporateBuilding,
    label: '住所4（建物名）',
    rules: getRules([RULES.notBlank, RULES.maxLen()]),
    placeholder: '日本橋マンション101',
    guideText: '住所に建物名や部屋番号がある場合のみ、入力してください。',
    colon: false,
  },

  [formNames.corporateUrl]: {
    name: formNames.corporateUrl,
    label: 'ホームページURL',
    placeholder: 'https://example.com',
    rules: getRules([RULES.maxLen(), RULES.url]),
    normalize: (value: string) => value.trim(),
    guideText: '法人・個人事務所の公式ホームページのURLをhttp://またはhttps://から略さずに入力してください。',
    colon: false,
  },

  [formNames.affiliation]: {
    name: formNames.affiliation,
    label: '所属団体',
    placeholder: 'XXX弁護士会',
    rules: getRules([RULES.requireSelection]),
  },

  [formNames.affiliationOther]: {
    name: formNames.affiliationOther,
    label: '所属団体（自由記入）',
    placeholder: '',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
  },

  representativeName: {
    label: '代表者名',
    [representativeFormNames.representativeSurname]: {
      name: representativeFormNames.representativeSurname,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '山田',
    },
    [representativeFormNames.representativeGivenName]: {
      name: representativeFormNames.representativeGivenName,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '太郎',
    },
  },

  representativeNameKana: {
    label: '代表者名（カナ）',
    [representativeFormNames.representativeSurnameKana]: {
      name: representativeFormNames.representativeSurnameKana,
      placeholder: 'ヤマダ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
    [representativeFormNames.representativeGivenNameKana]: {
      name: representativeFormNames.representativeGivenNameKana,
      placeholder: 'タロウ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
  },

  representativeBirthDate: {
    name: representativeFormNames.representativeBirthDate,
    label: '代表者生年月日',
    placeholder: '1990/01/01',
    rules: getRules([RULES.requireEntry]),
  },

  representativePostCode: {
    label: '郵便番号',
    first: {
      name: representativeFormNames.representativePostCode.first,
      rules: getRules([RULES.requireEntry, RULES.postCodeFirst]),
      placeholder: '000',
    },
    second: {
      name: representativeFormNames.representativePostCode.second,
      rules: getRules([RULES.requireEntry, RULES.postCodeSecond]),
      placeholder: '0000',
    },
  },

  representativeAddress: {
    name: representativeFormNames.representativeAddress,
    label: '住所',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '東京都千代田区丸の内1-2-3',
  },

  representativeBuilding: {
    name: representativeFormNames.representativeBuilding,
    label: '建物名',
    rules: getRules([RULES.notBlank, RULES.maxLen()]),
    placeholder: '日本橋マンション101',
  },

  /** 担当者情報 */
  contactName: {
    label: '担当者名',
    first: {
      name: formNames.contactSurname,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '山田',
    },
    second: {
      name: formNames.contactGivenName,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '太郎',
    },
    guideText: '担当者が複数名いる場合には、実際にアカウント作成やアカウント運用を行う担当者の名前を入力してください。',
    colon: false,
  },

  contactNameKana: {
    label: '担当者名（カナ）',
    first: {
      name: formNames.contactSurnameKana,
      placeholder: 'ヤマダ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
      annotation: '※全角カタカナ',
    },
    second: {
      name: formNames.contactGivenNameKana,
      placeholder: 'タロウ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
  },

  [formNames.loginEmailAddress]: {
    name: formNames.loginEmailAddress,
    label: 'アカウント作成用メールアドレス',
    guideText: '申込の際に認証用に使用したメールアドレスになります。',
    colon: false,
  },

  [formNames.loginPhoneNumber]: {
    label: 'アカウント作成用携帯電話番号',
    name: formNames.loginPhoneNumber,
    rules: getRules([RULES.requireEntry, RULES.mobileTel]),
    placeholder: '090 1234 5678',
    annotation: '※ ハイフンなし 半角数字',
    guideText:
      '「そうぞくガイド 専門家サービス」のアカウント作成後、サービスにログインするために携帯電話のSMS（ショートメッセージサービス）を用いて二段階認証を行う必要があります。その際に利用するための携帯電話番号を入力してください。',
    colon: false,
  },

  [formNames.contactEmailAddress]: {
    name: formNames.contactEmailAddress,
    label: '銀行連絡用メールアドレス',
    rules: getRules([RULES.requireEntry, RULES.email, RULES.emailMaxLen]),
    placeholder: 'taro.yamada@xxx.com',
    guideText:
      'こちらのメールアドレスは、顧客からの問い合わせ用ではなく、銀行からの問い合わせ用になります。複数名で対応される場合には、info@やcontact@などの代表メールアドレスを利用することを推奨しています。',
    colon: false,
  },

  [formNames.contactPhoneNumber]: {
    name: formNames.contactPhoneNumber,
    label: '銀行連絡用電話番号',
    rules: getRules([RULES.requireEntry, RULES.tel]),
    placeholder: '090 1234 5678',
    annotation: '※ ハイフンなし 半角数字',
    guideText:
      'こちらの電話番号は、顧客からの問い合わせ用ではなく、銀行からの問い合わせ用になります。代表番号や固定番号がない場合には、担当者の携帯電話番号を入力してください。',
    colon: false,
  },

  [formNames.note]: {
    label: '備考',
    name: formNames.note,
    rules: getRules([RULES.notBlank]),
    placeholder: undefined,
    multiple: true,
    autoSize: { minRows: 3 },
    showCount: true,
    maxLength: EIGHT_BIT_STRING_MAX_LEN,
    guideText: 'その他、利用申込に当たって特記事項がある場合は入力してください。',
    colon: false,
  },

  /** 紹介コード */
  [formNames.invitationCode]: {
    name: formNames.invitationCode,
    label: '紹介コード',
    rules: getRules([RULES.maxLen(12), RULES.alphabetNumber]),
    placeholder: '',
  },

  // 利用規約
  [formNames.termsOfUseAgreement]: {
    rules: getRules([RULES.requireCheck]),
    name: formNames.termsOfUseAgreement,
  },
  // プライバシーポリシー
  [formNames.privacyPolicy]: {
    rules: getRules([RULES.requireCheck]),
    name: formNames.privacyPolicy,
  },

  // 確認事項
  [formNames.howManyInquiriesAnticipateFromUsersPerYear]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).howManyInquiriesAnticipateFromUsersPerYear,
      rules: getRules([RULES.requireEntry, RULES.requireNumber, RULES.maxNumber(1000000000)]),
      name: formNames.howManyInquiriesAnticipateFromUsersPerYear,
      column: false,
      suffix: '件',
    }
  },
  [formNames.howManyProfessionalsMoreThan3YearsOfExperience]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).howManyProfessionalsMoreThan3YearsOfExperience,
      rules: getRules([RULES.requireEntry, RULES.requireNumber, RULES.maxNumber(1000000000)]),
      name: formNames.howManyProfessionalsMoreThan3YearsOfExperience,
      column: false,
      suffix: '人',
    }
  },
  [formNames.howManyCasesHandledSoFar]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).howManyCasesHandledSoFar,
      rules: getRules([RULES.requireEntry, RULES.requireNumber, RULES.maxNumber(1000000000)]),
      name: formNames.howManyCasesHandledSoFar,
      column: false,
      suffix: '件',
    }
  },
  [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).isNotUndertakenAnyLitigationAgainstMUFGBank,
      rules: getRules([RULES.requireEntry]),
      name: formNames.isNotUndertakenAnyLitigationAgainstMUFGBank,
      items: trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank,
    }
  },
  [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).isIntendedToHandleSummaryCourtLegalRepresentation,
      rules: getRules([RULES.requireEntry]),
      name: formNames.isIntendedToHandleSummaryCourtLegalRepresentation,
      items: trueOrFalse,
      column: false,
    }
  },
  [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]: (param: TGetScreeningQuestionLabelParam) => {
    return {
      label: getScreeningQuestionLabel(param).isBusinessOpenOverseasUnderNameOfAdvertiser,
      rules: getRules([RULES.requireEntry]),
      name: formNames.isBusinessOpenOverseasUnderNameOfAdvertiser,
      items: trueOrFalse,
      column: false,
    }
  },
}

export type ContractFormType = {
  [formNames.professionalCategory]: string
  [formNames.businessCategory]: string
  [formNames.corporateName]: string
  [formNames.corporateNameKana]: string
  [formNames.dateOfEstablishment]: Dayjs
  [formNames.corporatePostCode.first]: string
  [formNames.corporatePostCode.second]: string
  [formNames.prefecture]: string
  [formNames.city]: string
  [formNames.otherArea]?: string
  [formNames.townAndStreetAddress]: string
  [formNames.corporateBuilding]?: string
  [formNames.corporateUrl]?: string
  [formNames.affiliation]: string
  [formNames.affiliationOther]: string
  [formNames.contactSurname]: string
  [formNames.contactGivenName]: string
  [formNames.contactSurnameKana]: string
  [formNames.contactGivenNameKana]: string
  [formNames.contactEmailAddress]: string
  [formNames.contactPhoneNumber]: string
  [formNames.loginEmailAddress]: string
  [formNames.loginPhoneNumber]: string
  [formNames.note]?: string
  [formNames.invitationCode]?: string
  [formNames.howManyInquiriesAnticipateFromUsersPerYear]: number
  [formNames.howManyProfessionalsMoreThan3YearsOfExperience]: number
  [formNames.howManyCasesHandledSoFar]: number
  [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]: boolean
  [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]?: boolean
  [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]?: boolean
} & RepresentativeFormType

type Item = {
  label: string
  value: string
}

export type ItemGroup = {
  title: string
  items: Item[]
}

const dateFormats = {
  display: 'YYYY/M/D',
  request: 'YYYY-MM-DD',
} as const

export const convertFormValuesToItemGroups = (
  form: ContractFormType,
  professionalCategories: ProfessionalCategory[]
): ItemGroup[] => {
  const professionalCategory = professionalCategories.find((category) => category.code === form.professionalCategory)
  const businessCategory = BUSINESS_CATEOGRY_OPTION.find((b) => b.value === form.businessCategory)
  if (!professionalCategory) throw 'unknown professional category'
  if (!businessCategory) throw 'unknown business category'

  return [
    {
      title: '事務所情報',
      items: [
        {
          label: formProps.professionalCategory.label,
          value: professionalCategory.name,
        },
        {
          label: formProps.businessCategory.label,
          value: businessCategory.label,
        },
        {
          label: formProps.corporateName.label,
          value: form.corporateName,
        },
        {
          label: formProps.corporateNameKana.label,
          value: form.corporateNameKana,
        },
        {
          label: formProps.dateOfEstablishment.label,
          value: form.dateOfEstablishment.format(dateFormats.display),
        },
        {
          label: formProps.corporatePostCode.label,
          value: `${form.corporatePostCodeFirst} ${form.corporatePostCodeSecond}`,
        },
        getOfficeAddressItem(form),
        {
          label: formProps.corporateBuilding.label,
          value: form.corporateBuilding ?? '',
        },
        {
          label: formProps.corporateUrl.label,
          value: form.corporateUrl ?? '',
        },
        ...getAffiliationItem(form, professionalCategory.code),
      ],
    },
    getRepresentativeItemGroup(form),
    {
      title: '担当者情報',
      items: [
        {
          label: formProps.contactName.label,
          value: `${form.contactSurname} ${form.contactGivenName}`,
        },
        {
          label: formProps.contactNameKana.label,
          value: `${form.contactSurnameKana} ${form.contactGivenNameKana}`,
        },
        { label: formProps.loginEmailAddress.label, value: form.loginEmailAddress },
        { label: formProps.loginPhoneNumber.label, value: form.loginPhoneNumber },
        { label: formProps.contactEmailAddress.label, value: form.contactEmailAddress },
        { label: formProps.contactPhoneNumber.label, value: form.contactPhoneNumber },
        { label: formProps.note.label, value: form.note ?? '' },
      ],
    },
    {
      title: '紹介コード',
      items: [
        {
          label: formProps.invitationCode.label,
          value: form.invitationCode ?? '',
        },
      ],
    },
    {
      title: '確認事項',
      items: getScreeningQuestionitem(form, businessCategory.value),
    },
  ]
}

const getOfficeAddressItem = (form: ContractFormType): Item => {
  const prefectureName = PREFECTURE.find((p) => p.code.slice(-2) === form.prefecture)?.name
  if (!prefectureName) throw 'prefecture item not found'
  const cityName = supportAreaMaster.find((s) => s.areaCode === form.city)?.areaName
  if (!cityName) throw 'city item not found'
  const cityString = form.otherArea ?? cityName

  const officeAddressString = prefectureName + cityString + form.townAndStreetAddress
  return {
    label: '法人住所',
    value: officeAddressString,
  }
}

const getAffiliationItem = (form: ContractFormType, professionalCategoryCode: ProfessionalCategoryCode): Item[] => {
  const label = affiliationLabelFromString(professionalCategoryCode)

  if (enableAffiliationOther(form.affiliation)) {
    return [
      {
        label,
        value: form.affiliation,
      },
      {
        label: `${label}（自由記入）`,
        value: form.affiliationOther,
      },
    ]
  }

  return [
    {
      label,
      value: form.affiliation,
    },
  ]
}

const getScreeningQuestionitem = (form: ContractFormType, businessCategory: TBusinessCategory): Item[] => {
  const screeningQuestionLabel = getScreeningQuestionLabel({ bizCategory: businessCategory, isForm: false })
  const isNotUndertakenAnyLitigationAgainstMUFGBank = trueOrFalseIsNotUndertakenAnyLitigationAgainstMUFGBank.find(
    (y) => y.value === form.isNotUndertakenAnyLitigationAgainstMUFGBank
  )
  if (isNotUndertakenAnyLitigationAgainstMUFGBank === undefined) {
    throw 'undefined isNotUndertakenAnyLitigationAgainstMUFGBank value'
  }

  const items: Item[] = [
    {
      label: screeningQuestionLabel[formNames.howManyInquiriesAnticipateFromUsersPerYear],
      value: form.howManyInquiriesAnticipateFromUsersPerYear + '件',
    },
    {
      label: screeningQuestionLabel[formNames.howManyProfessionalsMoreThan3YearsOfExperience],
      value: form.howManyProfessionalsMoreThan3YearsOfExperience + '人',
    },
    {
      label: screeningQuestionLabel[formNames.howManyCasesHandledSoFar],
      value: form.howManyCasesHandledSoFar + '件',
    },
    {
      label: screeningQuestionLabel[formNames.isNotUndertakenAnyLitigationAgainstMUFGBank],
      value: isNotUndertakenAnyLitigationAgainstMUFGBank.label,
    },
  ]
  if (form.professionalCategory === 'judicialScrivener') {
    items.push({
      label: screeningQuestionLabel[formNames.isIntendedToHandleSummaryCourtLegalRepresentation],
      value: trueOrFalse.find((y) => y.value === form.isIntendedToHandleSummaryCourtLegalRepresentation)?.label ?? '',
    })
  }
  if (form.businessCategory === 'individual') {
    items.push({
      label: screeningQuestionLabel[formNames.isBusinessOpenOverseasUnderNameOfAdvertiser],
      value: trueOrFalse.find((y) => y.value === form.isBusinessOpenOverseasUnderNameOfAdvertiser)?.label ?? '',
    })
  }
  return items
}

const getRepresentativeItemGroup = (form: ContractFormType): ItemGroup => {
  return {
    title: '代表者情報',
    items: getRepresentativeFormValue(form)
      .map((v, i) => {
        const items: Item[] = []
        if (i === 0) {
          items.push({ label: formProps.representativePostCode.label, value: v.address?.postCode ?? '' })
          items.push({ label: formProps.representativeAddress.label, value: v.address?.address ?? '' })
          items.push({ label: formProps.representativeBuilding.label, value: v.address?.building ?? '' })
        }

        items.push({ label: formProps.representativeName.label, value: `${v.name.surname} ${v.name.givenName}` })
        items.push({
          label: formProps.representativeNameKana.label,
          value: `${v.name.surnameKana} ${v.name.givenNameKana}`,
        })

        if (i === 0) {
          items.push({
            label: formProps.representativeBirthDate.label,
            value: v.birthDate ? moment(v.birthDate).format(dateFormats.display) : '',
          })
        }

        return items
      })
      .flat(),
  }
}

const getRepresentativeFormValue = (form: ContractFormType): RepresentativeItem[] => {
  const formKeys = Object.keys(form)
    .filter((e) => e.startsWith(representativeFormNames.representativeSurnameKana))
    .map((str) => str.replace(representativeFormNames.representativeSurnameKana, ''))

  return formKeys.map((key, index) => {
    const isAppendixRepresentative = index !== 0

    // @ts-expect-error appendix form key
    const rowBirthDate: moment.Moment | undefined = form[`${representativeFormNames.representativeBirthDate}${key}`]
    const birthDate = rowBirthDate?.format(dateFormats.request)

    return {
      name: {
        // @ts-expect-error appendix form key
        surname: `${form[`${representativeFormNames.representativeSurname}${key}`]}`,
        // @ts-expect-error appendix form key
        givenName: `${form[`${representativeFormNames.representativeGivenName}${key}`]}`,
        // @ts-expect-error appendix form key
        surnameKana: `${form[`${representativeFormNames.representativeSurnameKana}${key}`]}`,
        // @ts-expect-error appendix form key
        givenNameKana: `${form[`${representativeFormNames.representativeGivenNameKana}${key}`]}`,
      },
      birthDate,
      address: isAppendixRepresentative
        ? undefined
        : {
            // @ts-expect-error appendix form key
            postCode: `${form[`${representativeFormNames.representativePostCode.first}${key}`]}${
              // @ts-expect-error appendix form key
              form[`${representativeFormNames.representativePostCode.second}${key}`]
            }`,
            // @ts-expect-error appendix form key
            address: form[`${representativeFormNames.representativeAddress}${key}`],
            // @ts-expect-error appendix form key
            building: form[`${representativeFormNames.representativeBuilding}${key}`],
          },
    }
  })
}

export const composeContractApplicationReqModel = (
  form: ContractFormType
): {
  contractApplication: ContractApplication
  screeningQuestion: ContractApplicationScreeningQuestion
  invitationCode?: InvitationCode
} => {
  const professionalCategory = toProfessionalCategoryCode(form.professionalCategory)
  if (!professionalCategory) throw 'unknown professional category'
  const businessCategory = toBusinessCategoryCode(form.businessCategory)
  if (!businessCategory) throw 'unknown business category'
  const prefecture = PREFECTURE.find((p) => p.code.slice(-2) === form.prefecture)
  if (!prefecture) throw 'prefecture item not found'
  const city = supportAreaMaster.find((s) => s.areaCode === form.city)
  if (!city) throw 'city item not found'

  return {
    contractApplication: {
      professionalCategory,
      businessCategory,
      corporate: {
        name: form.corporateName,
        nameKana: form.corporateNameKana,
        dateOfEstablishment: form.dateOfEstablishment.format('YYYY-MM-DD'),
        officeAddress: {
          postCode: form.corporatePostCodeFirst + form.corporatePostCodeSecond,
          prefecture: {
            prefectureCode: form.prefecture,
            prefectureName: prefecture.name,
          },
          city: {
            areaCode: city.areaCode,
            areaName: city.areaName,
            otherAreaName: form.otherArea,
          },
          townAndStreetAddress: form.townAndStreetAddress,
          building: form.corporateBuilding || undefined,
        },
        url: form.corporateUrl || undefined,
        affiliation: getAffiliation(form.affiliation, form.affiliationOther),
      },
      representatives: getRepresentativeFormValue(form).map((v) => {
        return {
          name: v.name,
          birthDate: v.birthDate,
          address: v.address,
        }
      }),
      contact: {
        name: {
          surname: form.contactSurname,
          givenName: form.contactGivenName,
          surnameKana: form.contactSurnameKana,
          givenNameKana: form.contactGivenNameKana,
        },
        emailAddress: form.contactEmailAddress,
        phoneNumber: removeSpace(form.contactPhoneNumber),
      },
      login: {
        emailAddress: form.loginEmailAddress,
        phoneNumber: removeSpace(form.loginPhoneNumber),
      },
      note: form.note ? splitByNewline(form.note) : undefined,
    },
    screeningQuestion: {
      howManyInquiriesAnticipateFromUsersPerYear: Number(form.howManyInquiriesAnticipateFromUsersPerYear),
      howManyProfessionalsMoreThan3YearsOfExperience: Number(form.howManyProfessionalsMoreThan3YearsOfExperience),
      howManyCasesHandledSoFar: Number(form.howManyCasesHandledSoFar),
      isNotUndertakenAnyLitigationAgainstMUFGBank: form.isNotUndertakenAnyLitigationAgainstMUFGBank,
      isIntendedToHandleSummaryCourtLegalRepresentation: form.isIntendedToHandleSummaryCourtLegalRepresentation,
      isBusinessOpenOverseasUnderNameOfAdvertiser: form.isBusinessOpenOverseasUnderNameOfAdvertiser,
    },
    invitationCode: form.invitationCode || undefined,
  }
}
