import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'
import { GetProfessionalSelfResponse } from 'inheritance-api'

export type UseProfessionalSelf = {
  refetch: () => Promise<void>
  professional: GetProfessionalSelfResponse
}

export const useProfessionalSelf = (): UseProfessionalSelf => {
  const { callGetProfessionalSelf } = useRpcContext()
  const { data, error, mutate } = useSWR(SWR_KEYS.professional, () => callGetProfessionalSelf())
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return {
    ...data,
    refetch: async () => {
      await mutate(undefined)
    },
  }
}
