import { css } from '@emotion/react'
import { Typography } from 'antd'
import { Wave } from './Wave'
import sp_sample from '@/assets/lp/sp_sample.png'
import icon_diagnosis from '@/assets/lp/icon_diagnosis.svg'
import icon_task from '@/assets/lp/icon_task.svg'
import icon_search from '@/assets/lp/icon_search.svg'
import { useLandingPageDesignToken } from './design-system/useLandingPageDesignToken'

const useIntroductionStyles = () => {
  const { color, mediaQuery } = useLandingPageDesignToken()

  return {
    wrap: css`
      position: relative;
      text-align: center;
      height: 550px;
      padding: 100px 0 48px;
      box-sizing: border-box;
      overflow: hidden;
      ${mediaQuery.small} {
        height: 830px;
      }
    `,
    content: css`
      display: flex;
      justify-content: center;
      gap: 50px;
      ${mediaQuery.small} {
        flex-direction: column;
      }
    `,
    heading: {
      text: css`
        font-size: 16px;
        line-height: 26px;
        font-weight: bold;
      `,
      title: css`
        font-size: 22px;
        line-height: 36px;
        font-weight: bold;
        margin: 4px 0 18px;
        em {
          color: ${color.accent.red.default};
        }
      `,
    },
    feature: {
      title: css`
        display: block;
        color: ${color.text.black02};
        font-size: 16px;
        line-height: 26px;
        font-weight: bold;
        margin-bottom: 12px;
      `,
      icons: css`
        display: flex;
        justify-content: center;
        gap: 40px;
      `,
    },
    element: {
      wave2: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      `,
      wave3: css`
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      `,
    },
    spSample: css`
      width: 224px;
    `,
  }
}

export const Introduction = () => {
  const styles = useIntroductionStyles()

  return (
    <div css={styles.wrap}>
      <Wave type="purple" rootCss={styles.element.wave2} />

      <div css={styles.content}>
        <div>
          <Typography.Text css={styles.heading.text}>そうぞくガイドでは</Typography.Text>
          <h2 css={styles.heading.title}>
            相続対策や相続手続でお悩みの方が
            <br />
            <em>弁護士・税理士・司法書士</em>を<em>検索</em>できます。
          </h2>
          <Typography.Text css={styles.feature.title}>そうぞくガイドの3つの機能</Typography.Text>
          <div css={styles.feature.icons}>
            <Feature
              icon={icon_diagnosis}
              label="そうぞく診断"
              text="いくつかの質問に答えるだけで、生前に対策しておいた方がいいこと/逝去後の相続手続をご案内します。"
            />
            <Feature
              icon={icon_task}
              label="やることリスト"
              text="そうぞく診断の結果をリスト化し、相続対策/相続手続の「やること」の管理ができます。"
            />
            <Feature
              icon={icon_search}
              label="専門家検索"
              text="相続をサポートする専門家を検索し、直接お問い合わせできます。"
            />
          </div>
        </div>

        <div>
          <img css={styles.spSample} src={sp_sample} />
        </div>
      </div>
      <Wave type="gray" rootCss={styles.element.wave3} />
    </div>
  )
}

const useSFeaturetyle = () => {
  const { color } = useLandingPageDesignToken()

  return {
    wrap: css`
      width: 100px;
    `,
    icon: css`
      width: 100%;
      text-align: center;
      border-radius: 16px;
      padding: 12px 0 2px;
      margin-bottom: 12px;
      box-sizing: border-box;
    `,
    image: css`
      display: inline-block;
      margin: 0 auto;
    `,
    label: css`
      display: block;
      font-size: 12px;
      line-height: 22px;
      font-weight: bold;
    `,
    text: css`
      color: ${color.text.black02};
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    `,
  }
}

const Feature = (prop: { icon: string; label: string; text: string }) => {
  const style = useSFeaturetyle()

  return (
    <div css={style.wrap}>
      <div css={style.icon}>
        <img css={style.image} src={prop.icon} />
        <span css={style.label}>{prop.label}</span>
      </div>
      <p css={style.text}>{prop.text}</p>
    </div>
  )
}
