import { Tag } from 'antd'
import { css } from '@emotion/react'

export const FormLabel = (props: { label: string; required?: boolean; labelHidden?: boolean }) => {
  const style = {
    label: css`
      display: flex;
      align-items: flex-start;
    `,
    tag: css`
      opacity: ${props.labelHidden ? 0 : 1};
    `,
  }
  return (
    <label css={style.label}>
      {props.required ? (
        <Tag color="red" css={style.tag}>
          必須
        </Tag>
      ) : (
        <Tag css={style.tag}>任意</Tag>
      )}
      {props.label}
    </label>
  )
}
