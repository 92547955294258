import { css } from '@emotion/react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { UserVisitData } from 'inheritance-api'
import { useProAndMgmtDesignToken } from '../design-system/token'

const useStyles = () => {
  const { themeColor } = useProAndMgmtDesignToken()

  return {
    description: css`
      text-align: center;
      margin: 10px 0 20px;
      p {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
        margin: 0;
        background: ${themeColor.background.red};
        border: 1px solid ${themeColor.border.red};
        border-radius: 2px;
      }
    `,
  }
}

interface AccessInfoDataType {
  key: number
  term: string
  pageView: number
  uniqueUser: number
  contact: number
}

export const accessInfoColumns: ColumnsType<AccessInfoDataType> = [
  {
    title: '期間',
    dataIndex: 'term',
  },
  {
    title: 'PV',
    dataIndex: 'pageView',
  },
  {
    title: 'UU',
    dataIndex: 'uniqueUser',
  },
  {
    title: '問い合わせ',
    dataIndex: 'contact',
  },
]

export const AccessInformationTable = ({ userVisitData }: { userVisitData: UserVisitData[] }) => {
  const accessInformation: AccessInfoDataType[] = userVisitData.map((item, index) => ({
    key: index,
    ...item,
  }))
  const styles = useStyles()

  return (
    <>
      <div css={styles.description}>
        <p>先月までのアクセス情報を表示しています。月初はデータ反映にお時間いただく場合がございます。</p>
      </div>
      <Table columns={accessInfoColumns} dataSource={accessInformation} pagination={false} />
    </>
  )
}
