import { SingleTextInputForm } from 'inheritance-components'
import { SelectForm } from '../form/SelectForm'
import { formProps } from './form'
import { css } from '@emotion/react'
import { useDesignToken } from '../design-system/token'
import React from 'react'
import { CONSULTATION_FEE_TYPE_OPTIONS, isConsultationFeeTypeTimeLimit } from 'inheritance-utils'
import { ConsultationFeeType } from 'inheritance-api'

type ConsultationFeeFormProps = {
  initialConsultationFeeType?: ConsultationFeeType
}

export const ConsultationFeeForm = ({ initialConsultationFeeType }: ConsultationFeeFormProps) => {
  const style = useStyle()
  const [visibilityTimeLimit, setVisibilityTimeLimit] = React.useState(
    (initialConsultationFeeType && isConsultationFeeTypeTimeLimit(initialConsultationFeeType)) || false
  )
  return (
    <>
      {/* 相談料 */}
      <SelectForm
        {...formProps['consultationFee']['consultationFeeType']}
        items={CONSULTATION_FEE_TYPE_OPTIONS}
        onSelect={(e) => setVisibilityTimeLimit(isConsultationFeeTypeTimeLimit(e))}
      />

      {/* 初回相談無料（時間制限あり）の場合: 初回相談の時間 */}
      {visibilityTimeLimit && (
        <SingleTextInputForm
          {...formProps['consultationFee']['consultationFreeTimeLimit']}
          rootCss={style.textareaItem}
        />
      )}

      {/* 相談料に関する備考 */}
      <SingleTextInputForm {...formProps['consultationFee']['consultationFeeNote']} rootCss={style.textareaItem} />
    </>
  )
}

const useStyle = () => {
  const { spacing } = useDesignToken()
  return {
    textareaItem: css`
      margin-top: ${spacing(5)};
    `,
  }
}
