import { useCallback, useState } from 'react'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { PasswordResetEntryLayout, PASSWORD_RESET_ENTRY_TITLE } from 'inheritance-components'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { getPath } from '@/router'
import { useQueryParamsDefinitely } from 'inheritance-router'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '@/auth/AuthContext'
import { useRpcContext } from '@/rpc/RpcContext'
import { Button, notification } from 'antd'
import { RecaptchaV2Invisible, getRecaptchaToken, RecaptchaCallbackArg } from 'inheritance-recaptcha'
import { ENV } from '@/config/environment'
import { css } from '@emotion/react'
import { useHandleRecaptchaError } from '@/share/recaptcha/hook'
import { AxiosError } from 'axios'

const styles = {
  recaptcha: css`
    position: fixed;
    top: 100px;
    left: 0;
  `,
}

const useProfessionalPasswordResetConfirm = ({
  passwordResetToken,
  onSuccess,
}: {
  passwordResetToken: string
  onSuccess: () => void
}) => {
  const { callPutProfessionalPasswordResetConfirm } = useRpcContext()
  const [mutating, setMutating] = useState(false)

  const mutate = async ({ password, recaptchaToken }: { password: string; recaptchaToken: string }) => {
    setMutating(true)
    const { error } = await callPutProfessionalPasswordResetConfirm({
      password,
      passwordResetToken,
      recaptchaToken,
    })
    setMutating(false)

    if (error) {
      if (error instanceof AxiosError && error.response && error.response.status === 401) {
        const key = `notification_${Date.now()}`
        notification.open({
          key,
          message: 'パスワード設定に失敗しました',
          description: '認証情報の取得に失敗しました。パスワード再設定を最初からやり直して下さい。',
          placement: 'top',
          btn: (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                notification.destroy(key)
                window.location.href = getPath('passwordResetRequest')
              }}
            >
              パスワード再設定を最初からやり直す
            </Button>
          ),
          duration: 10,
        })
      } else {
        notification.open({
          message: 'パスワード設定に失敗しました',
          description: 'パスワード条件を確認した上で、再度入力してください。',
          placement: 'top',
        })
      }
      return
    }

    onSuccess()
  }

  return { mutating, mutate }
}

export const PasswordResetConfirm = () => {
  const navigate = useNavigate()
  const { passwordResetToken } = useQueryParamsDefinitely({
    keys: ['passwordResetToken'],
    errorWhenFailed: new Error('無効なURLです'),
  })
  const { getAuthenticator } = useAuthContext()
  const authenticator = getAuthenticator()
  if (!authenticator) throw new Error('failed to get authenticator')

  const { handleRecaptchaError } = useHandleRecaptchaError()
  const [formValue, setFormValue] = useState<{ password: string }>({
    password: '',
  })

  const onSuccess = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authenticator.signOut()
    navigate(getPath('passwordResetComplete'), { state: { isCompleted: true } })
  }, [navigate, authenticator])

  const onSubmit = async (password: string) => {
    setFormValue({ password })
    const token = await getRecaptchaToken()
    if (!token) return
    await mutate({ password, recaptchaToken: token })
  }

  const { mutate, mutating } = useProfessionalPasswordResetConfirm({ passwordResetToken, onSuccess })

  const recaptchaCallback = async (arg: RecaptchaCallbackArg) => {
    if (arg.type === 'verified') {
      await mutate({ password: formValue.password, recaptchaToken: arg.token })
      return
    }
    await handleRecaptchaError(arg.error)
  }

  return (
    <AppLayout>
      <PasswordResetEntryLayout
        header={<ContentTitleHeader title={PASSWORD_RESET_ENTRY_TITLE} />}
        onFinish={(value) => onSubmit(value.password)}
        loading={mutating}
      />
      <div css={styles.recaptcha}>
        <RecaptchaV2Invisible callback={recaptchaCallback} siteKey={ENV.RECAPTCHA_SITE_KEY} />
      </div>
    </AppLayout>
  )
}
