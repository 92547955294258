/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetApiVersionResponse {
  /**
   * APIバージョン（spec.yamlファイルのハッシュをとって生成）
   * @example "a676cb89094bc5cd6883d5bb0d0bb3eabbbd4068"
   */
  apiVersion: string
}

export interface InquiryFormValue {
  /**
   * ご意見のカテゴリー
   * @example "やることリストについて"
   */
  category: string
  /** ご意見。全角換算で1024文字以内 */
  opinion: MultiLineString
  /**
   * お住まいの都道府県
   * @example "東京都"
   */
  prefecture?: string
  /**
   * 性別
   * @example "男性"
   */
  gender?: string
  /**
   * 年代
   * @example "50歳代"
   */
  age?: string
}

export interface PutUserInquiryRequest {
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
  formValue: InquiryFormValue
}

export type PutUserInquiryResponse = object

export interface ErrorResponse {
  error: string
}

export type PostUserErrorRequest = Record<string, object>

export type PostProfessionalErrorRequest = Record<string, object>

export type PostManagementErrorRequest = Record<string, object>

export type PostUserErrorResponse = object

export type PostProfessionalErrorResponse = object

export type PostManagementErrorResponse = object

export interface GetUserMastersResponse {
  additionalInfo: AdditionalInfo[]
  supportServices: SupportService[]
}

export interface PutUserDiagRequest {
  version: string
  /** 逝去後フラグ(true 逝去後, false 生前) */
  isPostMortem: boolean
  values: Record<string, UserDiagItem>
}

export type UserDiagItem = string | string[]

export interface PutUserDiagStateRequest {
  preMortemVersion?: string
  postMortemVersion?: string
  diagnosis?: object
}

export type PutUserDiagResponse = object

export type PutUserDiagStateResponse = object

export interface GetUserDiagStateResponse {
  preMortemVersion?: string | null
  postMortemVersion?: string | null
  diagnosis?: object
}

/** @format binary */
export type GetManagementUserDiagCsvResponse = File

/** @format binary */
export type GetManagementUserTasksCsvResponse = File

export interface PutUserTasksRequest {
  version: string
  /** 逝去後フラグ(true 逝去後, false 生前) */
  isPostMortem: boolean
  tasks: Task
}

export interface PutUserTasksStateRequest {
  preMortemVersion?: string
  postMortemVersion?: string
  taskList?: object
}

export type PutUserTasksResponse = object

export type PutUserTasksStateResponse = object

export interface GetUserTasksStateResponse {
  preMortemVersion?: string | null
  postMortemVersion?: string | null
  taskList?: object
}

export type PutUserTasksCheckedIdsRequest = string[]

export type PutUserTasksCheckedIdsResponse = object

export type GetUserTasksCheckedIdsResponse = string[]

export interface PostProfessionalRequest {
  category: ProfessionalCategoryCode
  areas: AreaCode[]
  supports: SupportServiceCode[]
  additionalInfo: AdditionalInfoCode[]
  start?: number
}

export interface PostProfessionalResponse {
  count?: number
  results: PublicProfessionalListItem[]
  nextStart?: number
}

export interface PutContactRequest {
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
  /** 連絡方法 */
  methodOfContact: 'mail' | 'phone'
  /** 名前 */
  customerName: string
  /** カナ */
  customerNameKana: string
  /** メールアドレス */
  customerEmail?: string
  /** 電話番号 */
  customerPhone?: string
  /** 相談内容 */
  supports: string[]
  /** 診断内容の送信の同意 */
  sendDiagnosticAnswersAgreement?: boolean
  /** 相続診断回答内容（生前） */
  preMortemDiagnosticsAnswers?: ContactDiagAnswers[]
  /** 相続診断回答内容（逝去後） */
  postMortemDiagnosticsAnswers?: ContactDiagAnswers[]
  /** 相談内容詳細 */
  message?: MultiLineString
}

export interface ContactDiagAnswers {
  name: string
  value: string[]
}

export type PutContactResponse = object

export interface GetProfessionalPublicStatusResponse {
  /**
   * 公開情報ID
   * @example 1
   */
  publicId: number
  /**
   * 問い合わせ受付停止状態になっているか
   * @example false
   */
  contactSuspended: boolean
}

export type ProfessionalCategoryCode = 'lawyer' | 'taxCounselor' | 'judicialScrivener'

/** 付加情報マスタ取得レスポンス */
export type GetMasterAdditionalInfoResponse = AdditionalInfo[]

/** 利用申込リクエスト */
export interface PostProfessionalContractRequest {
  /**
   * @maxLength 254
   * @example "hoge@example.com"
   */
  emailAddress: string
  'g-recaptcha-response'?: string
}

/** 利用申込リクエスト */
export interface PutProfessionalContractRequest {
  /** 検証情報 */
  validation: ContractApplicationValidation
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
  /** 利用申込APIに渡される利用申込情報 */
  contractApplication: ContractApplication
  /** 利用申込の広告主審査向け質問項目 */
  screeningQuestion: ContractApplicationScreeningQuestion
  /**
   * 紹介コード
   *
   * 紹介コードは銀行にて作成し専門家が利用申込の際に入力する。（システム的には生成しない）
   * （半角英数字最大12文字）
   */
  invitationCode?: InvitationCode
}

/** 利用申込取得レスポンス */
export interface GetManagementContractResponse {
  /** 利用申込の取得APIにより返される利用申込情報 */
  application: ContractApplicationDetails
  /**
   * 利用申込の広告主審査向け質問項目（レスポンス用）
   *
   * 質問項目はすべて回答されているとは限らないためrequiredになりません。
   */
  screeningQuestion: ContractApplicationScreeningQuestionDetails
  /**
   * 紹介コード
   *
   * 紹介コードは銀行にて作成し専門家が利用申込の際に入力する。（システム的には生成しない）
   * （半角英数字最大12文字）
   */
  invitationCode?: InvitationCode
  /**
   * ステータス履歴
   *
   * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
   */
  statusHistory: ContractApplicationStatusHistoryItem[]
  /** 次に遷移可能なステータス */
  nextAvailableStatuses: ContractApplicationStatusCode[]
  /** 申込サマリ */
  summary: IncompleteApplicationListItem
}

/**
 * 利用申込ステータス変更リクエスト
 *
 * コメントは審査NGの場合は必須で指定すること。
 */
export interface PutManagementContractStatusRequest {
  /** 変更ステータス */
  status: ContractApplicationStatusCode
  /**
   * ステータス変更コメント
   * @maxLength 255
   */
  comment?: MultiLineString
}

/** 専門家情報 (管理ポータル向け) */
export interface GetManagementProfessionalResponse {
  accountCreated: boolean
  /** 解約済み */
  churned: boolean
  /** 利用申込 */
  contract: ContractApplicationDetails
  /**
   * 利用申込の広告主審査向け質問項目（レスポンス用）
   *
   * 質問項目はすべて回答されているとは限らないためrequiredになりません。
   */
  screeningQuestion: ContractApplicationScreeningQuestionDetails
  /**
   * 紹介コード
   *
   * 紹介コードは銀行にて作成し専門家が利用申込の際に入力する。（システム的には生成しない）
   * （半角英数字最大12文字）
   */
  invitationCode?: InvitationCode
  /** 公開済み掲載申込み */
  publicInformation?: PublicInformation
  /** 公開停止履歴 */
  suspensionHistory: SuspensionHistory
  /** 直近の掲載申込み */
  placementApplication?: {
    /** 掲載申込み回数 */
    count: number
    /** 提出済みの掲載申込情報 */
    content: PlacementApplicationDetails
    /**
     * ステータス履歴
     *
     * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
     */
    statusHistory: PlacementApplicationStatusHistory
  }
  /** 初回の審査OKになった掲載申込 */
  firstAcceptedPlacementApplication?: {
    /**
     * ステータス履歴
     *
     * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
     */
    statusHistory: PlacementApplicationStatusHistory
  }
  churnApplication?: {
    /** 解約申込 */
    content: ChurnApplicationDetails
  }
  /** 強制解約情報 */
  forceChurn?: {
    /** 掲載終了日 */
    endDate: Date
    /** 強制解約を設定した業務担当者 */
    operatorEmailAddress: string
    /** 強制解約操作日時 */
    forceChurnAt: UnixEpochTimeMS
  }
  /** 問い合わせ受付停止状態 */
  contactSuspended: boolean
}

/** 専門家認証情報更新 */
export interface PutManagementProfessionalLoginRequest {
  /** ログイン用メールアドレス */
  loginEmailAddress?: string
  /** MFA電話番号 */
  mfaPhoneNumber?: string
}

export interface PutManagementProfessionalLogin409ErrorResponse {
  /** エラー内容 */
  error?: {
    /** 登録済みのメールアドレス */
    duplicatedEmailAddress?: boolean
    /** 登録済みの電話番号 */
    duplicatedMfaPhoneNumber?: boolean
  }
}

/** 専門家アクセス情報取得レスポンス */
export interface GetManagementProfessionalStatsResponse {
  /** ユーザー訪問データ(一覧) */
  items: UserVisitData[]
}

/** 掲載申込取得レスポンス */
export interface GetManagementPlacementResponse {
  /** 提出済みの掲載申込情報 */
  application: PlacementApplicationDetails
  /** 最新の承認済み掲載申込 */
  lastAcceptedApplication?: PlacementApplicationDetails
  /**
   * ステータス履歴
   *
   * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
   */
  statusHistory: PlacementApplicationStatusHistory
  /** 次に遷移可能なステータス */
  nextAvailableStatuses: PlacementApplicationStatusCode[]
  /** 申込サマリ */
  summary: IncompleteApplicationListItem
}

/**
 * 掲載申込ステータス変更リクエスト
 *
 * コメントは審査NGの場合は必須で指定すること。
 */
export interface PutManagementPlacementStatusRequest {
  /** 変更ステータス */
  status: PlacementApplicationStatusCode
  /**
   * ステータス変更コメント
   * @maxLength 255
   */
  comment?: MultiLineString
}

export type PutProfesionalChurnRequest = ChurnApplication

export interface PutProfessionalChurnResponse {
  /** 掲載終了日 */
  endDate: Date
}

export interface PutProfessionalContactSuspendRequest {
  /**
   * 問い合わせ受付を停止するか (true 停止 / false 停止解除)
   * @example false
   */
  contactSuspend: boolean
}

export interface GetProfessionalPasswordResetValidateResponse {
  /**
   * 検証結果(true 検証OK, false 検証NG)
   * @example true
   */
  result: boolean
}

export interface GetProfessionalCreateAccountValidateResponse {
  /**
   * 検証結果(true 検証OK, false 検証NG)
   * @example true
   */
  result: boolean
  /**
   * すでにユーザーが存在しているか
   * @example false
   */
  alreadyExists: boolean
}

/** アカウント作成リクエスト */
export interface PutProfessionalCreateAccountRequest {
  /** アカウント作成用トークン */
  createAccountToken: CreateAccountToken
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
  /** アカウント作成情報 */
  accountInfo: CreateAccountInfo
}

export type GetProfessionalPublicResponse = any

export type GetProfessionalPublicImageResponse = any

/** 未完了申込取得レスポンス */
export interface GetManagementIncompleteApplicationResponse {
  /** 未完了の利用申込 */
  contractApplications: IncompleteApplicationListItem[]
  /** 未完了の掲載申込 */
  placementApplications: IncompleteApplicationListItem[]
}

export type GetProfessionalPlacementDefaultsResponse = PlacementApplicationDetails

export type GetProfessionalPlacementImageResponse = Binary

export type PutProfessionalPlacementRequest = PlacementApplication

/** プレビュー作成結果レスポンス */
export interface PutProfessionalPlacementPreviewResponse {
  /** プレビュー要求ハッシュ */
  previewHash: string
  /** プレビュー表示用トークン */
  previewToken: string
  /** プレビュー表示期限 (seconds from UNIX epoc) */
  previewExpireAt: number
  /** 専門家ID */
  professionalId: ProfessionalId
}

/** 画像取得レスポンス */
export type GetPlacementImageResponse = Binary

/** 掲載画像アップロードリクエスト */
export type PutProfessionalPlacementImageRequest = Binary

/** 掲載画像アップロードレスポンス */
export interface PutProfessionalPlacementImageResponse {
  /**
   * アップロードされた掲載画像を参照するために利用するID
   * @example "xxxxxxxxxx"
   */
  placementImageId: string
  /**
   * アップロードされた掲載画像をダウンロードするURL（要認証情報付きリクエスト）
   * @example "https://xxxxxxxxx"
   */
  url: string
}

/** 専門家情報取得レスポンス */
export interface GetProfessionalSelfResponse {
  /** 利用申込 */
  contract: ContractApplicationDetails
  /**
   * 利用申込の広告主審査向け質問項目（レスポンス用）
   *
   * 質問項目はすべて回答されているとは限らないためrequiredになりません。
   */
  screeningQuestion: ContractApplicationScreeningQuestionDetails
  /**
   * 紹介コード
   *
   * 紹介コードは銀行にて作成し専門家が利用申込の際に入力する。（システム的には生成しない）
   * （半角英数字最大12文字）
   */
  invitationCode?: InvitationCode
  /** 公開情報 */
  publicInformation?: PublicInformation
  /** 最後の掲載申込 */
  placementApplication?: {
    /** 提出済みの掲載申込情報 */
    content: PlacementApplicationDetails
    status: PlacementApplicationStatus
  }
  /** 解約申込 */
  churnApplication?: ChurnApplicationDetails
  /** 強制解約情報（専門家向け） */
  forceChurn?: {
    /** 掲載終了日 */
    endDate: Date
    /** 強制解約操作日時 */
    forceChurnAt: UnixEpochTimeMS
  }
  isChurnable: boolean
  contactSuspended: boolean
  /** 可能な操作 */
  availableActions?: ProfessionalAvailableActions
}

/** 利用申込リクエスト検証レスポンス */
export interface GetProfessionalContractValidateResponse {
  /**
   * 検証結果
   * @example "ok"
   */
  result: 'ok' | 'invalid' | 'loginEmailAddressAlreadyInUse'
}

/** 専門家パスワードリセットメール送信リクエスト */
export interface PostProfessionalPasswordResetSendEmailRequest {
  /**
   * 認証用メールアドレス
   * Gmailのエイリアスなどの制限などはしない。
   * @maxLength 254
   * @example "hoge@gmail.com"
   */
  emailAddress: string
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
}

/** 専門家パスワード変更リクエスト */
export interface PutProfessionalPasswordResetConfirmRequest {
  /**
   * パスワードリセットトークン
   * @example "3114de2eab5a163ded8b57c03cf77f38"
   */
  passwordResetToken: string
  /**
   * 新パスワード
   *
   * 新しいFirebaseのログイン用のパスワードとなる。
   * @example "hello"
   */
  password: string
  /** reCAPTCHAレスポンス */
  'g-recaptcha-response': string
}

/** 専門家カテゴリマスタ取得レスポンス */
export type GetMasterProfessionalCategoryResponse = ProfessionalCategory[]

/** ユーザー訪問データ取得レスポンス */
export interface GetProfessionalUserVisitDataResponse {
  /** ユーザー訪問データ(一覧) */
  items: UserVisitData[]
}

/** 管理ポータル向け専門家検索結果 */
export type ListManagementProfessionalResponse = ListManagementProfessionalItem[]

export type GetManagementContractCsvResponse = Binary

/** 対応サービスマスタ取得レスポンス */
export interface GetMasterSupportServicesResponse {
  /** 対応サービスマスタ定義内容 */
  items: SupportService[]
}

/** Can be any value - string, number, boolean, array, object or null. */
export type Any = any

/** @format binary */
export type Binary = File

export type SupportServiceCode = any

export type Task = Record<string, boolean>

/** 専門家検索結果 */
export interface PublicProfessionalListItem {
  /** 公開情報ID */
  publicId?: number
  /** 専門家ID */
  professionalId?: string
  /** 事務所名 */
  officeName?: string
  /** 事務所住所 */
  officeAddress?: string
  /** 受付対応時間 */
  receptionHours?: string
  /** アクセス */
  access?: MultiLineString
  /** 事業所説明1 */
  description?: MultiLineString
  /** 電話番号 */
  phoneNumber?: string
  /** 対応地域(県) 県コードの配列 */
  prefectures?: string[]
  /** 画像URL */
  profile1ImageUrl?: string
  /**
   * 電話での問い合わせ可否
   * @example false
   */
  phoneContactDisabled?: boolean
}

/** 対応サービス定義 */
export interface SupportService {
  /** 対応サービスコード */
  code: SupportServiceCode
  /** 対応サービスの名称 */
  name: string
  /** 専門家種別 */
  professionalCategory: ProfessionalCategory
}

/** 管理ポータル向け専門家検索の各要素 */
export interface ListManagementProfessionalItem {
  /** 利用申込ID */
  contractId: ContractApplicationId
  /** 専門家ID */
  professionalId: string
  /** 専門家種別 */
  professionalCategory: ProfessionalCategory
  /** 事務所名 */
  officeName: string
  /** 最後の掲載申込みの日時 */
  latestPlacementApplicationDate?: UnixEpochTimeMS
}

/** 公開済み掲載申込み */
export interface PublicInformation {
  /** 提出済みの掲載申込情報 */
  content: PlacementApplicationDetails
  /**
   * 公開停止状態になっているか
   * @example false
   */
  suspended: boolean
  /** 電話での問い合わせを受け付けるか */
  phoneContactDisabled?: boolean
}

/**
 * 付加情報コード値
 *
 * フロントエンドからAPIで送信する値
 */
export type AdditionalInfoCode = string

/** 付加情報 選択肢 */
export interface AdditionalInfo {
  /**
   * 付加情報コード値
   *
   * フロントエンドからAPIで送信する値
   */
  code: AdditionalInfoCode
  category: ProfessionalCategoryCode
  /**
   * ユーザー表示用名称
   *
   * フォームの選択肢表示などに使うことを想定しています。
   * @example "18時以降相談可"
   */
  description: string
}

export interface Address {
  /**
   * 郵便番号
   * @maxLength 7
   * @example "1010029"
   */
  postCode: string
  /**
   * 住所
   * @maxLength 255
   * @example "東京都千代田区神田相生町3-10"
   */
  address: string
  /**
   * 建物名
   * @maxLength 255
   * @example "プラザ神田相生町213"
   */
  building?: string
}

export interface OfficeAddress {
  /**
   * 郵便番号
   * @maxLength 7
   * @example "1010029"
   */
  postCode: string
  /** 都道府県の名称とコード */
  prefecture?: {
    /** @example "13" */
    prefectureCode: string
    /** @example "東京都" */
    prefectureName: string
  }
  /** 市区町村の名称とコード */
  city?: {
    /** 掲載エリアコード */
    areaCode: AreaCode
    /** 市区町村名 */
    areaName?: string
    /**
     * その他を選んだ場合の自由入力の市区町村名
     * @maxLength 255
     * @example "檜原村"
     */
    otherAreaName?: string
  }
  /**
   * 町名・番地
   * @maxLength 255
   * @example "丸の内1-2-3"
   */
  townAndStreetAddress: string
  /**
   * 建物名
   * @maxLength 255
   * @example "プラザ神田相生町213"
   */
  building?: string
}

export interface ChurnApplication {
  /** 日本語での理由表記 */
  reason: MultiLineString
}

/** 解約申込 */
export interface ChurnApplicationDetails {
  /** 日本語での理由表記 */
  reason: MultiLineString
  /** 掲載終了日 */
  endDate: Date
}

export interface ContractApplicationStatusHistoryItem {
  /**
   * 利用申込ステータス
   * - waitForScreening: 申込受付
   * - screeningNg: 審査結果NG
   * - screeningOk: 審査結果OK
   * - sentScreeningOkEmail: 掲載OKメール送付済
   * - receivedContractOk: 契約OK受領済
   * - sentContract: 電子契約書送付済
   * - contracted: 契約締結済
   * - sentClTransferApplication: CL宛口振申込書郵送済
   * - sentMufTransferApplication: MUF宛口振申込書郵送済
   * - completedSettlementSettings: 決済設定完了
   * - readyForPlacement: 掲載準備OK
   * - contractFailed: 契約不成立
   */
  status: ContractApplicationStatusCode
  /**
   * ステータス変更した業務担当者のメールアドレス
   *
   * システムでステータス変更したものについては設定されない
   * @example "tanaka@example.com"
   */
  operatorEmailAddress?: string
  /**
   * ステータス変更コメント
   * @maxLength 255
   */
  comment?: MultiLineString
  /** ステータス生成時刻 */
  createdAt: UnixEpochTimeMS
}

/**
 * 利用申込ステータス
 * - waitForScreening: 申込受付
 * - screeningNg: 審査結果NG
 * - screeningOk: 審査結果OK
 * - sentScreeningOkEmail: 掲載OKメール送付済
 * - receivedContractOk: 契約OK受領済
 * - sentContract: 電子契約書送付済
 * - contracted: 契約締結済
 * - sentClTransferApplication: CL宛口振申込書郵送済
 * - sentMufTransferApplication: MUF宛口振申込書郵送済
 * - completedSettlementSettings: 決済設定完了
 * - readyForPlacement: 掲載準備OK
 * - contractFailed: 契約不成立
 * @example "waitForScreening"
 */
export type ContractApplicationStatusCode =
  | 'waitForScreening'
  | 'screeningNg'
  | 'screeningOk'
  | 'sentScreeningOkEmail'
  | 'receivedContractOk'
  | 'sentContract'
  | 'contracted'
  | 'sentClTransferApplication'
  | 'sentMufTransferApplication'
  | 'completedSettlementSettings'
  | 'readyForPlacement'
  | 'contractFailed'

/** 検証情報 */
export interface ContractApplicationValidation {
  /**
   * 利用申込メールアドレス
   * @example "hoge@example.com"
   */
  emailAddress: string
  /** 利用申込リンク有効期限 */
  expirationDatetime: UnixEpochTimeMS
  /**
   * 検証用トークン
   * @example "3114de2eab5a163ded8b57c03cf77f38"
   */
  validationToken: string
}

export interface SuspensionHistoryItem {
  /**
   * ステータス変更した業務担当者のメールアドレス
   * @example "tanaka@example.com"
   */
  operatorEmailAddress: string
  /** 公開停止Flg（true:停止、false:公開停止解除） */
  suspend: boolean
  /** ステータス生成時刻 */
  createdAt: UnixEpochTimeMS
}

export interface PlacementApplicationStatusHistoryItem {
  /**
   * 掲載申込ステータス
   * - waitForScreening: 審査待ち
   * - waitForSecondScreening : 再鑑待ち
   * - accepted: 審査OK
   * - declined: 審査NG
   * - terminated: 審査中止
   */
  status: PlacementApplicationStatusCode
  /**
   * ステータス変更した業務担当者のメールアドレス
   *
   * システムでステータス変更したものについては設定されない
   * @example "tanaka@example.com"
   */
  operatorEmailAddress?: string
  /**
   * ステータス変更コメント
   * @maxLength 255
   */
  comment?: MultiLineString
  /** ステータス生成時刻 */
  createdAt: UnixEpochTimeMS
}

/**
 * 掲載申込ステータス
 * - waitForScreening: 審査待ち
 * - waitForSecondScreening : 再鑑待ち
 * - accepted: 審査OK
 * - declined: 審査NG
 * - terminated: 審査中止
 * @example "waitForScreening"
 */
export type PlacementApplicationStatusCode =
  | 'waitForScreening'
  | 'waitForSecondScreening'
  | 'accepted'
  | 'declined'
  | 'terminated'

/** アカウント作成情報 */
export interface CreateAccountInfo {
  /**
   * ログインパスワード
   *
   * Firebaseのログイン用のパスワードとなる。
   * @example "hello"
   */
  password: string
}

/**
 * アカウント作成用トークン
 * @example "3114de2eab5a163ded8b57c03cf77f38"
 */
export type CreateAccountToken = string

export type ProfessionalId = string

/** @pattern PCA^\d{12} */
export type ContractApplicationId = string

export interface ProfessionalIdObj {
  professionalId: ProfessionalId
}

/** 専門家サービスのホームにおいて、専門家が可能な操作 */
export interface ProfessionalAvailableActions {
  /** 掲載申込みが可能 */
  placementApplication: boolean
  /** 解約申込みが可能 */
  churnApplication: boolean
}

export interface ContractApplicationIdObj {
  contractApplicationId: ContractApplicationId
}

export type ContractApplicationIdOrProfessionalId = ContractApplicationIdObj | ProfessionalIdObj

/** 未完了申込一覧アイテム */
export interface IncompleteApplicationListItem {
  /**
   * 申込種別
   * - contractApplication: 利用申込
   * - placementApplication: 掲載申込
   * @example "placementApplication"
   */
  type: 'contractApplication' | 'placementApplication'
  /**
   * 利用申込の場合には該当する利用申込ID、それ以外では専門家ID
   * @example "xxxxxx"
   */
  related: ContractApplicationIdOrProfessionalId
  /**
   * 申込種別に該当する申込のステータス
   *
   * 利用申込ステータス
   * - waitForScreening: 申込受付
   * - screeningNg: 審査結果NG （このステータスは返却されない）
   * - screeningOk: 審査結果OK
   * - sentScreeningOkEmail: 掲載OKメール送付済
   * - receivedContractOk: 契約OK受領済
   * - sentContract: 電子契約書送付済
   * - contracted: 契約締結済
   * - sentClTransferApplication: CL宛口振申込書郵送済
   * - sentMufTransferApplication: MUF宛口振申込書郵送済
   * - completedSettlementSettings: 決済設定完了
   * - readyForPlacement: 掲載準備OK （このステータスは返却されない）
   * - contractFailed: 契約不成立 （このステータスは返却されない）
   *
   * 掲載申込ステータス
   * - waitForScreening: 審査待ち
   * - waitForSecondScreening : 再鑑待ち
   * - accepted: 審査OK （このステータスは返却されない）
   * - declined: 審査NG （このステータスは返却されない）
   * - terminated: 審査中止 （このステータスは返却されない）
   *
   * 注）内部ステータスとして「再鑑待ち」がある。
   *   「再鑑待ち」ステータスは専門家の表示には出さず管理ポータル内では出すので注意すること。
   * @example "waitForScreening"
   */
  status: string
  /** 契約ID */
  contractId?: ContractApplicationId
  /** 申込日時 */
  appliedAt: UnixEpochTimeMS
  /**
   * 専門家カテゴリのコード値
   * @example "lawyer"
   */
  professionalCategory: ProfessionalCategory
  /**
   * 法人名 / 事務所名
   *
   * - 利用申込の場合には法人名をセット
   * - 掲載申込の場合には掲載申込の事務所名をセット
   * - 解約申込の場合には法人名をセット
   * @example "佐々木法律事務所"
   */
  applicantName: string
}

/** 行ごとの配列 */
export type MultiLineString = string[]

export interface Name {
  /**
   * 苗字
   * @maxLength 255
   * @example "安川"
   */
  surname: string
  /**
   * 名前
   * @maxLength 255
   * @example "洋子"
   */
  givenName: string
  /**
   * カナ苗字
   * @maxLength 255
   * @example "ヤスカワ"
   */
  surnameKana: string
  /**
   * カナ名前
   * @maxLength 255
   * @example "ヨウコ"
   */
  givenNameKana: string
}

/** 掲載画像のID */
export type PlacementApplicationImageId = string

/** 掲載申込画像 */
export interface PlacementApplicationImage {
  /** 掲載画像のID */
  placementImageId: PlacementApplicationImageId
  /**
   * 画像取得API URL
   * @example "https://xxxxxxxxx"
   */
  url?: string
  /**
   * 画像高さ
   * @example 1200
   */
  height?: number
  /**
   * 画像幅
   * @example 1200
   */
  width?: number
}

export interface PlacementApplicationStatus {
  /**
   * 掲載申込ステータス
   * - 審査待ち
   * - 審査OK
   * - 審査NG
   *
   * 注）内部ステータスとして「再鑑待ち」がある。「再鑑待ち」ステータスの時は「審査待ち」にして返却する。
   * @example "waitForScreening"
   */
  code: PlacementApplicationStatusCode
  /**
   * ステータス変更コメント
   * @maxLength 255
   */
  comment?: MultiLineString
}

/**
 * 業態
 * - individual: 個人
 * - corporation: 法人
 * @example "corporation"
 */
export type BusinessCategory = 'individual' | 'corporation'

export interface PlacementApplication {
  images?: {
    /** 掲載情報1画像の掲載画像ID */
    profile1Image?: PlacementApplicationImageId
    /** 掲載情報2画像(1枚目)の掲載画像ID */
    profile2Image1?: PlacementApplicationImageId
    /** 掲載情報2画像(2枚目)の掲載画像ID */
    profile2Image2?: PlacementApplicationImageId
    /** 掲載情報2画像(3枚目)の掲載画像ID */
    profile2Image3?: PlacementApplicationImageId
    /** 掲載情報3画像の掲載画像ID */
    profile3Image?: PlacementApplicationImageId
  }
  /** 事務所説明1 */
  description1: MultiLineString
  /**
   * 事務所名
   * @maxLength 255
   * @example "佐々木法律事務所"
   */
  officeName: string
  /**
   * 事務所名カナ
   * @maxLength 255
   * @example "ササキホウリツジムショ"
   */
  officeNameKana: string
  /** 事務所説明2 */
  description2: MultiLineString
  /** 詳細説明2 */
  detail2: MultiLineString
  /** 事務所説明3 */
  description3?: MultiLineString
  /** 詳細説明3 */
  detail3?: MultiLineString
  /** 付加情報 */
  additionalInfo?: AdditionalInfoCode[]
  /**
   * 問い合わせ用電話番号
   * @example "0423334444"
   */
  phoneNumber: string
  /** 受付（送信用） */
  reception: Reception
  /** アクセス */
  access: MultiLineString
  /**
   * ホームページURL
   * @maxLength 255
   * @example "https://example.com/"
   */
  url?: string
  officeAddress: OfficeAddress
  /**
   * 問い合わせ用メールアドレス
   * @maxLength 254
   * @example "hoge@example.com"
   */
  emailAddress: string
  /** 掲載エリア */
  supportArea: SupportArea
  /** 料金 */
  fee: {
    /** 相談料 */
    consultationFee: ConsultationFee
    /** 着手金 */
    mobilizationFee: MultiLineString
    /** 報奨金 */
    reward: MultiLineString
  }
  supportServices?: SupportServiceCode[]
  /** 担当者名 */
  contactName: Name
  /**
   * 所属団体
   * @maxLength 255
   * @example "東京弁護士会"
   */
  affiliation: string
  representatives: RepresentativeItem[]
  /** 簡裁代理（司法書士の場合にのみ任意項目で扱う） */
  summaryCourtLegalRepresentation?: SummaryCourtLegalRepresentation
  /** 電話での問い合わせ可否 */
  phoneContactDisabled: boolean
}

/** 提出済みの掲載申込情報 */
export interface PlacementApplicationDetails {
  images?: {
    /** 掲載情報1画像の掲載画像 */
    profile1Image?: PlacementApplicationImage
    /** 掲載情報2画像(1枚目)の掲載画像 */
    profile2Image1?: PlacementApplicationImage
    /** 掲載情報2画像(2枚目)の掲載画像 */
    profile2Image2?: PlacementApplicationImage
    /** 掲載情報2画像(3枚目)の掲載画像 */
    profile2Image3?: PlacementApplicationImage
    /** 掲載情報3画像の掲載画像 */
    profile3Image?: PlacementApplicationImage
  }
  /** 事務所説明1 */
  description1: MultiLineString
  /**
   * 専門家カテゴリのコード値と名称
   * @example "lawyer"
   */
  professionalCategory: ProfessionalCategory
  /** 専門家カテゴリの名称 */
  professionalCategoryDescription?: string
  /**
   * 事務所名
   * @example "佐々木法律事務所"
   */
  officeName: string
  /**
   * 事務所名カナ
   * @example "ササキホウリツジムショ"
   */
  officeNameKana: string
  /** 事務所説明2 */
  description2: MultiLineString
  /** 詳細説明2 */
  detail2: MultiLineString
  /** 事務所説明3 */
  description3?: MultiLineString
  /** 詳細説明3 */
  detail3?: MultiLineString
  /** 付加情報 */
  additionalInfo?: AdditionalInfo[]
  /**
   * 問い合わせ用電話番号
   * @example "0423334444"
   */
  phoneNumber: string
  /** 受付（取得用） */
  reception: ReceptionDetails
  /** アクセス */
  access?: MultiLineString
  /**
   * ホームページURL
   * @example "https://example.com/"
   */
  url?: string
  officeAddress: OfficeAddress
  /**
   * 問い合わせ用メールアドレス
   * @example "hoge@example.com"
   */
  emailAddress?: string
  /** 掲載エリア */
  supportArea: SupportAreaDetails
  /** 対応サービス */
  supportServices: SupportService[]
  /** 料金 */
  fee: {
    /** 相談料（取得用） */
    consultationFee: ConsultationFeeDetails
    /** 着手金 */
    mobilizationFee: MultiLineString
    /** 報奨金 */
    reward: MultiLineString
  }
  /** 担当者名 */
  contactName: Name
  /**
   * 所属団体
   * @maxLength 255
   * @example "東京弁護士会"
   */
  affiliation: string
  representatives: RepresentativeItem[]
  /** 簡裁代理（司法書士の場合にのみ任意項目で扱う） */
  summaryCourtLegalRepresentation?: SummaryCourtLegalRepresentation
  /** 電話での問い合わせ可否 */
  phoneContactDisabled: boolean
  /** 簡裁代理入力済みフラグ初期値 */
  summaryCourtLegalRepresentationInputDefault: boolean
}

/**
 * 専門家カテゴリ
 * - lawyer: 弁護士
 * - taxCounselor: 税理士
 * - judicialScrivener: 司法書士
 */
export interface ProfessionalCategory {
  /**
   * 専門家カテゴリコード値
   *
   * フロントエンドからAPIで送信する値
   * @example "lawyer"
   */
  code: ProfessionalCategoryCode
  /**
   * ユーザー表示用名称
   *
   * フォームの選択肢表示などに使うことを想定しています。
   * @example "弁護士"
   */
  name: string
}

/** 受付（送信用） */
export interface Reception {
  /**
   * 受付開始時間（日本時間）
   *
   * 24時間表記
   * @example "09:00"
   */
  receptionHoursStart: string
  /**
   * 受付終了時間（日本時間）
   *
   * 24時間表記
   * @example "17:00"
   */
  receptionHoursEnd: string
  /** 24時間電話受付可否 */
  receptionCall24HoursAvailable: boolean
  /**
   * 受付時間に関する備考
   *
   * 過去の掲載申込における「受付時間」と同じ値となる。
   * ただし過去の「受付時間」は必須パラメータだったが任意項目になったため値が保存されないことがある。
   *
   * 当変更時期はこちらのPRを参照 https://github.com/japan-d2/inheritance/pull/5093
   *
   * 例：
   * [
   *   "土日は17:00まで。水曜は午前のみ。",
   *   "年末年始は休業します。"
   * ]
   */
  receptionHoursNote?: MultiLineString
  /** 定休日 */
  regularHoliday: RegularHoliday
}

/** 受付（取得用） */
export interface ReceptionDetails {
  /**
   * 受付開始時間（日本時間）
   *
   * 24時間表記
   *
   * UI上は必須項目だが、APIでは返却項目としては任意項目として扱う（過去データには存在しないため）
   * @example "09:00"
   */
  receptionHoursStart?: string
  /**
   * 受付終了時間（日本時間）
   *
   * 24時間表記
   *
   * UI上は必須項目だが、APIでは返却項目としては任意項目として扱う（過去データには存在しないため）
   * @example "17:00"
   */
  receptionHoursEnd?: string
  /** 24時間電話受付可否 */
  receptionCall24HoursAvailable?: boolean
  /**
   * 受付時間に関する備考
   *
   * 過去の掲載申込における「受付時間」と同じ値となる。
   * ただし過去の「受付時間」は必須パラメータだったが任意項目になったため値が保存されないことがある。
   *
   * 当変更時期はこちらのPRを参照 https://github.com/japan-d2/inheritance/pull/5093
   *
   * 例：
   * [
   *   "土日は17:00まで。水曜は午前のみ。",
   *   "年末年始は休業します。"
   * ]
   */
  receptionHoursNote?: MultiLineString
  /**
   * 定休日
   *
   * UI上は必須項目だが、APIでは返却項目としては任意項目として扱う（過去データには存在しないため）
   */
  regularHoliday?: RegularHoliday
}

/** 相談料（送信用） */
export interface ConsultationFee {
  /**
   * 相談料の種類
   *
   * 以下の選択肢がある。
   * - 初回相談無料（時間制限なし）
   * - 初回相談無料（時間制限あり）→時間を自由入力
   * - 相談は何度でも無料
   * - その他
   */
  consultationFeeType: ConsultationFeeType
  /**
   * 初回相談無料（時間制限あり）の場合の時間制限（分）
   *
   * consultationFeeTypeがfreeForFirstTimeWithTimeLimitの場合に必須
   * @example 30
   */
  consultationFreeTimeLimit?: number
  /**
   * 相談料に関する備考
   *
   * 過去の掲載申込における「相談料」と同じ値となる。
   * ただし過去の「相談料」は必須パラメータだったが任意項目になったため値が保存されないことがある。
   *
   * 当変更時期はこちらのPRを参照 https://github.com/japan-d2/inheritance/pull/5093
   */
  consultationFeeNote?: MultiLineString
}

/** 相談料（取得用） */
export interface ConsultationFeeDetails {
  /**
   * 相談料の種類
   *
   * 以下の選択肢がある。
   * - 初回相談無料（時間制限なし）
   * - 初回相談無料（時間制限あり）→時間を自由入力
   * - 相談は何度でも無料
   * - その他
   */
  consultationFeeType?: ConsultationFeeType
  /**
   * 初回相談無料（時間制限あり）の場合の時間制限（分）
   *
   * consultationFeeTypeがfreeForFirstTimeWithTimeLimitの場合に必須
   * @example 30
   */
  consultationFreeTimeLimit?: number
  /**
   * 相談料に関する備考
   *
   * 過去の掲載申込における「相談料」と同じ値となる。
   * ただし過去の「相談料」は必須パラメータだったが任意項目になったため値が保存されないことがある。
   *
   * 当変更時期はこちらのPRを参照 https://github.com/japan-d2/inheritance/pull/5093
   */
  consultationFeeNote?: MultiLineString
}

/**
 * 相談料の種類
 *
 * 以下の選択肢がある。
 * - 初回相談無料（時間制限なし）
 * - 初回相談無料（時間制限あり）→時間を自由入力
 * - 相談は何度でも無料
 * - その他
 * @example "freeForFirstTimeWithTimeLimit"
 */
export type ConsultationFeeType = 'freeForFirstTime' | 'freeForFirstTimeWithTimeLimit' | 'freeAnytime' | 'other'

/** 定休日 */
export type RegularHoliday = (
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | 'nationalHoliday'
  | 'notApplicable'
)[]

/** ユーザー訪問データ */
export interface UserVisitData {
  /**
   * 期間（月）
   * YYYY-MMの形式
   * @example "2023-10"
   */
  term: string
  /**
   * ユーザーの専門家ページへのPV
   * @example 57
   */
  pageView: number
  /**
   * ユーザーの専門家ページへのUU
   * @example 32
   */
  uniqueUser: number
  /**
   * ユーザーの専門家への問い合わせフォームからの問い合わせ数
   * @example 2
   */
  contact: number
}

/** 掲載エリア */
export interface SupportArea {
  /**
   * 全国指定
   *
   * trueの場合は全国指定のため
   * @example false
   */
  isWholeArea: boolean
  /**
   * 地域個別指定
   *
   * isWholeAreaがfalseの場合にこちらを指定します。
   */
  areas?: AreaCode[]
}

/** 掲載エリア */
export interface SupportAreaDetails {
  /**
   * 全国指定
   *
   * trueの場合は全国指定のため
   * @example false
   */
  isWholeArea: boolean
  /**
   * 地域個別指定
   *
   * isWholeAreaがfalseの場合にこちらを指定します。
   */
  areas?: SupportAreaItem[]
}

/** 掲載エリアのコード値と名称 */
export interface SupportAreaItem {
  /** 掲載エリアコード */
  areaCode: AreaCode
  /** 掲載エリアの名称 */
  areaName: string
}

/** 掲載エリアコード */
export type AreaCode = string

/**
 * UNIXエポック時間（ミリ秒）
 * @example 1666145712654
 */
export type UnixEpochTimeMS = number

/** 利用申込APIに渡される利用申込情報 */
export interface ContractApplication {
  /**
   * 専門家カテゴリのコード値
   * @example "lawyer"
   */
  professionalCategory: ProfessionalCategoryCode
  /**
   * 業態
   * - individual: 個人
   * - corporation: 法人
   */
  businessCategory: BusinessCategory
  /** 事務所情報 */
  corporate: {
    /**
     * 法人名
     * @maxLength 255
     * @example "佐々木法律事務所"
     */
    name: string
    /**
     * 法人名カナ
     * @maxLength 255
     * @example "ササキホウリツジムショ"
     */
    nameKana: string
    /**
     * 設立年月日
     * @example "1944-08-09"
     */
    dateOfEstablishment: string
    officeAddress: OfficeAddress
    /**
     * ホームページURL
     * @maxLength 255
     * @example "https://example.com/"
     */
    url?: string
    /**
     * 所属団体
     * @maxLength 255
     * @example "東京弁護士会"
     */
    affiliation: string
  }
  representatives: RepresentativeItem[]
  /** 担当者情報 */
  contact: {
    name: Name
    /**
     * 銀行連絡用メールアドレス
     * @maxLength 255
     * @example "hoge@example.com"
     */
    emailAddress: string
    /**
     * 銀行連絡用電話番号
     * @maxLength 255
     * @example "0423334444"
     */
    phoneNumber: string
  }
  /** ログイン情報 */
  login: {
    /**
     * 認証用メールアドレス
     * Gmailのエイリアスなどの制限などはしない。
     * @maxLength 254
     * @example "hoge@gmail.com"
     */
    emailAddress: string
    /**
     * 認証用電話番号
     * 11桁の070,080,090から始まる番号
     * @maxLength 11
     * @example "09012345678"
     */
    phoneNumber: string
  }
  /** 備考 */
  note?: MultiLineString
}

/** 代表者情報 */
export interface RepresentativeItem {
  name: Name
  /**
   * 代表者生年月日
   * @example "1973-02-19"
   */
  birthDate?: string
  address?: Address
}

/** 利用申込の広告主審査向け質問項目 */
export interface ContractApplicationScreeningQuestion {
  /**
   * 現時点において、三菱UFJ銀行を相手とした訴訟案件を引き受けていませんか。
   * - true: はい
   * - false: いいえ
   * @example true
   */
  isNotUndertakenAnyLitigationAgainstMUFGBank: boolean
  /**
   * そうぞくガイドにおける一般ユーザからのお問い合わせについて、年間何件程度をご想定されますか。
   * @min 0
   * @max 1000000000
   * @example 100
   */
  howManyInquiriesAnticipateFromUsersPerYear: number
  /**
   * 広告掲載予定の事務所において、相続実務経験「3年以上」の先生の人数は何人いますか。
   * @min 0
   * @max 1000000000
   * @example 3
   */
  howManyProfessionalsMoreThan3YearsOfExperience: number
  /**
   * 広告掲載予定の事務所において、現在に至るまで、相続業務を何件程度取り扱ってきましたか。
   * @min 0
   * @max 1000000000
   * @example 1000
   */
  howManyCasesHandledSoFar: number
  /**
   * 【司法書士のみ】掲載予定の事務所は司法書士法第3条第1項第6号乃至第8号で定める業務（簡裁訴訟代理等関係業務）を行うことを目的としていますか。
   * - true: はい
   * - false: いいえ
   * @example false
   */
  isIntendedToHandleSummaryCourtLegalRepresentation?: boolean
  /**
   * 【個人事業主のみ】広告主名義(事務所名)で外国にて営業を行っていますか。
   * - true: はい
   * - false: いいえ
   * @example false
   */
  isBusinessOpenOverseasUnderNameOfAdvertiser?: boolean
}

/**
 * 利用申込の広告主審査向け質問項目（レスポンス用）
 *
 * 質問項目はすべて回答されているとは限らないためrequiredになりません。
 */
export interface ContractApplicationScreeningQuestionDetails {
  /**
   * 現時点において、三菱UFJ銀行を相手とした訴訟案件を引き受けていませんか。
   * - true: はい
   * - false: いいえ
   * @example true
   */
  isNotUndertakenAnyLitigationAgainstMUFGBank?: boolean
  /**
   * そうぞくガイドにおける一般ユーザからのお問い合わせについて、年間何件程度をご想定されますか。
   * @min 0
   * @max 1000000000
   * @example 100
   */
  howManyInquiriesAnticipateFromUsersPerYear?: number
  /**
   * 広告掲載予定の事務所において、相続実務経験「3年以上」の先生の人数は何人いますか。
   * @min 0
   * @max 1000000000
   * @example 3
   */
  howManyProfessionalsMoreThan3YearsOfExperience?: number
  /**
   * 広告掲載予定の事務所において、現在に至るまで、相続業務を何件程度取り扱ってきましたか。
   * @min 0
   * @max 1000000000
   * @example 1000
   */
  howManyCasesHandledSoFar?: number
  /**
   * 【司法書士のみ】掲載予定の事務所は司法書士法第3条第1項第6号乃至第8号で定める業務（簡裁訴訟代理等関係業務）を行うことを目的としていますか。
   * - true: はい
   * - false: いいえ
   * @example false
   */
  isIntendedToHandleSummaryCourtLegalRepresentation?: boolean
  /**
   * 【個人事業主のみ】広告主名義(事務所名)で外国にて営業を行っていますか。
   * - true: はい
   * - false: いいえ
   * @example false
   */
  isBusinessOpenOverseasUnderNameOfAdvertiser?: boolean
}

/** 公開停止履歴 */
export type SuspensionHistory = SuspensionHistoryItem[]

/**
 * ステータス履歴
 *
 * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
 */
export type PlacementApplicationStatusHistory = PlacementApplicationStatusHistoryItem[]

/**
 * 利用申込ステータス履歴
 *
 * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
 */
export type ContractApplicationStatusHistory = ContractApplicationStatusHistoryItem[]

/**
 * 西暦年月日
 * @format date
 */
export type Date = string

/**
 * 紹介コード
 *
 * 紹介コードは銀行にて作成し専門家が利用申込の際に入力する。（システム的には生成しない）
 * （半角英数字最大12文字）
 * @minLength 1
 * @maxLength 12
 * @example "JDD123456789"
 */
export type InvitationCode = string

/** 利用申込の取得APIにより返される利用申込情報 */
export interface ContractApplicationDetails {
  /** 利用申込ID */
  contractApplicationId: ContractApplicationId
  /**
   * 専門家カテゴリ
   * @example "lawyer"
   */
  professionalCategory: ProfessionalCategory
  /**
   * 業態
   * - individual: 個人
   * - corporation: 法人
   */
  businessCategory?: BusinessCategory
  /** 事務所情報 */
  corporate: {
    /**
     * 法人名
     * @example "佐々木法律事務所"
     */
    name: string
    /**
     * 法人名カナ
     * @example "ササキホウリツジムショ"
     */
    nameKana: string
    /** 設立年月日 */
    dateOfEstablishment: Date
    officeAddress: OfficeAddress
    /**
     * ホームページURL
     * @example "https://example.com/"
     */
    url?: string
    /**
     * 所属団体
     * @example "東京弁護士会"
     */
    affiliation: string
  }
  /**
   * 利用申込ステータス履歴
   *
   * ステータスの古い順に並んでいる（最後の要素が現在のステータス）
   */
  statusHistory: ContractApplicationStatusHistory
  /** 代表者情報 */
  representatives: RepresentativeItem[]
  /** 担当者情報 */
  contact: {
    name: Name
    /**
     * 銀行連絡用メールアドレス
     * @example "hoge@example.com"
     */
    emailAddress: string
    /**
     * 銀行連絡用電話番号
     * @example "0423334444"
     */
    phoneNumber: string
  }
  /** ログイン情報 */
  login: {
    /**
     * 認証用メールアドレス
     * Gmailのエイリアスなどの制限などはしない。
     * @example "hoge@gmail.com"
     */
    emailAddress: string
    /**
     * 認証用電話番号
     * 11桁の070,080,090から始まる番号
     * @example "09012345678"
     */
    phoneNumber: string
  }
  /** 備考 */
  note?: MultiLineString
}

/**
 * 簡裁代理
 *
 * 特定社員と認定番号はそれぞれ任意でどちらかのみ指定されることがある
 */
export interface SummaryCourtLegalRepresentation {
  /** 簡裁代理 特定社員 */
  specifiedMember: {
    /**
     * 苗字
     * @maxLength 255
     * @example "簡裁"
     */
    surname: string
    /**
     * 名前
     * @maxLength 255
     * @example "二郎"
     */
    givenName: string
  }
  /**
   * 簡裁代理認定番号
   * @maxLength 8
   * @example "1234567"
   */
  certificationNumber: string
}

/** 公開情報詳細 */
export interface PublicInfoDetails {
  /** 専門家ID */
  professionalId: string
  /** 法人名、法人名カナ */
  officeName: CorporateName
  /** 行ごとの配列 */
  description2: MultiLineString
  additionalInfo?: NameAndCodePair[]
  /** 行ごとの配列 */
  detail2: MultiLineString
  /** 行ごとの配列 */
  description3: MultiLineString
  /** 行ごとの配列 */
  detail3: MultiLineString
  areaPrefectures: string[]
  /** 受付（取得用） */
  reception?: ReceptionDetails
  officeAddress: string
  /** 行ごとの配列 */
  access: MultiLineString
  contactPhoneNumber?: string
  url?: string
  affiliation: string
  representatives: PersonNameWithoutKana[]
  /** 相談料（取得用） */
  consultationFee?: ConsultationFeeDetails
  /** 行ごとの配列 */
  mobilizationFee?: MultiLineString
  /** 行ごとの配列 */
  reward?: MultiLineString
  /** 公開画像 */
  images?: PublicInfoDetailImages
  supportServices?: NameAndCodePair[]
  summaryCourtLegalRepresentation?: {
    /** 氏名（カナ無し） */
    specifiedMember: PersonNameWithoutKana
    certificationNumber: string
  }
  lastUpdateDate?: string
  /**
   * 業態
   * - individual: 個人
   * - corporation: 法人
   */
  businessCategory?: BusinessCategory
}

/** 氏名（カナ無し） */
export interface PersonNameWithoutKana {
  surname: string
  givenName: string
}

/** 付加情報/対応サービス 名前とコード */
export interface NameAndCodePair {
  /** 付加情報/対応サービス名 */
  name: string
  /** 付加情報/対応サービスコード */
  code: string
}

/** 法人名、法人名カナ */
export interface CorporateName {
  /** 法人名 */
  name: string
  /** 法人名カナ */
  nameKana: string
}

/** 公開画像 */
export interface PublicInfoDetailImages {
  profile1: {
    image1?: string
  }
  profile2: {
    image1?: string
    image2?: string
    image3?: string
  }
  profile3: {
    image1?: string
  }
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://to.be.defined/api' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Inheritance API
 * @version 1.0.0
 * @baseUrl https://to.be.defined/api
 *
 * 相続システムのAPI
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  healthCheck = {
    /**
     * @description 専門家DBおよび、ユーザーDBに接続し現在時刻を取得するクエリを実行し各DBへの接続を検証する
     *
     * @tags health-check
     * @name GetHealthCheck
     * @summary バックエンドとしての稼働状態を確認する
     * @request GET:/health_check
     */
    getHealthCheck: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health_check`,
        method: 'GET',
        ...params,
      }),
  }
  version = {
    /**
     * @description バージョンはspec.yamlファイルのハッシュをとって生成
     *
     * @name GetApiVersion
     * @summary バックエンドのAPIバージョンを取得する
     * @request GET:/version
     */
    getApiVersion: (params: RequestParams = {}) =>
      this.request<GetApiVersionResponse, any>({
        path: `/version`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  user = {
    /**
     * @description バックエンドではログに出力する以外なにもしない
     *
     * @tags user
     * @name PostUserError
     * @summary フロントエンドで発生したエラーを通知する
     * @request POST:/user/error
     */
    postUserError: (data: PostUserErrorRequest, params: RequestParams = {}) =>
      this.request<PostUserErrorResponse, any>({
        path: `/user/error`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 付加情報、対応サービスといった少量の定義を含む一連のマスタを取得する
     *
     * @tags user
     * @name GetUserMasters
     * @summary 各種マスター取得
     * @request GET:/user/masters
     */
    getUserMasters: (params: RequestParams = {}) =>
      this.request<GetUserMastersResponse, any>({
        path: `/user/masters`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description 相続診断の入力内容をアップロードする
     *
     * @tags user
     * @name PutUserDiag
     * @summary 相続診断アップロード(CSV用)
     * @request PUT:/user/diag
     */
    putUserDiag: (data: PutUserDiagRequest, params: RequestParams = {}) =>
      this.request<PutUserDiagResponse, any>({
        path: `/user/diag`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 相続診断の入力内容（途中含む）を取得
     *
     * @tags user
     * @name GetUserDiagState
     * @summary 相続診断取得
     * @request GET:/user/diag/state
     */
    getUserDiagState: (params: RequestParams = {}) =>
      this.request<GetUserDiagStateResponse, any>({
        path: `/user/diag/state`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description 相続診断の入力内容（途中も含む）をアップロードする
     *
     * @tags user
     * @name PutUserDiagState
     * @summary 相続診断アップロード
     * @request PUT:/user/diag/state
     */
    putUserDiagState: (data: PutUserDiagStateRequest, params: RequestParams = {}) =>
      this.request<PutUserDiagStateResponse, any>({
        path: `/user/diag/state`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PutUserTasks
     * @summary やることリストアップロード(CSV用)
     * @request PUT:/user/tasks
     */
    putUserTasks: (data: PutUserTasksRequest, params: RequestParams = {}) =>
      this.request<PutUserTasksResponse, any>({
        path: `/user/tasks`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserTasksState
     * @summary やることリスト取得
     * @request GET:/user/tasks/state
     */
    getUserTasksState: (params: RequestParams = {}) =>
      this.request<GetUserTasksStateResponse, any>({
        path: `/user/tasks/state`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PutUserTasksState
     * @summary やることリストアップロード
     * @request PUT:/user/tasks/state
     */
    putUserTasksState: (data: PutUserTasksStateRequest, params: RequestParams = {}) =>
      this.request<PutUserTasksStateResponse, any>({
        path: `/user/tasks/state`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUserTasksChecked
     * @summary やることリストチェック状況取得
     * @request GET:/user/tasks/checked
     */
    getUserTasksChecked: (params: RequestParams = {}) =>
      this.request<GetUserTasksCheckedIdsResponse, any>({
        path: `/user/tasks/checked`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PutUserTasksChecked
     * @summary やることリストチェック状況アップロード
     * @request PUT:/user/tasks/checked
     */
    putUserTasksChecked: (data: PutUserTasksCheckedIdsRequest, params: RequestParams = {}) =>
      this.request<PutUserTasksCheckedIdsResponse, any>({
        path: `/user/tasks/checked`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PutUserInquiry
     * @summary 業務担当者への問い合わせ
     * @request PUT:/user/inquiry
     */
    putUserInquiry: (data: PutUserInquiryRequest, params: RequestParams = {}) =>
      this.request<PutUserInquiryResponse, void>({
        path: `/user/inquiry`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  professional = {
    /**
     * @description バックエンドではログに出力する以外なにもしない
     *
     * @tags professional
     * @name PostProfessionalError
     * @summary フロントエンドで発生したエラーを通知する
     * @request POST:/professional/error
     */
    postProfessionalError: (data: PostProfessionalErrorRequest, params: RequestParams = {}) =>
      this.request<PostProfessionalErrorResponse, any>({
        path: `/professional/error`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostProfessional
     * @summary 専門家検索
     * @request POST:/professional
     */
    postProfessional: (data: PostProfessionalRequest, params: RequestParams = {}) =>
      this.request<PostProfessionalResponse, any>({
        path: `/professional`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name PostProfessionalPreview
     * @summary 専門家掲載申込みプレビュー(検索結果)
     * @request POST:/professional/preview/{professionalId}/{hash}
     */
    postProfessionalPreview: (
      professionalId: string,
      hash: string,
      query: {
        /** プレビュー要求の検証トークン */
        token: string
        /** プレビュー要求の有効期限 */
        expire: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PostProfessionalResponse, void>({
        path: `/professional/preview/${professionalId}/${hash}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetProfessionalPreviewDetails
     * @summary 専門家掲載申込みプレビュー(詳細)
     * @request GET:/professional/preview/{professionalId}/{hash}/details
     */
    getProfessionalPreviewDetails: (
      professionalId: string,
      hash: string,
      query: {
        /** プレビュー要求の検証トークン */
        token: string
        /** プレビュー要求の有効期限 */
        expire: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PublicInfoDetails, void>({
        path: `/professional/preview/${professionalId}/${hash}/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetProfessionalPreviewImage
     * @summary 専門家掲載申し込みプレビュー（画像）
     * @request GET:/professional/preview/{professionalId}/{hash}/images/{part}
     */
    getProfessionalPreviewImage: (
      professionalId: string,
      hash: string,
      part: string,
      query: {
        /** プレビュー要求の検証トークン */
        token: string
        /** プレビュー要求の有効期限 */
        expire: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GetPlacementImageResponse, void>({
        path: `/professional/preview/${professionalId}/${hash}/images/${part}`,
        method: 'GET',
        query: query,
        format: 'blob',
        ...params,
      }),

    /**
     * @description 専門家の公開IDおよび問い合わせ受付停止フラグの値を返却する。公開停止や解約済み等、有効な専門家IDが見つからない場合は404を返答する。
     *
     * @tags user
     * @name GetProfessionalPublicStatus
     * @summary 専門家の公開状態取得
     * @request GET:/professional/{professionalId}/public-status
     */
    getProfessionalPublicStatus: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<GetProfessionalPublicStatusResponse, void>({
        path: `/professional/${professionalId}/public-status`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description クライアントで取得したreCAPTCHAのレスポンスを指定する必要がある。 利用申込リクエストID、利用申込のメールアドレス、有効期限を保存する。 利用申込リクエストIDから利用申込リンクを生成する。 * リンクの有効期限は24時間 * 時間以内にクリックされなければリンクは無効となる 利用申込メールアドレスにリンクを含めて実在確認用のメールを送信する。
     *
     * @tags professional
     * @name PostProfessionalContract
     * @summary 利用申込リクエスト
     * @request POST:/professional/contract
     */
    postProfessionalContract: (data: PostProfessionalContractRequest, params: RequestParams = {}) =>
      this.request<AdditionalInfoCode, void>({
        path: `/professional/contract`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 利用申込を行う。 以下の処理を行う。 * 利用申込情報、利用規約同意保存する。 * 利用申込ステータスは申込受付で保存する。 * すべての業務担当者に利用申込の通知メールを送信する。 リクエストは以下の検証を行う。 * reCAPTCHAレスポンスの検証 * 検証データ(emailAddress, expirationDatetime, validationToken)の検証 * 有効期限内であるか * トークンが不正であるか 利用規約同意がtrueでない場合もエラーとなる。 validation.emailAddress と login.emailAddress が相違する場合もエラーとなる。 すでに利用申込があった場合、エラーとしないで何もしないで成功で返す。
     *
     * @tags professional
     * @name PutProfessionalContract
     * @summary 利用申込
     * @request PUT:/professional/contract
     */
    putProfessionalContract: (data: PutProfessionalContractRequest, params: RequestParams = {}) =>
      this.request<AdditionalInfoCode, ErrorResponse>({
        path: `/professional/contract`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 利用申込リクエストのパラメータを検証して結果を返却する。 検証では以下の内容をチェックする。 * 有効期限内であるか * トークンが不正であるか
     *
     * @tags professional
     * @name GetProfessionalContractValidate
     * @summary 利用申込リクエスト検証
     * @request GET:/professional/contract/validate
     */
    getProfessionalContractValidate: (
      query: {
        /**
         * 利用申込メールアドレス
         * @example "hoge@example.com"
         */
        emailAddress: string
        /** 利用申込リンク有効期限 */
        expirationDatetime: UnixEpochTimeMS
        /**
         * 検証用トークン
         * @example "3114de2eab5a163ded8b57c03cf77f38"
         */
        validationToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GetProfessionalContractValidateResponse, void>({
        path: `/professional/contract/validate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家向けのパスワードリセットメールを指定されたメールアドレスに送信する。 ただし、メールアドレスがIdentityテーブルに存在しない場合には何もせず200で終了する。 reCAPTCHAレスポンスの検証を実施する。 メールには、パスワードリセット画面へのリンクを含める。 リンクにはパラメータとしてパスワードリセットトークンを含める。
     *
     * @tags professional
     * @name PostProfessionalPasswordResetSendEmail
     * @summary 専門家パスワードリセットメール送信
     * @request POST:/professional/password-reset/send-email
     */
    postProfessionalPasswordResetSendEmail: (
      data: PostProfessionalPasswordResetSendEmailRequest,
      params: RequestParams = {}
    ) =>
      this.request<AdditionalInfoCode, void>({
        path: `/professional/password-reset/send-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家パスワードリセットリンクのパラメータを検証して結果を返却する。 検証では以下の内容をチェックする。 * パスワードリセットトークンが有効であるか（存在チェック、有効期限）
     *
     * @tags professional
     * @name GetProfessionalPasswordResetValidate
     * @summary パスワードリセット検証
     * @request GET:/professional/password-reset/validate
     */
    getProfessionalPasswordResetValidate: (
      query: {
        /**
         * パスワードリセットトークン
         * @example "3114de2eab5a163ded8b57c03cf77f38"
         */
        passwordResetToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GetProfessionalPasswordResetValidateResponse, void>({
        path: `/professional/password-reset/validate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家向けのパスワードリセットを実施する。 パスワードリセットは次の検証をパスした場合に実施する。検証に失敗した場合には400を返却する。 * reCAPTCHAレスポンスの検証 * 電話認証プロバイダのidTokenを検証 * パスワードリセットトークンが有効であるか（存在チェック、有効期限, まだトークンが利用されていないか） * 電話認証プロバイダの電話番号とメール認証プロバイダから取得したMFA電話番号が一致している * 電話認証プロバイダの電話番号は、idTokenにて電話認証プロバイダから取得 * MFA電話番号は、検証トークンからDBでメールアドレスをひきIdentityテーブルから取得 リクエストは、電話認証プロバイダでの認証状態で行う必要がある。
     *
     * @tags professional
     * @name PutProfessionalPasswordResetConfirm
     * @summary 専門家パスワードリセット
     * @request PUT:/professional/password-reset/confirm
     * @secure
     */
    putProfessionalPasswordResetConfirm: (
      data: PutProfessionalPasswordResetConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<AdditionalInfoCode, void>({
        path: `/professional/password-reset/confirm`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家アカウント作成リンクのパラメータを検証して結果を返却する。 検証では以下の内容をチェックする。 * 有効期限内であるか * トークンが有効であるか すでにユーザーが作成されているかの状態も返却する。
     *
     * @tags professional
     * @name GetProfessionalCreateAccountValidate
     * @summary アカウント作成検証
     * @request GET:/professional/create-account/validate
     */
    getProfessionalCreateAccountValidate: (
      query: {
        /**
         * 検証用トークン
         * @example "3114de2eab5a163ded8b57c03cf77f38"
         */
        createAccountToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<GetProfessionalCreateAccountValidateResponse, void>({
        path: `/professional/create-account/validate`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家アカウントを作成する。 クライアントで専門家アカウント作成リンクから取得したパラメータおよび、reCAPTCHAのレスポンスを指定する必要がある。 リクエストは以下の検証を行う。 * reCAPTCHAレスポンスの検証 * 検証データ(createAccountToken)の検証 * 有効期限内であるか * トークンが有効であるか * まだトークンが利用されていないか * 電話認証プロバイダのidTokenを検証 * 電話認証プロバイダの電話番号とMFA電話番号が一致している * 電話認証プロバイダの電話番号は、idTokenにて電話認証プロバイダから取得 * MFA電話番号は、利用申込のMFA電話番号を利用 メール認証プロバイダに対して、認証用メールアドレス、パスワード、MFA電話番号、emailVerified=trueを指定して専門家アカウントを作成する。 専門家テーブルに専門家を登録する（Firebaseのuidも保存する） すでにアカウントがあった場合、エラーとしないで何もしないで成功で返す。 リクエストは、電話認証プロバイダでの認証状態で行う必要がある。
     *
     * @tags professional
     * @name PutProfessionalCreateAccount
     * @summary アカウント作成
     * @request PUT:/professional/create-account
     * @secure
     */
    putProfessionalCreateAccount: (data: PutProfessionalCreateAccountRequest, params: RequestParams = {}) =>
      this.request<AdditionalInfoCode, void>({
        path: `/professional/create-account`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家の以下の情報があれば返却する。 - 公開情報, ステータス - 最後の掲載申込, ステータス - 解約申込, ステータス
     *
     * @tags professional
     * @name GetProfessionalSelf
     * @summary 専門家情報取得
     * @request GET:/professional/me
     * @secure
     */
    getProfessionalSelf: (params: RequestParams = {}) =>
      this.request<GetProfessionalSelfResponse, any>({
        path: `/professional/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 掲載申込フォームの初期値を取得する。 過去の掲載申込があればその値を返却する。 なければ利用申込の内容で一部の値を返却する。 利用申込内容の場合は、以下のみが返却される。 - 専門家カテゴリ - 事務所名 - 住所 - メールアドレス - 電話番号 - 担当者名
     *
     * @tags professional
     * @name GetProfessionalPlacementDefaults
     * @summary 掲載申込フォーム初期値取得
     * @request GET:/professional/placement/defaults
     * @secure
     */
    getProfessionalPlacementDefaults: (params: RequestParams = {}) =>
      this.request<GetProfessionalPlacementDefaultsResponse, any>({
        path: `/professional/placement/defaults`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description すでに申込中の掲載申込または解約申込がある場合はエラーとせず処理を成功として終了させる。 掲載申込情報を「審査待ち」ステータスで保存する すべての業務担当者に掲載申込の通知メールを送信する。
     *
     * @tags professional
     * @name PutProfessionalPlacement
     * @summary 掲載申込
     * @request PUT:/professional/placement
     * @secure
     */
    putProfessionalPlacement: (data: PutProfessionalPlacementRequest, params: RequestParams = {}) =>
      this.request<AdditionalInfoCode, void>({
        path: `/professional/placement`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 掲載申し込み内容を元にプレビューを作成する
     *
     * @tags professional
     * @name PutProfessionalPlacementPreview
     * @summary 掲載申し込みのプレビュー
     * @request PUT:/professional/placement/preview
     * @secure
     */
    putProfessionalPlacementPreview: (data: PutProfessionalPlacementRequest, params: RequestParams = {}) =>
      this.request<PutProfessionalPlacementPreviewResponse, void>({
        path: `/professional/placement/preview`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家ページに表示する掲載情報画像をアップロードする。 フロントエンド側で画像種別に応じてcropして送信してくる前提とし、バックエンドでのリサイズは行わない。 以下の画像でない場合はエラーとする。 - ファイル形式（jpeg, png） - 画像 profile1Image - 240 x 320 (width, height) 以下 - 画像 profile2Image1, profile2Image2, profile2Image3 - 1200 x 1200 (width, height) 以下 - 画像 profile3Image - 1500 x 1000 (width, height) 以下 画像ファイルに対して以下の処理を行う。 - JPEGのEXIF情報は削除する。 - 画像を保存する。 - 画像IDを返却する。
     *
     * @tags professional
     * @name PutProfessionalPlacementImage
     * @summary 掲載画像アップロード
     * @request PUT:/professional/placement/images/{imagePosition}
     * @secure
     */
    putProfessionalPlacementImage: (
      imagePosition: 'profile1Image' | 'profile2Image1' | 'profile2Image2' | 'profile2Image3' | 'profile3Image',
      data: PutProfessionalPlacementImageRequest,
      params: RequestParams = {}
    ) =>
      this.request<PutProfessionalPlacementImageResponse, void>({
        path: `/professional/placement/images/${imagePosition}`,
        method: 'PUT',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description アップロードされた掲載画像を取得する
     *
     * @tags professional
     * @name GetProfessionalPlacementImage
     * @summary 掲載画像取得
     * @request GET:/professional/placement/images/{placementImageId}
     * @secure
     */
    getProfessionalPlacementImage: (placementImageId: string, params: RequestParams = {}) =>
      this.request<GetProfessionalPlacementImageResponse, void>({
        path: `/professional/placement/images/${placementImageId}`,
        method: 'GET',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProfessionalPublic
     * @request GET:/professional/public
     */
    getProfessionalPublic: (params: RequestParams = {}) =>
      this.request<GetProfessionalPublicResponse, any>({
        path: `/professional/public`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProfessionalPublicImages
     * @request GET:/professional/public/images/{imagePosition}
     */
    getProfessionalPublicImages: (imagePosition: string, params: RequestParams = {}) =>
      this.request<GetProfessionalPublicImageResponse, any>({
        path: `/professional/public/images/${imagePosition}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description 解約申込の保存 掲載終了日の設定（申込時刻の翌月末日） 終了ステータス（「解約完了メール送付済」ステータス）の保存 専門家および業務担当者への解約申込完了メール通知を行う 申込中の掲載申込ステータスは審査中止ステータスに変更する
     *
     * @tags professional
     * @name PutProfessionalChurn
     * @summary 解約申込
     * @request PUT:/professional/churn
     * @secure
     */
    putProfessionalChurn: (data: PutProfesionalChurnRequest, params: RequestParams = {}) =>
      this.request<PutProfessionalChurnResponse, void>({
        path: `/professional/churn`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description ユーザー訪問データを取得する。 日付ごとのデータなので月毎に集計する。 最大12ヶ月分を返却する。リストは月で降順にソートされた状態返却される。
     *
     * @tags professional
     * @name GetProfessionalUserVisitData
     * @summary ユーザー訪問データ取得
     * @request GET:/professional/stats
     * @secure
     */
    getProfessionalUserVisitData: (params: RequestParams = {}) =>
      this.request<GetProfessionalUserVisitDataResponse, any>({
        path: `/professional/stats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description ユーザーからの専門家への問い合わせの受付を停止または停止解除する。
     *
     * @tags professional
     * @name PutProfessionalContactSuspend
     * @summary 問い合わせ受付停止
     * @request PUT:/professional/contact/suspend
     * @secure
     */
    putProfessionalContactSuspend: (data: PutProfessionalContactSuspendRequest, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/professional/contact/suspend`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  contact = {
    /**
     * No description
     *
     * @tags user
     * @name PutContact
     * @request PUT:/contact/{publicId}
     */
    putContact: (publicId: number, data: PutContactRequest, params: RequestParams = {}) =>
      this.request<PutContactResponse, void>({
        path: `/contact/${publicId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  management = {
    /**
     * @description バックエンドではログに出力する以外なにもしない
     *
     * @tags management
     * @name PostManagementError
     * @summary フロントエンドで発生したエラーを通知する
     * @request POST:/management/error
     */
    postManagementError: (data: PostManagementErrorRequest, params: RequestParams = {}) =>
      this.request<PostManagementErrorResponse, any>({
        path: `/management/error`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家の申込のうち未完了の申込の一覧を全件返却する。 クライアント側にてソートや絞り込みを行う。
     *
     * @tags management
     * @name GetManagementIncompleteApplication
     * @summary 未完了申込取得
     * @request GET:/management/incomplete-application
     * @secure
     */
    getManagementIncompleteApplication: (params: RequestParams = {}) =>
      this.request<GetManagementIncompleteApplicationResponse, any>({
        path: `/management/incomplete-application`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定された申込IDから以下の内容を返却する - 申込内容 - 現状のステータス - ステータス変更履歴 - 次に遷移可能なステータス - 申込サマリ
     *
     * @tags management
     * @name GetManagementContract
     * @summary 利用申込取得
     * @request GET:/management/contract/{contractApplicationId}
     * @secure
     */
    getManagementContract: (contractApplicationId: ContractApplicationId, params: RequestParams = {}) =>
      this.request<GetManagementContractResponse, void>({
        path: `/management/contract/${contractApplicationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description - 変更可能なステータスの場合、ステータスを保存する - ステータス変更者も記録する - 業務担当者の google identity platform アカウントからメールアドレスを取得する - 変更不可能なステータスを指定された場合はエラー - 掲載準備OKステータス変更の場合には、アカウント作成依頼メール送信および電話認証プロバイダへ電話番号を登録する
     *
     * @tags management
     * @name PutManagementContractStatus
     * @summary 利用申込ステータス変更
     * @request PUT:/management/contract/{contractApplicationId}/status
     * @secure
     */
    putManagementContractStatus: (
      contractApplicationId: ContractApplicationId,
      data: PutManagementContractStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/management/contract/${contractApplicationId}/status`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description アップロードされた掲載画像を取得する
     *
     * @tags management
     * @name GetManagementPlacementImage
     * @summary 掲載画像取得（管理ポータル向け）
     * @request GET:/management/placement/images/{placementImageId}
     * @secure
     */
    getManagementPlacementImage: (placementImageId: string, params: RequestParams = {}) =>
      this.request<GetPlacementImageResponse, void>({
        path: `/management/placement/images/${placementImageId}`,
        method: 'GET',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @tags management
     * @name ListManagementProfessional
     * @request GET:/management/professional
     * @secure
     */
    listManagementProfessional: (
      query?: {
        /** 利用申込ID */
        ca?: ContractApplicationId
        /** 電話番号 */
        phone?: string
        /** 名前検索文字列 */
        namelike?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<ListManagementProfessionalResponse, any>({
        path: `/management/professional`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags management
     * @name GetManagementProfessional
     * @request GET:/management/professional/{professionalId}
     * @secure
     */
    getManagementProfessional: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<GetManagementProfessionalResponse, any>({
        path: `/management/professional/${professionalId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家の強制解約
     *
     * @tags management
     * @name DeleteManagementProfessional
     * @request DELETE:/management/professional/{professionalId}
     * @secure
     */
    deleteManagementProfessional: (
      professionalId: ProfessionalId,
      query: {
        /** 掲載終了日 */
        endDate: Date
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/management/professional/${professionalId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 専門家認証情報更新
     *
     * @tags management
     * @name PutManagementProfessionalLogin
     * @request PUT:/management/professional/{professionalId}/login
     * @secure
     */
    putManagementProfessionalLogin: (
      professionalId: ProfessionalId,
      data: PutManagementProfessionalLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, void | PutManagementProfessionalLogin409ErrorResponse>({
        path: `/management/professional/${professionalId}/login`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 専門家アカウント作成メール再送
     *
     * @tags management
     * @name PostManagementProfessionalLoginResend
     * @request POST:/management/professional/{professionalId}/login/resend
     * @secure
     */
    postManagementProfessionalLoginResend: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/management/professional/${professionalId}/login/resend`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description 専門家のアクセス情報を取得する
     *
     * @tags management
     * @name GetManagementStats
     * @summary 専門家アクセス情報取得
     * @request GET:/management/professional/{professionalId}/stats
     * @secure
     */
    getManagementStats: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<GetManagementProfessionalStatsResponse, void>({
        path: `/management/professional/${professionalId}/stats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 指定された申込IDから以下の内容を返却する - 申込内容 - 現状のステータス - ステータス変更履歴 - 次に遷移可能なステータス - 申込サマリ
     *
     * @tags management
     * @name GetManagementPlacement
     * @summary 掲載申込取得
     * @request GET:/management/professional/{professionalId}/placement
     * @secure
     */
    getManagementPlacement: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<GetManagementPlacementResponse, void>({
        path: `/management/professional/${professionalId}/placement`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description - 変更可能なステータスの場合、ステータスを保存する - ステータス変更者も記録する - 業務担当者の google identity platform アカウントからメールアドレスを取得する - 変更不可能なステータスを指定された場合はエラー
     *
     * @tags management
     * @name PutManagementPlacementStatus
     * @summary 掲載申込ステータス変更
     * @request PUT:/management/professional/{professionalId}/placement/status
     * @secure
     */
    putManagementPlacementStatus: (
      professionalId: ProfessionalId,
      data: PutManagementPlacementStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/management/professional/${professionalId}/placement/status`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description 専門家の掲載情報を公開停止とする。 - 公開情報を削除する - 公開停止履歴テーブルに公開停止履歴を保存する - 専門家テーブルの非公開フラグをTRUEに設定する
     *
     * @tags management
     * @name DeleteManagementPublic
     * @summary 公開停止
     * @request DELETE:/management/professional/{professionalId}/public
     * @secure
     */
    deleteManagementPublic: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/management/professional/${professionalId}/public`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description 専門家の掲載情報を公開停止解除（再公開）とする。 - 公開情報を登録する（最新の審査が通過した掲載情報を公開する） - 公開停止履歴テーブルに公開停止解除履歴を保存する - 専門家テーブルの非公開フラグをFALSEに設定する
     *
     * @tags management
     * @name PutManagementPublic
     * @summary 公開停止解除
     * @request PUT:/management/professional/{professionalId}/public
     * @secure
     */
    putManagementPublic: (professionalId: ProfessionalId, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/management/professional/${professionalId}/public`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description 相続診断CSVをダウンロードする
     *
     * @tags management
     * @name GetManagementUserDiagCsv
     * @summary 相続診断CSVダウンロード
     * @request GET:/management/user/diag/csv
     * @secure
     */
    getManagementUserDiagCsv: (
      query: {
        /** 逝去後フラグ(true 逝去後, false 生前) */
        isPostMortem: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<GetManagementUserDiagCsvResponse, any>({
        path: `/management/user/diag/csv`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description やることリストCSVをダウンロードする
     *
     * @tags management
     * @name GetManagementUserTasksCsv
     * @summary やることリストCSVダウンロード
     * @request GET:/management/user/tasks/csv
     * @secure
     */
    getManagementUserTasksCsv: (
      query: {
        /** 逝去後フラグ(true 逝去後, false 生前) */
        isPostMortem: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<GetManagementUserTasksCsvResponse, any>({
        path: `/management/user/tasks/csv`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description 専門家CSVをダウンロードする
     *
     * @tags management
     * @name GetManagementContractCsv
     * @summary 専門家CSVダウンロード
     * @request GET:/management/contract/csv
     * @secure
     */
    getManagementContractCsv: (params: RequestParams = {}) =>
      this.request<GetManagementContractCsvResponse, any>({
        path: `/management/contract/csv`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  master = {
    /**
     * @description 付加情報マスタを返却する。
     *
     * @tags master
     * @name GetMasterAdditionalInfo
     * @summary 付加情報マスタ取得
     * @request GET:/master/additional-info/{professionalCategory}
     * @secure
     */
    getMasterAdditionalInfo: (professionalCategory: ProfessionalCategoryCode, params: RequestParams = {}) =>
      this.request<GetMasterAdditionalInfoResponse, any>({
        path: `/master/additional-info/${professionalCategory}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 対応サービスのマスタを返却する
     *
     * @tags master
     * @name GetMasterSupportServices
     * @summary 対応サービスマスタ取得
     * @request GET:/master/support-services/{professionalCategory}
     * @secure
     */
    getMasterSupportServices: (professionalCategory: ProfessionalCategoryCode, params: RequestParams = {}) =>
      this.request<GetMasterSupportServicesResponse, any>({
        path: `/master/support-services/${professionalCategory}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description 専門家カテゴリマスタを返却する。
     *
     * @tags master
     * @name GetMasterProfessionalCategory
     * @summary 専門家カテゴリマスタ取得
     * @request GET:/master/professional-category
     */
    getMasterProfessionalCategory: (params: RequestParams = {}) =>
      this.request<GetMasterProfessionalCategoryResponse, any>({
        path: `/master/professional-category`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
}
