import { parseJsonSafely } from './utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Validator<T> = (arg: any) => { success: true; result: T } | { success: false; result: undefined }
type Props<T> = { storageKey: string; validator: Validator<T>; storage?: typeof window.localStorage }

export class GenericStorageManager<T> {
  private storage: Exclude<Props<T>['storage'], undefined>
  private storageKey: Props<T>['storageKey']
  private validator: Validator<T>

  constructor({ storage = window.localStorage, storageKey, validator }: Props<T>) {
    this.storage = storage
    this.storageKey = storageKey
    this.validator = validator
  }

  get(): T | undefined {
    const jsonString = this.storage.getItem(this.storageKey)

    if (!jsonString) return undefined

    const parsed = parseJsonSafely(jsonString)
    if (!parsed.success) {
      this.storage.removeItem(this.storageKey)
      return undefined
    }

    const { success, result } = this.validator(parsed.json)
    if (!success) {
      this.storage.removeItem(this.storageKey)
      return undefined
    }
    return result
  }

  set(arg: T) {
    this.storage.setItem(this.storageKey, JSON.stringify(arg))
  }

  remove() {
    this.storage.removeItem(this.storageKey)
  }
}
