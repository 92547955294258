import { useProfessionalSelf } from '@/rpc/professionalSelf/useSWR'
import { css } from '@emotion/react'
import { ContractDetail, Loading } from 'inheritance-components'
import { Suspense } from 'react'

export const ContractInformationContainer = () => {
  return (
    <Suspense fallback={<Loading height="400px" />}>
      <ContractInformationInner />
    </Suspense>
  )
}

const styles = {
  root: css`
    margin-top: 10px;
  `,
}

export const ContractInformationInner = () => {
  const { professional } = useProfessionalSelf()
  return (
    <div css={styles.root}>
      <ContractDetail
        kind="api-schema"
        contractDetail={professional.contract}
        screeningQuestion={professional.screeningQuestion}
        invitationCode={professional.invitationCode}
      />
    </div>
  )
}
