import { SerializedStyles } from '@emotion/react'
import { Guideline } from './Guideline'

export const PasswordGuideline = ({ rootCss }: { rootCss?: SerializedStyles }) => {
  return (
    <Guideline
      rootCss={rootCss}
      texts={[
        'パスワードは以下の組み合わせで設定してください。',
        '・半角英数字記号で8文字以上',
        '・大文字、小文字、数字、記号（@ # $ % & ? ! - _）を含む',
        'また過去に利用したパスワードは設定しないでください。',
      ]}
    />
  )
}
