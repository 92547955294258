import { css } from '@emotion/react'
import { Button, Form, FormInstance, Input } from 'antd'
import { Rule } from 'antd/lib/form'
import { ReactNode } from 'react'
import { SingleTextInputForm, SingleTextInputFormProps } from '../../form/SingleTextInputForm'
import { getRules, RULES } from '../../form/validation-rules'
import { PasswordGuideline } from '../../guideline/PasswordGuideline'

export const PASSWORD_RESET_ENTRY_TITLE = 'パスワード再設定'

const styles = {
  container: css`
    padding-bottom: 48px;
  `,
  description: css`
    font-size: 16px;
    line-height: 42px;
    margin: 32px 0 16px;
    text-align: center;
  `,
  guideline: css`
    margin-bottom: 42px;
  `,
  form: css`
    max-width: 680px;
    margin: 0 auto 32px;
  `,
  button: css`
    text-align: center;
    margin-top: 64px;
  `,
}

const rule: { [key: string]: Rule[] } = {
  password: getRules([RULES.requireEntry, RULES.passwordPattern]),
  passwordConfirm: getRules([RULES.requireEntry, RULES.passwordConfirm]),
}

const useFormProps = (form: FormInstance): Record<string, SingleTextInputFormProps> => {
  // パスワード入力時、パスワード（確認）が入力されていたら検証する
  const onChangePassword = async () => {
    const passwordConfirmValue = form.getFieldValue('passwordConfirm')
    if (passwordConfirmValue) {
      await form.validateFields(['passwordConfirm'])
    }
  }

  return {
    password: {
      name: 'password',
      label: '新規パスワード',
      rules: rule.password,
      renderInput: () => <Input.Password onChange={onChangePassword} />,
    },
    passwordConfirm: {
      name: 'passwordConfirm',
      label: '新規パスワード（確認）',
      rules: rule.passwordConfirm,
      renderInput: () => <Input.Password />,
    },
  }
}

export const PasswordResetEntryLayout = (props: {
  header: ReactNode
  onFinish: (value: { password: string }) => void
  loading?: boolean
}) => {
  const [form] = Form.useForm<{ password: string; passwordConfirm: string }>()
  const formProps = useFormProps(form)
  const onFinish = ({ password }: { password: string }) => {
    props.onFinish({ password })
  }

  return (
    <div css={styles.container}>
      {props.header}
      <p css={styles.description}>新しいパスワードを入力し、「パスワードを再設定する」ボタンを押してください</p>
      <PasswordGuideline rootCss={styles.guideline} />
      <Form form={form} css={styles.form} name="login" labelAlign="left" labelCol={{ span: 8 }} onFinish={onFinish}>
        <SingleTextInputForm {...formProps['password']} />
        <SingleTextInputForm {...formProps['passwordConfirm']} />

        <Form.Item css={styles.button}>
          <Button type="primary" htmlType="submit" loading={props.loading}>
            パスワードを再設定する
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
