import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { getPath } from '@/router'
import { useNavigate } from 'react-router-dom'
import { User, Auth, RecaptchaVerifier } from 'inheritance-auth'
import { ServiceInformationGuideline } from './components/ServiceInformationGuideline'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { CopyRightFooter } from '@/share/components/CopyRightFooter/CopyRightFooter'
import { PinInput, LoginForm, LoginLayout, PinInputLayout } from 'inheritance-components'
import { ContentBrandHeader } from '@/share/components/content-headers/ContentBrandHeader/ContentBrandHeader'
import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { useAuthContext } from '@/auth/AuthContext'
import { useHandleFirebaseError } from '@/auth'
import { useServiceInformation } from '@/rpc/serviceInformation/useSWR'

const recaptchaLoginButtonId = 'recaptcha-login-button'

const styles = {
  copyright: css`
    padding-top: 64px;
  `,
}

export const Login = () => {
  type Resolve = (value: string | PromiseLike<string>) => void
  type Reject = (reason?: string | null) => void
  type SubmitEvent = {
    resolve: Resolve | null
    reject: Reject | null
  }
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
  const [submitPinInput, setSubmitPinInput] = useState<SubmitEvent>({ resolve: null, reject: null })
  const navigate = useNavigate()
  const { getAuthenticator } = useAuthContext()
  const { handleFirebaseError } = useHandleFirebaseError()
  const { serviceInformationLines } = useServiceInformation()

  const recaptchaVerifiyCallback = async (auth: Auth): Promise<RecaptchaVerifier> => {
    return new RecaptchaVerifier(
      recaptchaLoginButtonId,
      {
        size: 'invisible',
        // called when successfully verified
        callback: () => {
          setIsRecaptchaVerified(true)
        },
      },
      auth
    )
  }

  const verificationCodeCallback = async (lastErrorCode?: string): Promise<string> => {
    if (lastErrorCode !== undefined) {
      await handleFirebaseError({ action: 'signin', error: lastErrorCode })
    }
    return new Promise((resolve, reject) => {
      setSubmitPinInput({ resolve: resolve, reject: reject })
    })
  }

  const onSubmitLogin = async (email: string, password: string): Promise<void> => {
    try {
      const authenticator = getAuthenticator()
      if (!authenticator) return

      const result = await authenticator.signin(email, password, recaptchaVerifiyCallback, verificationCodeCallback)
      if (result instanceof User) {
        // locationの更新により、AuthGuard.tsx > onAuthStateChanged() イベントでfirebaseの認証情報が取得されたタイミングで/homeへリダイレクトする
        navigate(getPath('login'))
      }
    } catch (error) {
      await handleFirebaseError({ action: 'signin', error })
    }
  }

  const onCompletePinInput = (pin: string) => {
    if (!submitPinInput.resolve) return
    submitPinInput.resolve(pin)
  }

  useEffect(() => {
    return () => {
      submitPinInput.reject?.()
    }
    // FIXME: remove eslint-disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isRecaptchaVerified) {
    return (
      <AppLayout>
        <LoginLayout
          header={{
            decoration: <ContentBrandHeader />,
            title: '専門家サービス',
            serviceOverview: `こちらは「そうぞくガイド」をご利用いただく専門家向けのサービスです。`,
          }}
          loginForm={
            <LoginForm
              toPasswordResetPageLink={getPath('passwordResetRequest')}
              onSubmitLogin={onSubmitLogin}
              loginButtonId={recaptchaLoginButtonId}
            />
          }
        />
        {serviceInformationLines && <ServiceInformationGuideline lines={serviceInformationLines} />}
        <CopyRightFooter rootCss={styles.copyright} />
      </AppLayout>
    )
  } else {
    return (
      <AppLayout>
        <PinInputLayout
          header={<ContentTitleHeader title="認証コードフォーム入力" />}
          pinInput={<PinInput digits={6} onInputComplete={onCompletePinInput} />}
          buttonArea={{
            description: '認証コードをお忘れの場合はログイン画面に戻り認証コードを再送信してください。',
            button: {
              label: 'ログイン画面に戻る',
              to: getPath('login'),
            },
          }}
        />
      </AppLayout>
    )
  }
}
