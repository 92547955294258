// eslint-disable-next-line node/no-extraneous-import
import * as z from 'zod'
import { GenericStorageManager } from 'inheritance-utils'
import { PlacementFormType, formNames } from '@/share/components/PlacementForm/form'
import { PlacementFormStorageData, FormStorageSchema } from './types/types'
import { MAX_SUPPORT_AREA_NUMBER } from '../../functions'
import { AREA_FORM_PREFIX } from '../../AreaForm'
import { representativeFormNamesWithKey, RepresentativeFormType } from '@/share/components/RepresentativeForm/form'
// eslint-disable-next-line node/no-extraneous-import
import dayjs from 'dayjs'
import { ConsultationFeeType, RegularHoliday } from 'inheritance-api'

type PlacementFormStorage = {
  loginEmailAddress: string
  data: PlacementFormStorageData
}

const formStorage = new GenericStorageManager<PlacementFormStorage>({
  storageKey: 'placementApplication',
  validator: (arg) => {
    const result = schemaToDomain(arg)
    if (!result) return { success: false }
    return { success: true, result: result }
  },
})

export type PlacementApplicationStorageHandler = {
  get: (loginEmailAddress: string) => PlacementFormStorageData | undefined
  set: (loginEmailAddress: string, values: PlacementFormType) => void
  remove: () => void
}

export const placementApplicationStorageHandler = (): PlacementApplicationStorageHandler => {
  const storageGetter = (loginEmailAddress: string) => {
    const result = formStorage.get()
    // NOTE: loginEmailAddress が使用中のアカウントのものと一致しない場合、storageのデータを取得せず破棄する（内部の動作確認等で複数のアカウントを使い分けている場合）
    if (result?.loginEmailAddress !== loginEmailAddress) {
      formStorage.remove()
      return undefined
    }
    return result.data
  }

  const storageSetter = (loginEmailAddress: string, values: PlacementFormType) => {
    const newValues = domainToStorage(loginEmailAddress, values)
    formStorage.set(newValues)
  }

  return {
    get: storageGetter,
    set: storageSetter,
    remove: () => formStorage.remove(),
  }
}

const representativeItems = (
  values: PlacementFormType | z.infer<typeof FormStorageSchema.shape.data>
): RepresentativeFormType => {
  return {
    representativeSurname0: values[representativeFormNamesWithKey.representativeSurname0],
    representativeGivenName0: values[representativeFormNamesWithKey.representativeGivenName0],
    representativeSurnameKana0: values[representativeFormNamesWithKey.representativeSurnameKana0],
    representativeGivenNameKana0: values[representativeFormNamesWithKey.representativeGivenNameKana0],
    representativeSurname1: values[representativeFormNamesWithKey.representativeSurname1],
    representativeGivenName1: values[representativeFormNamesWithKey.representativeGivenName1],
    representativeSurnameKana1: values[representativeFormNamesWithKey.representativeSurnameKana1],
    representativeGivenNameKana1: values[representativeFormNamesWithKey.representativeGivenNameKana1],
    representativeSurname2: values[representativeFormNamesWithKey.representativeSurname2],
    representativeGivenName2: values[representativeFormNamesWithKey.representativeGivenName2],
    representativeSurnameKana2: values[representativeFormNamesWithKey.representativeSurnameKana2],
    representativeGivenNameKana2: values[representativeFormNamesWithKey.representativeGivenNameKana2],
    representativeSurname3: values[representativeFormNamesWithKey.representativeSurname3],
    representativeGivenName3: values[representativeFormNamesWithKey.representativeGivenName3],
    representativeSurnameKana3: values[representativeFormNamesWithKey.representativeSurnameKana3],
    representativeGivenNameKana3: values[representativeFormNamesWithKey.representativeGivenNameKana3],
    representativeSurname4: values[representativeFormNamesWithKey.representativeSurname4],
    representativeGivenName4: values[representativeFormNamesWithKey.representativeGivenName4],
    representativeSurnameKana4: values[representativeFormNamesWithKey.representativeSurnameKana4],
    representativeGivenNameKana4: values[representativeFormNamesWithKey.representativeGivenNameKana4],
    representativeSurname5: values[representativeFormNamesWithKey.representativeSurname5],
    representativeGivenName5: values[representativeFormNamesWithKey.representativeGivenName5],
    representativeSurnameKana5: values[representativeFormNamesWithKey.representativeSurnameKana5],
    representativeGivenNameKana5: values[representativeFormNamesWithKey.representativeGivenNameKana5],
    representativeSurname6: values[representativeFormNamesWithKey.representativeSurname6],
    representativeGivenName6: values[representativeFormNamesWithKey.representativeGivenName6],
    representativeSurnameKana6: values[representativeFormNamesWithKey.representativeSurnameKana6],
    representativeGivenNameKana6: values[representativeFormNamesWithKey.representativeGivenNameKana6],
    representativeSurname7: values[representativeFormNamesWithKey.representativeSurname7],
    representativeGivenName7: values[representativeFormNamesWithKey.representativeGivenName7],
    representativeSurnameKana7: values[representativeFormNamesWithKey.representativeSurnameKana7],
    representativeGivenNameKana7: values[representativeFormNamesWithKey.representativeGivenNameKana7],
    representativeSurname8: values[representativeFormNamesWithKey.representativeSurname8],
    representativeGivenName8: values[representativeFormNamesWithKey.representativeGivenName8],
    representativeSurnameKana8: values[representativeFormNamesWithKey.representativeSurnameKana8],
    representativeGivenNameKana8: values[representativeFormNamesWithKey.representativeGivenNameKana8],
    representativeSurname9: values[representativeFormNamesWithKey.representativeSurname9],
    representativeGivenName9: values[representativeFormNamesWithKey.representativeGivenName9],
    representativeSurnameKana9: values[representativeFormNamesWithKey.representativeSurnameKana9],
    representativeGivenNameKana9: values[representativeFormNamesWithKey.representativeGivenNameKana9],
    representativeSurname10: values[representativeFormNamesWithKey.representativeSurname10],
    representativeGivenName10: values[representativeFormNamesWithKey.representativeGivenName10],
    representativeSurnameKana10: values[representativeFormNamesWithKey.representativeSurnameKana10],
    representativeGivenNameKana10: values[representativeFormNamesWithKey.representativeGivenNameKana10],
    representativeSurname11: values[representativeFormNamesWithKey.representativeSurname11],
    representativeGivenName11: values[representativeFormNamesWithKey.representativeGivenName11],
    representativeSurnameKana11: values[representativeFormNamesWithKey.representativeSurnameKana11],
    representativeGivenNameKana11: values[representativeFormNamesWithKey.representativeGivenNameKana11],
    representativeSurname12: values[representativeFormNamesWithKey.representativeSurname12],
    representativeGivenName12: values[representativeFormNamesWithKey.representativeGivenName12],
    representativeSurnameKana12: values[representativeFormNamesWithKey.representativeSurnameKana12],
    representativeGivenNameKana12: values[representativeFormNamesWithKey.representativeGivenNameKana12],
    representativeSurname13: values[representativeFormNamesWithKey.representativeSurname13],
    representativeGivenName13: values[representativeFormNamesWithKey.representativeGivenName13],
    representativeSurnameKana13: values[representativeFormNamesWithKey.representativeSurnameKana13],
    representativeGivenNameKana13: values[representativeFormNamesWithKey.representativeGivenNameKana13],
    representativeSurname14: values[representativeFormNamesWithKey.representativeSurname14],
    representativeGivenName14: values[representativeFormNamesWithKey.representativeGivenName14],
    representativeSurnameKana14: values[representativeFormNamesWithKey.representativeSurnameKana14],
    representativeGivenNameKana14: values[representativeFormNamesWithKey.representativeGivenNameKana14],
    representativeSurname15: values[representativeFormNamesWithKey.representativeSurname15],
    representativeGivenName15: values[representativeFormNamesWithKey.representativeGivenName15],
    representativeSurnameKana15: values[representativeFormNamesWithKey.representativeSurnameKana15],
    representativeGivenNameKana15: values[representativeFormNamesWithKey.representativeGivenNameKana15],
    representativeSurname16: values[representativeFormNamesWithKey.representativeSurname16],
    representativeGivenName16: values[representativeFormNamesWithKey.representativeGivenName16],
    representativeSurnameKana16: values[representativeFormNamesWithKey.representativeSurnameKana16],
    representativeGivenNameKana16: values[representativeFormNamesWithKey.representativeGivenNameKana16],
    representativeSurname17: values[representativeFormNamesWithKey.representativeSurname17],
    representativeGivenName17: values[representativeFormNamesWithKey.representativeGivenName17],
    representativeSurnameKana17: values[representativeFormNamesWithKey.representativeSurnameKana17],
    representativeGivenNameKana17: values[representativeFormNamesWithKey.representativeGivenNameKana17],
    representativeSurname18: values[representativeFormNamesWithKey.representativeSurname18],
    representativeGivenName18: values[representativeFormNamesWithKey.representativeGivenName18],
    representativeSurnameKana18: values[representativeFormNamesWithKey.representativeSurnameKana18],
    representativeGivenNameKana18: values[representativeFormNamesWithKey.representativeGivenNameKana18],
    representativeSurname19: values[representativeFormNamesWithKey.representativeSurname19],
    representativeGivenName19: values[representativeFormNamesWithKey.representativeGivenName19],
    representativeSurnameKana19: values[representativeFormNamesWithKey.representativeSurnameKana19],
    representativeGivenNameKana19: values[representativeFormNamesWithKey.representativeGivenNameKana19],
  }
}

const areaItems = (values: PlacementFormType): PlacementFormStorageData => {
  let areas: string[] = []
  for (let id = 0; id < MAX_SUPPORT_AREA_NUMBER; id++) {
    // @ts-expect-error appendix form key
    const ids: string[] = values[`${AREA_FORM_PREFIX.city}${id}`]
    if (ids) areas = areas.concat(ids)
  }
  return { [formNames.areas]: areas }
}

const basicInfoAreaItems = (values: PlacementFormType): PlacementFormStorageData => {
  return {
    [formNames.prefecture]: values.prefecture,
    [formNames.city]: values.city,
    [formNames.otherArea]: values.otherArea,
  }
}

// UIのformから取得したデータをlocalstorageに保存するデータに変換する
const domainToStorage = (loginEmailAddress: string, values: PlacementFormType): PlacementFormStorage => {
  const zodValue: PlacementFormStorageData = {
    [formNames.profile1Image]: values.profile1Image,
    [formNames.description1]: values.description1,
    [formNames.profile2Image1]: values.profile2Image1,
    [formNames.profile2Image2]: values.profile2Image2,
    [formNames.profile2Image3]: values.profile2Image3,
    [formNames.professionalCategory]: values.professionalCategory,
    [formNames.officeName]: values.officeName,
    [formNames.officeNameKana]: values.officeNameKana,
    [formNames.description2]: values.description2,
    [formNames.detail2]: values.detail2,
    [formNames.profile3Image]: values.profile3Image,
    [formNames.description3]: values.description3,
    [formNames.detail3]: values.detail3,
    [formNames.additionalInfo]: values.additionalInfo,
    [formNames.phoneNumber]: values.phoneNumber,
    [formNames.phoneContactDisabled]: values.phoneContactDisabled,
    [formNames.reception.receptionHours]: values.receptionHours,
    [formNames.reception.receptionCall24HoursAvailable]: values.receptionCall24HoursAvailable,
    [formNames.reception.receptionHoursNote]: values.receptionHoursNote,
    [formNames.reception.regularHoliday]: values.regularHoliday,
    [formNames.access]: values.access,
    [formNames.url]: values.url,
    [formNames.postCode.first]: values.postCodeFirst,
    [formNames.postCode.second]: values.postCodeSecond,
    [formNames.townAndStreetAddress]: values.townAndStreetAddress,
    [formNames.building]: values.building,
    [formNames.isWholeArea]: values.isWholeArea,
    [formNames.supportServices]: values.supportServices,
    [formNames.emailAddress]: values.emailAddress,
    [formNames.affiliation]: values.affiliation,
    [formNames.affiliationOther]: values.affiliationOther,
    [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]: values.summaryCourtLegalRepresentationSurname,
    [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]:
      values.summaryCourtLegalRepresentationGivenName,
    [formNames.summaryCourtLegalRepresentation.certificationNumber]:
      values.summaryCourtLegalRepresentationCertificationNumber,
    [formNames.consultationFee.consultationFeeType]: values.consultationFeeType,
    [formNames.consultationFee.consultationFreeTimeLimit]: values.consultationFreeTimeLimit,
    [formNames.consultationFee.consultationFeeNote]: values.consultationFeeNote,
    [formNames.mobilizationFee]: values.mobilizationFee,
    [formNames.reward]: values.reward,
    [formNames.contactSurname]: values.contactSurname,
    [formNames.contactGivenName]: values.contactGivenName,
    [formNames.contactSurnameKana]: values.contactSurnameKana,
    [formNames.contactGivenNameKana]: values.contactGivenNameKana,
    ...areaItems(values),
    ...basicInfoAreaItems(values),
    ...representativeItems(values),
  }

  return {
    loginEmailAddress,
    data: zodValue,
  }
}

// localstorageから取得したany型のデータを、zodのparseを通してUIのフォームで利用するデータに変換する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const schemaToDomain = (object: any): PlacementFormStorage | undefined => {
  let result = FormStorageSchema.safeParse(object)

  // localstorageの保存データと最新のフォームデータの型が異なる場合、型が異なる項目のみ保存データのみ復元の対象外とし、型が正しい項目は復元する
  if (!result.success) {
    const reducedObject = JSON.parse(JSON.stringify(object))
    result.error.issues.forEach((issue) => {
      const errorKey = issue.path[0]
      delete reducedObject[errorKey]
    })
    result = FormStorageSchema.safeParse(reducedObject)
  }

  if (!result.success) return undefined
  const loginEmailAddress = result.data.loginEmailAddress
  const data = result.data.data

  return {
    loginEmailAddress,
    data: {
      [formNames.profile1Image]: data.profile1Image,
      [formNames.description1]: data.description1,
      [formNames.profile2Image1]: data.profile2Image1,
      [formNames.profile2Image2]: data.profile2Image2,
      [formNames.profile2Image3]: data.profile2Image3,
      [formNames.professionalCategory]: data.professionalCategory,
      [formNames.officeName]: data.officeName,
      [formNames.officeNameKana]: data.officeNameKana,
      [formNames.description2]: data.description2,
      [formNames.detail2]: data.detail2,
      [formNames.profile3Image]: data.profile3Image,
      [formNames.description3]: data.description3,
      [formNames.detail3]: data.detail3,
      [formNames.additionalInfo]: data.additionalInfo,
      [formNames.phoneNumber]: data.phoneNumber,
      [formNames.phoneContactDisabled]: data.phoneContactDisabled,
      [formNames.reception.receptionHours]:
        data.receptionHours && data.receptionHours.length === 2
          ? [dayjs(data.receptionHours[0]), dayjs(data.receptionHours[1])]
          : [],
      [formNames.reception.receptionCall24HoursAvailable]: data.receptionCall24HoursAvailable,
      [formNames.reception.receptionHoursNote]: data.receptionHoursNote,
      [formNames.reception.regularHoliday]: data.regularHoliday
        ? (data.regularHoliday as unknown as RegularHoliday)
        : undefined,
      [formNames.access]: data.access,
      [formNames.url]: data.url,
      [formNames.postCode.first]: data.postCodeFirst,
      [formNames.postCode.second]: data.postCodeSecond,
      [formNames.prefecture]: data.prefecture,
      [formNames.city]: data.city,
      [formNames.otherArea]: data.otherArea,
      [formNames.townAndStreetAddress]: data.townAndStreetAddress,
      [formNames.building]: data.building,
      [formNames.isWholeArea]: data.isWholeArea,
      [formNames.areas]: data.areas,
      [formNames.supportServices]: data.supportServices,
      [formNames.emailAddress]: data.emailAddress,
      [formNames.affiliation]: data.affiliation,
      [formNames.affiliationOther]: data.affiliationOther,
      [formNames.summaryCourtLegalRepresentation.specifiedMember.surname]: data.summaryCourtLegalRepresentationSurname,
      [formNames.summaryCourtLegalRepresentation.specifiedMember.givenName]:
        data.summaryCourtLegalRepresentationGivenName,
      [formNames.summaryCourtLegalRepresentation.certificationNumber]:
        data.summaryCourtLegalRepresentationCertificationNumber,
      [formNames.consultationFee.consultationFeeType]: data.consultationFeeType
        ? (data.consultationFeeType as ConsultationFeeType)
        : undefined,
      [formNames.consultationFee.consultationFreeTimeLimit]: data.consultationFreeTimeLimit,
      [formNames.consultationFee.consultationFeeNote]: data.consultationFeeNote,
      [formNames.mobilizationFee]: data.mobilizationFee,
      [formNames.reward]: data.reward,
      [formNames.contactSurname]: data.contactSurname,
      [formNames.contactGivenName]: data.contactGivenName,
      [formNames.contactSurnameKana]: data.contactSurnameKana,
      [formNames.contactGivenNameKana]: data.contactGivenNameKana,
      ...representativeItems(data),
    },
  }
}
