import { css } from '@emotion/react'
import { Form, Input, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormLabel } from 'inheritance-components'
import { judgeRequired } from 'inheritance-components'
import { FormLabelWithGuideText } from 'inheritance-components'

const styles = {
  halfInput: css`
    display: inline-block;
    width: calc(50% - 8px);
  `,
  doubleForm: css`
    margin-bottom: 0;
  `,
  annotation: css`
    display: block;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    margin-top: 4px;
  `,
}

export const DoubleTextInputForm = (props: {
  label: string
  first: {
    name: string
    placeholder: string
    rules?: Rule[]
    annotation?: string
  }
  second: { name: string; placeholder: string; rules?: Rule[] }
  onInput?: () => void
  guideText?: string
  colon?: boolean
}) => {
  const required = judgeRequired([...(props.first.rules ?? []), ...(props.second.rules ?? [])])

  return (
    <Form.Item
      css={styles.doubleForm}
      // guideTextが指定される場合、guideTextでFormLabelをラップしたFormLabelWithGuideTextを使う。
      label={
        props.guideText ? (
          <FormLabelWithGuideText label={props.label} guideText={props.guideText} required={required} />
        ) : (
          <FormLabel label={props.label} required={required} />
        )
      }
      colon={props.colon}
    >
      <Form.Item
        css={styles.halfInput}
        name={props.first.name}
        hasFeedback
        rules={props.first.rules}
        extra={
          props.first.annotation && <Typography.Text css={styles.annotation}>{props.first.annotation}</Typography.Text>
        }
      >
        <Input placeholder={props.first.placeholder} onInput={props.onInput} />
      </Form.Item>
      <Form.Item
        css={styles.halfInput}
        style={{ margin: '0 8px' }}
        name={props.second.name}
        hasFeedback
        rules={props.second.rules}
      >
        <Input placeholder={props.second.placeholder} onInput={props.onInput} />
      </Form.Item>
    </Form.Item>
  )
}
