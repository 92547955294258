import { css } from '@emotion/react'
import { Typography } from 'antd'
import { EmailForm, EmailFormProps } from './EmailForm'
import { Wave } from './Wave'
import serviceLogo from '@/assets/lp/service_logo.svg'
import heroImage from '@/assets/lp/professional_lp_hero.jpg'
import particle1 from '@/assets/lp/particle1.svg'
import particle2 from '@/assets/lp/particle2.svg'
import { useLandingPageDesignToken } from './design-system/useLandingPageDesignToken'

const useStyles = () => {
  const { color, mediaQuery } = useLandingPageDesignToken()

  return {
    hero: {
      wrap: css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 620px;
        overflow: hidden;
        ${mediaQuery.small} {
          height: 800px;
        }
      `,
      contents: css`
        position: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        width: 100%;
        z-index: 2;
        ${mediaQuery.small} {
          flex-direction: column;
          gap: 34px;
          margin-top: 120px;
        }
      `,
      image: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        opacity: 0.35;
        background-image: url(${heroImage});
        background-position: center;
        z-index: 0;
      `,
      description: css`
        width: 480px;
      `,
      headingText: css`
        display: block;
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        ${mediaQuery.small} {
          text-align: center;
        }
      `,
      heading: css`
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        margin: 8px 0 20px;
        em {
          color: ${color.accent.red.default};
        }
        ${mediaQuery.small} {
          text-align: center;
        }
      `,
      logo: css`
        display: block;
        width: 372px;
        margin: 28px 12px;
        ${mediaQuery.small} {
          margin: 28px auto;
        }
      `,
      text: css`
        font-size: 14px;
        line-height: 22px;
      `,
    },
    element: {
      particle1: css`
        position: absolute;
        bottom: 0;
        left: 36px;
        z-index: 1;
      `,
      particle2: css`
        position: absolute;
        bottom: 0;
        right: 24px;
        z-index: 1;
      `,
      wave1: css`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 0;
      `,
    },
    emailForm: css`
      width: 408px;
      box-shadow: 0 8px 8px 8px rgb(0 0 0 / 5%);
      z-index: 2;
      ${mediaQuery.small} {
        width: 540px;
      }
    `,
  }
}

export const Hero = (props: { emailFormProps: EmailFormProps }) => {
  const styles = useStyles()

  return (
    <div css={styles.hero.wrap}>
      <div css={styles.hero.contents}>
        <div css={styles.hero.description}>
          <Typography.Text css={styles.hero.headingText}>相続でお悩みの方と専門家をつなぐ</Typography.Text>
          <h1 css={styles.hero.heading}>
            <em>三菱ＵＦＪ銀行</em>の相続支援サービス
          </h1>
          <img src={serviceLogo} css={styles.hero.logo} alt="そうぞくガイド" />
          <Typography.Text css={styles.hero.text}>
            そうぞくガイドは、相続分野の専門家（弁護士・税理士・司法書士）を検索できるWebサービスです。相続領域での集客に力を入れたいとお考えの専門家の方にご利用いただけます。右記申し込みフォームよりお問い合わせください。
          </Typography.Text>
        </div>
        <EmailForm {...props.emailFormProps} rootCss={styles.emailForm} />
      </div>

      <div css={styles.hero.image} />
      <img css={styles.element.particle1} src={particle1} />
      <img css={styles.element.particle2} src={particle2} />
      <Wave type="purple" direction="down" rootCss={styles.element.wave1} />
    </div>
  )
}
