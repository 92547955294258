import { ConsultationFeeType } from 'inheritance-api'

export type CONSULTATION_FEE_TYPE_VALUES = ConsultationFeeType | undefined
/**
 * 初回相談無料（時間制限あり）
 */
export const CONSULTATION_FEE_TYPE_TIME_LIMIT: ConsultationFeeType = 'freeForFirstTimeWithTimeLimit'
export const isConsultationFeeTypeTimeLimit = (value?: string | number): boolean =>
  value === CONSULTATION_FEE_TYPE_TIME_LIMIT
export const CONSULTATION_FEE_TYPE = {
  freeForFirstTime: '初回相談無料（時間制限なし）',
  [CONSULTATION_FEE_TYPE_TIME_LIMIT]: '初回相談無料（時間制限あり）',
  freeAnytime: '相談は何度でも無料',
  other: 'その他',
} as const satisfies Record<ConsultationFeeType, string>
export const CONSULTATION_FEE_TYPE_FOR_DISPLAY = {
  freeForFirstTime: '初回相談無料',
  [CONSULTATION_FEE_TYPE_TIME_LIMIT]: '初回相談無料',
  freeAnytime: '相談は何度でも無料',
  other: '',
} as const satisfies Record<ConsultationFeeType, string>
export const CONSULTATION_FEE_TYPE_OPTIONS = [
  { label: CONSULTATION_FEE_TYPE.freeForFirstTime, value: 'freeForFirstTime' },
  { label: CONSULTATION_FEE_TYPE.freeForFirstTimeWithTimeLimit, value: CONSULTATION_FEE_TYPE_TIME_LIMIT },
  { label: CONSULTATION_FEE_TYPE.freeAnytime, value: 'freeAnytime' },
  { label: CONSULTATION_FEE_TYPE.other, value: 'other' },
]
