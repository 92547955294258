import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Divider, Modal, Typography } from 'antd'
import { AgreementProps } from './AgreementProps'
import { AgreementContent } from './AgreementContent'
import { css } from '@emotion/react'

const style = {
  content: css`
    max-height: 60vh;
    overflow-y: scroll;
  `,
  noMarginDivider: css`
    margin: 0;
  `,
  footerButtons: css`
    margin-top: 8px;
  `,
}

export const Agreements = ({
  agreementProps,
  onAgreed,
}: {
  agreementProps: AgreementProps
  onAgreed: (agreed: boolean) => void
}) => {
  const [open, setOpen] = useState(false)
  const [allSeen, setAllSeen] = useState(false)

  const modalContentRef = useRef<HTMLDivElement>(null)

  const onAgree = (agreed: boolean) => {
    onAgreed(agreed)
    setOpen(false)
  }

  const toggleAllSeen = useCallback(() => {
    const modalContent = modalContentRef.current
    if (!modalContent) return

    const { clientHeight, scrollTop, scrollHeight } = modalContent
    // 小数を含む可能性があるのに対して、スクロール量が閾値判定1pxの誤差を許容Math.abs()を使用
    if (Math.abs(clientHeight + scrollTop - scrollHeight) <= 1) setAllSeen(true)
  }, [])

  useEffect(() => {
    const modalContent = modalContentRef.current
    if (!modalContent) return

    modalContent.addEventListener('scroll', toggleAllSeen)
    return () => {
      // ダイアログを閉じた時に、スクロール位置と同意ボタンを元に戻す
      if (open) {
        setAllSeen(false)
        modalContent.scrollTo({ top: 0 })
      }
      modalContent.removeEventListener('scroll', toggleAllSeen)
    }
  }, [toggleAllSeen, open])

  return (
    <>
      <Button type="link" onClick={() => setOpen(true)}>
        {agreementProps.title}
      </Button>
      <Modal
        centered
        title={agreementProps.title}
        open={open}
        width={1000}
        onCancel={() => onAgree(false)}
        footer={
          <>
            <Typography.Text type="secondary">
              広告主規約に同意するには、コンテンツを最下部までスクロールしてください。
            </Typography.Text>
            <div css={style.footerButtons}>
              <Button onClick={() => onAgree(false)}>同意しない</Button>
              <Button type="primary" disabled={!allSeen} onClick={() => onAgree(true)}>
                同意する
              </Button>
            </div>
          </>
        }
      >
        <Divider css={style.noMarginDivider} />
        <div css={style.content} ref={modalContentRef}>
          <AgreementContent agreementProps={agreementProps} />
        </div>
        <Divider css={style.noMarginDivider} />
      </Modal>
    </>
  )
}
