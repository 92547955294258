export type Action = 'signin' | 'sendPasswordResetEmail' | 'confirmPasswordReset' | 'updatePasswordReset'

export type ErrorMessage = {
  message: string
  description?: string
}

type ErrorMessageList = {
  [key: string]: ErrorMessage
}

type Errors = {
  [key in Action]: ErrorMessageList
}

// firebaseのAPIが返すエラーコードからアプリで使うものを抜粋
export const ERROR_CODES = {
  userDisabled: 'auth/user-disabled',
  tooManyRequests: 'auth/too-many-requests',
  networkRequestFailed: 'auth/network-request-failed',
  invalidLoginCredentials: 'auth/invalid-login-credentials',
  invalidEmail: 'auth/invalid-email',
  invalidPhoneNumber: 'auth/invalid-phone-number',
  missingPhoneNumber: 'auth/missing-phone-number',
  adminRestrictedOperation: 'auth/admin-restricted-operation',
  mfaRetryCountExceeded: 'mfa-retry-count-exceeded',
  invalidVerificationCode: 'auth/invalid-verification-code',
  codeExpired: 'auth/code-expired',
  missingVerificationCode: 'auth/missing-verification-code',
  invalidVerificationId: 'auth/invalid-verification-id',
  missingVerificationId: 'auth/missing-verification-id',
  invalidMultiFactorSession: 'auth/invalid-multi-factor-session',
  missingMultiFactorSession: 'auth/missing-multi-factor-session',
  invalidAppCredential: 'auth/invalid-app-credential',
  expiredActionCode: 'auth/expired-action-code',
  invalidActionCode: 'auth/invalid-action-code',
  weakPassword: 'auth/weak-password',
  requiresRecentLogin: 'auth/requires-recent-login',
} as const

/**
 * Common Error
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
 */

const commonError: ErrorMessageList = {
  [ERROR_CODES.userDisabled]: {
    message: '無効なユーザーです',
  },
  [ERROR_CODES.tooManyRequests]: {
    message: '連続でアクセスがあっため、一時的に機能が制限されています。',
    description: 'しばらく時間をあけてからお試し下さい。',
  },
  [ERROR_CODES.networkRequestFailed]: {
    message: 'ネットワークエラーが発生しました。',
    description: 'ネットワークの接続状態をご確認下さい。',
  },
}

/**
 * signInWithEmailAndPassword
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
 * resolveSignIn
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.multifactorresolver#resolvesignin
 */

const signinError: ErrorMessageList = {
  ...commonError,
  [ERROR_CODES.invalidLoginCredentials]: {
    message: 'ログインに失敗しました',
    description: '登録したメールアドレスとパスワードをご確認ください。',
  },
  [ERROR_CODES.invalidEmail]: {
    message: 'ログインに失敗しました',
    description: '登録したメールアドレスとパスワードをご確認ください。',
  },
  [ERROR_CODES.mfaRetryCountExceeded]: {
    message: '認証に失敗しました',
    description: '繰り返し認証に失敗しました。認証コードを再送信してお試しください。',
  },
  [ERROR_CODES.invalidVerificationCode]: {
    message: '認証に失敗しました',
    description: 'ご確認の上、認証コードを入力してください。',
  },
  [ERROR_CODES.codeExpired]: {
    message: '認証に失敗しました',
    description: '認証コードの有効期限が切れています。認証コードを再送信してお試しください。',
  },
  [ERROR_CODES.missingVerificationCode]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って認証コードを再送信して下さい。',
  },
  [ERROR_CODES.invalidVerificationId]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って認証コードを再送信して下さい。',
  },
  [ERROR_CODES.missingVerificationId]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って認証コードを再送信して下さい。',
  },
  [ERROR_CODES.invalidMultiFactorSession]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って認証コードを再送信して下さい。',
  },
  [ERROR_CODES.missingMultiFactorSession]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って認証コードを再送信して下さい。',
  },
  // recaptcha error
  [ERROR_CODES.invalidAppCredential]: {
    message: '認証に失敗しました',
    description: 'ログイン画面に戻って再度ログインして下さい。',
  },
  // signinWithPhoneNumber() error
  [ERROR_CODES.invalidPhoneNumber]: {
    message: '認証に失敗しました',
    description: '登録した電話番号をご確認ください。',
  },
  [ERROR_CODES.missingPhoneNumber]: {
    message: '認証に失敗しました',
    description: '登録した電話番号をご確認ください。',
  },
  // 電話番号認証の失敗でSMS送信が失敗した場合に返るエラー（auth/user-not-found のようなエラーコードが返る訳ではない模様）
  [ERROR_CODES.adminRestrictedOperation]: {
    message: '認証に失敗しました',
    description: '登録した電話番号をご確認ください。',
  },
}

/**
 * sendPasswordResetEmail
 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
 */

const sendPasswordResetEmailError: ErrorMessageList = {
  ...commonError,
  [ERROR_CODES.invalidEmail]: {
    message: '送信に失敗しました',
    description: 'メールアドレスをご確認下さい。',
  },
  [ERROR_CODES.invalidLoginCredentials]: {
    message: '送信に失敗しました',
    description: 'メールアドレスをご確認下さい。',
  },
}

const confirmPasswordResetError: ErrorMessageList = {
  ...commonError,
  [ERROR_CODES.expiredActionCode]: {
    message: 'URLの有効期限が切れています',
    description: 'リンクを送信し直してください',
  },
  [ERROR_CODES.invalidActionCode]: {
    message: '無効なURLです',
    description: 'メールアドレス宛に受信したリンクをご確認下さい。',
  },
  [ERROR_CODES.weakPassword]: {
    message: 'パスワードの強度に問題があります',
    description: 'パスワードの形式をご確認下さい。',
  },
}

const updatePasswordResetError: ErrorMessageList = {
  ...commonError,
  [ERROR_CODES.weakPassword]: {
    message: 'パスワードの強度に問題があります',
    description: 'パスワードの形式をご確認下さい',
  },
  [ERROR_CODES.requiresRecentLogin]: {
    message: 'ログインから一定時間が経過しています',
    description: 'ログアウト後、再度ログインしてからパスワードを変更してください',
  },
}

export const errors: Errors = {
  signin: signinError,
  sendPasswordResetEmail: sendPasswordResetEmailError,
  confirmPasswordReset: confirmPasswordResetError,
  updatePasswordReset: updatePasswordResetError,
}
