import { GetApiVersionResponse } from 'inheritance-api'
import { buildRethrowingError } from '../buildRethrowingError'
import { apiVersionClient } from '../client'

/**
 * 1. このAPIをキャッシュ付きで呼び出しておく
 * 2. キャッシュせず他のAPIを呼ぶ前にこのAPIを呼び出す
 * 3. APIのresponsenのハッシュが違ったらリフレッシュ
 */
export const callApiVersion = async () => {
  try {
    const data = await apiVersionClient.getApiVersion().then((res) => res.data as GetApiVersionResponse)
    return data
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
