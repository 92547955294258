type ProfessionalCategoryCode = 'lawyer' | 'taxCounselor' | 'judicialScrivener'
type AffiliationLabel = '所属弁護士会' | '所属税理士会' | '所属司法書士会' | '所属団体'

export const toProfessionalCategoryCode = (
  maybeCategoryCode: string | undefined
): ProfessionalCategoryCode | undefined => {
  switch (maybeCategoryCode) {
    case 'lawyer':
      return 'lawyer'
    case 'taxCounselor':
      return 'taxCounselor'
    case 'judicialScrivener':
      return 'judicialScrivener'
    default:
      return undefined
  }
}

export const AFFILIATION_LABEL: {
  [key in ProfessionalCategoryCode]: AffiliationLabel
} = {
  lawyer: '所属弁護士会',
  taxCounselor: '所属税理士会',
  judicialScrivener: '所属司法書士会',
}

/** フォーム入力値などのstringから所属団体のラベルを取得する */
export const affiliationLabelFromString = (category?: string): AffiliationLabel => {
  if (category === 'lawyer' || category === 'taxCounselor' || category === 'judicialScrivener') {
    return AFFILIATION_LABEL[category]
  }
  return '所属団体'
}
