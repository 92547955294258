import { createContext, ReactNode, useContext } from 'react'
import { callGetAdditionalInfo } from './additionalInfo/function'
import { callGetSupportServiceMaster } from './supportServiceMaster/function'
import { callGetProfessionalCategories } from './professionalCategory/function'
import {
  callPostProfessionalPasswordResetSendEmail,
  callGetProfessionalPasswordResetValidate,
  callPutProfessionalPasswordResetConfirm,
} from './professionalPasswordReset/function'
import { callPutProfessionalChurn } from './professionalChurn/function'
import {
  callCreateContractApplcation,
  callSendEmailForContractApplication,
  callValidateEmailToken,
} from './professionalContract/function'
import {
  callGetProfessionalCreateAccountValidate,
  callPutProfessionalCreateAccount,
} from './professionalCreateAccount/function'
import { callGetProfessionalPlacementDefaults, callPutProfessionalPlacement } from './professionalPlacement/function'
import { callPutProfessionalPlacementImage, placementImageFetcher } from './professionalPlacementImage/function'
import { callGetProfessionalSelf } from './professionalSelf/function'
import { callGetProfessionalUserVisitData } from './professionalUserVisitData/function'
import { callUpdateProfessionalContactSuspended } from './professionalContactSuspend/function'
import { callApiVersion } from './apiVersion/function'
import { callPostProfessionalError } from './professionalError/function'
import { callPutProfessionalPreviewPlacement } from './professionalPreviewPlacement/function'
import { callServiceInformation } from './serviceInformation/function'

const rpcDefaultFuncs = {
  callGetAdditionalInfo,
  callGetSupportServiceMaster,
  callGetProfessionalCategories,
  callPostProfessionalPasswordResetSendEmail,
  callGetProfessionalPasswordResetValidate,
  callPutProfessionalPasswordResetConfirm,
  callPutProfessionalChurn,
  callUpdateProfessionalContactSuspended,
  callSendEmailForContractApplication,
  callValidateEmailToken,
  callCreateContractApplcation,
  callGetProfessionalCreateAccountValidate,
  callPutProfessionalCreateAccount,
  callGetProfessionalPlacementDefaults,
  callPutProfessionalPlacement,
  callPutProfessionalPlacementImage,
  callPutProfessionalPreviewPlacement,
  callGetProfessionalSelf,
  callGetProfessionalUserVisitData,
  placementImageFetcher,
  callApiVersion,
  callPostProfessionalError,
  callServiceInformation,
}

type RpcContextValueType = typeof rpcDefaultFuncs

const RpcContext = createContext<RpcContextValueType>(rpcDefaultFuncs)

export const RpcContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode
  value?: Partial<RpcContextValueType>
}) => {
  if (!props.value) {
    return <RpcContext.Provider value={rpcDefaultFuncs}>{children}</RpcContext.Provider>
  }

  const specifiedFuncs = props.value as { [key: string]: unknown }

  const value = Object.keys(rpcDefaultFuncs).reduce((acc, cur) => {
    if (specifiedFuncs[cur]) {
      return { ...acc, [cur]: specifiedFuncs[cur] }
    }
    return acc
  }, rpcDefaultFuncs)

  return <RpcContext.Provider value={value}>{children}</RpcContext.Provider>
}

export const useRpcContext = () => {
  return useContext(RpcContext)
}
