import { css } from '@emotion/react'
import { Skeleton, Table } from 'antd'
import { Suspense } from 'react'
import { AccessInformationTable, accessInfoColumns } from 'inheritance-components'
import { useGetProfessionalUserVisitData } from '@/rpc/professionalUserVisitData/useSWR'

const styles = {
  wrapper: css`
    margin-bottom: 80px;
  `,
}

export const AccessInformationContainer = () => {
  return (
    <div css={styles.wrapper}>
      <Suspense
        fallback={
          <Table
            columns={accessInfoColumns}
            pagination={false}
            loading
            locale={{
              emptyText: <Skeleton />,
            }}
          />
        }
      >
        <AccessInformationInner />
      </Suspense>
    </div>
  )
}

const AccessInformationInner = () => {
  const { items } = useGetProfessionalUserVisitData()

  return <AccessInformationTable userVisitData={items} />
}
