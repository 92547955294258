import { Suspense, useMemo, useState } from 'react'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { css } from '@emotion/react'
import { Col, Form, Row, Skeleton, notification } from 'antd'
import { Primal } from './components/Primal'
import { Profile } from './components/profile/Profile'
import { EditForm } from '@/share/components/PlacementForm/EditForm'
import { ConfirmForm } from '@/share/components/PlacementForm/ConfirmForm'
import {
  PlacementFormType,
  composeAreaOption,
  convertFormValuesToItemGroups,
} from '@/share/components/PlacementForm/form'
import { PlacementApplicationDetails, ProfessionalCategoryCode } from 'inheritance-api'
import { useProfessionalCategories } from '@/rpc/professionalCategory/useSWR'
import { useAdditionalInfo } from '@/rpc/additionalInfo/useSWR'
import { useGetSupportServiceMaster } from '@/rpc/supportServiceMaster/useSWR'
import { UseProfessionalSelf, useProfessionalSelf } from '@/rpc/professionalSelf/useSWR'
import { supportAreaMaster } from 'inheritance-utils'
import { getTypeSafeHomeValue } from './homeValue'
import { useUploadImageHandler, usePlacementApplication, UploadImage } from '@/share/components/PlacementForm/hooks'
import { ContactSuspendEventHandler } from './components/profile-parts/publicInfo/PublicInfoContactSuspendedButton'
import { useRpcContext } from '@/rpc/RpcContext'
import { getErrMsg } from '@/share/error'
import { placementApplicationStorageHandler } from '@/share/components/PlacementForm/functions/storage/'
import { FormInstance } from 'antd/lib/form'
import { PreviewHeaderWithActions } from '@/share/components/Preview/PreviewHeaderWithActions'
import {
  PlacementFormProvider,
  buildPlacementFormState,
  usePlacementFormContext,
} from '@/share/components/contexts/PlacementFormProvider'

const styles = {
  fallbackRow: css`
    margin-top: 60px;
  `,
  form: {
    form: (props: { hidden: boolean }) => css`
      display: ${props.hidden ? 'none' : 'block'};
    `,
    title: css`
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    `,
  },
  divider: css`
    margin: 0;
  `,
}

const scrollToTop = () => window.scrollTo({ top: 0 })

type Mode = 'edit' | 'confirm' | 'default'
type Props = {
  mode: Mode
  setMode: (mode: Mode) => void
  form: FormInstance<PlacementFormType>
  uploadImage: UploadImage
  uploadingImage: boolean
  professionalSelf: UseProfessionalSelf
  professionalCategoryCode: ProfessionalCategoryCode
}

const HomeInner = ({
  mode,
  setMode,
  form,
  uploadImage,
  uploadingImage,
  professionalSelf: { professional, refetch },
  professionalCategoryCode,
}: Props) => {
  const { callUpdateProfessionalContactSuspended: callUpdateProfessionalContactSuspended } = useRpcContext()
  const { professionalCategories } = useProfessionalCategories()
  const { additionalInfo } = useAdditionalInfo(professionalCategoryCode)
  const { supportServicesMaster } = useGetSupportServiceMaster(professionalCategoryCode)

  const [editContent, setEditContent] = useState<PlacementApplicationDetails>()
  const formStorage = placementApplicationStorageHandler()

  const { mutate, mutating } = usePlacementApplication({
    onSuccess: async () => {
      formStorage.remove()
      await refetch()
      setMode('default')
      scrollToTop()
    },
  })

  const formOptions = {
    professionalCategories: professionalCategories.map(({ code, name }) => ({ label: name, value: code })),
    additionalInfo: additionalInfo.map(({ code, description }) => ({ label: description, value: code })),
    supportServices: supportServicesMaster.map(({ code, name }) => ({ value: code, label: name })),
    areaOption: composeAreaOption(supportAreaMaster),
  }

  const onNext = () => {
    setMode('confirm')
    scrollToTop()
  }

  const onBack = () => {
    setMode('edit')
    scrollToTop()
  }

  const onEditClick = (usePublicContent: boolean) => {
    setEditContent(
      usePublicContent ? professional.publicInformation?.content : professional.placementApplication?.content
    )
    setMode('edit')
    scrollToTop()
  }

  const onContactSuspend: ContactSuspendEventHandler = async ({
    suspended: contactSuspend,
  }: {
    suspended: boolean
  }) => {
    const { error } = await callUpdateProfessionalContactSuspended({ contactSuspend })
    if (error) {
      notification.open({
        message: '問い合わせ受付停止ステータスの更新に失敗しました',
        description: getErrMsg({ error }),
        placement: 'top',
      })
      return { ok: false }
    }

    await refetch()
    return { ok: true }
  }

  const onSubmit = async () => {
    await mutate(form.getFieldsValue())
  }

  const itemGroups = useMemo(() => {
    if (mode !== 'confirm') {
      return []
    }
    const values = form.getFieldsValue()
    return convertFormValuesToItemGroups(
      values,
      additionalInfo,
      supportServicesMaster,
      supportAreaMaster,
      professionalCategories,
      professional.contract.businessCategory
    )
  }, [
    mode,
    form,
    additionalInfo,
    supportServicesMaster,
    professionalCategories,
    professional.contract.businessCategory,
  ])

  const homeValues = getTypeSafeHomeValue(professional)
  const { homeCondition, profileChurnStatus } = homeValues

  if (homeCondition === 'primal') {
    if (homeValues.profileChurnStatus === 'can-apply') {
      return <Primal profileChurnStatus="can-apply" />
    }

    const placementEndDate =
      profileChurnStatus === 'terminated' ? homeValues.forceChurn.endDate : homeValues.churnApplication.endDate

    return <Primal profileChurnStatus={profileChurnStatus} placementEndDate={placementEndDate} />
  }

  if (mode === 'default') {
    return (
      <>
        <Profile onEditClick={onEditClick} onContactSuspend={onContactSuspend} homeValues={homeValues} />
      </>
    )
  }

  /**
   * 編集ボタンを押下するまで、editContentはundefined
   */
  if (!editContent) {
    return null
  }

  /**
   * 公開掲載情報のみなら掲載申込画面の文言が掲載情報"修正"となる
   */
  const isUpdateProfile = homeCondition === 'only-public'

  return (
    <>
      <div css={styles.form.form({ hidden: mode !== 'edit' })}>
        <section>
          <EditForm
            form={form}
            formStorage={formStorage}
            onNext={onNext}
            onImageUpload={uploadImage}
            uploadingImage={uploadingImage}
            formOptions={formOptions}
            isUpdateView={isUpdateProfile}
            businessCategory={professional.contract.businessCategory}
            loginEmailAddress={professional.contract.login.emailAddress}
            placementApplicationDefaults={editContent}
          />
        </section>
      </div>
      {mode === 'confirm' && (
        <ConfirmForm itemGroups={itemGroups} onBack={onBack} onSubmit={onSubmit} mutating={mutating} />
      )}
    </>
  )
}

const HomeInnerWrapper = ({
  professionalSelf: { professional, refetch },
}: {
  professionalSelf: UseProfessionalSelf
}) => {
  const [mode, setMode] = useState<Mode>('default')
  const [form] = Form.useForm<PlacementFormType>()
  const { uploadImage, loading: uploadingImage } = useUploadImageHandler({ form })

  const professionalCategoryCode = professional.placementApplication
    ? professional.placementApplication.content.professionalCategory.code
    : professional.publicInformation?.content.professionalCategory.code ?? 'lawyer'

  const { hasSummaryCourtLegalRepresentation } = usePlacementFormContext()

  return (
    <AppLayout
      pageHeader={
        mode === 'edit' || mode === 'confirm' ? (
          <PreviewHeaderWithActions
            form={form}
            loading={uploadingImage}
            professionalCategoryCode={professionalCategoryCode}
            hasSummaryCourtLegalRepresentation={hasSummaryCourtLegalRepresentation}
            isCorporation={professional.contract.businessCategory === 'corporation'}
          />
        ) : undefined
      }
    >
      <Suspense
        fallback={
          <Row css={styles.fallbackRow}>
            <Col offset={2} span={20}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </Row>
        }
      ></Suspense>
      <HomeInner
        mode={mode}
        setMode={setMode}
        form={form}
        uploadImage={uploadImage}
        uploadingImage={uploadingImage}
        professionalSelf={{ professional, refetch }}
        professionalCategoryCode={professionalCategoryCode}
      />
    </AppLayout>
  )
}

export const Home = () => {
  const { professional, refetch } = useProfessionalSelf()
  return (
    <PlacementFormProvider
      state={buildPlacementFormState(
        professional.placementApplication?.content.summaryCourtLegalRepresentationInputDefault
      )}
    >
      <HomeInnerWrapper professionalSelf={{ professional, refetch }} />
    </PlacementFormProvider>
  )
}
