import { getPath } from '@/router'
import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { Button } from 'antd'

export const ContractApplicationEmailAlreadyInUse = () => {
  return (
    <ErrorScreen
      kind="composition"
      title="既にお申し込みされております"
      content={
        <>
          <Button type="primary" href={getPath('lp')}>
            トップ画面に戻る
          </Button>
        </>
      }
    />
  )
}
