import { BusinessCategory, PlacementApplicationDetails, SupportAreaItem } from 'inheritance-api'
import {
  AFFILIATION_LABEL,
  hyphenatePostCode,
  IMAGEPROPS,
  ImageProps,
  PREFECTURE,
  SupportAreaMaster,
} from 'inheritance-utils'
import { ContactSuspended, PlacementDescriptionValue } from './type'
import { getOfficeAddressStringFromApiSchema } from '../util'
import { getReceptionDetailsItems } from './formItems/getReceptionHours'
import { getConsultationFeeItem } from './formItems/getConsultationFee'
import { DescriptionItemGroupTypeWithOrder, DescriptionItemTypeWithOrder } from '../generic/type'

export const MAX_GROUP_ORDER = 9 // 一番最後のグループのorder（現在は問い合わせ停止中のグループ）。変わる場合はこの値を変更してください

const summaryCourtLegalRepresentation = (
  value: PlacementApplicationDetails,
  businessCategory?: BusinessCategory
): DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] => {
  if (value.professionalCategory.code !== 'judicialScrivener') return []

  const nameLabel = businessCategory === 'individual' ? '認定司法書士' : '特定社員'

  if (!value.summaryCourtLegalRepresentation) {
    return [
      { label: nameLabel, value: { kind: 'text', text: '' }, itemOrder: 16 },
      { label: '簡裁代理認定番号', value: { kind: 'text', text: '' }, itemOrder: 17 },
    ]
  }

  const {
    specifiedMember: { surname, givenName },
    certificationNumber,
  } = value.summaryCourtLegalRepresentation

  return [
    { label: nameLabel, value: { kind: 'text', text: `${surname} ${givenName}` }, itemOrder: 16 },
    { label: '簡裁代理認定番号', value: { kind: 'text', text: certificationNumber }, itemOrder: 17 },
  ]
}

export const placementApiSchemaToItemGroup = ({
  value,
  contactSuspended,
  supportAreaMaster,
  businessCategory,
}: {
  value: PlacementApplicationDetails
  contactSuspended: ContactSuspended
  supportAreaMaster: SupportAreaMaster[]
  businessCategory?: BusinessCategory
}): DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[] => {
  const items: DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[] = [
    {
      title: '掲載情報1',
      items: [
        { label: '掲載写真1', value: getImageValue(value, 1), itemOrder: 1 },
        {
          label: '事務所説明1',
          value: { kind: 'text', text: value.description1 ? value.description1.join('\n') : '' },
          itemOrder: 2,
        },
      ],
      groupOrder: 1,
    },
    {
      title: '掲載情報2',
      items: [
        { label: '掲載写真2', value: getImageValue(value, 2), itemOrder: 1 },
        { label: '専門家カテゴリー', value: { kind: 'text', text: value.professionalCategory.name }, itemOrder: 2 },
        { label: '事務所名', value: { kind: 'text', text: value.officeName }, itemOrder: 3 },
        { label: '事務所名（カナ）', value: { kind: 'text', text: value.officeNameKana }, itemOrder: 4 },
        {
          label: '事務所説明2',
          value: { kind: 'text', text: value.description2 ? value.description2.join('\n') : '' },
          itemOrder: 5,
        },
        {
          label: '詳細説明2',
          value: { kind: 'text', text: value.detail2 ? value.detail2.join('\n') : '' },
          itemOrder: 6,
        },
      ],
      groupOrder: 2,
    },
    {
      title: '掲載情報3',
      items: [
        { label: '掲載写真3', value: getImageValue(value, 3), itemOrder: 1 },
        {
          label: '事務所説明3',
          value: { kind: 'text', text: value.description3 ? value.description3.join('\n') : '' },
          itemOrder: 2,
        },
        {
          label: '詳細説明3',
          value: { kind: 'text', text: value.detail3 ? value.detail3.join('\n') : '' },
          itemOrder: 3,
        },
        {
          label: '付属情報',
          value: { kind: 'labels', labels: value.additionalInfo?.map((a) => a.description) ?? [] },
          itemOrder: 4,
        },
      ],
      groupOrder: 3,
    },
    {
      title: '基本情報',
      items: [
        { label: '電話番号', value: { kind: 'text', text: value.phoneNumber }, itemOrder: 1 },
        {
          label: '電話番号掲載設定',
          value: { kind: 'text', text: `電話番号を掲載${value.phoneContactDisabled ? 'しない' : 'する'}` },
          itemOrder: 2,
        },
        ...getReceptionDetailsItems(value.reception), // itemOrder: 3, 4, 5, 6
        { label: 'アクセス', value: { kind: 'text', text: value.access ? value.access.join('\n') : '' }, itemOrder: 7 },
        { label: 'ホームページURL', value: { kind: 'text', text: value.url ?? '' }, itemOrder: 8 },
        {
          label: '郵便番号',
          value: {
            kind: 'text',
            text: hyphenatePostCode(value.officeAddress.postCode),
          },
          itemOrder: 9,
        },
        getOfficeAddressItem(value), // itemOrder: 10
        {
          label: '建物名',
          value: { kind: 'text', text: value.officeAddress.building ?? '' },
          itemOrder: 11,
        },
        { label: 'メールアドレス', value: { kind: 'text', text: value.emailAddress ?? '' }, itemOrder: 12 },
        {
          label: AFFILIATION_LABEL[value.professionalCategory.code],
          value: { kind: 'text', text: value.affiliation },
          itemOrder: 13, // 14: 自由記入
        },
        ...getRepresentativeItem(value), // itemOrder: 15
        ...summaryCourtLegalRepresentation(value, businessCategory), // itemOrder: 16, 17
      ],
      groupOrder: 4,
    },
    ...getSupportAreaItemGroup(value, supportAreaMaster), // groupOrder: 5
    {
      title: '対応サービス',
      items: [
        {
          label: '対応サービス',
          value: {
            kind: 'text',
            text: value.supportServices.length ? value.supportServices.map((e) => e.name).join('\n') : '',
          },
          itemOrder: 1,
        },
      ],
      groupOrder: 6,
    },
    {
      title: '料金',
      items: [
        ...getConsultationFeeItem(value.fee.consultationFee), // itemOrder: 1, 2, 3
        {
          label: '着手金',
          value: { kind: 'text', text: value.fee?.mobilizationFee ? value.fee.mobilizationFee.join('\n') : '' },
          itemOrder: 4,
        },
        {
          label: '報酬金',
          value: { kind: 'text', text: value.fee?.reward ? value.fee.reward.join('\n') : '' },
          itemOrder: 5,
        },
      ],
      groupOrder: 7,
    },
    {
      title: '担当者情報',
      items: [
        {
          label: '担当者名',
          value: { kind: 'text', text: `${value.contactName.surname} ${value.contactName.givenName}` },
          itemOrder: 1,
        },
        {
          label: '担当者名（カナ）',
          value: { kind: 'text', text: `${value.contactName.surnameKana} ${value.contactName.givenNameKana}` },
          itemOrder: 2,
        },
      ],
      groupOrder: 8,
    },
  ]

  // 問い合わせ停止中が未定義の時には、表示項目なし
  if (contactSuspended) {
    items.push({
      title: '問い合わせ',
      items: [
        {
          label: '問い合わせステータス',
          value: {
            kind: 'text',
            text: contactSuspended === 'suspended' ? '問い合わせ受付停止中' : '問い合わせ受付中',
          },
          itemOrder: 1,
        },
      ],
      groupOrder: 9, // 一番最後のグループ
    })
  }

  return items
}

const getOfficeAddressItem = (
  value: PlacementApplicationDetails
): DescriptionItemTypeWithOrder<PlacementDescriptionValue> => {
  const officeAddressString = getOfficeAddressStringFromApiSchema(value.officeAddress)

  return {
    label: '住所',
    value: { kind: 'text', text: officeAddressString },
    itemOrder: 10,
  }
}

const getRepresentativeItem = (
  value: PlacementApplicationDetails
): DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] => {
  const items: DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] = []
  const itemOrder = 15 // 代表者名は可変長のためorderは共通にしておく
  value.representatives.map((r) => {
    items.push({
      label: '代表者名',
      value: { kind: 'text', text: `${r.name.surname} ${r.name.givenName}` },
      itemOrder: itemOrder,
    })
    items.push({
      label: '代表者名（カナ）',
      value: { kind: 'text', text: `${r.name.surnameKana} ${r.name.givenNameKana}` },
      itemOrder: itemOrder,
    })
  })

  return items
}

const getSupportAreaItemGroup = (
  value: PlacementApplicationDetails,
  supportAreaMaster: SupportAreaMaster[]
): DescriptionItemGroupTypeWithOrder<PlacementDescriptionValue>[] => {
  if (value.supportArea.isWholeArea) {
    return [
      {
        title: '対応エリア',
        items: [
          { label: '都道府県', value: { kind: 'text', text: '全国' }, itemOrder: 1 },
          { label: '市区町村', value: { kind: 'labels', labels: ['-'] }, itemOrder: 2 },
        ] as DescriptionItemTypeWithOrder<PlacementDescriptionValue>[],
        groupOrder: 5,
      },
    ]
  }

  // 対応エリアのソート仕様
  // - areaCodeの昇順
  // - 「その他」は末尾
  const selectedArea =
    value.supportArea.areas?.sort((a: SupportAreaItem, b: SupportAreaItem) => a.areaCode.localeCompare(b.areaCode)) ??
    []

  const items: {
    prefecture: string
    cities: string[]
  }[] = []

  selectedArea.forEach((s) => {
    const prefecture = supportAreaMaster.find((p) => p.areaCode === s.areaCode)
    if (!prefecture) return
    const prefectureName = PREFECTURE.find((p) => p.code === prefecture.prefectureCode)?.name
    if (!prefectureName) return
    const targetIndex = items.findIndex((i) => i.prefecture === prefectureName)
    if (prefectureName && targetIndex > -1 && items[targetIndex]) {
      items[targetIndex].cities.push(s.areaName)
    } else {
      items.push({
        prefecture: prefectureName,
        cities: [s.areaName],
      })
    }
  })

  const groupOrder = 5 // 対応エリアは可変長のためorderは共通にしておく
  return items.map((e, i) => {
    return {
      title: i === 0 ? '対応エリア' : '',
      items: [
        {
          label: '都道府県',
          value: { kind: 'text', text: e.prefecture },
          itemOrder: 1,
        },
        {
          label: '市区町村',
          value: { kind: 'labels', labels: e.cities },
          itemOrder: 2,
        },
      ],
      groupOrder: groupOrder,
    }
  })
}

const getImageValue = (value: PlacementApplicationDetails, profileId: 1 | 2 | 3): PlacementDescriptionValue => {
  const images: string[] = []
  let imageProps: ImageProps = IMAGEPROPS.PORTRAIT

  if (profileId === 1) {
    imageProps = IMAGEPROPS.PORTRAIT
    const id = value.images?.profile1Image?.placementImageId
    if (id) images.push(id)
  } else if (profileId === 2) {
    imageProps = IMAGEPROPS.SQUARE
    const id1 = value.images?.profile2Image1?.placementImageId
    const id2 = value.images?.profile2Image2?.placementImageId
    const id3 = value.images?.profile2Image3?.placementImageId
    if (id1) images.push(id1)
    if (id2) images.push(id2)
    if (id3) images.push(id3)
  } else {
    imageProps = IMAGEPROPS.LANDSCAPE
    const id = value.images?.profile3Image?.placementImageId
    if (id) images.push(id)
  }

  return {
    kind: 'images',
    images: images.map((id) => {
      return { id, imageProps }
    }),
  }
}
