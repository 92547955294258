import { composePlacementEndDateDescription, PlacementEndDate } from '@/share/domain/placement/placementEndDate'

// 掲載申込ステータス
export const PROFILE_PLACEMENT_STATUS = {
  // 審査待ち
  waitForScreening: 'waitForScreening',

  // 審査OK(=公開待ち)
  accepted: 'accepted',

  // 審査NG
  declined: 'declined',

  // 審査中止
  terminated: 'terminated',

  // 掲載申込がない
  nothing: 'nothing',
} as const

export type ProfilePlacementStatus = keyof typeof PROFILE_PLACEMENT_STATUS

export const PROFILE_PUBLIC_STATUS = {
  'in-public': 'in-public',
  suspended: 'suspended',
  nothing: 'nothing',
} as const

export type ProfilePublicStatus = keyof typeof PROFILE_PUBLIC_STATUS

// 解約受付ステータス
export const PROFILE_CHURN_STATUS = {
  // 解約申込可能
  'can-apply': 'can-apply',
  // 解約申込完了
  completed: 'completed',
  // 強制解約済み
  terminated: 'terminated',
} as const

export type ProfileChurnStatus = keyof typeof PROFILE_CHURN_STATUS

export type ProfileStatus = {
  // 掲載申込のステータス
  placementStatus: ProfilePlacementStatus

  // 公開情報のステータス
  publicStatus: ProfilePublicStatus

  // 解約ステータス
  churnStatus: ProfileChurnStatus
}

export const PLACEMENT_PROPS = {
  [PROFILE_PLACEMENT_STATUS.waitForScreening]: {
    title: '申請済み掲載情報',
    tagColor: undefined,
    tagLabel: '審査待ち',
    description: 'あなたの公開掲載情報です。\n公開までもうしばらくお待ちください。',
    alert: false,
  },
  [PROFILE_PLACEMENT_STATUS.accepted]: {
    title: '申請済み掲載情報',
    tagColor: 'green',
    tagLabel: '審査OK',
    description: 'あなたの公開掲載情報です。',
    alert: false,
  },
  [PROFILE_PLACEMENT_STATUS.declined]: {
    title: '申請済み掲載情報',
    tagColor: 'red',
    tagLabel: '審査NG',
    description: (comment: string[]) => comment.join('\n'),
    alert: true,
  },
  // 現状、terminated状態では申込表示なしの強制解約画面が表示されるためこの定義で表示されることはない
  [PROFILE_PLACEMENT_STATUS.terminated]: {
    title: '申請済み掲載情報',
    tagColor: 'red',
    tagLabel: '審査中止',
    description: '審査が中止されました。',
    alert: true,
  },
} as const

export const PUBLIC_PROPS = {
  [PROFILE_PUBLIC_STATUS['in-public']]: {
    title: '公開掲載情報',
    tagColor: 'green',
    tagLabel: '公開中',
    description: '',
    alert: false,
  },
  [PROFILE_PUBLIC_STATUS.suspended]: {
    title: '公開掲載情報',
    tagColor: 'red',
    tagLabel: '公開停止',
    description: '掲載情報の公開が停止されました。',
    alert: true,
  },
}

export const PRIMAL_AND_CHURN_PROPS = {
  [PROFILE_CHURN_STATUS['can-apply']]: {
    title: '公開掲載情報',
  },
  [PROFILE_CHURN_STATUS.completed]: {
    title: '公開掲載情報',
    tagLabel: '解約申込完了',
    tagColor: undefined,
    description: (placementEndDate: PlacementEndDate) =>
      `解約申込完了\n${composePlacementEndDateDescription(placementEndDate)}`,
    alert: true,
  },
  // 現状、terminated状態では申込表示なしの強制解約画面が表示されるためこの定義で表示されることはない
  [PROFILE_CHURN_STATUS.terminated]: {
    title: '公開掲載情報',
    tagLabel: '強制解約',
    tagColor: 'red',
    description: (placementEndDate: PlacementEndDate) =>
      `業務担当者より契約を解除されました。\n${composePlacementEndDateDescription(placementEndDate)}`,
    alert: true,
  },
} as const
