import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Typography } from 'antd'
import * as React from 'react'
import { ReactNode } from 'react'
import { useProAndMgmtDesignToken } from '../design-system/token'

const useStyles = () => {
  const { themeColor } = useProAndMgmtDesignToken()

  return {
    content: css`
      padding: 88px 0 40px;
      text-align: center;
    `,
    checkedCircle: css`
      color: ${themeColor.text.success};
      font-size: 80px;
    `,
    closeCircle: css`
      color: ${themeColor.text.alert1};
      font-size: 80px;
    `,
    textSection: css`
      margin: 32px auto;
    `,
  }
}

export type LayoutComponent = (props: { children: ReactNode }) => React.JSX.Element

const ResultLayout = (props: { content: ReactNode; title: string; icon: ReactNode; Layout: LayoutComponent }) => {
  const styles = useStyles()

  return (
    <props.Layout>
      <div css={styles.content}>
        {props.icon}
        <section css={styles.textSection}>
          <Typography.Title level={4}>{props.title}</Typography.Title>
          {props.content}
        </section>
      </div>
    </props.Layout>
  )
}

const JoinedDescription = ({ description }: { description: string | string[] }) => {
  if (typeof description === 'string') {
    return <>{description}</>
  }

  return (
    <>
      {description.map((text, index) => (
        <React.Fragment key={text}>
          {text}
          {description.length - 1 !== index && <br />}
        </React.Fragment>
      ))}
    </>
  )
}

export type ResultScreenProps =
  | {
      kind: 'default'
      title: string
      description: string | string[]
    }
  | {
      kind: 'composition'
      title: string
      content: ReactNode
    }

type ResultBaseScreenProps = ResultScreenProps & { Layout: LayoutComponent }

export const ResultBaseScreen = (props: { icon: ReactNode } & ResultBaseScreenProps) => {
  return (
    <ResultLayout
      {...props}
      content={
        props.kind === 'composition' ? (
          props.content
        ) : (
          <Typography.Paragraph type="secondary">
            <JoinedDescription description={props.description} />
          </Typography.Paragraph>
        )
      }
    />
  )
}

export const ErrorScreen = (props: ResultBaseScreenProps) => {
  const styles = useStyles()
  return <ResultBaseScreen {...props} icon={<CloseCircleFilled css={styles.closeCircle} />} />
}

export const SuccessScreen = (props: ResultBaseScreenProps) => {
  const styles = useStyles()
  return <ResultBaseScreen {...props} icon={<CheckCircleFilled css={styles.checkedCircle} />} />
}
