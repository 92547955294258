import {
  ErrorScreen as _ErrorScreen,
  SuccessScreen as _SuccessScreen,
  ResultScreenProps,
  NotFoundScreen as _NotFoundScreen,
} from 'inheritance-components'
import { AppLayout } from '../layout/AppLayout'

export const ErrorScreen = (props: ResultScreenProps) => <_ErrorScreen {...props} Layout={AppLayout} />

export const SuccessScreen = (props: ResultScreenProps) => <_SuccessScreen {...props} Layout={AppLayout} />

export const NotFoundScreen = () => <_NotFoundScreen Layout={AppLayout} />
