import { css } from '@emotion/react'
import { theme } from 'antd'

export const useDialogStyle = () => {
  const {
    token: { colorError, colorSuccess, colorPrimary, colorWarning },
  } = theme.useToken()

  const titleIcon = css`
    margin-right: 16px;
    font-size: 22px;
  `
  const dialogStyle = {
    title: css`
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-weight: 700;
    `,
    titleRedIcon: css`
      ${titleIcon}
      color: ${colorError};
    `,
    titlePurpleIcon: css`
      ${titleIcon}
      color: ${colorPrimary};
    `,
    titleYellowIcon: css`
      ${titleIcon}
      color: ${colorWarning};
    `,
    titleGreenIcon: css`
      ${titleIcon}
      color: ${colorSuccess};
    `,
    content: css`
      margin-left: 38px;
    `,
    buttonSpace: css`
      margin-top: 24px;
      margin-bottom: 0px;
      display: flex;
      justify-content: flex-end;
    `,
    form: css`
      margin-top: 16px;
      textarea {
        padding-right: 32px;
      }
    `,
  }

  return { dialogStyle }
}
