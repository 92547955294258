import { buildRethrowingError } from '../buildRethrowingError'
import { getApiAuthProfessionalClient } from '../client'

export const callGetProfessionalUserVisitData = async () => {
  try {
    const apiClient = await getApiAuthProfessionalClient()
    const {
      data: { items },
    } = await apiClient.getProfessionalUserVisitData({ secure: true })
    return { items }
  } catch (e) {
    throw buildRethrowingError(e)
  }
}
