import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getPath } from '@/router'
import { Typography, Button } from 'antd'
import { ErrorScreen } from '@/share/components/result-screen/ResultScreen'
import { SuccessScreen } from '@/share/components/result-screen/ResultScreen'
import { PasswordResetCompleteContent, PASSWORD_RESET_COMPLETE_TITLE } from 'inheritance-components'

export const PasswordResetComplete = () => {
  const location = useLocation()
  const [state] = useState(location.state as { isCompleted: boolean | undefined })

  if (state && state.isCompleted) {
    return (
      <SuccessScreen
        kind="composition"
        title={PASSWORD_RESET_COMPLETE_TITLE}
        content={<PasswordResetCompleteContent toLoginLink={getPath('login')} />}
      />
    )
  } else {
    return (
      <ErrorScreen
        kind="composition"
        title="エラーが発生しました"
        content={
          <>
            <Typography.Paragraph type="secondary">パスワードリセットメールをご確認下さい。</Typography.Paragraph>
            <Button type="primary" href={getPath('passwordResetRequest')}>
              パスワードをリセットページに戻る
            </Button>
          </>
        }
      />
    )
  }
}
