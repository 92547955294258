import { css, SerializedStyles } from '@emotion/react'
import { Typography } from 'antd'
import { useDesignToken } from '../design-system/token'

const MUFGLogo = () => {
  return (
    <svg width="115" height="33" viewBox="0 0 115 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M102.526 25.1746C97.3975 25.1746 93.9089 21.4998 93.9089 16.5562V16.5033C93.9089 11.7447 97.5017 7.83203 102.5 7.83203C105.494 7.83203 107.265 8.65156 108.983 10.132L106.666 12.9343C105.39 11.8768 104.323 11.2423 102.422 11.2423C99.7666 11.2423 97.7359 13.6217 97.7359 16.4504V16.5032C97.7359 19.5699 99.7406 21.8171 102.683 21.8171C104.01 21.8171 105.026 21.4998 106.067 20.8125V18.6182H102.24V15.1021H109.53V22.5045C107.786 23.985 105.546 25.1746 102.526 25.1746ZM83.8332 11.5596V15.1022H91.123V18.6182H83.8332V24.9631H80.3706V8.04356H93.2058V11.5596H83.8332ZM70.425 25.1482C65.999 25.1482 63.3696 22.6367 63.3696 17.7195V8.04356H66.8322V17.6136C66.8322 20.3366 68.2642 21.7642 70.4772 21.7642C72.6901 21.7642 74.122 20.3895 74.122 17.7458V8.04356H77.5847V17.5872C77.5847 22.6631 74.8771 25.1482 70.425 25.1482ZM57.1211 24.9631V13.939L52.4869 21.0769H52.4088L47.7485 14.0182V24.9631H44.2598V8.04356H48.139L52.4869 15.1286L56.8347 8.04356H60.5838V24.9631H57.1211Z"
        fill="#5A5A5A"
      />
      <path
        d="M25.775 16.5032C25.775 19.4112 23.4579 21.7905 20.568 21.7905C17.7041 21.7905 15.387 19.4112 15.387 16.5032C15.387 13.5951 17.7041 11.2158 20.568 11.2158C23.4578 11.2158 25.775 13.5951 25.775 16.5032ZM20.568 27.9767C14.3456 27.9767 9.26869 22.848 9.26869 16.5032C9.26869 10.1583 14.3456 5.02955 20.568 5.02955C26.8164 5.02955 31.8673 10.1583 31.8673 16.5032C31.8673 22.848 26.8164 27.9767 20.568 27.9767ZM25.6708 3.81348C23.8484 3.81348 22.1301 4.20995 20.568 4.92378C19.0059 4.20995 17.2876 3.81348 15.4651 3.81348C8.56579 3.81348 2.96826 9.49736 2.96826 16.5032C2.96826 23.5089 8.56586 29.1928 15.4651 29.1928C17.2876 29.1928 19.0059 28.7963 20.568 28.0824C22.1301 28.7963 23.8484 29.1928 25.6708 29.1928C32.5702 29.1928 38.1677 23.5089 38.1677 16.5032C38.1677 9.49736 32.5702 3.81348 25.6708 3.81348Z"
        fill="#E60000"
      />
    </svg>
  )
}

const footerCss = css`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 64px;
  font-size: 9px;
`

const useCopyRightTextCss = () => {
  const { themeColor } = useDesignToken()

  return css`
    display: block;
    color: ${themeColor.text.yellow};
    font-size: 9px;
    line-height: 24px;
  `
}

export const CopyRightFooter = ({ showLogo = true, rootCss }: { showLogo?: boolean; rootCss?: SerializedStyles }) => {
  return (
    <footer css={css(footerCss, rootCss)}>
      {showLogo && <MUFGLogo />}
      <Typography.Text css={useCopyRightTextCss()}>©MUFG Bank, Ltd. All rights reserved</Typography.Text>
    </footer>
  )
}
