import { SelectedArea } from './form'

export const MAX_SUPPORT_AREA_NUMBER = 10

export const checkCanAddArea = (
  selectedArea: SelectedArea[]
): {
  canBeAdded: boolean
} => {
  // 上限に達しているか
  if (selectedArea.length >= MAX_SUPPORT_AREA_NUMBER) {
    return {
      canBeAdded: false,
    }
  }

  // 追加済みのフォームが全て入力済みか
  const isAllItemFilled = !selectedArea.some((e) => {
    if (!e.prefecture || e.cities.length < 1) return true
  })

  return { canBeAdded: isAllItemFilled }
}
