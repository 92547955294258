import { ReceptionDetails } from 'inheritance-api'
import { PlacementDescriptionValue } from '../type'
import { REGULAR_HOLIDAY } from 'inheritance-utils'
import { DescriptionItemTypeWithOrder } from '../../generic/type'

export const RECEPTION_DETAILS_LABEL = {
  receptionHours: '受付時間',
  receptionCall24HoursAvailable: '電話受付設定',
  receptionHoursNote: '受付時間に関する備考',
  regularHoliday: '定休日',
} as const

export const getReceptionDetailsItems = ({
  receptionHoursStart,
  receptionHoursEnd,
  receptionCall24HoursAvailable,
  receptionHoursNote,
  regularHoliday,
}: ReceptionDetails): DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] => {
  const items: DescriptionItemTypeWithOrder<PlacementDescriptionValue>[] = []
  items.push({
    label: RECEPTION_DETAILS_LABEL.receptionHours,
    value: {
      kind: 'text',
      text: receptionHoursStart && receptionHoursEnd ? `${receptionHoursStart}~${receptionHoursEnd}` : '',
    },
    itemOrder: 3,
  })
  items.push({
    label: RECEPTION_DETAILS_LABEL.receptionCall24HoursAvailable,
    value: { kind: 'text', text: receptionCall24HoursAvailable ? '電話受付24時間対応可能' : '' },
    itemOrder: 4,
  })
  items.push({
    label: RECEPTION_DETAILS_LABEL.receptionHoursNote,
    value: { kind: 'text', text: receptionHoursNote?.join('\n') ?? '' },
    itemOrder: 5,
  })
  items.push({
    label: RECEPTION_DETAILS_LABEL.regularHoliday,
    value: {
      kind: 'text',
      text:
        regularHoliday && regularHoliday.length > 0
          ? regularHoliday.map((holiday) => REGULAR_HOLIDAY[holiday]).join('、')
          : '',
    },
    itemOrder: 6,
  })
  return items
}
