// eslint-disable-next-line node/no-extraneous-import
import * as z from 'zod'
// eslint-disable-next-line node/no-extraneous-import
import { Dayjs } from 'dayjs'
import { formNames } from '@/pages/ContractApplicationForm/form'
import { RepresentativeFormStorageSchema } from '@/share/components/PlacementForm/functions/storage/types/representativeTypes'
export * from '@/share/components/PlacementForm/functions/storage/types/representativeTypes'
import { RepresentativeFormType } from '@/share/components/RepresentativeForm/form'

export type ContractFormStorageData = {
  [formNames.professionalCategory]?: string
  [formNames.businessCategory]?: string
  [formNames.corporateName]?: string
  [formNames.corporateNameKana]?: string
  [formNames.dateOfEstablishment]?: Dayjs
  [formNames.corporatePostCode.first]?: string
  [formNames.corporatePostCode.second]?: string
  [formNames.prefecture]?: string
  [formNames.city]?: string
  [formNames.otherArea]?: string
  [formNames.townAndStreetAddress]?: string
  [formNames.corporateBuilding]?: string
  [formNames.corporateUrl]?: string
  [formNames.affiliation]?: string
  [formNames.affiliationOther]?: string
  [formNames.contactSurname]?: string
  [formNames.contactGivenName]?: string
  [formNames.contactSurnameKana]?: string
  [formNames.contactGivenNameKana]?: string
  [formNames.contactEmailAddress]?: string
  [formNames.contactPhoneNumber]?: string
  [formNames.loginEmailAddress]?: string
  [formNames.loginPhoneNumber]?: string
  [formNames.note]?: string
  [formNames.invitationCode]?: string
  [formNames.howManyInquiriesAnticipateFromUsersPerYear]?: number
  [formNames.howManyProfessionalsMoreThan3YearsOfExperience]?: number
  [formNames.howManyCasesHandledSoFar]?: number
  [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]?: boolean
  [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]?: boolean
  [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]?: boolean
} & RepresentativeFormType

export const FormStorageSchema = z.object({
  loginEmailAddress: z.string(),
  data: z
    .object({
      [formNames.professionalCategory]: z.string().optional(),
      [formNames.businessCategory]: z.string().optional(),
      [formNames.corporateName]: z.string().optional(),
      [formNames.corporateNameKana]: z.string().optional(),
      [formNames.dateOfEstablishment]: z.string().optional(),
      [formNames.corporatePostCode.first]: z.string().optional(),
      [formNames.corporatePostCode.second]: z.string().optional(),
      [formNames.prefecture]: z.string().optional(),
      [formNames.city]: z.string().optional(),
      [formNames.otherArea]: z.string().optional(),
      [formNames.townAndStreetAddress]: z.string().optional(),
      [formNames.corporateBuilding]: z.string().optional(),
      [formNames.corporateUrl]: z.string().optional(),
      [formNames.affiliation]: z.string().optional(),
      [formNames.affiliationOther]: z.string().optional(),
      [formNames.contactSurname]: z.string().optional(),
      [formNames.contactGivenName]: z.string().optional(),
      [formNames.contactSurnameKana]: z.string().optional(),
      [formNames.contactGivenNameKana]: z.string().optional(),
      [formNames.contactEmailAddress]: z.string().optional(),
      [formNames.contactPhoneNumber]: z.string().optional(),
      [formNames.loginEmailAddress]: z.string().optional(),
      [formNames.loginPhoneNumber]: z.string().optional(),
      [formNames.note]: z.string().optional(),
      [formNames.invitationCode]: z.string().optional(),
      [formNames.howManyInquiriesAnticipateFromUsersPerYear]: z.number().optional(),
      [formNames.howManyProfessionalsMoreThan3YearsOfExperience]: z.number().optional(),
      [formNames.howManyCasesHandledSoFar]: z.number().optional(),
      [formNames.isNotUndertakenAnyLitigationAgainstMUFGBank]: z.boolean().optional(),
      [formNames.isIntendedToHandleSummaryCourtLegalRepresentation]: z.boolean().optional(),
      [formNames.isBusinessOpenOverseasUnderNameOfAdvertiser]: z.boolean().optional(),
    })
    .merge(RepresentativeFormStorageSchema),
})
