import { Rule } from 'antd/lib/form'
// eslint-disable-next-line node/no-extraneous-import
import { Dayjs } from 'dayjs'
import { getRules, RULES } from 'inheritance-components'

export const representativeFormNames = {
  representativePostCode: {
    first: 'representativePostCodeFirst',
    second: 'representativePostCodeSecond',
  },
  representativeAddress: 'representativeAddress',
  representativeBuilding: 'representativeBuilding',
  representativeSurname: 'representativeSurname',
  representativeGivenName: 'representativeGivenName',
  representativeSurnameKana: 'representativeSurnameKana',
  representativeGivenNameKana: 'representativeGivenNameKana',
  representativeBirthDate: 'representativeBirthDate',
} as const

export const representativeFormNamesWithKey = {
  representativePostCode0: {
    first0: `${representativeFormNames.representativePostCode.first}0`,
    second0: `${representativeFormNames.representativePostCode.second}0`,
  },
  representativeAddress0: `${representativeFormNames.representativeAddress}0`,
  representativeBuilding0: `${representativeFormNames.representativeBuilding}0`,
  representativeSurname0: `${representativeFormNames.representativeSurname}0`,
  representativeGivenName0: `${representativeFormNames.representativeGivenName}0`,
  representativeSurnameKana0: `${representativeFormNames.representativeSurnameKana}0`,
  representativeGivenNameKana0: `${representativeFormNames.representativeGivenNameKana}0`,
  representativeBirthDate0: `${representativeFormNames.representativeBirthDate}0`,
  representativeSurname1: `${representativeFormNames.representativeSurname}1`,
  representativeGivenName1: `${representativeFormNames.representativeGivenName}1`,
  representativeSurnameKana1: `${representativeFormNames.representativeSurnameKana}1`,
  representativeGivenNameKana1: `${representativeFormNames.representativeGivenNameKana}1`,
  representativeSurname2: `${representativeFormNames.representativeSurname}2`,
  representativeGivenName2: `${representativeFormNames.representativeGivenName}2`,
  representativeSurnameKana2: `${representativeFormNames.representativeSurnameKana}2`,
  representativeGivenNameKana2: `${representativeFormNames.representativeGivenNameKana}2`,
  representativeSurname3: `${representativeFormNames.representativeSurname}3`,
  representativeGivenName3: `${representativeFormNames.representativeGivenName}3`,
  representativeSurnameKana3: `${representativeFormNames.representativeSurnameKana}3`,
  representativeGivenNameKana3: `${representativeFormNames.representativeGivenNameKana}3`,
  representativeSurname4: `${representativeFormNames.representativeSurname}4`,
  representativeGivenName4: `${representativeFormNames.representativeGivenName}4`,
  representativeSurnameKana4: `${representativeFormNames.representativeSurnameKana}4`,
  representativeGivenNameKana4: `${representativeFormNames.representativeGivenNameKana}4`,
  representativeSurname5: `${representativeFormNames.representativeSurname}5`,
  representativeGivenName5: `${representativeFormNames.representativeGivenName}5`,
  representativeSurnameKana5: `${representativeFormNames.representativeSurnameKana}5`,
  representativeGivenNameKana5: `${representativeFormNames.representativeGivenNameKana}5`,
  representativeSurname6: `${representativeFormNames.representativeSurname}6`,
  representativeGivenName6: `${representativeFormNames.representativeGivenName}6`,
  representativeSurnameKana6: `${representativeFormNames.representativeSurnameKana}6`,
  representativeGivenNameKana6: `${representativeFormNames.representativeGivenNameKana}6`,
  representativeSurname7: `${representativeFormNames.representativeSurname}7`,
  representativeGivenName7: `${representativeFormNames.representativeGivenName}7`,
  representativeSurnameKana7: `${representativeFormNames.representativeSurnameKana}7`,
  representativeGivenNameKana7: `${representativeFormNames.representativeGivenNameKana}7`,
  representativeSurname8: `${representativeFormNames.representativeSurname}8`,
  representativeGivenName8: `${representativeFormNames.representativeGivenName}8`,
  representativeSurnameKana8: `${representativeFormNames.representativeSurnameKana}8`,
  representativeGivenNameKana8: `${representativeFormNames.representativeGivenNameKana}8`,
  representativeSurname9: `${representativeFormNames.representativeSurname}9`,
  representativeGivenName9: `${representativeFormNames.representativeGivenName}9`,
  representativeSurnameKana9: `${representativeFormNames.representativeSurnameKana}9`,
  representativeGivenNameKana9: `${representativeFormNames.representativeGivenNameKana}9`,
  representativeSurname10: `${representativeFormNames.representativeSurname}10`,
  representativeGivenName10: `${representativeFormNames.representativeGivenName}10`,
  representativeSurnameKana10: `${representativeFormNames.representativeSurnameKana}10`,
  representativeGivenNameKana10: `${representativeFormNames.representativeGivenNameKana}10`,
  representativeSurname11: `${representativeFormNames.representativeSurname}11`,
  representativeGivenName11: `${representativeFormNames.representativeGivenName}11`,
  representativeSurnameKana11: `${representativeFormNames.representativeSurnameKana}11`,
  representativeGivenNameKana11: `${representativeFormNames.representativeGivenNameKana}11`,
  representativeSurname12: `${representativeFormNames.representativeSurname}12`,
  representativeGivenName12: `${representativeFormNames.representativeGivenName}12`,
  representativeSurnameKana12: `${representativeFormNames.representativeSurnameKana}12`,
  representativeGivenNameKana12: `${representativeFormNames.representativeGivenNameKana}12`,
  representativeSurname13: `${representativeFormNames.representativeSurname}13`,
  representativeGivenName13: `${representativeFormNames.representativeGivenName}13`,
  representativeSurnameKana13: `${representativeFormNames.representativeSurnameKana}13`,
  representativeGivenNameKana13: `${representativeFormNames.representativeGivenNameKana}13`,
  representativeSurname14: `${representativeFormNames.representativeSurname}14`,
  representativeGivenName14: `${representativeFormNames.representativeGivenName}14`,
  representativeSurnameKana14: `${representativeFormNames.representativeSurnameKana}14`,
  representativeGivenNameKana14: `${representativeFormNames.representativeGivenNameKana}14`,
  representativeSurname15: `${representativeFormNames.representativeSurname}15`,
  representativeGivenName15: `${representativeFormNames.representativeGivenName}15`,
  representativeSurnameKana15: `${representativeFormNames.representativeSurnameKana}15`,
  representativeGivenNameKana15: `${representativeFormNames.representativeGivenNameKana}15`,
  representativeSurname16: `${representativeFormNames.representativeSurname}16`,
  representativeGivenName16: `${representativeFormNames.representativeGivenName}16`,
  representativeSurnameKana16: `${representativeFormNames.representativeSurnameKana}16`,
  representativeGivenNameKana16: `${representativeFormNames.representativeGivenNameKana}16`,
  representativeSurname17: `${representativeFormNames.representativeSurname}17`,
  representativeGivenName17: `${representativeFormNames.representativeGivenName}17`,
  representativeSurnameKana17: `${representativeFormNames.representativeSurnameKana}17`,
  representativeGivenNameKana17: `${representativeFormNames.representativeGivenNameKana}17`,
  representativeSurname18: `${representativeFormNames.representativeSurname}18`,
  representativeGivenName18: `${representativeFormNames.representativeGivenName}18`,
  representativeSurnameKana18: `${representativeFormNames.representativeSurnameKana}18`,
  representativeGivenNameKana18: `${representativeFormNames.representativeGivenNameKana}18`,
  representativeSurname19: `${representativeFormNames.representativeSurname}19`,
  representativeGivenName19: `${representativeFormNames.representativeGivenName}19`,
  representativeSurnameKana19: `${representativeFormNames.representativeSurnameKana}19`,
  representativeGivenNameKana19: `${representativeFormNames.representativeGivenNameKana}19`,
} as const

export type RepresentativeFormType = {
  [representativeFormNamesWithKey.representativeBirthDate0]?: Dayjs
  [representativeFormNamesWithKey.representativePostCode0.first0]?: string
  [representativeFormNamesWithKey.representativePostCode0.second0]?: string
  [representativeFormNamesWithKey.representativeAddress0]?: string
  [representativeFormNamesWithKey.representativeBuilding0]?: string
  [representativeFormNamesWithKey.representativeSurname0]?: string
  [representativeFormNamesWithKey.representativeGivenName0]?: string
  [representativeFormNamesWithKey.representativeSurnameKana0]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana0]?: string
  [representativeFormNamesWithKey.representativeSurname1]?: string
  [representativeFormNamesWithKey.representativeGivenName1]?: string
  [representativeFormNamesWithKey.representativeSurnameKana1]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana1]?: string
  [representativeFormNamesWithKey.representativeSurname2]?: string
  [representativeFormNamesWithKey.representativeGivenName2]?: string
  [representativeFormNamesWithKey.representativeSurnameKana2]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana2]?: string
  [representativeFormNamesWithKey.representativeSurname3]?: string
  [representativeFormNamesWithKey.representativeGivenName3]?: string
  [representativeFormNamesWithKey.representativeSurnameKana3]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana3]?: string
  [representativeFormNamesWithKey.representativeSurname4]?: string
  [representativeFormNamesWithKey.representativeGivenName4]?: string
  [representativeFormNamesWithKey.representativeSurnameKana4]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana4]?: string
  [representativeFormNamesWithKey.representativeSurname5]?: string
  [representativeFormNamesWithKey.representativeGivenName5]?: string
  [representativeFormNamesWithKey.representativeSurnameKana5]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana5]?: string
  [representativeFormNamesWithKey.representativeSurname6]?: string
  [representativeFormNamesWithKey.representativeGivenName6]?: string
  [representativeFormNamesWithKey.representativeSurnameKana6]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana6]?: string
  [representativeFormNamesWithKey.representativeSurname7]?: string
  [representativeFormNamesWithKey.representativeGivenName7]?: string
  [representativeFormNamesWithKey.representativeSurnameKana7]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana7]?: string
  [representativeFormNamesWithKey.representativeSurname8]?: string
  [representativeFormNamesWithKey.representativeGivenName8]?: string
  [representativeFormNamesWithKey.representativeSurnameKana8]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana8]?: string
  [representativeFormNamesWithKey.representativeSurname9]?: string
  [representativeFormNamesWithKey.representativeGivenName9]?: string
  [representativeFormNamesWithKey.representativeSurnameKana9]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana9]?: string
  [representativeFormNamesWithKey.representativeSurname10]?: string
  [representativeFormNamesWithKey.representativeGivenName10]?: string
  [representativeFormNamesWithKey.representativeSurnameKana10]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana10]?: string
  [representativeFormNamesWithKey.representativeSurname11]?: string
  [representativeFormNamesWithKey.representativeGivenName11]?: string
  [representativeFormNamesWithKey.representativeSurnameKana11]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana11]?: string
  [representativeFormNamesWithKey.representativeSurname12]?: string
  [representativeFormNamesWithKey.representativeGivenName12]?: string
  [representativeFormNamesWithKey.representativeSurnameKana12]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana12]?: string
  [representativeFormNamesWithKey.representativeSurname13]?: string
  [representativeFormNamesWithKey.representativeGivenName13]?: string
  [representativeFormNamesWithKey.representativeSurnameKana13]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana13]?: string
  [representativeFormNamesWithKey.representativeSurname14]?: string
  [representativeFormNamesWithKey.representativeGivenName14]?: string
  [representativeFormNamesWithKey.representativeSurnameKana14]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana14]?: string
  [representativeFormNamesWithKey.representativeSurname15]?: string
  [representativeFormNamesWithKey.representativeGivenName15]?: string
  [representativeFormNamesWithKey.representativeSurnameKana15]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana15]?: string
  [representativeFormNamesWithKey.representativeSurname16]?: string
  [representativeFormNamesWithKey.representativeGivenName16]?: string
  [representativeFormNamesWithKey.representativeSurnameKana16]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana16]?: string
  [representativeFormNamesWithKey.representativeSurname17]?: string
  [representativeFormNamesWithKey.representativeGivenName17]?: string
  [representativeFormNamesWithKey.representativeSurnameKana17]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana17]?: string
  [representativeFormNamesWithKey.representativeSurname18]?: string
  [representativeFormNamesWithKey.representativeGivenName18]?: string
  [representativeFormNamesWithKey.representativeSurnameKana18]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana18]?: string
  [representativeFormNamesWithKey.representativeSurname19]?: string
  [representativeFormNamesWithKey.representativeGivenName19]?: string
  [representativeFormNamesWithKey.representativeSurnameKana19]?: string
  [representativeFormNamesWithKey.representativeGivenNameKana19]?: string
}

export type RepresentativeFormTypeOnlyName = Omit<
  RepresentativeFormType,
  | 'representativeBirthDate0'
  | 'representativePostCodeFirst0'
  | 'representativePostCodeSecond0'
  | 'representativeAddress0'
  | 'representativeBuilding0'
>

export const MAX_REPRESENTATIVE_NUMBER = 20

/** 代表者の氏名が重複して入力されていないか検証する */
const uniqueRepresentative = (index: number) => {
  const validateDuplication: Rule = ({ getFieldValue }) => ({
    async validator(r, value) {
      const inputIds: number[] = [...Array(MAX_REPRESENTATIVE_NUMBER)]
        .filter((_, i) => {
          const surName = document.getElementById(`${representativeFormNames.representativeSurname}${i}`)
          const givenName = document.getElementById(`${representativeFormNames.representativeGivenName}${i}`)
          return surName && givenName
        })
        .map((_, i) => i)

      const surName = getFieldValue(`${representativeFormNames.representativeSurname}${index}`)
      const givenName = getFieldValue(`${representativeFormNames.representativeGivenName}${index}`)
      const fullName: string | null = surName && givenName ? surName + givenName : null

      const allFullName: string[] = inputIds
        .map((id) => {
          const surName = getFieldValue(`${representativeFormNames.representativeSurname}${id}`)
          const givenName = getFieldValue(`${representativeFormNames.representativeGivenName}${id}`)
          return surName && givenName ? surName + givenName : ''
        })
        .filter((v) => v)

      if (!value || allFullName.filter((v) => v === fullName).length <= 1) {
        return
      }

      throw new Error('この氏名は既に入力されています')
    },
  })
  return validateDuplication
}

export const formProps = {
  representativeName: {
    label: '代表者名',
    [representativeFormNames.representativeSurname]: {
      name: representativeFormNames.representativeSurname,
      rules: (index: number) =>
        getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen(), uniqueRepresentative(index)]),
      placeholder: '山田',
    },
    [representativeFormNames.representativeGivenName]: {
      name: representativeFormNames.representativeGivenName,
      rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
      placeholder: '太郎',
    },
    guideText: '代表者が複数名いる場合には「代表者を追加」ボタンをクリックしてください。',
    colon: false,
  },

  representativeNameKana: {
    label: '代表者名（カナ）',
    [representativeFormNames.representativeSurnameKana]: {
      name: representativeFormNames.representativeSurnameKana,
      placeholder: 'ヤマダ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
      annotation: '※全角カタカナ',
    },
    [representativeFormNames.representativeGivenNameKana]: {
      name: representativeFormNames.representativeGivenNameKana,
      placeholder: 'タロウ',
      rules: getRules([RULES.requireEntry, RULES.kana, RULES.maxLen()]),
    },
  },

  [representativeFormNames.representativeBirthDate]: {
    name: representativeFormNames.representativeBirthDate,
    label: '代表者生年月日',
    placeholder: '1990/01/01',
    rules: getRules([RULES.requireEntry]),
  },

  representativePostCode: {
    label: '郵便番号',
    first: {
      name: representativeFormNames.representativePostCode.first,
      rules: getRules([RULES.requireEntry, RULES.postCodeFirst]),
      placeholder: '000',
      annotation: '※半角数字',
    },
    second: {
      name: representativeFormNames.representativePostCode.second,
      rules: getRules([RULES.requireEntry, RULES.postCodeSecond]),
      placeholder: '0000',
    },
  },

  [representativeFormNames.representativeAddress]: {
    name: representativeFormNames.representativeAddress,
    label: '住所',
    rules: getRules([RULES.requireEntry, RULES.notBlank, RULES.maxLen()]),
    placeholder: '東京都千代田区丸の内1-2-3',
  },

  [representativeFormNames.representativeBuilding]: {
    name: representativeFormNames.representativeBuilding,
    label: '建物名',
    rules: getRules([RULES.notBlank, RULES.maxLen()]),
    placeholder: '日本橋マンション101',
    guideText: '住所に建物名や部屋番号がある場合のみ、入力してください。',
    colon: false,
  },
}
