import { mediaQuery } from './responsive'

const color = {
  text: {
    black01: '#313131',
    black02: '#646464',
    black03: '#BFBFBF',
    white: '#FFFFFF',
  },
  accent: {
    red: {
      [700]: '#E60000',
      default: '#EB3333',
      [500]: '#F06666',
      [400]: '#F59999',
      [300]: '#FACCCC',
      [200]: '#FCE6E6',
      [100]: '#FEF5F5',
    },
    purple: {
      [700]: '#653157',
      default: '#AA5194',
      [500]: '#BB74A9',
      [400]: '#CC97BF',
      [300]: '#E0C3DA',
      [200]: '#EEDCEA',
      [100]: '#F7EEF4',
    },
    gray: {
      [700]: '#5A5A5A',
      default: '#737373',
      [500]: '#838383',
      [400]: '#9C9C9C',
      [300]: '#ACACAC',
      [200]: '#D6D6D6',
      [100]: '#EFEFEF',
    },
  },
  background: {
    purple: '#FCF8FB',
    gray: '#F7F7F7',
    white: '#FFFFFF',
  },
} as const

const icon = {
  colorSet: {
    gray: {
      single: {
        main: color.accent.gray.default,
        sub: color.accent.gray.default,
      },
      multi: {
        main: color.accent.gray.default,
        sub: color.accent.gray.default,
      },
    },
    red: {
      single: {
        main: color.accent.red.default,
        sub: color.accent.red.default,
      },
      multi: {
        main: color.accent.red.default,
        sub: color.accent.red.default,
      },
    },
    purple: {
      single: {
        main: color.accent.purple.default,
        sub: color.accent.purple.default,
      },
      multi: {
        main: color.accent.purple.default,
        sub: color.accent.purple.default,
      },
    },
  },
} as const
export type ColorSet = keyof typeof icon.colorSet

const layout = {
  defaultContentWidth: '327px', // 375px - 左右余白48px
  mediumContentMaxWidth: '768px',
  maxContentInnerWidth: '1024px',
  maxContentOuterWidth: '1440px',
  minHeight: '100vh',
  headerHeight: {
    sp: '48px',
    pc: '64px',
  },
  footerHeight: {
    sp: '594px',
    pc: '413px',
  },
  waveHeight: {
    sp: '38px',
    pc: '45px',
  },
} as const

export const zIndex = {
  deep: -99999,
  default: 1,
  dropDown: 3000,
  globalNav: 5000,
  footerModal: 8500,
  modal: 9000,
  loading: 9500,
} as const

export type SpacingScale = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export const designToken = {
  color,
  icon,
  layout,
  zIndex,
  spacing: (scale: SpacingScale) => `${8 * scale}px`,
  mediaQuery,
} as const
