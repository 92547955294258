import { buildRethrowingError } from '../buildRethrowingError'
import { apiClient } from '../client'
import { PostProfessionalErrorRequest } from 'inheritance-api'

export const callPostProfessionalError = async ({ data }: { data: unknown }): Promise<object | { error: Error }> => {
  try {
    const parsedData = composePostProfessionalErrorRequestData(data)
    const response = await apiClient.postProfessionalError({ data: parsedData })

    return { response, error: undefined }
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}

export const composePostProfessionalErrorRequestData = (data: unknown): PostProfessionalErrorRequest => {
  if (data === null) throw 'invalid log data of null'

  if (typeof data === 'object') {
    return {
      error: data,
    }
  } else {
    return {
      error: { data },
    }
  }
}
