import { getPath } from '@/router'
import { css } from '@emotion/react'
import { Button, Divider, Typography } from 'antd'
import { ProfileTitle } from './profile-parts/ProfileTitle'
import { ChurnStatus } from './profile-parts/ChurnStatus'
import { ProfileChurnStatus, PROFILE_CHURN_STATUS } from '@/share/components/PlacementForm/profileStatus'
import { ProfileAddIcon } from '@/share/components/icons/ProfileIcon'
import { useDesignToken } from '@/share/components/design-system/token'

const useStyles = () => {
  const { themeColor } = useDesignToken()

  return {
    header: css`
      padding: 23px 24px 22px;
    `,
    containerIcon: css`
      display: block;
      font-size: 80px;
      margin: 2px 0 4px;
    `,
    messageIcon: css`
      position: absolute;
      font-size: 32px;
      right: 230px;
      top: -20px;
    `,
    contentBody: css`
      margin: 0 auto;
      max-width: 600px;
      padding-top: 80px;
      text-align: center;
    `,
    buttonWrapper: css`
      padding-top: 20px;
      padding-bottom: 20px;
    `,
    title: css`
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    `,
    tag: css`
      margin-left: 8px;
    `,
    description: css`
      text-align: center;
      color: ${themeColor.text.black};
      line-height: 24px;
      margin-top: 28px;
      margin-bottom: 80px;
      text-align: center;
    `,
    divider: css`
      margin: 0;
    `,
  }
}

type PrimalProps =
  | {
      profileChurnStatus: (typeof PROFILE_CHURN_STATUS)['can-apply']
    }
  | {
      profileChurnStatus: Exclude<ProfileChurnStatus, (typeof PROFILE_CHURN_STATUS)['can-apply']>
      placementEndDate: string
    }

export const Primal = (props: PrimalProps) => {
  const styles = useStyles()
  return (
    <>
      <header css={styles.header}>
        <ProfileTitle kind="primal" churnStatus={props.profileChurnStatus} />
      </header>
      <Divider css={styles.divider} />
      <section css={styles.contentBody}>
        {props.profileChurnStatus === 'can-apply' ? (
          <>
            <ProfileAddIcon />
            <div css={styles.description}>
              <Typography.Paragraph>
                専門家情報公開申し込みボタンより
                <br />
                掲載情報を作成して申請してください。
              </Typography.Paragraph>
            </div>
            <Divider />
            <div css={styles.buttonWrapper}>
              <Button type="primary" href={getPath('placementApplication')}>
                専門家情報公開申し込み
              </Button>
            </div>
          </>
        ) : (
          <ChurnStatus profileChurnStatus={props.profileChurnStatus} placementEndDate={props.placementEndDate} />
        )}
      </section>
    </>
  )
}
