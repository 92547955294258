import { dateStringToDate, DATE_FORMAT } from 'inheritance-utils'
import { DescriptionItemType } from '../generic'
import { ChurnDescriptionValue, ChurnType, ForceChurnType } from './type'

export const churnToItems = (value: ChurnType): DescriptionItemType<ChurnDescriptionValue>[] => {
  let items: DescriptionItemType<ChurnDescriptionValue>[] = []

  if (value.reason) {
    items = [...items, { label: '解約理由', value: value.reason.join('\n') }]
  }

  if (value.endDate) {
    items = [
      {
        label: '掲載終了日',
        value: value?.endDate ? dateStringToDate(value.endDate, DATE_FORMAT['yyyy/M/d']) : '',
      },
      ...items,
    ]
  }

  return items
}

export const forceChurnToItems = (value: ForceChurnType) => {
  return [
    {
      label: '掲載終了日',
      value: value?.endDate ? dateStringToDate(value.endDate, DATE_FORMAT['yyyy/M/d']) : '',
    },
  ]
}
