import { getPath } from '@/router'
import { css } from '@emotion/react'
import { Typography, Button } from 'antd'

const useStyles = () => {
  return {
    contentBody: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      max-width: 600px;
      padding-top: 40px;
    `,
    buttonArea: css`
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;
      gap: 32px;
    `,

    backButton: css`
      margin-bottom: 40px;
    `,
  }
}

export const NotChurnableMessage = () => {
  const styles = useStyles()
  return (
    <section css={styles.contentBody}>
      <Typography.Text>広告掲載開始日から起算して4ヶ月後の月末日までは解約操作はできません。</Typography.Text>
      <Typography.Text>解約希望日の1ヶ月前までにお申し込みください。</Typography.Text>
      <Typography.Text>解約日は解約申し込み日の翌月末となります。</Typography.Text>
      <br />
      <div css={styles.buttonArea}>
        <Button css={styles.backButton} htmlType="button" href={getPath('home')}>
          専門家情報に戻る
        </Button>
      </div>
    </section>
  )
}
