import { Button, Form, Input, Typography, theme } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { getRules, RULES } from '../../form'
export interface SubmitLogin {
  (email: string, password: string): void
}

export interface LoginFormProps {
  onSubmitLogin: SubmitLogin
  toPasswordResetPageLink: string
  loginButtonId: string
}

const useStyle = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken()

  const styles = {
    form: css`
      margin-bottom: 24px;
    `,
    input: css`
      height: 40px;
    `,
    linkWrap: css`
      text-align: center;
    `,
    inlineLink: css`
      display: inline-block;
      color: ${colorPrimary};
      font-size: 16px;
      margin-bottom: 24px;
    `,
    submitButton: css`
      display: block;
      height: 40px;
      width: 100%;
    `,
  }

  return { styles }
}

export const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  interface Values {
    email: string
    password: string
  }
  const onFinish = async (values: Values) => {
    props.onSubmitLogin(values.email, values.password)
  }

  const { styles } = useStyle()

  return (
    <Form name="login" onFinish={onFinish}>
      <Form.Item name="email" css={styles.form} rules={getRules([RULES.requireEntry, RULES.email])}>
        <Input css={styles.input} prefix={<MailOutlined />} placeholder="Eメールアドレス" />
      </Form.Item>

      <Form.Item name="password" css={styles.form} rules={getRules([RULES.requireEntry])}>
        <Input.Password css={styles.input} prefix={<LockOutlined />} placeholder="パスワード" />
      </Form.Item>

      <Form.Item>
        <Button css={(styles.form, styles.submitButton)} type="primary" htmlType="submit" id={props.loginButtonId}>
          ログイン
        </Button>
      </Form.Item>

      <div css={styles.linkWrap}>
        <Typography.Link css={styles.inlineLink} href={props.toPasswordResetPageLink}>
          パスワードをお忘れの方はこちらから
        </Typography.Link>
      </div>
    </Form>
  )
}
