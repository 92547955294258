import { ErrorScreen, LayoutComponent } from './ResultBaseScreen'

export const NotFoundScreen = (props: { Layout: LayoutComponent }) => {
  return (
    <ErrorScreen
      {...props}
      kind="default"
      title="404 Not Found"
      description={[
        '申し訳ありませんが、お探しのページは見つかりませんでした。',
        'お探しのページは削除されたか、URLが変更された可能性がございます。',
      ]}
    />
  )
}
