import { useMemo, useRef, useState } from 'react'
import { getPath } from '@/router'
import { css } from '@emotion/react'
import { Button, Divider, Form, Input, Col, FormInstance, Space, Typography } from 'antd'
import { SingleTextInputForm, FormLabelWithGuideText } from 'inheritance-components'
import {
  ContractApplicationDetails,
  GetProfessionalPlacementDefaultsResponse,
  PutProfessionalPlacementImageResponse,
  ProfessionalCategoryCode,
} from 'inheritance-api'
import { DoubleTextInputForm } from '@/share/components/form/DoubleTextInputForm'
import { SelectAndTextForm } from '@/share/components/form/SelectAndTextForm'
import { CheckboxForm } from '@/share/components/form/CheckboxForm'
import { SelectForm, SelectFormItem } from '@/share/components/form/SelectForm'
import { DummyStyledForm } from '@/share/components/form/DummyStyledForm'
import { ImageSelect } from '@/share/components/ImageSelect/ImageSelect'
import { RepresentativeForm } from '@/share/components/RepresentativeForm/RepresentativeForm'
import {
  IMAGEPROPS,
  affiliationLabelFromString,
  supportAreaMaster,
  PREFECTURE,
  affiliationSelection,
} from 'inheritance-utils'
import { AreaForm } from './AreaForm'
import {
  formProps,
  formNames,
  initialValues as placementInitialValues,
  initialImages as placementInitialImages,
  initialSupportAreaValue as placementinitialSupportAreaValue,
  PlacementFormType,
  SelectedArea,
  AreaOption,
} from './form'
import { ImagePosition } from '@/rpc/professionalPlacementImage/function'
import { SummaryCourtLegalRepresentation } from './SummaryCourtLegalRepresentationForm'
import { RadioForm } from '../form/RadioForm'
import { usePreventBrowserClosing } from '@/share/hooks/usePreventBrowserClosing'
import { useDesignToken } from '../design-system/token'
import { MAX_SUPPORT_AREA_NUMBER } from './functions'
import { AffiliationForm } from './AffiliationForm'
import { ReceptionHoursRelations } from './ReceptionHoursRelations'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { PlacementApplicationStorageHandler } from './functions/storage'
import { UploadImage } from './hooks'
import { DELAY_BEFORE_SAVE } from '@/share/constants/formStorage'
import { ConsultationFeeForm } from './ConsultationFeeForm'

const layout = {
  form: { labelCol: { span: 12 }, wrapperCol: { span: 12 } },
  button: { wrapperCol: { span: 24 } },
}

const useStyles = () => {
  const { color } = useDesignToken()

  return {
    header: css`
      padding: 23px 24px 22px;
    `,
    form: css`
      margin: 0 auto;
      max-width: 858px;
      padding-bottom: 0px;
    `,
    description: css`
      text-align: center;
      padding-top: 40px;
      margin-bottom: 40px;
      background: ${color.background.white};
    `,
    access: css`
      margin-bottom: 40px;
    `,
    groupTitle: css`
      margin-bottom: 16px;
    `,
    groupTitleWithDescription: css`
      margin-bottom: 8px;
    `,
    groupDescription: css`
      margin-bottom: 32px !important; // overwrite antd style
    `,
    textareaItem: css`
      margin-bottom: 24px;
    `,
    toggleItem: css`
      margin-top: 24px;
    `,
    buttonGroup: css`
      padding-bottom: 80px;
      margin-top: 64px;
      text-align: center;
    `,
    formFullWidth: css`
      width: 100% !important;
    `,
    divider: css`
      margin-top: 48px;
    `,
    imagesContainer: css`
      display: flex;
      gap: 0 24px;
    `,
    imageAnnotation: css`
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
    `,
    hidden: css`
      display: none;
    `,
    profileImage: css`
      margin-bottom: 0;
    `,
  }
}

interface FormOptions {
  professionalCategories: { label: string; value: string }[]
  additionalInfo: { label: string; value: string }[]
  supportServices: { label: string; value: string }[]
  areaOption: AreaOption[]
}

type EditFormState = {
  affiliation: string | undefined
  cityOption: SelectFormItem[]
  showOtherArea: boolean
}

export const EditForm = ({
  form,
  formStorage,
  onNext,
  onImageUpload: onCropped,
  uploadingImage,
  formOptions,
  isUpdateView,
  businessCategory,
  loginEmailAddress,
  placementApplicationDefaults,
}: {
  form: FormInstance<PlacementFormType>
  formStorage: PlacementApplicationStorageHandler
  onNext: () => void
  onImageUpload: UploadImage
  uploadingImage: boolean
  formOptions: FormOptions
  isUpdateView: boolean
  businessCategory?: ContractApplicationDetails['businessCategory']
  loginEmailAddress: string
  placementApplicationDefaults: GetProfessionalPlacementDefaultsResponse
}) => {
  usePreventBrowserClosing()

  placementInitialValues(placementApplicationDefaults)

  const storageData = useMemo(() => formStorage.get(loginEmailAddress), [formStorage, loginEmailAddress])

  const initialValues = useMemo(() => {
    if (storageData) {
      return storageData
    }
    return placementInitialValues(placementApplicationDefaults)
  }, [placementApplicationDefaults, storageData])

  const initialImages = useMemo(() => {
    if (storageData) {
      return placementInitialImages({ storageData })
    }
    return placementInitialImages({ placementApplicationDefaults })
  }, [placementApplicationDefaults, storageData])

  const initialSupportAreaValue = useMemo<SelectedArea[]>(() => {
    const areaCodes = storageData
      ? storageData.areas ?? []
      : placementApplicationDefaults.supportArea?.areas?.map((a) => a.areaCode) ?? []
    return placementinitialSupportAreaValue(supportAreaMaster, areaCodes)
  }, [placementApplicationDefaults, storageData])

  const prefectureOption = useMemo<SelectFormItem[]>(
    () => PREFECTURE.map((p) => ({ value: p.code.slice(-2), label: p.name })),
    []
  )
  const [state, setState] = useState<EditFormState>({
    affiliation: initialValues.affiliation,
    cityOption: supportAreaMaster
      .filter((s) => {
        if (storageData) {
          return s.prefectureCode.slice(-2) === storageData.prefecture
        }
        return s.prefectureCode.slice(-2) === placementApplicationDefaults.officeAddress?.prefecture?.prefectureCode
      })
      .map((s) => ({
        value: s.areaCode,
        label: s.areaName,
      })),
    showOtherArea: (() => {
      if (storageData) {
        const suffixCode = storageData?.city?.substring(6 - 4)
        return suffixCode === 'xxxx' ? true : false
      }
      return placementApplicationDefaults.officeAddress?.city?.otherAreaName ? true : false
    })(),
  })

  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined)

  const handleSaveAfterDelay = (values: PlacementFormType) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = undefined
    }

    timeoutId.current = setTimeout(() => {
      formStorage.set(loginEmailAddress, values)
      timeoutId.current = undefined
    }, DELAY_BEFORE_SAVE)
  }

  const handleCropImage = (image: File, imagePosition: ImagePosition) => {
    const onImageUploadSuccess = (data?: PutProfessionalPlacementImageResponse) => {
      if (!data?.placementImageId) return console.error('placementImageId undefined')

      const oldValue = form.getFieldsValue()
      formStorage.set(loginEmailAddress, { ...oldValue, [imagePosition]: data.placementImageId })
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onCropped({ image, imagePosition, onSuccess: onImageUploadSuccess })
  }

  const onDeleteImage = (imagePosition: ImagePosition) => {
    form.setFieldValue(imagePosition, '')
    handleSaveAfterDelay(form.getFieldsValue())
  }

  const imageNotFoundErrorCallbak = (imagePosition: ImagePosition) => {
    onDeleteImage(imagePosition)
    formStorage.set(loginEmailAddress, form.getFieldsValue())
  }

  const onSelectPrefecture = (prefectureCode: string) => {
    const cityOption = supportAreaMaster
      .filter((s) => s.prefectureCode.slice(-2) === prefectureCode)
      .map((s) => ({
        value: s.areaCode,
        label: s.areaName,
      }))
    setState({
      ...state,
      cityOption,
      showOtherArea: false,
    })
    form.setFieldValue(formNames.city, undefined)
    form.setFieldValue(formNames.otherArea, undefined)
    handleSaveAfterDelay(form.getFieldsValue())
  }

  const onSelectCity = (areaCode: string) => {
    const city = supportAreaMaster.find((s) => s.areaCode === areaCode)
    if (!city) throw 'city item not found'
    setState({
      ...state,
      showOtherArea: city.areaName === 'その他',
    })
    form.setFieldValue(formNames.otherArea, undefined)
  }

  const onSelectAffiliation = (affiliation: string) => {
    setState({
      ...state,
      affiliation,
    })
  }

  const professionalCategory = formOptions.professionalCategories.find(
    (category) => category.value === initialValues.professionalCategory
  )
  const professionalCategoryName = professionalCategory?.label ?? ''
  const affiliationLabel = affiliationLabelFromString(professionalCategory?.value ?? '')
  const affiliationPlaceholder =
    affiliationLabel === '所属団体' ? affiliationLabel : affiliationLabel.replace('所属', 'XXX')

  const affiliations = useMemo(() => {
    if (!professionalCategory) return []
    return affiliationSelection(professionalCategory.value as ProfessionalCategoryCode)
  }, [professionalCategory])

  const setRegularHolidayForm = (e: CheckboxValueType[]) => {
    form.setFieldValue(formNames.reception.regularHoliday, e)
  }

  const styles = useStyles()

  return (
    <>
      <div css={styles.description}>
        <Typography.Paragraph>
          入力内容は自動で一時保存されています。
          <br />
          入力後、下部の「申込内容を確認する」ボタンを押してください。
        </Typography.Paragraph>
      </div>
      <Form
        css={styles.form}
        labelCol={layout.form.labelCol}
        onFinish={onNext}
        form={form}
        initialValues={initialValues}
        labelAlign="left"
        scrollToFirstError={true}
        requiredMark={false}
        onValuesChange={(_, values) => handleSaveAfterDelay(values)}
      >
        <Divider />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>掲載情報1</Typography.Title>
        </Col>

        <Form.Item
          hasFeedback
          label={<FormLabelWithGuideText {...formProps['profile1Image']} />}
          colon={formProps['profile1Image'].colon}
          css={styles.profileImage}
        >
          <div css={styles.imagesContainer}>
            <ImageSelect
              imageProps={IMAGEPROPS.PORTRAIT}
              initialPlacementImageId={initialImages.profile1ImageId}
              onCropped={(image) => handleCropImage(image, 'profile1Image')}
              onDelete={() => onDeleteImage('profile1Image')}
              notFoundErrorCallback={() => imageNotFoundErrorCallbak('profile1Image')}
            />
            <p css={styles.imageAnnotation}>
              形式： jpeg または png
              <br />
              サイズ： 240×320px 以上推奨
            </p>
          </div>
        </Form.Item>
        <Form.Item name="profile1Image" hidden>
          <Input />
        </Form.Item>

        <SingleTextInputForm {...formProps['description1']} rootCss={styles.textareaItem} />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>掲載情報2</Typography.Title>
        </Col>

        <Form.Item
          hasFeedback
          label={<FormLabelWithGuideText {...formProps['profile2Image']} />}
          colon={formProps['profile2Image'].colon}
          css={styles.profileImage}
        >
          <div css={styles.imagesContainer}>
            <ImageSelect
              imageProps={IMAGEPROPS.SQUARE}
              initialPlacementImageId={initialImages.profile2ImageId1}
              onCropped={(binary) => handleCropImage(binary, 'profile2Image1')}
              onDelete={() => onDeleteImage('profile2Image1')}
              notFoundErrorCallback={() => imageNotFoundErrorCallbak('profile2Image1')}
            />
            <ImageSelect
              imageProps={IMAGEPROPS.SQUARE}
              initialPlacementImageId={initialImages.profile2ImageId2}
              onCropped={(binary) => handleCropImage(binary, 'profile2Image2')}
              onDelete={() => onDeleteImage('profile2Image2')}
              notFoundErrorCallback={() => imageNotFoundErrorCallbak('profile2Image2')}
            />
            <ImageSelect
              imageProps={IMAGEPROPS.SQUARE}
              initialPlacementImageId={initialImages.profile2ImageId3}
              onCropped={(binary) => handleCropImage(binary, 'profile2Image3')}
              onDelete={() => onDeleteImage('profile2Image3')}
              notFoundErrorCallback={() => imageNotFoundErrorCallbak('profile2Image3')}
            />
            <p css={styles.imageAnnotation}>
              形式： jpeg または png
              <br />
              サイズ：654×654px以上推奨
              <br />
              枚数： 最大 3枚
            </p>
          </div>
        </Form.Item>
        <Form.Item name="profile2Image1" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="profile2Image2" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="profile2Image3" hidden>
          <Input />
        </Form.Item>

        <div css={styles.hidden}>
          <SelectForm {...formProps['professionalCategory']} items={formOptions.professionalCategories} />
        </div>
        <DummyStyledForm {...formProps['professionalCategory']} text={professionalCategoryName} />

        <SingleTextInputForm {...formProps['officeName']} />
        <SingleTextInputForm {...formProps['officeNameKana']} />
        <SingleTextInputForm {...formProps['description2']} rootCss={styles.textareaItem} />
        <SingleTextInputForm {...formProps['detail2']} rootCss={styles.textareaItem} />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>掲載情報3</Typography.Title>
        </Col>

        <Form.Item
          hasFeedback
          label={<FormLabelWithGuideText {...formProps['profile3Image']} />}
          colon={formProps['profile3Image'].colon}
          css={styles.profileImage}
        >
          <div css={styles.imagesContainer}>
            <ImageSelect
              imageProps={IMAGEPROPS.LANDSCAPE}
              initialPlacementImageId={initialImages.profile3ImageId}
              onCropped={(binary) => handleCropImage(binary, 'profile3Image')}
              onDelete={() => onDeleteImage('profile3Image')}
              notFoundErrorCallback={() => imageNotFoundErrorCallbak('profile3Image')}
            />
            <p css={styles.imageAnnotation}>
              形式： jpeg または png
              <br />
              サイズ：654×440px 以上推奨
            </p>
          </div>
        </Form.Item>
        <Form.Item name="profile3Image" hidden>
          <Input />
        </Form.Item>

        <SingleTextInputForm {...formProps['description3']} rootCss={styles.textareaItem} />
        <SingleTextInputForm {...formProps['detail3']} rootCss={styles.textareaItem} />
        <CheckboxForm {...formProps['additionalInfo']} options={formOptions.additionalInfo} />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>基本情報</Typography.Title>
        </Col>

        <SingleTextInputForm {...formProps['phoneNumber']} />
        <RadioForm {...formProps['phoneContactDisabled']} />

        <ReceptionHoursRelations setRegularHolidayForm={setRegularHolidayForm} />

        <SingleTextInputForm {...formProps['access']} rootCss={styles.access} />
        <SingleTextInputForm {...formProps['url']} />
        <DoubleTextInputForm {...formProps['postCode']} />
        <SelectForm
          {...formProps['prefecture']}
          items={prefectureOption}
          formCss={{ width: 'calc(50% - 8px)' }}
          onSelect={(prefectureCode: string | number) => onSelectPrefecture(prefectureCode as string)}
        />
        <SelectAndTextForm
          label={formProps['city'].label}
          select={{
            ...formProps['city'],
            option: state.cityOption,
            onSelect: (areaCode: string | number) => onSelectCity(areaCode as string),
          }}
          text={{
            show: state.showOtherArea,
            ...formProps['otherArea'],
          }}
        />
        <SingleTextInputForm {...formProps['townAndStreetAddress']} />
        <SingleTextInputForm {...formProps['building']} />
        <SingleTextInputForm {...formProps['emailAddress']} />

        <AffiliationForm
          label={affiliationLabel}
          placeholder={affiliationPlaceholder}
          affiliation={state.affiliation}
          affiliations={affiliations}
          onSelect={onSelectAffiliation}
        />

        <RepresentativeForm
          form={form}
          formStorageData={storageData}
          fullForm={false}
          placementApplicationDefaults={placementApplicationDefaults}
          handleRemoveCallback={() => formStorage.set(loginEmailAddress, form.getFieldsValue())}
        />
        {professionalCategory?.value === 'judicialScrivener' && businessCategory && (
          <SummaryCourtLegalRepresentation
            isCorporation={businessCategory === 'corporation'}
            rootCss={styles.toggleItem}
          />
        )}

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4} css={styles.groupTitleWithDescription}>
            対応エリア
          </Typography.Title>
          <Typography.Paragraph
            css={styles.groupDescription}
          >{`都道府県指定は最大${MAX_SUPPORT_AREA_NUMBER}ヶ所まで入力できます。`}</Typography.Paragraph>
        </Col>

        <AreaForm
          form={form}
          areaOption={formOptions.areaOption}
          initialSupportAreaValue={initialSupportAreaValue}
          stateSetterCallback={handleSaveAfterDelay}
        />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>対応サービス</Typography.Title>
        </Col>

        <CheckboxForm {...formProps['supportServices']} options={formOptions.supportServices} />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>料金</Typography.Title>
        </Col>

        <ConsultationFeeForm initialConsultationFeeType={initialValues.consultationFeeType} />
        <SingleTextInputForm {...formProps['mobilizationFee']} rootCss={styles.textareaItem} />
        <SingleTextInputForm {...formProps['reward']} rootCss={styles.textareaItem} />

        <Divider css={styles.divider} />

        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>担当者情報</Typography.Title>
        </Col>

        <DoubleTextInputForm {...formProps['contactName']} />
        <DoubleTextInputForm {...formProps['contactNameKana']} />

        <Form.Item css={styles.buttonGroup} wrapperCol={layout.button.wrapperCol}>
          <Space>
            <Button href={getPath('home')}>キャンセル</Button>
            <Button type="primary" htmlType="button" onClick={form.submit} loading={uploadingImage}>
              {isUpdateView ? '修正内容を確認する' : '申込内容を確認する'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}
