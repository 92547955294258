import { ContentTitleHeader } from '@/share/components/content-headers/ContentTitleHeader/ContentTitleHeader'
import { AppLayout } from '@/share/components/layout/AppLayout'
import { Col, Form, notification, Row, Skeleton } from 'antd'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { ConfirmForm } from './components/ConfirmForm'
import { composeContractApplicationReqModel, convertFormValuesToItemGroups, ContractFormType } from './form'
import { EditForm } from './components/EditForm'
import { css } from '@emotion/react'
import { RecaptchaV2Invisible, getRecaptchaToken, RecaptchaCallbackArg } from 'inheritance-recaptcha'
import { ENV } from '@/config/environment'
import { getPath } from '@/router'
import { useNavigate } from 'react-router-dom'
import { resetRecaptcha } from 'inheritance-recaptcha/src/RecaptchaV2Invisible'
import { useQueryParamsDefinitely } from 'inheritance-router'
import { useEmailTokenValidation } from '@/rpc/professionalContract/useSWR'
import { useProfessionalCategories } from '@/rpc/professionalCategory/useSWR'
import { useRpcContext } from '@/rpc/RpcContext'
import { getErrMsg } from '@/share/error'
import { useHandleRecaptchaError } from '@/share/recaptcha/hook'
import { contractApplicationStorageHandler } from './functions/storage'
import { useAuthContext } from '@/auth/AuthContext'

const styles = {
  fallbackRow: css`
    margin-top: 60px;
  `,
  editForm: (props: { hidden: boolean }) => css`
    display: ${props.hidden ? 'none' : 'block'};
  `,
}

const scrollToTop = () => window.scrollTo({ top: 0 })

const useEffectOnce = (effect: () => void) => {
  const effected = useRef(false)

  useEffect(() => {
    if (effected.current) return
    effect()
    effected.current = true
  }, [effect])
}

const useRedirectByEmailTokenValidation = () => {
  const {
    emailAddress: loginEmailAddress,
    expirationDatetime,
    validationToken,
  } = useQueryParamsDefinitely({
    keys: ['emailAddress', 'expirationDatetime', 'validationToken'],
    errorWhenFailed: new Error('無効なURL、もしくは有効期限切れです'),
  })

  const { result } = useEmailTokenValidation({
    emailAddress: loginEmailAddress,
    expirationDatetime: parseInt(expirationDatetime),
    validationToken: validationToken,
  })

  const navigate = useNavigate()

  /**
   * Eメール検証がOKであれば、通知を開く
   */
  useEffectOnce(() => {
    if (result === 'ok') {
      notification.success({
        message: 'メールアドレスを確認しました',
        description: '利用申込手続きを進めてください',
      })
    }
  })

  useEffect(() => {
    if (result === 'invalid') {
      navigate(getPath('contractApplicationURLExpired'))
    } else if (result === 'loginEmailAddressAlreadyInUse') {
      navigate(getPath('contractApplicationEmailAlreadyInUse'))
    }
  }, [navigate, result])

  return {
    loginEmailAddress,
    expirationDatetime: parseInt(expirationDatetime),
    validationToken,
  }
}

const useCreateContractApplication = () => {
  const navigate = useNavigate()
  const { getAuthenticator } = useAuthContext()
  const authenticator = getAuthenticator()
  if (!authenticator) throw new Error('failed to get authenticator')

  const { callCreateContractApplcation } = useRpcContext()

  const [mutating, setMutating] = useState(false)

  const formStorage = contractApplicationStorageHandler()

  const mutate = async (
    recaptchaToken: string,
    formValues: ContractFormType,
    validation: {
      expirationDatetime: number
      validationToken: string
    }
  ) => {
    setMutating(true)
    const { contractApplication, screeningQuestion, invitationCode } = composeContractApplicationReqModel(formValues)

    const { error } = await callCreateContractApplcation({
      validation: {
        emailAddress: formValues.loginEmailAddress,
        ...validation,
      },
      'g-recaptcha-response': recaptchaToken,
      contractApplication,
      screeningQuestion,
      invitationCode,
    })

    setMutating(false)

    resetRecaptcha()

    if (error) {
      notification.open({
        message: '利用の申し込みに失敗しました',
        description: getErrMsg({ error }),
        placement: 'top',
      })
      return
    }

    formStorage.remove()
    navigate(getPath('contractApplicationCompleted'))
  }

  return { mutating, mutate }
}

const ContractApplicationFormInner = () => {
  const { loginEmailAddress, ...validation } = useRedirectByEmailTokenValidation()
  const { professionalCategories } = useProfessionalCategories()
  const { mutate, mutating } = useCreateContractApplication()
  const [form] = Form.useForm<ContractFormType>()
  const { handleRecaptchaError } = useHandleRecaptchaError()

  const [isConfirm, setIsConfirm] = useState(false)
  const formStorage = contractApplicationStorageHandler()

  const onNext = () => {
    setIsConfirm(true)
    scrollToTop()
  }

  const onBack = () => {
    setIsConfirm(false)
    scrollToTop()
  }

  const onSubmit = async () => {
    const token = await getRecaptchaToken()
    if (!token) {
      // 後続の処理を行わず、callbackを待機
      return
    }
    await mutate(token, form.getFieldsValue(), validation)
  }

  const recaptchaCallback = async (arg: RecaptchaCallbackArg) => {
    if (arg.type === 'verified') {
      await mutate(arg.token, form.getFieldsValue(), validation)
      return
    }
    await handleRecaptchaError(arg.error)
  }

  const itemGroups = useMemo(() => {
    if (!isConfirm) {
      return []
    }

    const values = form.getFieldsValue()

    return convertFormValuesToItemGroups(values, professionalCategories)
  }, [form, isConfirm, professionalCategories])

  return (
    <>
      <section css={styles.editForm({ hidden: isConfirm })}>
        <EditForm
          form={form}
          formStorage={formStorage}
          onNext={onNext}
          loginEmailAddress={loginEmailAddress}
          professionalCategories={professionalCategories.map(({ code, name }) => ({ value: code, label: name }))}
        />
      </section>

      {isConfirm && <ConfirmForm itemGroups={itemGroups} onBack={onBack} onSubmit={onSubmit} mutating={mutating} />}
      <RecaptchaV2Invisible callback={recaptchaCallback} siteKey={ENV.RECAPTCHA_SITE_KEY} />
    </>
  )
}

export const ContractApplicationForm = () => {
  return (
    <AppLayout>
      <ContentTitleHeader title="利用申込フォーム" />
      <Suspense
        fallback={
          <Row css={styles.fallbackRow}>
            <Col offset={2} span={20}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          </Row>
        }
      >
        <ContractApplicationFormInner />
      </Suspense>
    </AppLayout>
  )
}
