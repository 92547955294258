import { ProfessionalCategoryCode } from 'inheritance-api'
import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'

export const useAdditionalInfo = (professionalCategoryCode: ProfessionalCategoryCode) => {
  const { callGetAdditionalInfo } = useRpcContext()
  const { data, error } = useSWR(SWR_KEYS.additionalInfo, () => callGetAdditionalInfo(professionalCategoryCode))
  if (!data) throw Error('Unexpectedly data is undefined')
  if (error) throw error

  return data
}
