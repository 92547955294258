import { useState, useMemo, useRef } from 'react'
import { getPath } from '@/router'
import { DateForm } from '@/share/components/form/DateForm'
import { DoubleTextInputForm } from '@/share/components/form/DoubleTextInputForm'
import { SelectAndTextForm } from '@/share/components/form/SelectAndTextForm'
import { ReadOnlyForm } from '@/share/components/form/ReadOnlyForm'
import { SelectForm, SelectFormItem } from '@/share/components/form/SelectForm'
import { SingleTextInputForm } from 'inheritance-components'
import { RadioForm } from '@/share/components/form/RadioForm'
import { css } from '@emotion/react'
import { Button, Checkbox, Col, Divider, Form, FormInstance, Space, Typography } from 'antd'
import { formProps, ContractFormType, formNames } from '../form'
import { RepresentativeForm } from '@/share/components/RepresentativeForm/RepresentativeForm'
import { Agreements } from '@/share/components/Agreements/Agreements'
import { AGREEMENTPROPS } from '@/share/components/Agreements/AgreementProps'
import {
  EXTERNAL_LINK_URL,
  affiliationLabelFromString,
  BUSINESS_CATEOGRY_OPTION,
  TBusinessCategory,
  PREFECTURE,
  supportAreaMaster,
  toProfessionalCategoryCode,
  toBusinessCategoryCode,
  affiliationSelection,
} from 'inheritance-utils'
import { ProfessionalCategoryCode } from 'inheritance-api'
import { usePreventBrowserClosing } from '@/share/hooks/usePreventBrowserClosing'
import { AffiliationForm } from '@/share/components/PlacementForm/AffiliationForm'
import { ContractApplicationStorageHandler } from '../functions/storage'
import { DELAY_BEFORE_SAVE } from '@/share/constants/formStorage'

const layout = {
  form: { labelCol: { span: 12 }, wrapperCol: { span: 12 } },
  offset: { wrapperCol: { span: 24 } },
  screeningQuestion: { labelCol: { span: 24 }, wrapperCol: { span: 6, offset: 18 } },
}

const styles = {
  form: css`
    margin: 0 auto;
    max-width: 858px;
    padding-bottom: 0px;
  `,
  description: css`
    text-align: center;
    margin-top: 48px;
    margin-bottom: 32px;
  `,
  groupTitle: css`
    margin-bottom: 16px;
  `,
  textareaItem: css`
    margin-bottom: 40px;
  `,
  footnote: css`
    font-size: 12px;
    line-height: 20px;
  `,
  footerDivider: css`
    margin: 24px 0 32px;
  `,
  buttonGroup: css`
    padding-bottom: 32px;
    text-align: center;
  `,
  agreeCheck: css`
    display: inline-block;
    text-align: left;
  `,
  termOfServiceSection: css`
    margin-top: 40px;
    margin-bottom: 0px;
    text-align: center;
  `,
}

type EditFormState = {
  proCategory: ProfessionalCategoryCode | undefined
  bizCategory: TBusinessCategory | undefined
  affiliations: SelectFormItem[]
  affiliation: string | undefined
  affiliationLabel: string
  cityOption: SelectFormItem[]
  showOtherArea: boolean
}

export const EditForm = ({
  form,
  formStorage,
  onNext,
  loginEmailAddress,
  professionalCategories,
}: {
  form: FormInstance<ContractFormType>
  formStorage: ContractApplicationStorageHandler
  onNext: () => void
  loginEmailAddress: string
  professionalCategories: { label: string; value: string }[]
}) => {
  usePreventBrowserClosing()
  const initialValues = useMemo(() => formStorage.get(loginEmailAddress), [formStorage, loginEmailAddress])
  const proCategoryCode = useMemo(
    () => toProfessionalCategoryCode(initialValues?.professionalCategory),
    [initialValues?.professionalCategory]
  )

  const [state, setState] = useState<EditFormState>({
    proCategory: proCategoryCode,
    bizCategory: toBusinessCategoryCode(initialValues?.businessCategory),
    affiliations: proCategoryCode ? affiliationSelection(proCategoryCode) : [],
    affiliation: initialValues?.affiliation,
    affiliationLabel: formProps.affiliation.label,
    cityOption: supportAreaMaster
      .filter((s) => {
        if (initialValues) {
          return s.prefectureCode.slice(-2) === initialValues.prefecture
        }
        return []
      })
      .map((s) => ({
        value: s.areaCode,
        label: s.areaName,
      })),
    showOtherArea: initialValues?.city?.includes('xxxx') ?? false,
  })

  const proCategoryLabel = professionalCategories.find(({ value }) => value === state.proCategory)?.label

  const affiliationPlaceholder = useMemo(() => {
    const label = state.affiliationLabel
    return label === '所属団体' ? label : label.replace('所属', 'XXX')
  }, [state.affiliationLabel])

  const prefectureOption = useMemo<SelectFormItem[]>(
    () => PREFECTURE.map((p) => ({ value: p.code.slice(-2), label: p.name })),
    []
  )

  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined)

  const handleSaveAfterDelay = (values: ContractFormType) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = undefined
    }

    timeoutId.current = setTimeout(() => {
      formStorage.set(loginEmailAddress, values)
      timeoutId.current = undefined
    }, DELAY_BEFORE_SAVE)
  }

  const onAgreed = (agreed: boolean, formName: string) => {
    form.setFieldValue(formName, agreed)
  }

  const validateCorporateName = async () => {
    if (form.getFieldValue([formNames.corporateName])) {
      await form.validateFields([formNames.corporateName])
    }
  }

  const onSelectProCategory = async (maybeCategory: string) => {
    const category = toProfessionalCategoryCode(maybeCategory)
    if (!category) throw new Error('invalid category')
    setState({
      ...state,
      proCategory: category,
      affiliation: undefined,
      affiliations: affiliationSelection(category),
      affiliationLabel: affiliationLabelFromString(category),
    })

    // 専門家カテゴリーが選択された場合、所属団体をリセットする
    form.setFieldValue(formNames.affiliation, undefined)
    form.setFieldValue(formNames.affiliationOther, undefined)
    handleSaveAfterDelay(form.getFieldsValue())

    await validateCorporateName()
  }

  const onSelectBizCategory = async (maybeCategory: string) => {
    setState({ ...state, bizCategory: toBusinessCategoryCode(maybeCategory) })
    await validateCorporateName()
  }

  const onSelectPrefecture = (prefectureCode: string) => {
    const cityOption = supportAreaMaster
      .filter((s) => s.prefectureCode.slice(-2) === prefectureCode)
      .map((s) => ({
        value: s.areaCode,
        label: s.areaName,
      }))
    setState({
      ...state,
      cityOption,
      showOtherArea: false,
    })
    form.setFieldValue(formNames.city, undefined)
    form.setFieldValue(formNames.otherArea, undefined)
    handleSaveAfterDelay(form.getFieldsValue())
  }

  const onSelectCity = (areaCode: string) => {
    const city = supportAreaMaster.find((s) => s.areaCode === areaCode)
    if (!city) throw 'city item not found'
    setState({
      ...state,
      showOtherArea: city.areaName === 'その他',
    })
  }

  const onSelectAffiliation = (affiliation: string) => {
    setState({
      ...state,
      affiliation,
    })
  }

  return (
    <>
      <div css={styles.description}>
        <Typography.Paragraph>
          入力内容は自動で一時保存されています。
          <br />
          入力後、下部の「申込内容を確認する」ボタンを押してください。
        </Typography.Paragraph>
      </div>
      <Form
        css={styles.form}
        labelCol={layout.form.labelCol}
        wrapperCol={layout.form.wrapperCol}
        onFinish={onNext}
        form={form}
        labelAlign="left"
        scrollToFirstError={true}
        requiredMark={false}
        initialValues={initialValues}
        onValuesChange={(_, values) => handleSaveAfterDelay(values)}
      >
        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>事務所情報</Typography.Title>
        </Col>

        <SelectForm
          {...formProps['professionalCategory']}
          items={professionalCategories}
          onSelect={(category: string | number) => onSelectProCategory(category as string)}
        />

        <SelectForm
          {...formProps['businessCategory']}
          items={BUSINESS_CATEOGRY_OPTION.map((b) => b)}
          onSelect={(category: string | number) => onSelectBizCategory(category as string)}
        />

        <SingleTextInputForm
          {...formProps['corporateName']}
          placeholder={formProps['corporateName'].placeholder({ proCategoryLabel, bizCategory: state.bizCategory })}
          rules={formProps['corporateName'].rules({ proCategoryLabel, bizCategory: state.bizCategory })}
        />

        <SingleTextInputForm {...formProps['corporateNameKana']} />

        <DateForm {...formProps['dateOfEstablishment']} />

        <DoubleTextInputForm {...formProps['corporatePostCode']} />

        <SelectForm
          {...formProps['prefecture']}
          items={prefectureOption}
          formCss={{ width: 'calc(50% - 8px)' }}
          onSelect={(prefectureCode: string | number) => onSelectPrefecture(prefectureCode as string)}
        />

        <SelectAndTextForm
          label={formProps['city'].label}
          select={{
            ...formProps['city'],
            option: state.cityOption,
            onSelect: (areaCode: string | number) => onSelectCity(areaCode as string),
          }}
          text={{
            show: state.showOtherArea,
            ...formProps['otherArea'],
          }}
        />

        <SingleTextInputForm {...formProps['townAndStreetAddress']} />
        <SingleTextInputForm {...formProps['corporateBuilding']} />
        <SingleTextInputForm {...formProps['corporateUrl']} />

        <AffiliationForm
          label={state.affiliationLabel}
          placeholder={affiliationPlaceholder}
          affiliation={state.affiliation}
          affiliations={state.affiliations}
          onSelect={onSelectAffiliation}
        />

        <Divider />
        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>代表者情報</Typography.Title>
          <Typography.Text>法人の場合は登記上の代表者情報、個人の場合は本人の情報を入力してください。</Typography.Text>
        </Col>

        <RepresentativeForm
          form={form}
          formStorageData={formStorage.get(loginEmailAddress)}
          fullForm
          handleRemoveCallback={() => formStorage.set(loginEmailAddress, form.getFieldsValue())}
        />

        <Divider />
        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>担当者情報</Typography.Title>
        </Col>

        <DoubleTextInputForm {...formProps['contactName']} />

        <DoubleTextInputForm {...formProps['contactNameKana']} />

        <ReadOnlyForm {...formProps['loginEmailAddress']} value={loginEmailAddress} />

        <SingleTextInputForm {...formProps['loginPhoneNumber']} />

        <SingleTextInputForm {...formProps['contactEmailAddress']} initialValue={loginEmailAddress} />

        <SingleTextInputForm {...formProps['contactPhoneNumber']} />

        <SingleTextInputForm {...formProps['note']} rootCss={styles.textareaItem} />

        <Divider />
        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>紹介コード</Typography.Title>
        </Col>

        <SingleTextInputForm {...formProps['invitationCode']} />

        <Divider />
        <Col css={styles.groupTitle}>
          <Typography.Title level={4}>確認事項</Typography.Title>
        </Col>

        <SingleTextInputForm
          {...formProps['howManyInquiriesAnticipateFromUsersPerYear']({ isForm: true, bizCategory: state.bizCategory })}
          labelCol={layout.screeningQuestion.labelCol}
          wrapperCol={layout.screeningQuestion.wrapperCol}
        />
        <SingleTextInputForm
          {...formProps['howManyProfessionalsMoreThan3YearsOfExperience']({
            isForm: true,
            bizCategory: state.bizCategory,
          })}
          labelCol={layout.screeningQuestion.labelCol}
          wrapperCol={layout.screeningQuestion.wrapperCol}
        />
        <SingleTextInputForm
          {...formProps['howManyCasesHandledSoFar']({ isForm: true, bizCategory: state.bizCategory })}
          labelCol={layout.screeningQuestion.labelCol}
          wrapperCol={layout.screeningQuestion.wrapperCol}
        />
        <RadioForm
          {...formProps['isNotUndertakenAnyLitigationAgainstMUFGBank']({
            isForm: true,
            bizCategory: state.bizCategory,
          })}
          labelCol={layout.screeningQuestion.labelCol}
          wrapperCol={layout.screeningQuestion.wrapperCol}
        />
        {state.proCategory === 'judicialScrivener' && (
          <RadioForm
            {...formProps['isIntendedToHandleSummaryCourtLegalRepresentation']({
              isForm: true,
              bizCategory: state.bizCategory,
            })}
            labelCol={layout.screeningQuestion.labelCol}
            wrapperCol={layout.screeningQuestion.wrapperCol}
          />
        )}
        {state.bizCategory === 'individual' && (
          <RadioForm
            {...formProps['isBusinessOpenOverseasUnderNameOfAdvertiser']({
              isForm: true,
              bizCategory: state.bizCategory,
            })}
            labelCol={layout.screeningQuestion.labelCol}
            wrapperCol={layout.screeningQuestion.wrapperCol}
          />
        )}

        <Typography.Paragraph type="secondary" css={styles.footnote}>
          確認事項の内容は、広告審査の参考資料となります。
          <br />
          ※1ご期待に沿えない可能性はございますが、ご意向確認の為です。
          <br />
          ※2弁護士：遺産分割/遺留分侵害事件受任、税理士：相続税申告、司法書士：相続登記/遺産分割協議作成。
          <br />
          ※3概算の件数で構いません。
          {state.proCategory === 'judicialScrivener' && (
            <>
              <br />
              ※4ご回答が「はい」の場合、広告中に、ご所属の司法書士会の規定・指針等で定める事項（司法書士法第3条第2項第2号で定める認定を受けている旨や特定社員が常駐していること等）の表示が必要になります。
            </>
          )}
        </Typography.Paragraph>

        <Form.Item css={styles.termOfServiceSection} wrapperCol={layout.offset.wrapperCol}>
          <Typography.Paragraph>
            利用申込には、
            <Agreements
              agreementProps={AGREEMENTPROPS.TERMSOFSERVICE}
              onAgreed={(agreed) => onAgreed(agreed, 'termsOfUseAgreement')}
            />
            及び
            <Button type="link" target="_blank" href={EXTERNAL_LINK_URL.kojinjouhou}>
              プライバシーポリシー
            </Button>
            への同意が必要です。
          </Typography.Paragraph>
          <div css={styles.agreeCheck}>
            <Form.Item valuePropName="checked" hasFeedback {...formProps['termsOfUseAgreement']}>
              <Checkbox>利用規約に同意します。</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" hasFeedback {...formProps['privacyPolicy']}>
              <Checkbox>プライバシーポリシーに同意します。</Checkbox>
            </Form.Item>
          </div>
        </Form.Item>

        <Divider css={styles.footerDivider} />

        <Form.Item css={styles.buttonGroup} wrapperCol={layout.offset.wrapperCol}>
          <Space>
            <Button href={getPath('lp')}>キャンセル</Button>
            <Button type="primary" htmlType="button" onClick={form.submit}>
              申込内容を確認する
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}
