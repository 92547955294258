import { Button, Typography } from 'antd'

export const PASSWORD_RESET_SENT_TITLE = 'メールをご確認ください'

export const PasswordResetSentContent = (props: { email?: string; toLoginLink: string }) => {
  return (
    <>
      <Typography.Paragraph type="secondary">
        入力したメールアドレス{props.email && ` ${props.email} `}にメールを送信しました。
        <br />
        メールに記載のURLより、パスワードの再設定を行ってください。
      </Typography.Paragraph>
      <Button type="primary" htmlType="button" href={props.toLoginLink}>
        ログイン画面に戻る
      </Button>
    </>
  )
}
