import useSWR from 'swr'
import { SWR_KEYS } from '../keys'
import { useRpcContext } from '../RpcContext'
import { ServiceInformationSchema } from '../types'

export const useServiceInformation = () => {
  const { callServiceInformation } = useRpcContext()
  const { data } = useSWR(SWR_KEYS.serviceInformation, () => callServiceInformation())
  // データが特定の場所に配置されているときにだけデータが取得できる。なくても特に問題なし。
  if (data) {
    const result = ServiceInformationSchema.safeParse(data)
    if (result.success && data.information.length > 0 && data.information[0].lines.length > 0) {
      // informationは複数受けられる型だが、現状1つしかサポートしない。
      return { serviceInformationLines: data.information[0].lines }
    }
  }

  return { serviceInformationLines: undefined }
}
