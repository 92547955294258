import { createContext, useContext } from 'react'

type TitleLevel = 1 | 2 | 3 | 4 | 5 | undefined

// context が undefined の場合、TitleLevelのデフォルト値を4とする
const DEFAULT_TITLE_LEVEL = 4

type TitleLevelContextProviderProps = {
  children: React.ReactNode
  value: TitleLevel
}

const TitleLevelContext = createContext<TitleLevel>(undefined)

export const TitleLevelContextProvider = ({ children, value }: TitleLevelContextProviderProps) => {
  return <TitleLevelContext.Provider value={value}>{children}</TitleLevelContext.Provider>
}

export const useTitleLevelContext = () => {
  const context = useContext(TitleLevelContext)
  const titleLevel = context === undefined ? DEFAULT_TITLE_LEVEL : context
  return titleLevel
}
