import { GetProfessionalContractValidateResponse } from 'inheritance-api'
import { apiClient } from '../client'
import { buildRethrowingError } from '../buildRethrowingError'

export const callSendEmailForContractApplication = async ({
  emailAddress,
  recaptchaToken,
}: {
  emailAddress: string
  recaptchaToken: string
}): Promise<{ error?: Error }> => {
  try {
    await apiClient.postProfessionalContract({
      emailAddress,
      'g-recaptcha-response': recaptchaToken,
    })
    return {}
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}

export const callValidateEmailToken = async ({
  emailAddress,
  expirationDatetime,
  validationToken,
}: {
  emailAddress: string
  expirationDatetime: number
  validationToken: string
}): Promise<GetProfessionalContractValidateResponse> => {
  try {
    const { data } = await apiClient.getProfessionalContractValidate({
      emailAddress: emailAddress,
      expirationDatetime: expirationDatetime,
      validationToken: validationToken,
    })

    return data
  } catch (e) {
    throw buildRethrowingError(e)
  }
}

export const callCreateContractApplcation = async (
  ...[data]: Parameters<typeof apiClient.putProfessionalContract>
): Promise<{ error?: Error }> => {
  try {
    await apiClient.putProfessionalContract(data)
    return {}
  } catch (e) {
    return { error: buildRethrowingError(e) }
  }
}
