import { ReactNode, JSX } from 'react'
import { createBrowserRouter as _createBrowserRouter } from 'react-router-dom'

type Routes<RouteKey extends string> = {
  [key in RouteKey]: {
    path: string
    element: JSX.Element
  }
}

export interface ElementWrapperProp {
  children: ReactNode
  isPublic: boolean
  isLoginPage: boolean
  isPasswordResetConfirmPage: boolean
  isCreateAccountConfirmPage: boolean
}

export const LoginPageKey = 'login'
export const PasswordResetConfirmPageKey = 'passwordResetConfirm'
export const CreateAccountConfirmPageKey = 'createAccountConfirm'

export const createBrowserRoutes = <PublicKey extends string, AuthKey extends string>(
  publicRoutes: Routes<PublicKey>,
  authRoutes: Routes<AuthKey>,
  ElementWrapper: (props: ElementWrapperProp) => JSX.Element
): Parameters<typeof _createBrowserRouter>[0] => {
  const routes = { ...publicRoutes, ...authRoutes }

  return (Object.keys(routes) as (keyof typeof routes)[]).map((key) => {
    const isPublic = Object.keys(publicRoutes).includes(key)
    return {
      ...routes[key],
      element: (
        <ElementWrapper
          isPublic={isPublic}
          isLoginPage={key === LoginPageKey}
          isPasswordResetConfirmPage={key === PasswordResetConfirmPageKey}
          isCreateAccountConfirmPage={key === CreateAccountConfirmPageKey}
        >
          {routes[key].element}
        </ElementWrapper>
      ),
    }
  })
}

export const createBrowserRouter = (...params: Parameters<typeof createBrowserRoutes>) => {
  return _createBrowserRouter(createBrowserRoutes(...params))
}
