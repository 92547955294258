import { Typography } from 'antd'
import { GenericDescriptionList } from './GenericDescriptionList'
import { DescriptionItemGroupType, DescriptionItemGroupTypeWithOrder, RenderDescriptionItem } from './type'
import { useTitleLevelContext } from '../contexts/TitleLevelProvider'

type GenericDescriptionGroupProps<ValueType> = {
  group: DescriptionItemGroupType<ValueType> | DescriptionItemGroupTypeWithOrder<ValueType>
  isDiff?: boolean
  renderDescriptionItem: RenderDescriptionItem<ValueType>
  needsHighlightForDiff?: (value: ValueType) => boolean
}

export const GenericDescriptionGroup = <ValueType,>({
  group: { title: groupTitle, items: groupItems },
  isDiff = false,
  ...props
}: GenericDescriptionGroupProps<ValueType>) => {
  const titleLevel = useTitleLevelContext()
  return (
    <>
      <Typography.Title level={titleLevel}>{groupTitle}</Typography.Title>
      <GenericDescriptionList
        items={groupItems}
        isDiff={isDiff}
        renderDescriptionItem={props.renderDescriptionItem as RenderDescriptionItem<ValueType>}
        needsHighlightForDiff={props.needsHighlightForDiff}
      />
    </>
  )
}
