export const IMAGEPROPS = {
  PORTRAIT: {
    aspect: 0.75,
    maxWidth: 240,
    maxHeight: 320,
    thumbnailWidth: 64,
    thumbnailHeight: 85,
  },
  SQUARE: {
    aspect: 1,
    maxWidth: 1200,
    maxHeight: 1200,
    thumbnailWidth: 64,
    thumbnailHeight: 64,
  },
  LANDSCAPE: {
    aspect: 1.5,
    maxWidth: 1500,
    maxHeight: 1000,
    thumbnailWidth: 96,
    thumbnailHeight: 64,
  },
} as const
export type ImageProps = (typeof IMAGEPROPS)[keyof typeof IMAGEPROPS]

export type SupportAreaMaster = {
  areaCode: string
  prefectureCode: string
  areaName: string
}
