import { getPath } from '@/router'
import { useDesignToken } from '@/share/components/design-system/token'
import { MailOutlined } from '@ant-design/icons'
import { css, SerializedStyles } from '@emotion/react'
import { Button, Form, Input, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import { getRules, RULES } from 'inheritance-components'

const useStyles = () => {
  const { color, themeColor } = useDesignToken()

  return {
    center: css`
      text-align: center;
      background: ${color.background.white};
      border-radius: 8px;
      padding: 32px 24px 6px;
    `,
    title: css`
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    `,
    form: css`
      margin-top: 16px;
    `,
    formItem: css`
      width: 100%;
    `,
    link: css`
      display: block;
      margin-bottom: 16px;
      a {
        color: ${themeColor.text.primary} !important;
      }
    `,
  }
}

const formProps = {
  name: 'email',
  placeholder: 'email address',
  rules: getRules([RULES.requireEntry, RULES.email, RULES.emailMaxLen]) as Rule[],
}
export type FormType = {
  email: string
}

export type EmailFormProps = {
  email: string
  onEmailChange: (value: string) => void
  onSubmit: (value: string) => Promise<void>
  mutating: boolean
}

type Props = {
  rootCss?: SerializedStyles
  showInquiryLink?: boolean
} & EmailFormProps

export const EmailForm = (props: Props) => {
  const [form] = Form.useForm<FormType>()

  const onFinish = async () => {
    await props.onSubmit(props.email)
  }

  const styles = useStyles()

  return (
    <div css={[styles.center, props.rootCss]}>
      <Typography.Text css={styles.title}>
        そうぞくガイドへの掲載をご希望の方は、
        <br />
        メールアドレスを入力しお申し込みください
      </Typography.Text>

      <Form form={form} css={styles.form} onFinish={onFinish}>
        <Form.Item hasFeedback name="email" rules={formProps.rules}>
          <Input
            css={styles.formItem}
            prefix={<MailOutlined />}
            placeholder={formProps.placeholder}
            value={props.email}
            onChange={(e) => props.onEmailChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button css={styles.formItem} type="primary" htmlType="submit" loading={props.mutating}>
            申し込む
          </Button>
        </Form.Item>
      </Form>

      <Typography.Text css={styles.link}>
        アカウントをお持ちの場合：
        <Typography.Link type="secondary" href={getPath('login')}>
          ログイン
        </Typography.Link>
      </Typography.Text>
      {props.showInquiryLink && (
        <Typography.Text css={styles.link}>
          広告掲載に関するお問い合わせ：
          <Typography.Link type="secondary" href="mailto:sozoku_guide_pf@mufg.jp">
            こちら
          </Typography.Link>
        </Typography.Text>
      )}
    </div>
  )
}
